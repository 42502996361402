export function mixinData(source = {}) {
  const target = {};
  for (const key in source) {
    if (key.startsWith('$.')) {
      const path = key.substring(2).split(/[.[]/);
      let assignTarget = target;
      for (let i = 0; i < path.length; i++) {
        let component = path[i];
        if (component.endsWith(']')) {
          component = parseInt(
            component.substring(0, component.length - 1),
            10
          );
        }
        if (i === path.length - 1) {
          assignTarget[component] = source[key];
        } else {
          if (assignTarget[component] === undefined) {
            assignTarget[component] = typeof path[i + 1] === 'number' ? [] : {};
          }
          assignTarget = assignTarget[component];
        }
      }
    } else {
      target[key] = source[key];
    }
  }
  return target;
}
