<template>
  <div>
    <div class="mb-2">
      <v-card class="new-element-border-radius" outlined>
        <div :class="notEditable ? '' : 'clickable'" :v-ripple="!notEditable">
          <v-list-item three-line>
            <v-avatar
              class="mr-5"
              style="cursor: pointer"
              color="#6E6E6E"
              size="30"
            >
              <span class="white--text headline">5</span>
            </v-avatar>
            <v-list-item-content
              :class="
                $vuetify.breakpoint.xs
                  ? 'checkbox_title_xs'
                  : 'checkbox_title_default'
              "
            >
              {{ $t('technical_inspection.connection_data') }}
            </v-list-item-content>
            <v-list-item-action class="ma-0" style="align-self: center">
              <v-checkbox
                color="#9B004B"
                v-model="checkboxState"
                @click.stop=""
                :disabled="disableButton() || notEditable"
              >
              </v-checkbox>
            </v-list-item-action>
          </v-list-item>
        </div>

        <div class="dropdowns_container">
          <div
            v-if="
              this.selectedOrder.product.includes('electricity') ||
              this.selectedOrder.product.includes('eMobility')
            "
            class="connection_data"
          >
            <montage-strom-icon class="mr-2" />
            <div class="title_dropdown_container">
              <div class="title_connection">
                <p>Strom</p>
              </div>
              <div class="dropdown_container">
                <div class="drop_down">
                  <v-select
                    :disabled="notEditable"
                    :items="connectionDataElectricity[0].items"
                    :label="connectionDataElectricity[0].title"
                    :item-text="connectionDataElectricity[0].items.text"
                    outlined
                    v-model="measuring_level"
                    class="select_box"
                  ></v-select>
                </div>
                <div class="drop_down">
                  <v-select
                    :disabled="notEditable"
                    :items="connectionDataElectricity[1].items"
                    :label="connectionDataElectricity[1].title"
                    :item-text="connectionDataElectricity[1].items.text"
                    outlined
                    v-model="measurement_voltage"
                    class="select_box"
                  ></v-select>
                </div>
                <div class="drop_down">
                  <v-select
                    :disabled="notEditable"
                    :items="connectionDataElectricity[2].items"
                    :label="connectionDataElectricity[2].title"
                    :item-text="connectionDataElectricity[2].items.text"
                    outlined
                    v-model="voltage_level"
                    class="select_box"
                  ></v-select>
                </div>
                <div class="drop_down">
                  <v-select
                    :disabled="notEditable"
                    :items="connectionDataElectricity[3].items"
                    :label="connectionDataElectricity[3].title"
                    :item-text="connectionDataElectricity[3].items.text"
                    outlined
                    v-model="ownership_limit_electricity"
                    class="select_box"
                  ></v-select>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="this.selectedOrder.product.includes('gas')"
            class="connection_data ml-3"
          >
            <montage-gas-icon class="mr-2" />
            <div class="title_dropdown_container">
              <div class="title_connection">
                <p>Gas</p>
              </div>
              <div class="dropdown_container">
                <div class="drop_down">
                  <v-select
                    :disabled="notEditable"
                    :items="connectionDataGas[0].items"
                    :label="connectionDataGas[0].title"
                    :item-text="connectionDataGas[0].items.text"
                    outlined
                    v-model="withdrawal_pressure"
                    class="select_box"
                  ></v-select>
                </div>
                <div class="drop_down">
                  <v-select
                    :disabled="notEditable"
                    :items="connectionDataGas[1].items"
                    :label="connectionDataGas[1].title"
                    :item-text="connectionDataGas[1].items.text"
                    outlined
                    v-model="pressure_level"
                    class="select_box"
                  ></v-select>
                </div>
                <div class="drop_down">
                  <v-select
                    :disabled="notEditable"
                    :items="connectionDataGas[2].items"
                    :label="connectionDataGas[2].title"
                    :item-text="connectionDataGas[2].items.text"
                    outlined
                    v-model="ownership_limit_gas"
                    class="select_box"
                  ></v-select>
                </div>
                <div class="drop_down">
                  <v-select
                    :disabled="notEditable"
                    :items="connectionDataGas[3].items"
                    :label="connectionDataGas[3].title"
                    :item-text="connectionDataGas[3].items.text"
                    outlined
                    v-model="gas_group"
                    class="select_box"
                  ></v-select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import MontageStromIcon from '../../icons/MontageStromIcon';
import MontageGasIcon from '../../icons/MontageGasIcon';

export default {
  name: 'ConnectionDataComponent',
  components: {
    MontageStromIcon,
    MontageGasIcon,
  },
  props: {
    notEditable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    connectionDataElectricity: [
      {
        title: 'Messebene',
        items: [
          { text: 'NS-NS', value: 'Niederspannung (NS-NS)' },
          { text: 'MS_NS', value: 'Niederspannung MS_NS' },
          { text: 'MS-MS', value: 'Mittelspannung MS-MS' },
          { text: 'USP-NS', value: 'Niederspannung USP-NS' },
        ],
      },
      {
        title: 'Messspannung',
        items: [
          { text: '0,4 kV', value: '0,4 kV' },
          { text: '10 kV', value: '10 kV' },
          { text: '20 kV', value: '20 kV' },
        ],
      },
      {
        title: 'Spannungsebene',
        items: [
          { text: '0,4 kV', value: '0,4 kV' },
          { text: '10 kV', value: '10 kV' },
          { text: '20 kV', value: '20 kV' },
          { text: '0,4 kV Umsp.', value: '0,4 kV Umsp.' },
          { text: '10 kV Hochsp./MSP', value: '10 kV Hochsp./MSP' },
          { text: '20 kV Hochspa./MSP', value: '20 kV Hochspa./MSP' },
        ],
      },
      {
        title: 'Eigentumsgrenze',
        items: [
          {
            text: 'Hausanschlusssicherung',
            value:
              'Abgangsklemmen der Netzanschlusssicherung im Hausanschlusskasten',
          },
          {
            text: 'Kabelendverschluss',
            value:
              'Kabelverschlüsse der in der Kundenanlage ankommenden Mittelspannungskabel des Netzbetreibers',
          },
        ],
      },
    ],
    connectionDataGas: [
      {
        title: 'Entnahmedruck',
        items: [
          { text: '23 mbar', value: 'hinter dem Druckregelgerät 23 mbar' },
          {
            text: 'variabel',
            value: 'hinter dem Druckreglergerät mbar variabel',
          },
          { text: 'ohne', value: 'ohne Druckreglergerät' },
        ],
      },
      {
        title: 'Druckebene Netzanschluss',
        items: [
          { text: 'ND', value: 'ND(<= 100 mbar)' },
          { text: 'MD', value: 'MD(> 100 mbar bis <= 1000 mbar)' },
          { text: 'HD', value: 'HD(> 1000 mbar)' },
        ],
      },
      {
        title: 'Eigentumsgrenze',
        items: [
          {
            text: 'Ausgangsseite',
            value:
              'Ausgansseite der Hauptsperreinrichtung; Hauptdruckregler ist Bestandteil der Gasanlage',
          },
          {
            text: 'Eingangsflansch',
            value: 'vor dem Eingangsflansch in der Gasdruckregelanlage',
          },
          {
            text: 'Ausgangsflansch',
            value: 'hinter dem Ausgangsflansch in der Gasdruckregelanlage',
          },
        ],
      },
      {
        title: 'Gasgruppe',
        items: [
          { text: 'L-Gas', value: 'L-Gas' },
          { text: 'H-Gas', value: 'H-Gas' },
        ],
      },
    ],
    checkbox_state: false,
  }),
  computed: {
    allDataElectricitySelected() {
      return this.$store.getters.getIfConnectionDataElectricitySelected;
    },
    allDataGasSelected() {
      return this.$store.getters.getIfConnectionDataGasSelected;
    },
    selectedOrder() {
      return this.$store.getters.getSelectedOrder;
    },
    checkboxState: {
      get() {
        return this.$store.getters.getTechnicalInspectionData
          .connection_data_checked;
      },
      set(value) {
        this.$store.commit('setconnectiondataSelected', value);
      },
    },
    measuring_level: {
      get() {
        return this.$store.getters.getTechnicalInspectionData
          .connection_data_electricity.measuring_level;
      },
      set(value) {
        this.$store.commit('setMeasuringLevel', value);
      },
    },
    measurement_voltage: {
      get() {
        return this.$store.getters.getTechnicalInspectionData
          .connection_data_electricity.measurement_voltage;
      },
      set(value) {
        this.$store.commit('setMeasurementVoltage', value);
      },
    },
    voltage_level: {
      get() {
        return this.$store.getters.getTechnicalInspectionData
          .connection_data_electricity.voltage_level;
      },
      set(value) {
        this.$store.commit('setVoltageLevel', value);
      },
    },
    ownership_limit_electricity: {
      get() {
        return this.$store.getters.getTechnicalInspectionData
          .connection_data_electricity.ownership_limit_electricity;
      },
      set(value) {
        this.$store.commit('setOwnershipLimitElectricity', value);
      },
    },
    withdrawal_pressure: {
      get() {
        return this.$store.getters.getTechnicalInspectionData
          .connection_data_gas.withdrawal_pressure;
      },
      set(value) {
        this.$store.commit('setWithdrawalPressure', value);
      },
    },
    pressure_level: {
      get() {
        return this.$store.getters.getTechnicalInspectionData
          .connection_data_gas.pressure_level;
      },
      set(value) {
        this.$store.commit('setPressureLevel', value);
      },
    },
    ownership_limit_gas: {
      get() {
        return this.$store.getters.getTechnicalInspectionData
          .connection_data_gas.ownership_limit_gas;
      },
      set(value) {
        this.$store.commit('setOwnershipLimitGas', value);
      },
    },
    gas_group: {
      get() {
        return this.$store.getters.getTechnicalInspectionData
          .connection_data_gas.gas_group;
      },
      set(value) {
        this.$store.commit('setGasGroup', value);
      },
    },
  },
  methods: {
    disableButton() {
      if (
        this.selectedOrder.product.includes('electricity') ||
        this.selectedOrder.product.includes('eMobility')
      ) {
        if (this.allDataElectricitySelected === true) {
          return false;
        } else return true;
      }
      if (this.selectedOrder.product.includes('gas')) {
        if (this.allDataGasSelected === true) {
          return false;
        } else return true;
      }
      if (
        this.selectedOrder.product.includes('electricity') &&
        this.selectedOrder.product.includes('gas')
      ) {
        if (
          this.allDataElectricitySelected === true &&
          this.allDataGasSelected === true
        ) {
          return false;
        } else return true;
      }
    },
  },
  watch: {
    allDataElectricitySelected(newValue) {
      if (!newValue) {
        this.checkboxState = false;
      } else if (
        newValue &&
        this.allDataGasSelected &&
        this.selectedOrder.product.includes('electricity') &&
        this.selectedOrder.product.includes('gas')
      ) {
        this.checkboxState = true;
      } else if (
        newValue &&
        this.selectedOrder.product.includes('electricity') &&
        !this.selectedOrder.product.includes('gas')
      ) {
        this.checkboxState = true;
      } else if (newValue && this.selectedOrder.product.includes('eMobility')) {
        this.checkboxState = true;
      }
    },
    allDataGasSelected(newValue) {
      if (!newValue) {
        this.checkboxState = false;
      } else if (
        newValue &&
        this.allDataElectricitySelected &&
        this.selectedOrder.product.includes('electricity') &&
        this.selectedOrder.product.includes('gas')
      ) {
        this.checkboxState = true;
      } else if (
        newValue &&
        this.selectedOrder.product.includes('gas') &&
        !this.selectedOrder.product.includes('electricity')
      ) {
        this.checkboxState = true;
      }
    },
  },
};
</script>

<style>
.dropdown_container {
  display: flex;
  flex-direction: column;
}
.clickable {
  cursor: pointer;
  box-shadow: 0px 1px 2px #00000029;
  position: relative;
}
.title_dropdown_container {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}

.checkbox_title_default {
  font-size: 20px;
  line-height: 24px;
}
.checkbox_title_xs {
  font-size: 18px;
  line-height: 21px;
}
.drop_down {
  display: flex;
  flex-direction: row;
}
.connection_data {
  padding: 10px;
  display: flex;
}
.title_connection {
  display: flex;
  flex-direction: row;
  font-weight: bold;
  margin-top: 5px;
}
.dropdowns_container {
  display: flex;
  margin-top: 10px;
}
.select_box {
  max-width: 250px;
}
.v-input__slot {
  margin: 0 !important;
}
</style>
