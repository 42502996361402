import GraphQLService from './GraphQLService';

export default {
  async getProductAvailability(requestBody) {
    return GraphQLService.requestWithOpenID(
      `
      query Products($lat: Float, $lon: Float) { 
        getProductAvailability(lat: $lat, lon: $lon)
      }`,
      {
        lat: requestBody.lat,
        lon: requestBody.lng,
      }
    );
  },
};
