import i18n from '../../plugins/i18n';

export const translatePVOrderStatus = (order_status) => {
  switch (order_status) {
    case 'ORDER_CREATED':
      return [i18n.t('order_statuses.count_pv_order_created'), '#D79BB8'];
    case 'CRITICAL_ORDER_CREATED':
      return [i18n.t('feed_in_statuses.CRITICAL_ORDER_CREATED'), '#D79BB8'];
    case 'ORDER_TECHNICAL_FEASIBILITY':
      return [
        i18n.t('order_statuses.count_pv_order_technical_feasibility'),
        '#CF8AA9',
      ];
    case 'ORDER_CONFIRMED':
      return [i18n.t('order_statuses.count_pv_order_confirmed'), '#C7799A'];
    case 'ORDER_IN_IMPLEMENTATION':
      return [
        i18n.t('order_statuses.count_pv_order_in_implementation'),
        '#BF678B',
      ];
    case 'ORDER_IN_IMPLEMENTATION_INSPECTION':
      return [
        i18n.t('order_statuses.count_pv_order_in_implementation_inspection'),
        '#B7567B',
      ];
    case 'ORDER_IN_IMPLEMENTATION_CONFIRMED':
      return [
        i18n.t('order_statuses.count_pv_order_in_implementation_confirmed'),
        '#B7567B',
      ];
    case 'ORDER_FULFILLED':
      return [i18n.t('order_statuses.count_pv_order_fulfilled'), '#AE436C'];
    case 'ORDER_FULFILLED_INSPECTION':
      return [
        i18n.t('order_statuses.count_pv_order_fulfilled_inspection'),
        '#AE436C',
      ];
    case 'ORDER_FULFILLED_CONFIRMED':
      return [
        i18n.t('order_statuses.count_pv_order_fulfilled_confirmed'),
        '#9B144C',
      ];
    case 'ORDER_TRANSMITTED':
      return [i18n.t('order_statuses.count_pv_order_transmitted'), '#9b0040'];
    case 'ORDER_DECLINED':
      return [i18n.t('order_statuses.ORDER_DECLINED'), '#525252'];
    case 'ORDER_CANCELLED':
      return [i18n.t('order_statuses.ORDER_CANCELLED'), '#ffffff'];
    default:
      return [];
  }
};
