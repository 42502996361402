export const emptyConnectionDataElectricity = {
  measuring_level: '',
  measurement_voltage: '',
  voltage_level: '',
  ownership_limit_electricity: '',
};
export const emptyConnectionDataGas = {
  withdrawal_pressure: '',
  pressure_level: '',
  ownership_limit_gas: '',
  gas_group: '',
};

export const emptyChargingPowerValues = {
  emobility_charging_power_inspection: '',
  emobility_charging_power_custom_inspection: '',
};

export const technicalInspectionModule = {
  state: {
    customer_information_checked: false,
    blueprints_checked: false,
    emobility_charging_power_checked: false,
    gis_checked: false,
    address_checked: false,
    connection_data_checked: false,
    status: '',
    connection_data_electricity: emptyConnectionDataElectricity,
    connection_data_gas: emptyConnectionDataGas,
    emobility_charging_power_values: emptyChargingPowerValues,
  },

  mutations: {
    setcustomerInformationSelected(state, customer_information_checked) {
      state.customer_information_checked = customer_information_checked;
    },
    setblueprintsSelected(state, blueprints_checked) {
      state.blueprints_checked = blueprints_checked;
    },
    setgisSelected(state, gis_checked) {
      state.gis_checked = gis_checked;
    },
    setaddressSelected(state, address_checked) {
      state.address_checked = address_checked;
    },
    setconnectiondataSelected(state, connection_data_checked) {
      state.connection_data_checked = connection_data_checked;
    },
    resetChecksForTechnicalInspection(state) {
      state.customer_information_checked = false;
      state.blueprints_checked = false;
      state.gis_checked = false;
      state.address_checked = false;
      state.connection_data_checked = false;
      state.connection_data_electricity = emptyConnectionDataElectricity;
      state.connection_data_gas = emptyConnectionDataGas;
    },
    setMeasuringLevel(state, measuring_level) {
      state.connection_data_electricity.measuring_level = measuring_level;
    },
    setVoltageLevel(state, voltage_level) {
      state.connection_data_electricity.voltage_level = voltage_level;
    },
    setMeasurementVoltage(state, measurement_voltage) {
      state.connection_data_electricity.measurement_voltage =
        measurement_voltage;
    },
    setOwnershipLimitElectricity(state, ownership_limit_electricity) {
      state.connection_data_electricity.ownership_limit_electricity =
        ownership_limit_electricity;
    },
    setWithdrawalPressure(state, withdrawal_pressure) {
      state.connection_data_gas.withdrawal_pressure = withdrawal_pressure;
    },
    setPressureLevel(state, pressure_level) {
      state.connection_data_gas.pressure_level = pressure_level;
    },
    setOwnershipLimitGas(state, ownership_limit_gas) {
      state.connection_data_gas.ownership_limit_gas = ownership_limit_gas;
    },
    setGasGroup(state, gas_group) {
      state.connection_data_gas.gas_group = gas_group;
    },
    setConnectionDataGas(state, connection_data_gas) {
      state.connection_data_gas = connection_data_gas;
    },
    setConnectionDataElectricity(state, connection_data_electricity) {
      state.connection_data_electricity = connection_data_electricity;
    },
    setTechnicalChargingPowerSelected(state, emobility_charging_power_checked) {
      state.emobility_charging_power_checked = emobility_charging_power_checked;
    },
    setTechnicalChargingPowerInspection(
      state,
      emobility_charging_power_inspection
    ) {
      state.emobility_charging_power_values.emobility_charging_power_inspection =
        emobility_charging_power_inspection;
    },
    setTechnicalChargingStationCustomValueInspection(
      state,
      emobility_charging_power_custom_inspection
    ) {
      state.emobility_charging_power_values.emobility_charging_power_custom_inspection =
        emobility_charging_power_custom_inspection;
    },
    setTechnicalChargingPowerValues(state, values) {
      state.emobility_charging_power_values = values;
    },
  },
  getters: {
    getcustomerInformationSelected: (state) => {
      return state.customer_information_checked;
    },
    getblueprintsSelected: (state) => {
      return state.blueprints_checked;
    },
    getgisSelected: (state) => {
      return state.gis_checked;
    },
    getaddressSelected: (state) => {
      return state.address_checked;
    },
    getconnectiondataSelected: (state) => {
      return state.connection_data_checked;
    },
    getIfAllDataSelected: (state, getters) => {
      let changing_station_custom_selected = getters.getSelectedOrder
        .emobility_payload
        ? getters.getSelectedOrder.emobility_payload &&
          !!state.emobility_charging_power_values
            .emobility_charging_power_inspection &&
          state.emobility_charging_power_checked
        : true;
      if (
        !!getters.getSelectedOrder.electric_installations &&
        state.emobility_charging_power_values
          .emobility_charging_power_inspection === 'charging_station_custom' &&
        !state.emobility_charging_power_values
          .emobility_charging_power_custom_inspection
      ) {
        changing_station_custom_selected = false;
      }
      return (
        state.customer_information_checked &&
        state.blueprints_checked &&
        state.gis_checked &&
        state.address_checked &&
        state.connection_data_checked &&
        changing_station_custom_selected
      );
    },
    getTechnicalInspectionData: (state, getters) => {
      const order = getters.getSelectedOrder;
      const data = {
        customer_information_checked: state.customer_information_checked,
        blueprints_checked: state.blueprints_checked,
        gis_checked: state.gis_checked,
        address_checked: state.address_checked,
        connection_data_checked: state.connection_data_checked,
        connection_data_electricity: state.connection_data_electricity,
        connection_data_gas: state.connection_data_gas,
      };
      if (order.emobility_payload) {
        data.emobility_charging_power_checked =
          state.emobility_charging_power_checked;
        data.emobility_charging_power_values =
          state.emobility_charging_power_values
            ? state.emobility_charging_power_values
            : {
                emobility_charging_power_inspection: '',
                emobility_charging_power_custom_inspection: '',
              };
      }
      return data;
    },
    getMeasuringLevel: (state) => {
      return state.connection_data_electricity.measuring_level;
    },
    getMeasurementVoltage: (state) => {
      return state.connection_data_electricity.measurement_voltage;
    },
    getVoltageLevel: (state) => {
      return state.connection_data_electricity.voltage_level;
    },
    getOwnershipLimitElectricity: (state) => {
      return state.connection_data_electricity.ownership_limit_electricity;
    },
    getIfConnectionDataElectricitySelected: (state) => {
      const valueSelected =
        state.connection_data_electricity.measuring_level !== '' &&
        state.connection_data_electricity.measurement_voltage !== '' &&
        state.connection_data_electricity.voltage_level !== '' &&
        state.connection_data_electricity.ownership_limit_electricity !== '';
      return valueSelected;
    },
    getWithdrawalPressure: (state) => {
      return state.connection_data_gas.withdrawal_pressure;
    },
    getPressureLevel: (state) => {
      return state.connection_data_gas.pressure_level;
    },
    getOwnershipLimitGas: (state) => {
      return state.connection_data_gas.ownership_limit_gas;
    },
    getGasGroup: (state) => {
      return state.connection_data_gas.gas_group;
    },
    getIfConnectionDataGasSelected: (state) => {
      const valueSelected =
        state.connection_data_gas.withdrawal_pressure !== '' &&
        state.connection_data_gas.pressure_level !== '' &&
        state.connection_data_gas.ownership_limit_gas !== '' &&
        state.connection_data_gas.gas_group !== '';
      return valueSelected;
    },
    getTechnicalIfDataChargingPowerSelected: (state) => {
      const valueSelected = state.emobility_charging_power_values
        .emobility_charging_power_inspection
        ? true
        : false;
      return valueSelected;
    },
  },
};
