<template>
  <div class="full_width">
    <v-btn class="refresh-button" @click="refreshOrders()">
      <v-icon color="#6E6E6E">mdi-sync</v-icon>
    </v-btn>
    <div class="table-style">
      <div class="table-header">
        <div class="pill-radio-buttons-div">
          <PillRadioButtons
            :selectedValue="
              Array.isArray(statusSelection) ? 'all' : statusSelection
            "
            :itemsList="statusValuesList"
            @update-selected-value="statusSelection = $event"
          />
        </div>
        <div class="pill-radio-buttons-div">
          <PillRadioButtons
            :selectedValue="regionalTeamSelection"
            :itemsList="regionalTeamList"
            @update-selected-value="regionalTeamSelection = $event"
          />
        </div>
        <div class="pill-radio-buttons-div">
          <v-text-field
            rounded
            outlined
            dense
            full-width
            v-model="searchValue"
            prepend-inner-icon="mdi-magnify"
            @input="elasticSearchOnBackend"
            label="Suche"
            single-line
            hide-details
          />
        </div>
      </div>
      <v-data-table
        disable-sort
        :headers="headersAktive"
        :items="activeOrdersDisplayed"
        item-key="orderId"
        :loading="loading"
        :loading-text="$t('overview_table.loading_text')"
        :no-data-text="
          this.isDataLoaded
            ? $t('overview_table.no_filter_data')
            : $t('overview_table.error_data')
        "
        :footer-props="{
          'items-per-page-options': [10],
        }"
        :server-items-length="filteredActiveOrdersCount"
        calculate-widths
        :options.sync="options"
        :page="currentPage"
      >
        <template v-slot:[`item.date`]="{ item }">
          <span>{{ item.date | formatDate('DD.MM.YYYY') }}</span>
        </template>
        <template v-slot:[`item.status`]="props">
          <v-btn rounded dark :color="props.item.status.color">{{
            props.item.status.label
          }}</v-btn>
        </template>
        <template v-slot:[`item.edit`]="props">
          <v-btn icon @click="showDetails(props.item.orderId)">
            <v-icon>mdi-pencil-box-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { getEmobilityWithFilter } from '../../services/EmobilityService';
import { getEmobilityTableConfig } from './emobilityTableConfig';
import { tableOrderMapper } from '../clerk-orders-overview/transform_raw_data_to_orders_table_format';
import { PillRadioButtons } from '@newnetzportal/netzportal-webapp-storybook-components';

export default {
  name: 'EMobilityOverviewTable',
  components: {
    PillRadioButtons,
  },
  created() {
    this.loading = true;
    this.getEmobilityWithFilter();
  },
  data() {
    return {
      statusSelection: this.$route.query.status
        ? this.$route.query.status
        : 'all',
      regionalTeamSelection: this.$route.query.rt
        ? this.$route.query.rt
        : 'all',
      currentPage: this.$route.query.page
        ? parseInt(this.$route.query.page)
        : 1,
      activeOrdersDisplayed: [],
      loading: false,
      options: {},
      searchValue: '',
      filteredActiveOrdersCount: 0,
      statusValuesList: getEmobilityTableConfig('statusValuesList'),
      headersAktive: getEmobilityTableConfig('headersAktive'),
      regionalTeamList: getEmobilityTableConfig('regionalTeams'),
      isDataLoaded: false,
    };
  },
  watch: {
    $route(to) {
      if (to.fullPath === '/emobilitaet') {
        this.refreshOrders();
      }
    },
    regionalTeamSelection(newValue) {
      this.currentPage = 1;
      if (newValue !== 'all') {
        this.regionalTeamSelection = newValue;
      }
      this.$store.commit('setEmobilityRegionalTeamFilter', newValue);
      this.updateURLParams({ name: 'rt', value: newValue });
      this.getEmobilityWithFilter();
    },
    statusSelection(newValue) {
      this.currentPage = 1;
      if (newValue !== 'all') {
        this.statusSelection = newValue;
      }
      this.$store.commit('setEmobilitystatusFilter', newValue);
      this.updateURLParams({ name: 'status', value: newValue });
      this.getEmobilityWithFilter();
    },
    options: {
      handler(event) {
        this.updateURLParams({ name: 'page', value: event.page });
        this.currentPage = event.page;
        this.getEmobilityWithFilter();
      },
      deep: true,
    },
  },
  methods: {
    updateURLParams(param) {
      const params = this.$route.query;
      params[param.name] = param.value;
      history.replaceState(
        {},
        null,
        `${this.$route.path}?${new URLSearchParams(params)}`
      );
    },
    refreshOrders() {
      const params = this.$route.query;
      params.page = 1;
      params.status = '';
      params.journey = '';
      params.rt = '';
      history.replaceState(
        {},
        null,
        `${this.$route.path}?${new URLSearchParams(params)}`
      );
      this.statusSelection = '';
      this.regionalTeamSelection = '';
      this.filterJourney = '';
      this.currentPage = 1;
      this.getEmobilityWithFilter();
    },
    elasticSearchOnBackend(value) {
      if (value.length === 0 || value.length > 2) {
        this.currentPage = 1;
        this.getEmobilityWithFilter();
      }
    },
    getEmobilityWithFilter(search_word) {
      const params = {
        limit: 10,
        order_status:
          this.statusSelection === 'all' ? '' : this.statusSelection,
        regional_team:
          this.regionalTeamSelection === 'all'
            ? ''
            : this.regionalTeamSelection,
        from_index: (this.currentPage - 1) * 10,
        search_word: search_word ? search_word : this.searchValue,
      };
      this.loading = true;
      getEmobilityWithFilter(params)
        .then((res) => {
          if (res.getEmobilityWithFilter) {
            this.filteredActiveOrdersCount =
              res.getEmobilityWithFilter.count_emobilities;
            const ordersWithStatusConfig = this.addStatusConfigForEachOrder(
              res.getEmobilityWithFilter.items
            );
            this.activeOrdersDisplayed =
              ordersWithStatusConfig.map(tableOrderMapper);
            this.isDataLoaded = true;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.isDataLoaded = false;
          this.activeOrdersDisplayed = [];
          console.log(err);
        });
    },
    showDetails(id) {
      this.$store.commit('deleteSelectedEmobility');
      this.$router.push({ name: 'EMobilityDetailsPage', params: { id: id } });
    },
    addStatusConfigForEachOrder(ordersArray) {
      ordersArray.forEach((item) => {
        item.status = this.statusValuesList.find(
          (status) => status.value === item.status
        );
      });
      return ordersArray;
    },
  },
};
</script>
