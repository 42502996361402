import Vue from 'vue';

Vue.filter('formatToEuro', function (priceValue) {
  return priceValue > 0
    ? new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      })
        .format(priceValue)
        .toString()
    : 'kostenfrei';
});
