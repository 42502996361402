var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"dashboard-and-order-number-search-container mb-10"},[_c('div',{staticClass:"dashboard-icon-and-text"},[_c('v-icon',{attrs:{"size":"60","color":"white"}},[_vm._v("mdi-view-dashboard")]),_c('p',[_vm._v(_vm._s(_vm.$t('navigation_bar.dashboard')))])],1),_c('div',{staticClass:"frosted_glass_card"},[_c('SearchFieldWithSuggestions',{staticClass:"search-input",attrs:{"labels":_vm.searchfieldLabels,"hasPrefix":true,"items":_vm.orderNumbersList,"itemValueIdentifier":"id","itemTextIdentifier":_vm.formatCaseNumber,"isLoading":!_vm.areOrderNumbersReady},on:{"searchFieldValueUpdate":_vm.searchFieldValueHandler,"searchValueUpdate":_vm.searchInputChangeHandler}})],1)]),_c('DashboardComponent',{staticClass:"default-mb",attrs:{"dataOrderObject":_vm.dataOrderObject,"dataInstallerEnergyTypeObject":_vm.dataInstallerEnergyTypeObject,"dataEMobilityObject":_vm.dataEMobilityObject,"dataReady":_vm.dataReady,"dataReadyInstallerEnergyType":_vm.dataReadyInstallerEnergyType,"dataReadyEMobility":_vm.dataReadyEMobility,"dataReadyInstallersExpiry":false,"dataInstallerObject":_vm.dataInstallerObject,"dataReadyInstallers":_vm.dataReadyInstallers,"dataFeedInSystemsObject":_vm.dataFeedInSystemsObject},on:{"show-filtered-details":function($event){return _vm.$router.push({
        name: 'ConnectionPage',
        query: {
          page: 1,
          journey: $event,
          order_status: ['ORDER_CREATED', 'ORDER_TECHNICAL_FEASIBILITY'],
          rt: 'all',
        },
      })},"show-details":function($event){return _vm.$router.push({
        name: 'ConnectionPage',
        query: { page: 1, journey: 'all', status: 'all', rt: 'all' },
      })},"show-details-installers":function($event){return _vm.$router.push({
        name: 'InstallersPage',
        query: { page: 1, status: 'all', energy_type: 'all' },
      })},"show-filtered-details-installers":function($event){return _vm.$router.push({
        name: 'InstallersPage',
        query: {
          page: 1,
          status: 'all',
          energy_type: 'all',
          internal: $event ? 'true' : 'false',
          expiry: 'all',
        },
      })},"show-filtered-details-installers-energy-type":function($event){return _vm.$router.push({
        name: 'InstallersPage',
        query: { page: 1, status: 'all', energy_type: $event, expiry: 'all' },
      })},"show-details-emobility":function($event){return _vm.$router.push({
        name: 'EMobilityPage',
        query: { page: 1, status: 'all', rt: 'all' },
      })},"show-filtered-details-emobility":function($event){return _vm.$router.push({
        name: 'EMobilityPage',
        query: {
          page: 1,
          status: ['ORDER_CREATED', 'ORDER_TECHNICAL_FEASIBILITY'],
          rt: 'all',
        },
      })},"show-filtered-details-feed-in-systems":function($event){return _vm.redirectToSelectedPVJourneyNewOrders($event)}}}),_c('div',{staticClass:"default-mb"},[_c('div',{staticClass:"service-container"},[_c('new-glas-card',{class:_vm.$vuetify.breakpoint.lg || _vm.$vuetify.breakpoint.xl
            ? 'frosted_glas'
            : 'frosted_glas mb-2',attrs:{"title":_vm.$t('more_services.title')}},[_c('div',{staticClass:"grid-style"},[_c('a',{staticClass:"white-text",attrs:{"href":"https://www.new-netz.de/technische-richtlinien/","target":"_blank"}},[_vm._v("Technische Richtlinien Kundenanlage")]),_c('a',{staticClass:"white-text",attrs:{"href":"https://www.new-netz.de/fuer-energie-einspeiser/technische-richtlinien/","target":"_blank"}},[_vm._v("Technische Richtlinien Erzeugungsanlagen")])])]),_c('new-glas-card',{staticClass:"frosted_glas",attrs:{"title":_vm.$t('gis_service.title')}},[_c('a',{staticClass:"white-text",attrs:{"href":"https://gis.geo.new.de","target":"_blank"}},[_vm._v("Link zum GIS Service")]),_c('div',{staticClass:"icon-container"},[_c('map-pointer-icon',{staticClass:"map_pointer_icon"}),_c('map-icon')],1)])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }