<template>
  <v-btn
    outlined
    :class="large ? 'menu_button_style_large' : 'menu_button_style_xs'"
    @click="$emit('click', $event)"
    color="primary"
    ><v-icon left v-if="large">mdi-menu</v-icon>Menü</v-btn
  >
</template>

<script>
export default {
  name: 'MenuButton',
  props: ['large'],
};
</script>

<style scoped>
.menu_button_style_xs {
  border-radius: 6px;
  width: 56px;
  height: 28px !important;
}
.menu_button_style_large {
  border-radius: 6px;
  width: 97px;
  height: 40px !important;
}
</style>
