import {
  deleteAllEmptyFields,
  deletePersonalParametersBasedOnTitle,
} from '@/helpers/globalFunctions';
import { PLANT_OPERATOR } from '@/helpers/constants';

const initialState = {
  title: null,
  name: null,
  surname: null,
  company_name: null,
  city_name: null,
  address: {
    street: '',
    street_number: '',
    zip: '',
    city: '',
    country: '',
  },
  phone_number: '',
  additional_phone_number: null,
  email: '',
  preferred_contact_channel: null,
  address_zip_from_google: false,
};

const getters = {
  getFeedInSystemsPlantOperatorTitle: (state) => {
    return state.title;
  },
  getFeedInSystemsPlantOperatorName: (state) => {
    return state.name;
  },
  getFeedInSystemsPlantOperatorSurname: (state) => {
    return state.surname;
  },
  getFeedInSystemsPlantOperatorCompanyName: (state) => {
    return state.company_name;
  },
  getFeedInSystemsPlantOperatorCityName: (state) => {
    return state.city_name;
  },
  getFeedInSystemsPlantOperatorStreet: (state) => {
    return state.address.street;
  },
  getFeedInSystemsPlantOperatorStreetNumber: (state) => {
    return state.address.street_number;
  },
  getFeedInSystemsPlantOperatorZip: (state) => {
    return state.address.zip;
  },
  getFeedInSystemsPlantOperatorCity: (state) => {
    return state.address.city;
  },
  getFeedInSystemsPlantOperatorCountry: (state) => {
    return state.address.country;
  },
  getFeedInSystemsPlantOperatorPhoneNumber: (state) => {
    return state.phone_number;
  },
  getFeedInSystemsPlantOperatorAdditionalPhoneNumber: (state) => {
    return state.additional_phone_number;
  },
  getFeedInSystemsPlantOperatorEmail: (state) => {
    return state.email;
  },
  getFeedInSystemsPlantOperatorPreferredContactChannel: (state) => {
    return state.preferred_contact_channel;
  },
  // used dynamically in AddressDataComponent
  get_form_plant_operator_address_zip_from_google: (state) => {
    return state.address_zip_from_google;
  },
  getFeedInSystemsPlantOperatorContractFormat(state, getters) {
    const applicantRole = getters.getApplicantRole;

    if (applicantRole === PLANT_OPERATOR) {
      return null;
    }
    let plantOperator = JSON.parse(JSON.stringify(state));
    delete plantOperator.address_zip_from_google;
    plantOperator = deletePersonalParametersBasedOnTitle(plantOperator);
    return deleteAllEmptyFields(plantOperator);
  },
};

const mutations = {
  initializeFeedInSystemsPlantOperatorModule(state, values) {
    Object.assign(state, { ...state, ...values });
  },
  // used dynamically in AddressDataComponent
  set_form_plant_operator_address_zip_from_google(state, value) {
    state.address_zip_from_google = value;
  },
  setFeedInSystemsPlantOperatorPersonalData(state, changedData) {
    state[changedData.key] = changedData.value;
  },
  setFeedInSystemsPlantOperatorAddressData(state, changedData) {
    state.address[changedData.key] = changedData.value;
  },
  resetFeedInSystemsPlantOperatorModule(state) {
    Object.assign(state, JSON.parse(JSON.stringify(initialState)));
  },
};

export const feedInSystemsPlantOperatorModule = {
  state: JSON.parse(JSON.stringify(initialState)),
  mutations,
  getters,
};
