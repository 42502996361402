import { render, staticRenderFns } from "./ButtonsRegionalTeam.vue?vue&type=template&id=0e56f3dc&scoped=true"
import script from "./ButtonsRegionalTeam.vue?vue&type=script&lang=js"
export * from "./ButtonsRegionalTeam.vue?vue&type=script&lang=js"
import style0 from "./ButtonsRegionalTeam.vue?vue&type=style&index=0&id=0e56f3dc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e56f3dc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSlideXReverseTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VBtn,VBtnToggle,VIcon,VSlideXReverseTransition})
