<template>
  <div>
    <div class="pipeline-background">
      <NEWPipelineComponent :statuses="other_status" />
      <NEWPipelineComponent :statuses="status_all" />
    </div>
    <e-mobility-overview-table />
  </div>
</template>

<script>
import { NEWPipelineComponent } from '@newnetzportal/netzportal-webapp-storybook-components';
import EMobilityOverviewTable from './EMobilityOverviewTable';
import { getEmobilitySummaryForPipeline } from '../../services/EmobilityService';

export default {
  name: 'EMobilityOverviewComponent',
  components: {
    NEWPipelineComponent,
    EMobilityOverviewTable,
  },
  created() {
    this.getOrdersSummaryForPipelineFromBackend();
  },
  data() {
    return {
      emobilitySummaryForPipeline: {},
    };
  },
  computed: {
    status_all() {
      return [
        {
          name: 'alle',
          color: '#999999',
          amount: this.emobilitySummaryForPipeline.count_all,
          percentage: 100,
        },
      ];
    },
    other_status() {
      return [
        {
          name: 'neu',
          color: '#d79bb8',
          amount: this.emobilitySummaryForPipeline.count_order_created,
          percentage:
            (this.emobilitySummaryForPipeline.count_order_created /
              this.emobilitySummaryForPipeline.count_all) *
            100,
        },
        {
          name: 'in Prüfung',
          color: '#CB80A2',
          amount:
            this.emobilitySummaryForPipeline.count_order_technical_feasibility,
          percentage:
            (this.emobilitySummaryForPipeline
              .count_order_technical_feasibility /
              this.emobilitySummaryForPipeline.count_all) *
            100,
        },
        {
          name: 'Auftrag bestätigt',
          color: '#C7799A',
          amount: this.emobilitySummaryForPipeline.count_order_confirmed,
          percentage:
            (this.emobilitySummaryForPipeline.count_order_confirmed /
              this.emobilitySummaryForPipeline.count_all) *
            100,
        },
        {
          name: 'Auftrag abgelehnt',
          color: '#525252',
          amount: this.emobilitySummaryForPipeline.count_order_cancelled,
          percentage:
            (this.emobilitySummaryForPipeline.count_order_cancelled /
              this.emobilitySummaryForPipeline.count_all) *
            100,
        },
      ];
    },
  },
  methods: {
    getOrdersSummaryForPipelineFromBackend() {
      getEmobilitySummaryForPipeline('')
        .then((res) => {
          this.emobilitySummaryForPipeline = res.getEmobilitySummaryForPipeline;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
