import {
  financeDataObject,
  installationAddressObject,
  presentableFeedInCompensation,
  maStRDataObject,
  storageMaStRDataObject,
  pvSystemInfoObject,
  energyStorageObject,
  measurementConceptObject,
  feedInProspectObject,
  pvContactDataObject,
  alternateInvoiceRecipientDataObject,
  alternateDeliveryAddressDataObject,
  appointmentObject,
  presentableCounterData,
  existingSystemsObject,
  measurementLocationObject,
  marketLocationObject,
  powerFlowMonitoringObject,
  systemProtectionObject,
  symmetryRequirementObject,
  reactivePowerProvisionObject,
} from '@/helpers/transformToPresentableData';
import * as dayjs from 'dayjs';

export function formatPVObject(rawData) {
  return {
    requestDate: dayjs(new Date(rawData.request_date)).format('DD.MM.YYYY'),
    contactData: pvContactDataObject(rawData),
    alternateInvoiceRecipient: alternateInvoiceRecipientDataObject(rawData),
    alternateDeliveryAddress: alternateDeliveryAddressDataObject(rawData),
    address: installationAddressObject(rawData),
    existingSystems: rawData.existing_systems
      ? existingSystemsObject(rawData.existing_systems)
      : null,
    financeData:
      rawData.finance_data && rawData.finance_data.account_data
        ? financeDataObject(rawData.finance_data)
        : null,
    maStR: rawData.maStR ? maStRDataObject(rawData.maStR) : null,
    storageMaStR: rawData.storage_maStR
      ? storageMaStRDataObject(rawData.storage_maStR)
      : null,
    feedInCompensation: presentableFeedInCompensation(rawData),
    plantConstructor: rawData.installation_company_electricity,
    counterNumber: presentableCounterData(rawData),
    implementationAppointment: appointmentObject(rawData),
    pvSystemInfo: pvSystemInfoObject(rawData.pv_system_infos),
    energyStorage: energyStorageObject(rawData),
    measurementConcept: measurementConceptObject(rawData),
    feedInProspect: feedInProspectObject(rawData),
    measurementLocation: measurementLocationObject(rawData),
    marketLocation: marketLocationObject(rawData),
    counters: () => rawData.pvimplementationprotocol_inspection.counters,
    powerFlowMonitoring: powerFlowMonitoringObject(rawData),
    systemProtection: systemProtectionObject(rawData),
    symmetryRequirement: symmetryRequirementObject(rawData),
    reactivePowerProvision: reactivePowerProvisionObject(rawData),
  };
}
