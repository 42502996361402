<template>
  <svg
    id="Icon_Baubesichtigungstermin"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
  >
    <path
      id="Pfad_234"
      data-name="Pfad 234"
      d="M16,0A16,16,0,1,1,0,16,16,16,0,0,1,16,0Z"
      fill="#6c0637"
    />
    <g id="Auge" transform="translate(5.181 7.724)">
      <path
        id="Pfad_230"
        data-name="Pfad 230"
        d="M17.913,20.2C13.452,20.2,9.372,22.919,7,27.472a1.058,1.058,0,0,0,0,.977C9.372,33,13.452,35.722,17.913,35.722S26.454,33,28.828,28.449a1.058,1.058,0,0,0,0-.977C26.454,22.919,22.373,20.2,17.913,20.2Zm0,13.439c-3.531,0-6.8-2.112-8.84-5.678,2.039-3.566,5.309-5.678,8.84-5.678s6.8,2.112,8.84,5.678C24.714,31.527,21.444,33.639,17.913,33.639Z"
        transform="translate(-6.878 -20.2)"
        fill="#fff"
      />
      <path
        id="Pfad_231"
        data-name="Pfad 231"
        d="M39.455,34.661c-2.643,0-4.794,1.8-4.794,4.018s2.15,4.018,4.794,4.018,4.794-1.8,4.794-4.018S42.1,34.661,39.455,34.661Zm0,5.94a2.135,2.135,0,0,1-2.294-1.922,2.329,2.329,0,0,1,4.587,0A2.135,2.135,0,0,1,39.455,40.6Z"
        transform="translate(-28.42 -30.917)"
        fill="#fff"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'BaubeabsichtigungsterminIcon',
  props: {
    width: {
      type: [Number, String],
      default: 30,
    },
    height: {
      type: [Number, String],
      default: 30,
    },
  },
};
</script>

<style scoped></style>
