import documentsService from '../../services/DocumentsService';
import axios from 'axios';
import fileMethods from '../../helpers/fileMethods';
import store from '../../store/index.js'; // path to your Vuex store

export default {
  getOrderDocument(order_id, item, file_type) {
    documentsService
      .getOrderDocumentGraphQL(`${order_id}/${item}`, process.env.VUE_APP_STAGE)
      .then(async (res) => {
        this.downloadFromPresignedURL(
          order_id,
          res.downloadFile,
          item,
          file_type
        );
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async downloadFromPresignedURL(order_id, fileURL, file_name, file_type) {
    const downloadResponse = await axios({
      url: fileURL,
      method: 'GET',
      headers: {
        Accept: '*',
        'Content-Type': 'image/png;charset=utf-8',
      },
      responseType: 'arraybuffer',
    });
    const product = 'order';
    const id = order_id + '_' + file_name.substring(0, file_name.indexOf('_'));
    const fileObject = fileMethods.convertToBlobAndReturnFileObject(
      downloadResponse.data,
      file_name,
      product,
      id,
      file_type
    );
    store.commit('setOrderFileURL', fileObject);
  },
};
