<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :fill="iconColor"
    viewBox="0 0 40.001 32"
  >
    <path
      d="M39,32H1a1,1,0,0,1-1-1V6.666H40V31A1,1,0,0,1,39,32ZM15.484,22.667v4h9.032v-4Zm-7.742,0v4h3.871v-4Zm7.742-6v4H31.017v-4Zm-7.742,0v4H11.73v-4Zm7.742-6v4H30.968v-4Zm-7.742,0v4h3.871v-4ZM40,5.334H0V1A1,1,0,0,1,1,0H39a1,1,0,0,1,1,1V5.333Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'NoteIcon',
  props: {
    width: {
      type: [Number, String],
      default: '40px',
    },
    height: {
      type: [Number, String],
      default: '32px',
    },
    iconColor: {
      type: String,
      default: '#6E6E6E',
    },
  },
};
</script>

<style scoped></style>
