export const historyModule = {
  state: {
    order_history_data: [],
    installer_history_data: [],
    emobility_history_data: [],
    photovoltaic_history_data: [],
    history_next_token: null,
  },
  mutations: {
    setOrderHistoryData(state, order_history_data) {
      state.order_history_data = order_history_data;
    },
    setInstallerHistoryData(state, installer_history_data) {
      state.installer_history_data = installer_history_data;
    },
    setEmobilityHistoryData(state, emobility_history_data) {
      state.emobility_history_data = emobility_history_data;
    },
    setPhotovoltaicHistoryData(state, photovoltaic_history_data) {
      state.photovoltaic_history_data = photovoltaic_history_data;
    },
    setHistoryNextToken(state, history_next_token) {
      state.history_next_token = history_next_token;
    },
  },
  getters: {
    getOrderHistoryData: (state) => {
      return state.order_history_data;
    },
    getInstallerHistoryData: (state) => {
      return state.installer_history_data;
    },
    getEmobilityHistoryData: (state) => {
      return state.emobility_history_data;
    },
    getPhotovoltaicHistoryData: (state) => {
      return state.photovoltaic_history_data;
    },
    getHistoryNextToken: (state) => {
      return state.history_next_token;
    },
  },
  actions: {},
};
