import axios from 'axios';

const baseURL = process.env.VUE_APP_API_URL;

const AxiosInstanceService = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default AxiosInstanceService;
