<template>
  <div>
    <div class="heading">{{ $t('auth_page.title') }}</div>
    <p class="page_description">{{ $t('auth_page.description') }}</p>
    <v-row align="center">
      <v-btn class="btn-new mr-2" @click="cognitoLogin" color="#9B004B">
        <div class="white-text">{{ $t('auth_page.login') }}</div>
      </v-btn>
      <v-btn class="btn-new" @click="cognitoLogin">
        <div class="black-text">{{ $t('auth_page.register') }}</div>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import auth from '../cognito-auth/cognitoAuth';

export default {
  name: 'AuthPage',
  methods: {
    /*       loginNewAuth() {
        // Dieser State wird nach dem login-Prozess versendet.
        // Kann verwendet werden, um nach dem Prozess auf eine spezielle Seite zu verlinken.
        const state = "";
        // Startet den login-Prozess. Dabei wird die URL verändert!
        // eslint-disable-next-line no-undef
        loginNew.auth(state);
      } */
    cognitoLogin() {
      auth.auth.getSession();
    },
  },
};
</script>

<style scoped>
.btn-new {
  border: none;
  cursor: pointer;
  border-radius: 8px;
  width: 158px;
  height: 38px;
  letter-spacing: 0px;
  font-size: 17px;
}

.black-text {
  color: black;
}
</style>
