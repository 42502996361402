<template>
  <v-menu
    v-model="menu2"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-row>
        <v-col cols="4">
          <v-text-field
            :value="formatDateTime(formatDate(), value.start_time, 'start_')"
            outlined
            dense
            label="Starttermin/Uhrzeit"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            :value="formatDateTime(formatDate2(), value.end_time, 'end_')"
            label="Endtermin/Uhrzeit"
            prepend-icon="mdi-calendar"
            readonly
            outlined
            dense
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
    <div class="date-time-card">
      <v-date-picker
        class="custom-date-picker"
        v-model="dates"
        range
        :min="minDate"
        color="#9b004b"
        font-color="#9b004b"
        first-day-of-week="1"
        no-title
        :show-current="false"
        locale="de"
        prev-icon="mdi-arrow-left-bold-circle"
        next-icon="mdi-arrow-right-bold-circle"
        @click:date="saveDates"
      ></v-date-picker>
      <v-divider class="mb-4"></v-divider>
      <time-autocomplete-component
        :dates="dates"
        v-model="valueItem.start_time"
        :v="v.start_time"
        @save-hours-minutes="$emit('save-hours-minutes', $event)"
        :type="'Start'"
      >
      </time-autocomplete-component>
      <v-divider class="mb-4"></v-divider>
      <time-autocomplete-component
        :dates="dates"
        v-model="valueItem.end_time"
        :v="v.end_time"
        @save-hours-minutes="$emit('save-hours-minutes', $event)"
        :type="'Ende'"
      >
      </time-autocomplete-component>
      <div class="button-container">
        <button class="save-date-button" @click="save">Speichern</button>
      </div>
    </div>
  </v-menu>
</template>

<script>
import TimeAutocompleteComponent from './TimeAutocompleteComponent';

export default {
  name: 'DateTimeComponent',
  components: {
    TimeAutocompleteComponent,
  },
  data() {
    return {
      //dates: [new Date().toISOString().slice(0, 10), new Date().toISOString().slice(0, 10)],
      valueItem: {},
      menu2: false,
    };
  },
  created() {
    this.valueItem = { ...this.value };
  },
  props: {
    v: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatDateTime(date, time, type) {
      const formatedDate = this.$dayjs(date).format('DD. MM. YYYY');
      return (
        formatedDate + ' ' + time[type + 'hour'] + ':' + time[type + 'minute']
      );
    },
    save() {
      this.menu2 = false;
    },
    saveDates() {
      this.sortDates();
      this.$emit('save-dates', { key: 'Dates', value: this.dates });
    },
    sortDates() {
      const dateArray = [];
      this.dates.forEach((date) => {
        dateArray.push(new Date(date));
      });
      dateArray.sort((a, b) => a - b);
      this.dates = [];
      dateArray.forEach((element) => {
        this.dates.push(this.$dayjs(new Date(element)).format('YYYY-MM-DD'));
      });
    },
    formatDate() {
      const d1 = Date.parse(this.dates[0]); //27
      let date = d1;
      if (this.dates.length > 1) {
        const d2 = Date.parse(this.dates[1]); //25 start
        if (this.type === 'Ende') {
          date = d1 > d2 ? d1 : d2;
        } else {
          date = d1 < d2 ? d1 : d2;
        }
      }
      return this.$dayjs(date).locale('de').format('DD. MMMM YYYY');
    },
    formatDate2() {
      const d1 = Date.parse(this.dates[0]); //27
      let date = d1;
      if (this.dates.length > 1) {
        const d2 = Date.parse(this.dates[1]); //25 start
        if (this.type === 'Ende') {
          date = d1 > d2 ? d2 : d1;
        } else {
          date = d1 < d2 ? d2 : d1;
        }
      }
      return this.$dayjs(date).locale('de').format('DD. MMMM YYYY');
    },
  },
  computed: {
    minDate() {
      const future = new Date();
      return new Date(future.setDate(future.getDate())).toISOString();
    },
    dates: {
      get() {
        return this.valueItem.dates
          ? this.valueItem.dates
          : [
              new Date().toISOString().slice(0, 10),
              new Date().toISOString().slice(0, 10),
            ];
      },
      set(newValue) {
        this.valueItem.dates = newValue;
      },
    },
  },
};
</script>

<style>
.date-time-card {
  background-color: white;
  width: 290px;
  background-color: #f4f4f4 !important;
}

.custom-date-picker > .theme--light.v-picker__body {
  background-color: #f4f4f4 !important;
}
.save-date-button {
  outline: none;
  background-color: #9b004b;
  color: white;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 5px 20px;
}
.button-container {
  display: flex;
  justify-content: center;
}
</style>
