<template>
  <v-snackbar
    v-model="visible"
    :timeout="3000"
    :bottom="true"
    :right="true"
    color="success"
  >
    {{ notification }}
  </v-snackbar>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'NotificationsComponent',
  components: {},
  data() {
    return {
      visible: false,
      notification: '',
    };
  },
  computed: {
    ...mapGetters(['getNotifications']),
  },
  methods: {
    ...mapMutations(['completeNotification']),
    processNotification(notification) {
      this.notification = notification;
      this.visible = true;
      const _this = this;
      setTimeout(() => {
        _this.completeNotification();
        _this.notification = '';
        _this.visible = false;
        if (_this.getNotifications.length) {
          _this.processNotification(_this.getNotifications[0]);
        }
      }, 2000);
    },
  },
  watch: {
    getNotifications(notifications) {
      if (notifications.length) {
        this.processNotification(notifications[0]);
      }
    },
  },
};
</script>
