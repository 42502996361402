<template>
  <v-img :src="logo" contain :max-width="logoWidth"></v-img>
</template>

<script>
export default {
  name: 'NavLogo',
  computed: {
    logoWidth() {
      if (this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
        return '250px';
      } else if (this.$vuetify.breakpoint.sm) return '170px';
      else if (this.$vuetify.breakpoint.md) return '200px';
      else {
        return '150px';
      }
    },
  },
  data() {
    return {
      logo: require('../../../src/assets/images/Logo_NEW-Netz.svg'),
    };
  },
};
</script>

<style scoped></style>
