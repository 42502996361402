const filesConfig = {
  internal: [
    {
      file: '',
      file_name: '',
      position: 1,
      type: 'craft_role',
      required: true,
    },
    {
      file: '',
      file_name: '',
      position: 2,
      type: 'business_registration',
      required: true,
    },
    {
      file: '',
      file_name: '',
      position: 3,
      type: 'commercial_register',
      required: false,
    },
    {
      file: '',
      file_name: '',
      position: 4,
      type: 'public_liability_insurance',
      required: true,
    },
    {
      file: '',
      file_name: '',
      position: 5,
      type: 'employment_contract',
      required: false,
    },
  ],
  external: [
    {
      file: '',
      file_name: '',
      position: 1,
      type: 'concession_electricity',
      required: true,
    },
    {
      file: '',
      file_name: '',
      position: 2,
      type: 'concession_gas',
      required: true,
    },
    {
      file: '',
      file_name: '',
      position: 3,
      type: 'concession_water',
      required: true,
    },
  ],
  eMobilityInfra: [
    {
      file: '',
      file_name: '',
      position: 1,
      type: 'cadastral_1',
    },
    {
      file: '',
      file_name: '',
      position: 2,
      type: 'cadastral_2',
    },
  ],
  eMobility: [
    {
      file: '',
      file_name: '',
      position: 1,
      type: 'cadastral_em_1',
    },
    {
      file: '',
      file_name: '',
      position: 2,
      type: 'cadastral_em_2',
    },
  ],
  blueprint: [
    {
      file: '',
      file_name: '',
      position: 1,
      type: 'blueprint_1',
    },
    {
      file: '',
      file_name: '',
      position: 2,
      type: 'blueprint_2',
    },
  ],
  cadastral: [
    {
      file: '',
      file_name: '',
      position: 3,
      type: 'cadastral_1',
    },
    {
      file: '',
      file_name: '',
      position: 4,
      type: 'cadastral_2',
    },
  ],
  water: [
    {
      file: '',
      file_name: '',
      position: 1,
      type: 'water',
    },
  ],
};

export const getFilesConfig = (configName) => {
  return JSON.parse(JSON.stringify(filesConfig[configName]));
};
