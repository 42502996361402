export const orderDetailsModule = {
  state: {
    coordinates: '',
    new_order_file_uploaded: false,
  },
  mutations: {
    setCoordinates(state, coordinates) {
      state.coordinates = coordinates;
    },
    setTeam(state, team) {
      state.regional_team = team;
    },
    updateAvailableProducts(state, availableProducts) {
      state.availableProducts = availableProducts;
    },
    setOrderFileChanged(state, newFileUploaded) {
      state.new_order_file_uploaded =
        state.new_order_file_uploaded || newFileUploaded;
    },
    resetOrderFileChanged(state) {
      state.new_order_file_uploaded = false;
    },
  },
  getters: {
    getCoordinates: (state) => {
      return state.coordinates;
    },
    getAvailableProducts(state) {
      return state.availableProducts;
    },
    getOrderFileChanged(state) {
      return state.new_order_file_uploaded;
    },
  },
  actions: {},
};
