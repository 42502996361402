<template>
  <div class="hour-content">
    <v-row no-gutters>
      <v-col cols="12"> {{ type }}: {{ formatDate() }} </v-col>
    </v-row>
    <v-row no-gutters class="hour-container">
      <v-col cols="1">
        <v-icon class="mt-2"> mdi-clock-time-four-outline </v-icon>
      </v-col>
      <v-col cols="5">
        <v-autocomplete v-model="selectedHour" :items="hours" outlined dense>
        </v-autocomplete>
      </v-col>
      <v-col cols="1">
        <div class="ml-3 mt-1" style="font-size: 24px">:</div>
      </v-col>
      <v-col cols="5">
        <v-autocomplete
          v-model="selectedMinute"
          :items="minutes"
          outlined
          dense
        >
        </v-autocomplete>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'TimeAutocompleteComponent',
  props: {
    dates: {
      default: () => ['2021-02-09', '2021-02-19'],
      type: Array,
      required: false,
    },
    value: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'Start',
      required: false,
    },
  },
  data() {
    return {
      valueItem: {},
      hours: [],
      minutes: [],
    };
  },
  created() {
    this.valueItem = { ...this.value };
    this.hours = this.getHours();
    this.minutes = this.getMinutes();
  },
  computed: {
    selectedHour: {
      get() {
        const defaultHour = this.type === 'Start' ? '08' : '18';
        const typeFormat = this.type === 'Start' ? 'start_' : 'end_';
        return this.valueItem[typeFormat + 'hour']
          ? this.valueItem[typeFormat + 'hour']
          : defaultHour;
      },
      set(value) {
        this.formatDate();
        const typeFormat = this.type === 'Start' ? 'start_' : 'end_';
        this.valueItem[typeFormat + 'hour'] = value;
        if (this.type === 'Start') {
          this.$emit('save-hours-minutes', {
            key: 'StartHour',
            value: this.valueItem[typeFormat + 'hour'],
          });
        } else {
          this.$emit('save-hours-minutes', {
            key: 'EndHour',
            value: this.valueItem[typeFormat + 'hour'],
          });
        }
      },
    },
    selectedMinute: {
      get() {
        return this.valueItem.minute ? this.valueItem.minute : '00';
      },
      set(value) {
        this.formatDate();
        const typeFormat = this.type === 'Start' ? 'start_' : 'end_';
        this.valueItem[typeFormat + 'minute'] = value;
        if (this.type === 'Start') {
          this.$emit('save-hours-minutes', {
            key: 'StartMinute',
            value: this.valueItem[typeFormat + 'minute'],
          });
        } else {
          this.$emit('save-hours-minutes', {
            key: 'EndMinute',
            value: this.valueItem[typeFormat + 'minute'],
          });
        }
      },
    },
  },
  methods: {
    getHours() {
      const hours = [];
      for (let i = 0; i < 24; i++) {
        hours.push((i < 10 ? '0' : '') + i);
      }
      return hours;
    },
    getMinutes() {
      const minutes = [];
      for (let i = 0; i <= 55; i += 5) {
        minutes.push((i < 10 ? '0' : '') + i);
      }
      return minutes;
    },
    formatDate() {
      const d1 = Date.parse(this.dates[0]); //27
      let date = d1;
      if (this.dates.length > 1) {
        const d2 = Date.parse(this.dates[1]); //25 start
        if (this.type === 'Ende') {
          date = d1 > d2 ? d1 : d2;
        } else {
          date = d1 < d2 ? d1 : d2;
        }
      }
      return this.$dayjs(date).format('ll');
    },
  },
};
</script>

<style>
.hour-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.hour-container {
  max-width: 190px;
}
</style>
