export const authModule = {
  state: {
    user: {
      user_id: '',
      groups: [],
      first_name: '',
      last_name: '',
      email: '',
      is_user_signed_in: false,
      accessOrdersW: false,
    },
  },
  mutations: {
    setUserId(state, user_id) {
      state.user.user_id = user_id;
    },
    setUserGroups(state, groups) {
      state.user.groups = groups;
    },
    setUserFirstName(state, first_name) {
      state.user.first_name = first_name;
    },
    setUserLastName(state, last_name) {
      state.user.last_name = last_name;
    },
    setUserEmail(state, email) {
      state.user.email = email;
    },
    setIsUserSignedIn(state, is_user_signed_in) {
      state.user.is_user_signed_in = is_user_signed_in;
    },
    setUserAccessOrdersW(state, accessOrdersW) {
      state.user.accessOrdersW = accessOrdersW;
    },
  },
  getters: {
    getUserId: (state) => {
      return state.user.user_id;
    },
    getUserGroups: (state) => {
      return state.user.groups;
    },
    getIsUserSignedIn: (state) => {
      return state.user.is_user_signed_in;
    },
    getUserFirstName: (state) => {
      return state.user.first_name;
    },
    getUserLastName: (state) => {
      return state.user.last_name;
    },
    getUserAccessOrdersW: (state) => {
      return state.user.accessOrdersW;
    },
    getUserInfo: (state) => {
      const user_data = {
        name: state.user.first_name,
        family_name: state.user.last_name,
        email: state.user.email,
        user_id: state.user.user_id,
      };
      return user_data;
    },
  },
  actions: {},
};
