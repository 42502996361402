export const mfhInstallationsModule = {
  state: {
    electricInstallationsData: {
      heat_pump_kw: null,
      water_heater_kw: null,
      electric_installations: [],
      status: 'disabled',
    },
    gasInstallationsData: {
      required_gas_installation: '',
      heating_gas_rating: '',
      optional_gas_installation: [],
      cooker_gas_rating: '',
      status: 'disabled',
    },
    waterInstallationsData: {
      water_flow: [],
      volumetric_flow: '',
      status: 'disabled',
      self_supply: [],
      self_supply_status: '',
      file_name: '',
    },
  },
  mutations: {
    setMfhElectricInstallations(state, electric_installations) {
      state.electricInstallationsData.electric_installations =
        electric_installations;
    },
    setMhfElectricInstallationsStatus(state, status) {
      state.electricInstallationsData.status = status;
    },
    setMfhElInstallationsOldValue(state, electric_installations) {
      this.state.old_value_object.electric_installations =
        electric_installations;
    },
    setMfhElInstallationsChangedObject(state, electric_installations) {
      this.state.changed_object.electric_installations = electric_installations;
    },
    setMfhGasInstallationsOldValue(state, gas_installations) {
      this.state.old_value_object.gas_installations = gas_installations;
    },
    setMfhGasInstallationsChangedObject(state, gas_installations) {
      this.state.changed_object.gas_installations = gas_installations;
    },
    setMfhWaterInstallationsOldValue(state, water_installations) {
      this.state.old_value_object.water_installations = water_installations;
    },
    setMfhWaterInstallationsChangedObject(state, water_installations) {
      this.state.changed_object.water_installations = water_installations;
    },
    setHeatPumpkW(state, heat_pump_kw) {
      this.state.changed_object.electric_installations.heat_pump_power_rating =
        Number(heat_pump_kw);
    },
    setWaterHeaterkW(state, water_heater_kw) {
      this.state.changed_object.electric_installations.water_heaters_power_rating =
        Number(water_heater_kw);
    },
    setSelectedGasHeatingChangedObject(state, selected_heating) {
      this.state.changed_object.gas_installations.selected_heating =
        selected_heating;
    },
    setSelectedGasHeating(state, required_gas_installation) {
      state.gasInstallationsData.required_gas_installation =
        required_gas_installation ? required_gas_installation : '';
    },
    setGasHeatingRating(state, heating_gas_rating) {
      state.gasInstallationsData.heating_gas_rating = heating_gas_rating
        ? heating_gas_rating
        : '';
    },
    setGasHeatingRatingChangedObject(state, heating_gas_rating) {
      this.state.changed_object.gas_installations.heating_gas_rating =
        Number(heating_gas_rating);
    },
    setOptionalGasInstallation(state, selected_installations) {
      this.state.changed_object.gas_installations.selected_installations =
        selected_installations;
    },
    setGasCookerRatingChangedObject(state, cooker_gas_rating) {
      this.state.changed_object.gas_installations.cooker_gas_rating =
        Number(cooker_gas_rating);
    },
    setGasCookerRating(state, cooker_gas_rating) {
      state.gasInstallationsData.cooker_gas_rating = cooker_gas_rating
        ? cooker_gas_rating
        : '';
    },
    setMfhGasInstallationsStatus(state, status) {
      state.gasInstallationsData.status = status;
    },
    setWaterFlow(state, water_flow) {
      state.waterInstallationsData.water_flow = water_flow;
    },
    setVolumetricFlow(state, volumetric_flow) {
      state.waterInstallationsData.volumetric_flow = volumetric_flow
        ? volumetric_flow.toString()
        : '';
    },
    setVolumetricFlowChangedObject(state, volumetric_flow) {
      this.state.changed_object.water_installations.apartment_units_water_ratings =
        Number(volumetric_flow);
    },
    setVolumetricFlowFile(state, file_name) {
      this.state.changed_object.water_installations.file_name = file_name;
    },
    setSelfSupply(state, self_supply) {
      state.waterInstallationsData.self_supply = self_supply;
    },
    setSelfSupplyStatus(state, self_supply_status) {
      state.waterInstallationsData.self_supply_status = self_supply_status;
    },
    setSelfSupplyStatusChangedObject(state, self_supply_status) {
      this.state.changed_object.water_installations.self_supply =
        self_supply_status;
    },
    setMfhWaterInstallationsStatus(state, status) {
      state.waterInstallationsData.status = status;
    },
  },
  getters: {
    getMfhElectricInstallations(state) {
      return state.electricInstallationsData.electric_installations;
    },
    getMfhElectricInstallationsStatus(state) {
      return state.electricInstallationsData.status;
    },
    getHeatPumpkW: (state, getters, rootState) => {
      return rootState.changed_object.electric_installations.heat_pump_power_rating.toString();
    },
    getWaterHeaterkW: (state, getters, rootState) => {
      return rootState.changed_object.electric_installations.water_heaters_power_rating.toString();
    },
    getSelectedGasHeating(state) {
      return state.gasInstallationsData.required_gas_installation;
    },
    getGasHeatingRating(state) {
      return state.gasInstallationsData.heating_gas_rating;
    },
    getOptionalGasInstallation: (state, getters, rootState) => {
      return rootState.changed_object.gas_installations.selected_installations;
    },
    getGasCookerRating(state) {
      return state.gasInstallationsData.cooker_gas_rating;
    },
    getMfhGasInstallationsStatus(state) {
      return state.gasInstallationsData.status;
    },
    getWaterFlow(state) {
      return state.waterInstallationsData.water_flow;
    },
    getVolumetricFlow(state) {
      return state.waterInstallationsData.volumetric_flow;
    },
    getVolumetricFlowFile: (state, getters, rootState) => {
      return rootState.changed_object.water_installations.file_name;
    },
    getSelfSupply(state) {
      return state.waterInstallationsData.self_supply;
    },
    getSelfSupplyStatus(state) {
      return state.waterInstallationsData.self_supply_status;
    },
    getMfhWaterInstallationsStatus(state) {
      return state.waterInstallationsData.status;
    },
  },
};
