export const orderUploadFilesModule = {
  state: {
    files: {},
    fileURLs: {},
  },
  mutations: {
    updateFileNamesblueprint(state, file) {
      this.state.changed_object.uploaded_file_names_blueprint[file.index] =
        file.fileName;
    },
    updateFileNamescadastral(state, file) {
      this.state.changed_object.uploaded_file_names_cadastral[file.index] =
        file.fileName;
    },
    updateOrderEmobilityFileName(state, file) {
      this.state.changed_object.file_uploads[file.index] = file.fileName;
    },
    setChangedFileObject(state, files) {
      this.state.changed_object[Object.keys(files)[0]] =
        files[Object.keys(files)[0]];
    },
    setOldFileObject(state, files) {
      this.state.old_value_object[Object.keys(files)[0]] =
        files[Object.keys(files)[0]];
    },
    setOrderFileFromBackend(state, fileObject) {
      state.files[fileObject.type] = fileObject.fileObject;
    },
    setOrderFileHistoryFromBackend(state, file) {
      state.files[file.name] = file;
    },
    setOrderFileURL(state, object) {
      state.fileURLs[object.imageName] = object.url;
    },
    deleteOrderFile(state, file) {
      delete state.files[file];
    },
    deleteOrderFileURL(state, file_name) {
      delete state.fileURLs[file_name];
    },
    deleteOrderEmobilityFiles(state) {
      Object.keys(state.files).forEach((file) => {
        if ('em_' === file.substring(0, 3)) {
          delete state.files[file];
        }
      });
    },
    deleteOrderEmobilityFileURLs(state) {
      Object.keys(state.fileURLs).forEach((file) => {
        if ('em_' === file.substring(0, 3)) {
          delete state.fileURLs[file];
        }
      });
    },
  },
  getters: {
    getOrderFileURLByName: (state) => (imageName) => {
      return state.fileURLs[imageName];
    },
    getOrderFileFromBackend: (state) => (imageName) => {
      return state.files[imageName];
    },
  },
};
