<template>
  <div class="details_dialog">
    <div class="header_dialog">
      <edit-icon class="mt-4" width="20px" height="20px" />
      <v-icon large class="ml-4 mt-4" @click="$emit('close-dialog')"
        >mdi-close-circle-outline</v-icon
      >
    </div>
    <div class="date mt-2">
      <v-icon class="mr-4">mdi-calendar</v-icon>
      <div>{{ formatDate(date) }}</div>
    </div>
    <div class="applicant_data">
      <div>
        {{
          this.selectedOrderCalendar.journey === 'einfamilienhaus'
            ? 'Netzanschluss Einfamilienhaus'
            : 'Netzanschluss E-Mobilität'
        }}
      </div>
      <div>
        {{ applicantNameObject(this.selectedOrderCalendar) }}
      </div>
      <div>
        {{ installationAddressObject(this.selectedOrderCalendar) }}
      </div>
    </div>
    <construction-dashboard-data-component
      :contactPerson="
        this.selectedOrderCalendar.internal_construction_management
          .construction_manager
      "
      :title="$t('calendar_side_selection_component.construction_manager_NEW')"
      :constructionDate="
        this.selectedOrderCalendar.internal_construction_management
          .construction_planned_date
      "
    >
      <template v-slot:icon>
        <baubesichtigungstermin-icon class="mr-2" />
      </template>
    </construction-dashboard-data-component>

    <construction-dashboard-data-component
      :companyName="this.selectedOrderCalendar.groundwork_company.company_name"
      :contactPerson="
        this.selectedOrderCalendar.groundwork_company.contact_person
      "
      :title="$t('construction_work.civil_engineering')"
      :constructionDate="
        this.selectedOrderCalendar.groundwork_company.construction_planned_date
      "
    >
      <template v-slot:icon>
        <tiefbau-icon class="mr-2" />
      </template>
    </construction-dashboard-data-component>

    <construction-dashboard-data-component
      v-if="
        selectedOrderProduct.includes('electricity') ||
        this.selectedOrderProduct.includes('eMobility')
      "
      :companyName="
        this.selectedOrderCalendar.assembly_electricity_company.company_name
      "
      :contactPerson="
        this.selectedOrderCalendar.assembly_electricity_company.contact_person
      "
      :title="$t('calendar_side_selection_component.montage_electricity')"
      :constructionDate="
        this.selectedOrderCalendar.assembly_electricity_company
          .construction_planned_date
      "
    >
      <template v-slot:icon>
        <montage-strom-icon class="mr-2" />
      </template>
    </construction-dashboard-data-component>

    <construction-dashboard-data-component
      v-if="
        selectedOrderProduct.includes('gas') ||
        this.selectedOrderProduct.includes('water')
      "
      :companyName="
        this.selectedOrderCalendar.assembly_gas_water_company.company_name
      "
      :contactPerson="
        this.selectedOrderCalendar.assembly_gas_water_company.contact_person
      "
      :title="
        selectedOrderProduct === 'electricity&water&gas'
          ? $t('calendar_side_selection_component.montage_gas_water')
          : selectedOrderProduct === 'electricity&water'
          ? $t('calendar_side_selection_component.montage_water')
          : $t('calendar_side_selection_component.montage_gas')
      "
      :constructionDate="
        this.selectedOrderCalendar.assembly_gas_water_company
          .construction_planned_date
      "
      :secondIcon="
        selectedOrderProduct === 'electricity&water&gas'
          ? (secondIcon = true)
          : false
      "
    >
      <template v-slot:icon>
        <montage-gas-icon
          class="mr-1"
          v-if="selectedOrderProduct.includes('gas')"
        />
        <montage-wasser-icon
          class="mr-1 mt-2"
          v-if="selectedOrderProduct === 'electricity&water'"
        />
      </template>
      <template
        v-slot:icon2
        v-if="selectedOrderProduct === 'electricity&water&gas'"
      >
        <montage-wasser-icon class="mr-2" />
      </template>
    </construction-dashboard-data-component>
  </div>
</template>

<script>
import EditIcon from '../../icons/EditIcon';
import BaubesichtigungsterminIcon from '../../icons/BaubesichtigungsterminIcon';
import TiefbauIcon from '../../icons/TiefbauIcon';
import ConstructionDashboardDataComponent from '../construction-work-component/ConstructionDashboardDataComponent';
import MontageStromIcon from '../../icons/MontageStromIcon';
import MontageWasserIcon from '../../icons/MontageWasserIcon';
import MontageGasIcon from '../../icons/MontageGasIcon';
import { COMPANY_TITLE } from '../../helpers/constants';

export default {
  name: 'CalendarDetailsComponent',
  components: {
    EditIcon,
    BaubesichtigungsterminIcon,
    ConstructionDashboardDataComponent,
    TiefbauIcon,
    MontageStromIcon,
    MontageWasserIcon,
    MontageGasIcon,
  },
  props: {
    date: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      installationAddress: '',
      applicantName: '',
    };
  },
  computed: {
    selectedOrderCalendar() {
      return this.$store.getters.getSelectedOrderCalendar;
    },
    selectedOrderProduct() {
      return this.$store.getters.getSelectedOrderCalendar.product;
    },
  },
  methods: {
    applicantNameObject(rawData) {
      let applicant = '';
      applicant =
        rawData.applicant.title === COMPANY_TITLE
          ? rawData.applicant.company_name
          : rawData.applicant.title +
            ' ' +
            rawData.applicant.name +
            ' ' +
            rawData.applicant.surname;
      return applicant;
    },
    installationAddressObject(rawData) {
      let installationAddress = '';
      installationAddress = rawData.product_installation_full_address
        ? rawData.product_installation_full_address.street +
          ' ' +
          rawData.product_installation_full_address.street_number +
          ', ' +
          rawData.product_installation_full_address.zip +
          ' ' +
          rawData.product_installation_full_address.city
        : 'Bauort: ' +
          rawData.product_installation_with_plot_number_address
            .new_building_area_name +
          ', Gemarkung: ' +
          rawData.product_installation_with_plot_number_address.district +
          ', Flur: ' +
          rawData.product_installation_with_plot_number_address.landing +
          ', Flurstück: ' +
          rawData.product_installation_with_plot_number_address.plot +
          ', ' +
          rawData.product_installation_with_plot_number_address.zip +
          ', ' +
          rawData.product_installation_with_plot_number_address.location;
      return installationAddress;
    },
    formatDate(date) {
      return this.$dayjs(date).format('DD. MMMM YYYY');
    },
  },
};
</script>

<style>
.date {
  display: flex;
}
.details_dialog {
  background: white;
  padding: 0px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  width: 450px;
}
.header_dialog {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.applicant_data {
  margin-top: 5px;
  margin-bottom: 20px;
  padding-right: 60px;
  padding-left: 45px;
}
</style>
