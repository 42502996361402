<template>
  <div class="foo-bar">
    <div style="width: 100%; display: flex; flex-direction: column">
      <div id="optional-header" v-if="optionalHeaderText !== ''">
        <div>{{ optionalHeaderText }}</div>
      </div>
      <div
        id="foo-data"
        :style="
          first
            ? 'border-radius: 8px 0 0 0'
            : last
            ? 'border-radius: 0 0 0 8px'
            : ''
        "
      >
        <div
          id="title"
          v-html="title"
          :style="{ lineHeight: withLineHeight ? '2' : '20px' }"
        ></div>
        <div id="content">
          <slot />
          <SuccessfulSaveAlert v-if="showUserfeedback" />
        </div>
      </div>
    </div>

    <!-- Edit button -->
    <div
      id="action"
      :style="
        first
          ? 'border-radius: 0 8px 0 0'
          : last
          ? 'border-radius: 0 0 8px 0'
          : ''
      "
    >
      <v-btn icon @click="$emit('edit', $event)" v-if="editable">
        <v-icon v-if="notEditable" :color="this.color">mdi-eye</v-icon>
        <edit-icon v-else />
      </v-btn>
    </div>
  </div>
</template>

<script>
import EditIcon from '../../icons/EditIcon';
import SuccessfulSaveAlert from './SuccessfulSaveAlert';
import { emptyEventData } from '../../store/feedInSystemsModule';

export default {
  name: 'RowItem',
  components: {
    EditIcon,
    SuccessfulSaveAlert,
  },
  props: {
    optionalHeaderText: {
      type: String,
      default: '',
      required: false,
    },
    title: {
      type: String,
      default: '-',
    },
    editable: {
      type: Boolean,
      default: true,
    },
    field: {
      type: String,
      default: '',
    },
    first: {
      type: Boolean,
      default: false,
    },
    last: {
      type: Boolean,
      default: false,
    },
    notEditable: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
    },
    withLineHeight: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    notifyEvent() {
      return this.$store.getters.getNotifyEvent ?? emptyEventData;
    },
    showUserfeedback() {
      return this.notifyEvent.field === this.field && this.field !== '';
    },
  },
  methods: {
    hideConfirmationMessage() {
      setTimeout(() => {
        this.$store.commit('setNotifyEvent', emptyEventData);
      }, 3000);
    },
  },
  watch: {
    notifyEvent(event) {
      if (event.field) {
        this.hideConfirmationMessage();
      }
    },
  },
};
</script>

<style scoped>
#foo-data {
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-self: flex-start;
  width: 100%;
  padding: 18px 0 18px 25px;
  margin-right: 1px;
}
#optional-header {
  color: #6e6e6e;
  padding: 18px 0 0 25px;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-self: flex-start;
  width: 100%;
  margin-right: 1px;
}
#title {
  width: 30%;
  font-size: 16px;
  letter-spacing: 0;
  color: #6e6e6e;
  opacity: 1;
}

#content {
  width: 70%;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0;
  color: #6e6e6e;
  opacity: 1;
}
#action {
  min-height: 100%;
  width: 6%;
  min-width: 48px;
  justify-content: center;
  align-items: center;
  display: flex;
  filter: brightness(1.1);
  background-color: rgba(255, 255, 255, 0.6);
}

.foo-bar {
  display: flex;
  width: 100%;
  min-height: 48px;
}

.round-top-corner {
  border-radius: 8px 0 0 0;
}

.round-bottom-corner {
  border-radius: 0 0 0 8px;
}
</style>
