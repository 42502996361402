import AxiosInstanceService from './AxiosInstanceService';
import { loggerService } from './LoggerService';

export default {
  async getInstallerCompanies(params) {
    try {
      const response = await AxiosInstanceService.get(
        `installers/installer_companies?zip=${params.zip}&product=${params.product}`
      );
      return response.data;
    } catch (error) {
      loggerService.error(error);
      return [];
    }
  },
};
