import { render, staticRenderFns } from "./OrderDetails.vue?vue&type=template&id=268fa015&scoped=true"
import script from "./OrderDetails.vue?vue&type=script&lang=js"
export * from "./OrderDetails.vue?vue&type=script&lang=js"
import style0 from "./OrderDetails.vue?vue&type=style&index=0&id=268fa015&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "268fa015",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VIcon,VRow})
