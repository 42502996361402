var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"foo-bar"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('div',{staticClass:"title-col"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"body-col"},_vm._l((_vm.filesArray),function(file,index){return _c('v-row',{key:file.type,style:((index === 0 && index === _vm.filesArray.length - 1) ||
          index === _vm.filesArray.length - 1
            ? 'min-height: 56px'
            : 'margin-bottom: 1px'),attrs:{"no-gutters":""}},[_c('div',{staticClass:"content-col",style:(index === 0
              ? 'padding-top: 18px'
              : index === _vm.filesArray.length - 1
              ? 'padding-bottom: 18px'
              : '')},[_c('div',[_vm._v(" "+_vm._s(_vm.$t(("file_types." + (file.type))))+" "),_vm._l((file.file_names),function(file_name){return _c('v-btn',{key:file_name,staticStyle:{"min-height":"36px","min-width":"36px","margin-right":"5px"},attrs:{"icon":""},on:{"click":function($event){return _vm.downloadOrderFile(file_name)}}},[_c('v-icon',{attrs:{"v":""}},[_vm._v("mdi-download")])],1)})],2),(index === _vm.filesArray.length - 1)?_vm._t("default"):_vm._e()],2),_c('div',{staticClass:"button-col",style:(_vm.first && index === 0
              ? 'border-radius: 0 8px 0 0'
              : _vm.last && index === _vm.filesArray.length - 1
              ? 'border-radius: 0 0 8px 0'
              : '')},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('edit', file)}}},[_c('edit-icon')],1)],1)])}),1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }