import { feedInSystemsApplicantModule } from './feedInSystemsApplicantModule';
import { feedInSystemsPlantOperatorModule } from './feedInSystemsPlantOperatorModule';
import { feedInSystemsLandownerModule } from './feedInSystemsLandownerModule';
import { PLANT_OPERATOR } from '@/helpers/constants';

const initialState = {
  is_initialized: false,
  status: 'finished',
  landowner_state: null,
  applicant_role: null,
};

const getters = {
  getFeedInSystemsContractContactIsInitialized: ({ is_initialized }) =>
    is_initialized,
  getFeedInSystemsLandownerState: ({ landowner_state }) => landowner_state,
  getFeedInSystemsApplicantRole: ({ applicant_role }) => applicant_role,
  getFeedInSystemsLandownerContractFormat: (state, getters) => {
    if (getters.getFeedInSystemsLandownerState) {
      return null;
    }
    const landownerObject = JSON.parse(JSON.stringify(state.landowner));
    delete landownerObject.address_zip_from_google;
    return landownerObject;
  },
  getFeedInSystemsApplicantContractFormat: (state) => {
    const applicantObject = JSON.parse(JSON.stringify(state.applicant));
    delete applicantObject.address_zip_from_google;
    return applicantObject;
  },
  getPlantOperatorContractFormat(state, getters) {
    const applicantRole = getters.getFeedInSystemsApplicantRole;

    if (applicantRole === PLANT_OPERATOR) {
      return null;
    }
    const plantOperator = JSON.parse(JSON.stringify(state.plant_operator));
    delete plantOperator.address_zip_from_google;
    return plantOperator;
  },
  getFeedInSystemsContactData: (state, getters) => {
    return {
      applicant_role: getters.getFeedInSystemsApplicantRole,
      applicant: getters.getFeedInSystemsApplicantContractFormat,
      same_landowner: getters.getFeedInSystemsLandownerState,
      landowner: getters.getFeedInSystemsLandownerContractFormat,
      plant_operator: getters.getPlantOperatorContractFormat,
    };
  },
};

const mutations = {
  setFeedInSystemsContractContactIsInitialized(state, value) {
    state.is_initialized = value;
  },
  setFeedInSystemsApplicantRole(state, applicant_role) {
    state.applicant_role = applicant_role;
  },
  setFeedInSystemsLandownerState(state, landowner_state) {
    state.landowner_state = landowner_state;
  },
  resetFeedInSystemsContractContactModule(state) {
    Object.assign(state, JSON.parse(JSON.stringify(initialState)));
  },
};

export const feedInSystemsContractContactModule = {
  state: JSON.parse(JSON.stringify(initialState)),
  getters,
  mutations,
  modules: {
    applicant: feedInSystemsApplicantModule,
    plant_operator: feedInSystemsPlantOperatorModule,
    landowner: feedInSystemsLandownerModule,
  },
};
