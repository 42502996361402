const emptyResponsibleSpecialist = {
  email: '',
  function_in_company: '',
  mobile_number: '',
  name: '',
  energy_types: [],
  energy_types_de: '',
  phone_number: '',
  surname: '',
  title: '',
};

const emptyContactPerson = {
  email: '',
  function_in_company: '',
  mobile_number: '',
  name: '',
  phone_number: '',
  surname: '',
  title: '',
};

export const installerCompanyDataModule = {
  state: {
    index: null,
    responsible_specialist_added: false,
    additional_contact_person_state: false,
  },
  mutations: {
    setInstallerCompanyName(state, company_name) {
      this.state.changed_object.company_data.company_name = company_name;
    },
    setInstallerStreet(state, street) {
      this.state.changed_object.company_data.company_address.street = street;
    },
    setInstallerStreetNumber(state, street_number) {
      this.state.changed_object.company_data.company_address.street_number =
        street_number;
    },
    setInstallerZip(state, zip) {
      this.state.changed_object.company_data.company_address.zip = zip;
    },
    setInstallerCity(state, city) {
      this.state.changed_object.company_data.company_address.city = city;
    },
    setInstallerCountry(state, country) {
      this.state.changed_object.company_data.company_address.country = country;
    },
    setInstallerPhoneNumber(state, phone_number) {
      this.state.changed_object.company_data.phone_number = phone_number;
    },
    setInstallerTroubleshootingNumber(state, troubleshooting_number) {
      this.state.changed_object.company_data.troubleshooting_number =
        troubleshooting_number;
    },
    setInstallerEmail(state, email) {
      this.state.changed_object.company_data.email = email;
    },
    set_form_company_data_address_zip_from_google(state, value) {
      state.address_zip_from_google = value;
    },
    setContactPersonObject(state, changedData) {
      this.state.changed_object.contact_person[changedData.key] =
        changedData.value;
    },
    resetContactPersonData() {
      this.state.changed_object.contact_person = JSON.parse(
        JSON.stringify(emptyContactPerson)
      );
    },
    setInstallerAdditionalContactPersonState(
      state,
      additional_contact_person_state
    ) {
      state.additional_contact_person_state = additional_contact_person_state;
    },
    setInstallerCompanyAddressChangedObject(state, changedData) {
      this.state.changed_object.company_data.company_address[changedData.key] =
        changedData.value;
    },
    addResponsibleSpecialistObject() {
      this.state.changed_object.responsible_specialists.push(
        JSON.parse(JSON.stringify(emptyResponsibleSpecialist))
      );
    },
    setNewResponsibleSpecialistAdded(state, responsible_specialist_added) {
      state.responsible_specialist_added = responsible_specialist_added;
    },
    removeResponsibleSpecialist(state, index) {
      this.state.changed_object.responsible_specialists.splice(index, 1);
    },
    setIndexResponsibleSpecialist(state, index) {
      state.index = index;
    },
    setResponsibleSpecialist(state, objectData) {
      this.state.changed_object.responsible_specialists[objectData.index][
        objectData.key
      ] = objectData.value;
    },
  },
  getters: {
    getNumberOfResponsibleSpecialists: (state, getters, rootState) => {
      return rootState.changed_object.responsible_specialists
        ? rootState.changed_object.responsible_specialists.length
        : getters.getSelectedInstaller.responsible_specialists.length;
    },
    getEnergyType: (state, getters) => {
      return getters.getSelectedInstaller.concession_division;
    },
    getIndexResponsibleSpecialist(state) {
      return state.index;
    },
    getNewResponsibleSpecialistAdded: (state) => {
      return state.responsible_specialist_added;
    },
    getInstallerAdditionalContactPersonState: (state) => {
      return state.additional_contact_person_state;
    },
    get_form_company_data_address_zip_from_google: (state) => {
      return state.address_zip_from_google;
    },
  },
};
