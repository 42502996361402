export const emobilityModule = {
  state: {
    nextToken: null,
    allEmobilitiesLoaded: false,
    numberOfAllEmobilities: 0,
    selectedEmobility: {},
    allEmobilities: [],
    emobilityStatusFilter: '',
    emobilityRegionalTeamFilter: '',
  },
  mutations: {
    deleteSelectedEmobility(state) {
      state.selectedEmobility = {};
    },
    setSelectedEmobility(state, selectedEmobility) {
      state.selectedEmobility = selectedEmobility;
    },
    updateEmobilityAvailableProducts(state, availableProducts) {
      state.availableProducts = availableProducts;
    },
    updateSelectedEmobilityData(state, newValue) {
      Object.keys(newValue).forEach((changedPart) => {
        state.selectedEmobility[changedPart] = newValue[changedPart];
      });
    },
    updateLastChangedByInSelectedEmobility(state, last_changed_by) {
      state.selectedEmobility.last_changed_by = last_changed_by;
    },
    updateLastUpdateInSelectedEmobility(state, last_update) {
      state.selectedEmobility.last_update = last_update;
    },
    setEmobilitystatusFilter(state, emobilityStatusFilter) {
      state.emobilityStatusFilter = emobilityStatusFilter;
    },
    setEmobilityRegionalTeamFilter(state, emobilityRegionalTeamFilter) {
      state.emobilityRegionalTeamFilter = emobilityRegionalTeamFilter;
    },
  },
  getters: {
    getSelectedEmobility(state) {
      return state.selectedEmobility;
    },
    getEmobilityAvailableProducts(state) {
      return state.availableProducts;
    },
    getEmobilitystatusFilter(state) {
      return state.emobilityStatusFilter;
    },
    getEmobilityRegionalTeamFilter(state) {
      return state.emobilityRegionalTeamFilter;
    },
  },
  actions: {},
};
