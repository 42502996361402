<template>
  <div class="priciong_component_content">
    <div class="raw_style" v-for="item in priceDetails" :key="item.name">
      <div>{{ item.name }}</div>
      <div>{{ Number(item.price).toFixed(2).replace('.', ',') }} €</div>
    </div>
    <div class="raw_style border_bottom_last_item">
      <div>Netzanschlusskosten (inkl. Mehrwertssteuer)</div>
      <div>{{ Number(totalPrice).toFixed(2).replace('.', ',') }} €</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PricingDetailsComponent',
  props: {
    totalPrice: {
      type: Number,
      default: 0,
    },
    priceDetails: {
      type: Array,
      default: () => [],
    },
    netWorkConnection: {
      type: Boolean,
    },
  },
};
</script>

<style scoped>
.raw_style {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #999999;
}

.priciong_component_content {
  line-height: 36px;
  font-size: 18px;
  margin-right: 29px;
}

.border_bottom_last_item {
  border-bottom: 3px double #999999 !important;
}
</style>
