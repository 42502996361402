<template>
  <div class="mb-2">
    <v-card class="new-element-border-radius" outlined>
      <div :class="notEditable ? '' : 'clickable'" :v-ripple="!notEditable">
        <v-list-item three-line>
          <v-avatar class="mr-5" color="#6E6E6E" size="30">
            <span class="white--text headline">{{ index + 1 }}</span>
          </v-avatar>
          <v-list-item-content
            :class="
              $vuetify.breakpoint.xs
                ? 'checkbox_title_xs'
                : 'checkbox_title_default'
            "
            @click="changeCheckboxValue"
          >
            {{ $t(title) }}
          </v-list-item-content>
          <v-btn
            icon
            v-if="!notEditable"
            @click="
              $emit('show-modal', {
                component: updateComponent,
                method: updateMethod,
                location: 'technicalInspectionComponent',
              })
            "
            class="mr-8"
          >
            <edit-icon />
          </v-btn>
          <v-list-item-action
            class="ma-0"
            style="align-self: center"
            @keydown.space="changeCheckboxValue"
          >
            <v-checkbox
              color="#9B004B"
              :disabled="notEditable"
              v-model="checkboxState"
              @click.stop=""
            >
            </v-checkbox>
          </v-list-item-action>
        </v-list-item>
      </div>
    </v-card>
    <v-alert
      class="alert_success"
      style="background-color: #e4f0d6 !important"
      v-if="alert"
      max-width="270px"
      max-height="35px"
      text
      transition="scale-transition"
    >
      <template v-slot:append>
        <v-icon color="green" class="ml-2">mdi-check</v-icon>
      </template>
      Erfolgreich gespeichert
    </v-alert>
    <div class="mb-2" v-if="showError && !checkboxState" style="color: #c20016">
      {{ $t(requiredText) }}
    </div>
  </div>
</template>

<script>
import EditIcon from '../../icons/EditIcon';
import { emptyEventData } from '../../store/ordersModule';

export default {
  name: 'TextCheckboxActionComponent',
  components: {
    EditIcon,
  },
  props: [
    'title',
    'checkboxName',
    'showError',
    'index',
    'requiredText',
    'updateMethod',
    'updateComponent',
    'notEditable',
  ],
  data() {
    return {
      alert: false,
    };
  },
  created() {
    this.$store.commit('setLastUpdatedComponent', '');
    this.$store.commit('setLastUpdatedLocation', '');
  },
  computed: {
    checkboxState: {
      get() {
        return this.$store.getters['get' + this.checkboxName + 'Selected'];
      },
      set(value) {
        //this.$store.commit("set" + this.checkboxName + 'Selected', value);
        this.$emit('update-checkbox-value', value);
      },
    },
    notifyEvent() {
      return this.$store.getters.getNotifyEvent
        ? this.$store.getters.getNotifyEvent
        : emptyEventData;
    },
    lastUpdatedComponent() {
      return this.$store.getters.getLastUpdatedComponent;
    },
    lastUpdatedLocation() {
      return this.$store.getters.getLastUpdatedLocation
        ? this.$store.getters.getLastUpdatedLocation
        : '';
    },
  },
  watch: {
    notifyEvent(newValue) {
      if (
        newValue.field === this.updateMethod &&
        this.lastUpdatedLocation === 'technicalInspectionComponent'
      ) {
        this.alert = true;
        setTimeout(() => {
          (this.alert = false),
            this.$store.commit('setNotifyEvent', emptyEventData);
        }, 3000);
      }
    },
  },
  methods: {
    changeCheckboxValue() {
      if (!this.notEditable) {
        this.checkboxState = !this.checkboxState;
      }
    },
  },
};
</script>

<style>
.clickable {
  cursor: pointer;
  box-shadow: 0px 1px 2px #00000029;
  position: relative;
}

.checkbox_title_default {
  font-size: 20px;
  line-height: 24px;
}
.checkbox_title_xs {
  font-size: 18px;
  line-height: 21px;
}

.alert_success {
  display: flex;
  margin-top: 10px;
}
</style>
