<template>
  <div class="concession-info-container">
    <div class="concession-info-container__icon">
      <MontageStromIcon v-if="data.icon === 'electricity'" />
      <MontageGasIcon v-if="data.icon === 'gas'" />
      <MontageWasserIcon v-if="data.icon === 'water'" />
    </div>
    <div class="concession-info-container__concession-info">
      <span class="info-item">{{ data.title }}</span>
      <span class="info-item">{{
        this.$t('installer_details.valid_until') + formatDate(data.value)
      }}</span>
    </div>
  </div>
</template>

<script>
import MontageWasserIcon from '../icons/MontageWasserIcon.vue';
import MontageGasIcon from '../icons/MontageGasIcon.vue';
import MontageStromIcon from '../icons/MontageStromIcon.vue';

export default {
  name: 'InstallerDetailsConcessionDate',
  components: {
    MontageStromIcon,
    MontageGasIcon,
    MontageWasserIcon,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return '';
      return new Date(date).toLocaleDateString('de', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    },
  },
};
</script>
<style>
.concession-info-container {
  display: flex;
  align-items: center;
  font-size: 18px;
}
.concession-info-container__icon {
  margin-right: 10px;
  margin-left: 20px;
}
.concession-info-container__concession-info {
  display: flex;
  flex-direction: column;
}
.info-item {
  flex-grow: 1;
}
</style>
