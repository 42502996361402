<template>
  <div>
    <div class="mb-2">
      <v-card class="new-element-border-radius" outlined>
        <div :class="notEditable ? '' : 'clickable'" :v-ripple="!notEditable">
          <v-list-item three-line>
            <v-avatar
              class="mr-5"
              style="cursor: pointer"
              color="#6E6E6E"
              size="30"
            >
              <span class="white--text headline">{{ index }}</span>
            </v-avatar>
            <v-list-item-content class="checkbox_title_default">
              {{ $t('emobility_inspection.charging_power_checked') }}
            </v-list-item-content>
            <v-list-item-action class="ma-0" style="align-self: center">
              <v-checkbox
                color="#9B004B"
                v-model="checkboxState"
                @click.stop=""
                :disabled="disableButton() || notEditable"
              >
              </v-checkbox>
            </v-list-item-action>
          </v-list-item>
        </div>
        <div class="radio-group">
          <v-radio-group
            class="radio-group-full-width ml-4 mr-4"
            v-for="chargingPowerInstallation of chargingPowerInstallations"
            v-bind:key="chargingPowerInstallation.subTitle"
            v-model="selectedChargingPowerInspection"
            style="width: 100%"
            :disabled="notEditable"
          >
            <v-radio
              :value="chargingPowerInstallation.name"
              :id="chargingPowerInstallation.name"
            >
              <template v-slot:label>
                <div style="display: flex; flex-direction: column">
                  <div style="font-weight: bold">
                    {{ $t('emobility_inspection.charging_power') }}
                  </div>
                  <div
                    style="font-size: 14px"
                    v-html="` ${$t(chargingPowerInstallation.subTitle)} `"
                  ></div>
                </div>
                <v-col
                  v-if="
                    chargingPowerInstallation.name === 'charging_station_custom'
                  "
                  style="width: 150px; margin-top: 18px"
                >
                  <v-text-field
                    type="number"
                    placeholder="kW"
                    outlined
                    dense
                    v-model="chargingStationCustomValueInspection"
                  ></v-text-field>
                </v-col>
              </template>
            </v-radio>
          </v-radio-group>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChargingPowerComponent',
  props: {
    notEditable: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      required: true,
      validator: (value) => {
        const validValueList = ['Emobility', 'Technical'];
        return validValueList.includes(value);
      },
    },
  },
  data() {
    return {
      chargingPowerInstallations: [
        {
          subTitle: 'emobility_installation_selection.wallbox_up_to_11_kW',
          name: 'charging_station_11',
        },
        {
          subTitle: 'emobility_installation_selection.wallbox_up_to_22_kW',
          name: 'charging_station_22',
        },
        { subtTitle: '', name: 'charging_station_custom' },
      ],
    };
  },
  computed: {
    checkboxState: {
      get() {
        return this.$store.getters['get' + this.type + 'InspectionData']
          .emobility_charging_power_checked;
      },
      set(value) {
        this.$store.commit('set' + this.type + 'ChargingPowerSelected', value);
      },
    },
    selectedChargingPowerInspection: {
      get() {
        return this.$store.getters['get' + this.type + 'InspectionData']
          .emobility_charging_power_values.emobility_charging_power_inspection;
      },
      set(newValue) {
        this.$store.commit(
          'set' + this.type + 'ChargingPowerInspection',
          newValue
        );
        this.chargingStationCustomValueInspection = null;
      },
    },
    chargingStationCustomValueInspection: {
      get() {
        return this.$store.getters['get' + this.type + 'InspectionData']
          .emobility_charging_power_values
          .emobility_charging_power_custom_inspection;
      },
      set(value) {
        this.$store.commit(
          'set' + this.type + 'ChargingStationCustomValueInspection',
          value
        );
      },
    },
    chargingPowerDataSelected() {
      return this.$store.getters[
        'get' + this.type + 'IfDataChargingPowerSelected'
      ];
    },
  },
  methods: {
    disableButton() {
      if (this.chargingPowerDataSelected === true) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    chargingPowerDataSelected(newValue) {
      if (!newValue) {
        this.checkboxState = false;
      } else {
        this.checkboxState = true;
      }
    },
  },
};
</script>

<style>
.clickable {
  cursor: pointer;
  box-shadow: 0px 1px 2px #00000029;
  position: relative;
}
.checkbox_title_default {
  font-size: 20px;
  line-height: 24px;
}
.radio-group {
  display: flex;
  flex-direction: row;
  height: 150px;
  width: 50%;
  align-items: center;
}
</style>
