import { deleteAllEmptyFields } from '@/helpers/globalFunctions';
export const installationsModule = {
  state: {
    electric_installations: {
      e_charging_station: null,
      water_heater: null,
      heat_pump_hot_water: null,
      heat_pump: null,
    },
    gas_installations: {
      gas_cooker: null,
      terrace_connection: null,
      gas_heating_hot_water: null,
      gas_heating: null,
    },
  },
  getters: {
    getInstallationValue: (state) => (module, key) => {
      return state[module]?.[key];
    },
    getGasInstallations: (state) => {
      return state.gas_installations;
    },
  },
  mutations: {
    initModuleElectric(state, payload) {
      if (payload) {
        state.electric_installations = {
          ...state.electric_installations,
          ...payload,
        };
      }
    },
    initModuleGas(state, payload) {
      if (payload) {
        state.gas_installations = {
          ...state.gas_installations,
          ...payload,
        };
      }
    },
    UPDATE_INSTALLATIONS_MODULE(state, payload) {
      const oldValueObject = JSON.parse(
        JSON.stringify({
          electric_installations:
            this.state?.orders?.selectedOrder?.electric_installations,
          gas_installations:
            this.state?.orders?.selectedOrder?.gas_installations,
        })
      );

      if (payload.electric_installations) {
        state.electric_installations = {
          ...state.electric_installations,
          ...payload.electric_installations,
        };
      }

      if (payload.gas_installations) {
        state.gas_installations = {
          ...state.gas_installations,
          ...payload.gas_installations,
        };
      }

      const changedObject = JSON.parse(JSON.stringify(state));

      this.state.changed_object = deleteAllEmptyFields(changedObject);
      this.state.old_value_object = deleteAllEmptyFields(oldValueObject);
    },
  },
};
