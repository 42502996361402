<template>
  <div>
    <div class="dashboard">
      <div class="container_dashboard">
        <construction-dashboard-data-component
          :contactPerson="internalConstructionManagement.construction_manager"
          :title="
            $t('calendar_side_selection_component.construction_manager_NEW')
          "
          :constructionDate="
            internalConstructionManagement.construction_planned_date
          "
        >
          <template v-slot:icon>
            <baubesichtigungstermin-icon class="mr-1" />
          </template>
        </construction-dashboard-data-component>

        <construction-dashboard-data-component
          :companyName="groundwork.company_name"
          :contactPerson="groundwork.contact_person"
          :title="$t('construction_work.civil_engineering')"
          :constructionDate="groundwork.construction_planned_date"
        >
          <template v-slot:icon>
            <tiefbau-icon class="mr-1" />
          </template>
        </construction-dashboard-data-component>

        <construction-dashboard-data-component
          v-if="
            selectedOrderProduct.includes('electricity') ||
            this.selectedOrderProduct.includes('eMobility')
          "
          :companyName="assemblyElectricity.company_name"
          :contactPerson="assemblyElectricity.contact_person"
          :title="$t('calendar_side_selection_component.montage_electricity')"
          :constructionDate="assemblyElectricity.construction_planned_date"
        >
          <template v-slot:icon>
            <montage-strom-icon class="mr-1" />
          </template>
        </construction-dashboard-data-component>

        <construction-dashboard-data-component
          v-if="
            selectedOrderProduct.includes('gas') ||
            this.selectedOrderProduct.includes('water')
          "
          :companyName="assemblyGasWater.company_name"
          :contactPerson="assemblyGasWater.contact_person"
          :title="
            selectedOrderProduct === 'electricity&water&gas'
              ? $t('calendar_side_selection_component.montage_gas_water')
              : selectedOrderProduct === 'electricity&water'
              ? $t('calendar_side_selection_component.montage_water')
              : $t('calendar_side_selection_component.montage_gas')
          "
          :constructionDate="assemblyGasWater.construction_planned_date"
          :secondIcon="
            selectedOrderProduct === 'electricity&water&gas'
              ? (secondIcon = true)
              : false
          "
        >
          <template v-slot:icon>
            <montage-gas-icon
              class="mr-1"
              v-if="selectedOrderProduct.includes('gas')"
            />
            <montage-wasser-icon
              class="mr-1 mt-2"
              v-if="selectedOrderProduct === 'electricity&water'"
            />
          </template>
          <template
            v-slot:icon2
            v-if="selectedOrderProduct === 'electricity&water&gas'"
          >
            <montage-wasser-icon class="mr-1 mt-2" />
          </template>
        </construction-dashboard-data-component>
      </div>
      <div class="edit_icon">
        <v-btn icon @click="$emit('edit', $event)">
          <edit-icon />
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import ConstructionDashboardDataComponent from './ConstructionDashboardDataComponent';
import EditIcon from '../../icons/EditIcon';
import BaubesichtigungsterminIcon from '../../icons/BaubesichtigungsterminIcon';
import TiefbauIcon from '../../icons/TiefbauIcon';
import MontageStromIcon from '../../icons/MontageStromIcon';
import MontageGasIcon from '../../icons/MontageGasIcon';
import MontageWasserIcon from '../../icons/MontageWasserIcon';

export default {
  name: 'ConstructionDashboard',
  components: {
    ConstructionDashboardDataComponent,
    EditIcon,
    BaubesichtigungsterminIcon,
    TiefbauIcon,
    MontageStromIcon,
    MontageGasIcon,
    MontageWasserIcon,
  },
  data() {
    return {};
  },
  computed: {
    internalConstructionManagement() {
      return this.$store.getters.getSelectedOrder
        .internal_construction_management;
    },
    groundwork() {
      return this.$store.getters.getSelectedOrder.groundwork_company;
    },
    assemblyElectricity() {
      return this.$store.getters.getSelectedOrder.assembly_electricity_company
        ? this.$store.getters.getSelectedOrder.assembly_electricity_company
        : '';
    },
    assemblyGasWater() {
      return this.$store.getters.getSelectedOrder.assembly_gas_water_company
        ? this.$store.getters.getSelectedOrder.assembly_gas_water_company
        : '';
    },
    selectedOrderProduct() {
      return this.$store.getters.getSelectedOrder.product;
    },
  },
};
</script>

<style>
.container_dashboard {
  display: flex;
  background-color: rgba(255, 255, 255, 0.9);
  justify-content: space-between;
  width: 100%;
  padding: 20px 0 5px 15px;
  color: #6e6e6e;
  border-radius: 8px 0px 0px 8px;
}
.dashboard {
  display: flex;
  width: 100%;
  min-height: 48px;
}
.edit_icon {
  min-height: 100%;
  width: 6%;
  min-width: 48px;
  justify-content: center;
  align-items: center;
  display: flex;
  filter: brightness(1.1);
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 0px 8px 8px 0px;
}

@media screen and (max-width: 1024px) {
  .container_dashboard {
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.9);
    justify-content: space-between;
    width: 100%;
    padding: 20px 0 5px 15px;
    color: #6e6e6e;
    border-radius: 8px 0px 0px 8px;
  }
}
</style>
