import {
  COMPANY_TITLE,
  CITY_TITLE,
  PLANT_OPERATOR,
} from '../../helpers/constants';
import i18n from '../../plugins/i18n';
import {
  changedByObject,
  mapProductName,
} from '../../helpers/transformToPresentableData';

export function tableOrderMapper(order) {
  const addressExist =
    order.product_installation_full_address ||
    order.product_installation_with_plot_number_address;
  return {
    orderId: order.id,
    date: new Date(order.request_date),
    applicant: order.applicant ? applicantObject(order.applicant) : '',
    plant_operator: order.plant_operator
      ? applicantObject(order.plant_operator)
      : order.applicant_role === PLANT_OPERATOR
      ? applicantObject(order.applicant)
      : '',
    product: order.products ? productObject(order) : null,
    address: addressExist ? installationAddressObject(order) : '',
    lastChangedBy: changedByObject(order),
    status: order.status,
    journey: journeyNameEditor(order.journey),
  };
}

function applicantObject(applicant) {
  if (!applicant) {
    applicant = {};
  }
  return applicant.title === COMPANY_TITLE
    ? `${applicant.company_name}`
    : applicant.title === CITY_TITLE
    ? `${i18n.t('clerk_orders_overview_table.city')} ${applicant.city_name}`
    : `${applicant.surname} ${applicant.name}`;
}

function journeyNameEditor(journey) {
  return i18n.t(`feed_in_systems.product_title.${journey}`);
}

function productObject(order) {
  return (
    mapProductName(order.products) +
    (order.own_contribution_selected === true
      ? `, ${i18n.t('clerk_orders_overview_table.with_own_contribution')}`
      : '') +
    (order.preferred_network_connection === true
      ? `, ${i18n.t(
          'clerk_orders_overview_table.with_preferred_network_connection'
        )}`
      : '')
  );
}

function installationAddressObject(order) {
  const full_address = order.product_installation_full_address;
  const plot_address =
    order.product_installation_with_plot_number_address ?? {};
  return full_address
    ? `${full_address.street} ${full_address.street_number}, ${full_address.zip} ${full_address.city}`
    : `${plot_address.new_building_area_name || '-'}, Gemarkung: ${
        plot_address.district
      }, Flur: ${plot_address.landing}, Flurstück: ${plot_address.plot}, ${
        plot_address.zip
      }, ${plot_address.location}`;
}
