<template>
  <div class="d-flex align-self-center">
    <nav-link to="/" :title="$t('navigation_bar.dashboard')" class="mx-4" />

    <nav-link
      to="/netzanschluesse?page=1&journey=all&status=all&rt=all"
      :title="$t('navigation_bar.power_connection')"
      class="mx-4"
    />

    <nav-link
      to="/emobilitaet?page=1&status=all&rt=all"
      :title="$t('navigation_bar.emobility')"
      class="mx-4"
    />

    <nav-link
      to="/installateure?page=1&status=all&expiry=all&energy_type=all"
      :title="$t('navigation_bar.metrology')"
      class="mx-4"
    />

    <nav-link
      to="/einspeiseanlagen?page=1&status=all&performance=all"
      :title="$t('navigation_bar.feed_in_systems')"
      class="mx-4"
    />

    <!-- <nav-link
      to="/erneuerbareenergien"
      :title="$t('navigation_bar.renewable_energy')"
      class="mx-4"
    /> -->
  </div>
</template>

<script>
import NavLink from './NavLink';

export default {
  name: 'NavMenu',
  components: {
    NavLink,
  },
};
</script>
