var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"fill-height"},[_c('v-col',[_c('v-sheet',{attrs:{"height":"64"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","color":"grey darken-2"},on:{"click":_vm.setToday}},[_vm._v(" Heute ")]),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.prev}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-left ")])],1),(_vm.$refs.calendar)?_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e(),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.next}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-right ")])],1),_c('v-spacer'),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","color":"grey darken-2"}},'v-btn',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.typeToLabel[_vm.type]))]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.type = 'day';
                _vm.showCalendar();}}},[_c('v-list-item-title',[_vm._v("Tag")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'week';
                _vm.showCalendar();}}},[_c('v-list-item-title',[_vm._v("Woche")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'month';
                _vm.showCalendar();}}},[_c('v-list-item-title',[_vm._v("Monat")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = '4day';
                _vm.showCalendar();}}},[_c('v-list-item-title',[_vm._v("4 Tage")])],1),_c('v-list-item',{on:{"click":_vm.showList}},[_c('v-list-item-title',[_vm._v("Liste")])],1)],1)],1)],1)],1),(!_vm.ishiddenList)?_c('list-component',{attrs:{"events":_vm.events}}):_vm._e(),_c('v-sheet',{attrs:{"height":"600"}},[(_vm.ishiddenList)?_c('v-calendar',{ref:"calendar",attrs:{"color":"primary","event-color":"#F7F7F7","type":_vm.type,"events":_vm.events,"locale":"de"},on:{"click:event":_vm.showEvent,"click:more":_vm.viewDay,"click:date":_vm.viewDay,"change":_vm.getEvents},scopedSlots:_vm._u([{key:"event",fn:function(ref){
                var event = ref.event;
return [_c('div',{staticClass:"event_container"},[(event.details === 'groundwork_company')?_c('tiefbau-icon',{staticClass:"ml-1 event_icon",attrs:{"width":15,"height":15}}):_vm._e(),(event.details === 'internal_construction_management')?_c('baubesichtigungstermin-icon',{staticClass:"ml-1 event_icon",attrs:{"width":15,"height":15}}):_vm._e(),(event.details === 'assembly_electricity_company')?_c('montage-strom-icon',{staticClass:"ml-1 event_icon",attrs:{"width":15,"height":15}}):_vm._e(),(event.details === 'assembly_gas_water_company')?_c('montage-gas-icon',{staticClass:"ml-1 event_icon",attrs:{"width":15,"height":15}}):_vm._e(),(event.details === 'assembly_gas_water_company')?_c('montage-wasser-icon',{staticClass:"ml-5 event_icon",attrs:{"width":15,"height":15}}):_vm._e(),(
                event.details === 'assembly_electricity_company' ||
                event.details === 'internal_construction_management' ||
                event.details === 'groundwork_company'
              )?_c('div',{staticClass:"event_content"},[_vm._v(" "+_vm._s(event.name)+" ")]):_vm._e(),(event.details === 'assembly_gas_water_company')?_c('div',{staticClass:"event_content_exception"},[_vm._v(" "+_vm._s(event.name)+" ")]):_vm._e()],1)]}}],null,false,53238056),model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}}):_vm._e(),_c('v-menu',{attrs:{"close-on-content-click":false,"activator":_vm.selectedElement,"offset-x":"","right":"","offset-y":"","top":""},model:{value:(_vm.selectedOpen),callback:function ($$v) {_vm.selectedOpen=$$v},expression:"selectedOpen"}},[_c('calendar-details-component',{attrs:{"date":this.date,"selectedEventId":_vm.selectedEvent},on:{"close-dialog":_vm.closeDialog}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }