<template>
  <div>
    <div class="pipeline-background">
      <NEWPipelineComponent :statuses="other_statuses" />
      <NEWPipelineComponent :statuses="status_all" />
    </div>
    <FeedInSystemsOverviewTable />
  </div>
</template>

<script>
import FeedInSystemsOverviewTable from './FeedInSystemsOverviewTable';
import { NEWPipelineComponent } from '@newnetzportal/netzportal-webapp-storybook-components';
import { getFeedInSystemsTableConfig } from './photovoltaicOrdersTableConfig';
import { getPhotovoltaicsSummaryForPipeline } from '@/services/FeedInSystemsService';
import {
  CANCELLED_PSEUDO_STATUS,
  FEED_IN_STATUSES,
} from '../../helpers/constants';
export default {
  name: 'FeedInSystemsOverviewComponent',
  components: {
    FeedInSystemsOverviewTable,
    NEWPipelineComponent,
  },
  created() {
    this.getPVOrdersSummaryForPipelineFromBackend();
  },
  data() {
    return {
      ordersSummaryForPipeline: {},
      filterJourney: this.$route.params.journey
        ? this.$route.params.journey
        : this.$store.state.orders.journeyFilter === 'alle'
        ? ''
        : this.$store.state.orders.journeyFilter,
      countOrderStatuses: getFeedInSystemsTableConfig('countOrderStatuses'),
    };
  },
  watch: {
    journeyFilter(newValue) {
      if (newValue !== 'alle') {
        this.filterJourney = newValue;
      } else {
        this.filterJourney = '';
      }
      this.getPVOrdersSummaryForPipelineFromBackend();
    },
  },
  computed: {
    journeyFilter() {
      return this.$store.state.orders.journeyFilter;
    },
    status_all() {
      const summaryAllOrders = [
        {
          name: 'alle',
          color: '#999999',
          amount: this.ordersSummaryForPipeline.count_all,
          percentage: 100,
        },
      ];
      return summaryAllOrders;
    },
    other_statuses() {
      const otherStatuses = [];
      for (const element of this.countOrderStatuses) {
        otherStatuses.push({
          name: element.label,
          color: element.color,
          amount:
            this.ordersSummaryForPipeline[
              `count_${element.value.toLowerCase()}`
            ],
          percentage:
            (this.ordersSummaryForPipeline[
              `count_${element.value.toLowerCase()}`
            ] /
              this.ordersSummaryForPipeline.count_all) *
            100,
        });
      }

      const getTotalAmount = (statuses) =>
        statuses.reduce(
          (sum, status) =>
            sum +
            this.ordersSummaryForPipeline[
              `count_${status.value.toLowerCase()}`
            ],
          0
        );

      const getTotalPercentage = (totalAmount) =>
        (totalAmount / this.ordersSummaryForPipeline.count_all) * 100;

      const cancelledStatuses = [
        FEED_IN_STATUSES.declined,
        FEED_IN_STATUSES.protocol_cancelled,
      ];
      const totalCancelledAmount = getTotalAmount(cancelledStatuses);
      const totalCancelledPercentage = getTotalPercentage(totalCancelledAmount);

      //we combine all "cancelled status" in one pseudo-status
      const pseudoCancelledStatus = {
        name: CANCELLED_PSEUDO_STATUS.label,
        color: CANCELLED_PSEUDO_STATUS.color,
        amount: totalCancelledAmount,
        percentage: totalCancelledPercentage,
      };

      const filteredConfig = otherStatuses.filter(
        (status) => pseudoCancelledStatus.name !== status.name
      );

      const updatedFilteredConfig = filteredConfig.map((status) => {
        if (status.name === 'Auftrag storniert') {
          return { ...status, color: '#808080' };
        }
        return status;
      });

      return [...updatedFilteredConfig, pseudoCancelledStatus];
    },
  },
  methods: {
    getPVOrdersSummaryForPipelineFromBackend() {
      const params = {
        journey:
          !this.filterJourney || this.filterJourney === 'all'
            ? ''
            : this.filterJourney,
      };
      getPhotovoltaicsSummaryForPipeline(params)
        .then((res) => {
          this.ordersSummaryForPipeline =
            res.getPhotovoltaicsSummaryForPipeline;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
