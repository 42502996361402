export const emobilityUploadFilesModule = {
  state: {
    fileURLs: {},
    files: {},
    new_emob_file_uploaded: false,
  },
  mutations: {
    updateEmobilityFileNamesCadastral(state, file) {
      this.state.changed_object.uploaded_file_names_cadastral[file.index] =
        file.fileName;
    },
    setPlansAvailable(state, plans_available) {
      this.state.changed_object.plans_available = plans_available;
    },
    setEmobilityFileURL(state, object) {
      state.fileURLs[object.imageName] = object.url;
    },
    setEmobilityFileFromBackend(state, fileObject) {
      state.files[fileObject.type] = fileObject.fileObject;
    },
    setEmobilityFileHistoryFromBackend(state, file) {
      state.files[file.name] = file;
    },
    setEmobFileChanged(state, newFileUploaded) {
      state.new_emob_file_uploaded =
        state.new_emob_file_uploaded || newFileUploaded;
    },
    resetEmobFileChanged(state) {
      state.new_emob_file_uploaded = false;
    },
    resetEmobilityFileAndFileURLs(state) {
      state.files = {};
      state.fileURLs = {};
    },
  },
  getters: {
    getPlansAvailable: (state, getters, rootState) => {
      return rootState.changed_object.plans_available;
    },
    getEmobilityFileURLByName: (state) => (fileName) => {
      return state.fileURLs[fileName];
    },
    getEmobilityFileFromBackend: (state) => (fileName) => {
      return state.files[fileName];
    },
    getEmobFileChanged(state) {
      return state.new_emob_file_uploaded;
    },
  },
};
