<template>
  <div class="flex-container">
    <div style="width: 100%">
      <div style="border-radius: 8px 8px 0px 0px" class="flex-container-nested">
        <div
          class="list"
          v-if="
            this.selectedInstaller.concession_division.includes('electricity')
          "
        >
          <div class="checkbox_icons">
            <montage-strom-icon class="mr-2" />
            <p class="title_checkbox_list">Strom</p>
          </div>
          <div class="checkbox-list">
            <div
              v-for="(checkboxData, index) in checkBoxConfigElectricity"
              v-bind:key="checkboxData.title"
            >
              <TextCheckboxComponent
                :title="checkboxData.title"
                @update-checkbox-value="[checkboxData.method]"
                :index="index"
                :checkboxName="checkboxData.checkboxName"
                :requiredText="checkboxData.required_text"
                :showError="showError"
                :indexData="checkboxData.index"
              />
            </div>
          </div>
        </div>
        <div
          class="list"
          v-if="
            this.selectedInstaller.concession_division.includes('gas') ||
            this.selectedInstaller.concession_division.includes('water')
          "
        >
          <div class="checkbox_icons">
            <montage-gas-icon class="mr-2" />
            <montage-wasser-icon class="mr-2" />
            <p class="title_checkbox_list">Gas/Wasser</p>
          </div>
          <div class="checkbox-list">
            <div
              v-for="(checkboxData, index) in checkBoxConfigGasWater"
              v-bind:key="checkboxData.title"
            >
              <TextCheckboxComponent
                :title="checkboxData.title"
                @update-checkbox-value="[checkboxData.method]"
                :index="index"
                :checkboxName="checkboxData.checkboxName"
                :requiredText="checkboxData.required_text"
                :showError="showError"
                :indexData="checkboxData.index"
              />
            </div>
          </div>
        </div>
        <div class="note-contact-container">
          <div v-if="!isNoteUploadInfo">
            <NEWNoteUploadComponent
              @send-note-and-uploadfile="sendNoteAndFile($event)"
              @note-data-deleted="deleteNote = false"
              :deleteNote="deleteNote"
              :labels="noteLabels"
              class="mb-2"
            >
              <template v-slot:status-note-alert>
                <v-alert
                  class="alert_success"
                  style="background-color: #e4f0d6 !important"
                  :value="alert"
                  max-width="270px"
                  max-height="35px"
                  text
                  transition="scale-transition"
                  v-if="
                    lastUpdatedComponent === 'installerNoteUploadFileComponent'
                  "
                >
                  <template v-slot:append>
                    <v-icon color="green" class="ml-2">mdi-check</v-icon>
                  </template>
                  Erfolgreich gespeichert
                </v-alert>
                <v-alert
                  type="error"
                  class="alert_error mt-4"
                  max-width="270px"
                  v-if="alertErrorNote"
                  text
                  transition="scale-transition"
                >
                  Speicherung fehlgeschlagen
                </v-alert>
              </template>
            </NEWNoteUploadComponent>
          </div>
          <div v-else class="container--note-upload-pending">
            <v-progress-circular
              indeterminate
              color="primary"
              :size="60"
              :width="5"
            />
            <NewAlert
              class="mt-4"
              color="INFO"
              :message="$t('emobility_inspection.info_file_upload')"
            />
          </div>
        </div>
      </div>
      <div class="flex-container-nested">
        <div class="buttons-label">
          <button
            type="button"
            class="btn-confirm-inspection"
            @click="confirmInstallerInspection"
          >
            <div class="btn-text" style="width: inherit; color: white">
              <v-progress-circular
                indeterminate
                color="white"
                class="mr-4"
                v-if="showLoading"
              ></v-progress-circular>
              Prüfung bestätigen
            </div>
          </button>
          <button
            type="button"
            class="btn-cancel-inspection"
            @click="declineInspection"
          >
            <div class="btn-text" style="width: inherit">Prüfung ablehnen</div>
          </button>
          <button
            type="button"
            class="btn-save-current-state"
            @click="updateInstallerInspectionValues(false)"
          >
            Zwischenstand speichern
          </button>
          <v-alert
            class="alert_success ml-4"
            style="background-color: #e4f0d6 !important"
            :value="alert"
            max-width="270px"
            max-height="35px"
            text
            transition="scale-transition"
            v-if="lastUpdatedComponent === 'installerSaveCurrentResult'"
          >
            <template v-slot:append>
              <v-icon color="green" class="ml-2">mdi-check</v-icon>
            </template>
            Erfolgreich gespeichert
          </v-alert>
          <v-alert
            type="error"
            class="alert_error mt-4"
            max-width="270px"
            v-if="
              alertError &&
              lastUpdatedComponent === 'installerSaveCurrentResult'
            "
            text
            transition="scale-transition"
          >
            Speicherung fehlgeschlagen
          </v-alert>
        </div>
      </div>
      <modal-dialog
        v-if="isModalVisible"
        :value="noteValue"
        role="dialog"
        @cancel="closeModal"
      >
        <template v-slot:body>
          <div class="dialogTitle">Prüfung ablehnen</div>
        </template>
        <template v-slot:footer>
          <div class="footer_container">
            <div v-if="showNoteError" class="error_message_dialog">
              Bitte Ablehnungsgrund eingeben.
            </div>
            <div class="button_container">
              <button
                type="button"
                class="btn-save"
                @click="closeModalAndSaveData"
              >
                Prüfung ablehnen
              </button>
              <button type="button" class="btn-cancel" @click="closeModal">
                abbrechen
              </button>
              <v-alert
                type="error"
                class="alert_error mt-4"
                max-width="270px"
                v-if="
                  alertError &&
                  lastUpdatedComponent === 'installerLicenseRefused'
                "
                text
                transition="scale-transition"
              >
                Speicherung fehlgeschlagen
              </v-alert>
            </div>
          </div>
        </template>
      </modal-dialog>
    </div>
  </div>
</template>

<script>
import TextCheckboxComponent from '../technical-inspection-component/TextCheckboxComponent';
import MontageStromIcon from '../../icons/MontageStromIcon';
import MontageGasIcon from '../../icons/MontageGasIcon';
import MontageWasserIcon from '../../icons/MontageWasserIcon';
import { NEWNoteUploadComponent } from '@newnetzportal/netzportal-webapp-storybook-components';
import UserService from '../../services/UserService';
import { uploadFile } from '../../services/FileUploadService';
import ModalDialog from '@bit/new-netzportal.netzportal.modal-dialog';
import { emptyEventData } from '../../store/ordersModule';
import {
  getInstallerInspectionElectricityConfig,
  getInstallerInspectionGasConfig,
} from '../../helpers/installerInspectionConfig';
import { NewAlert } from '@bit/new-netzportal.netzportal.alert';
import noteService from '../../services/NoteService';
import { getTranslations } from '@/helpers/globalFunctions';

export default {
  name: 'InstallerInspectionComponent',
  components: {
    TextCheckboxComponent,
    MontageStromIcon,
    MontageGasIcon,
    MontageWasserIcon,
    NEWNoteUploadComponent,
    ModalDialog,
    NewAlert,
  },
  created() {
    this.$store.commit('resetChecksForInstallerInspection');
    this.setChecksForInstallerInspection();
    this.changedObject = this.getChangedData();
    this.$store.commit(
      'setOldValueObject',
      JSON.parse(JSON.stringify(this.changedObject))
    );
    this.$store.commit('setLastUpdatedComponent', '');
  },
  data() {
    return {
      showError: false,
      isModalVisible: false,
      noteValue: { note: '' },
      alert: false,
      alertErrorNote: false,
      alertError: false,
      showLoading: false,
      selectedInstaller: this.$store.getters.getSelectedInstaller,
      changedObject: this.$store.getters.getChangedObject,
      deleteNote: false,
      showNoteError: false,
      isNoteUploadInfo: false,
      noteLabels: getTranslations('note_upload'),
    };
  },
  watch: {
    notifyEvent(newValue) {
      if (
        newValue.field === 'addInstallerNote' ||
        newValue.field === 'updateInstallerInspectionData'
      )
        this.alert = true;
      setTimeout(() => {
        (this.alert = false),
          this.$store.commit('setNotifyEvent', emptyEventData),
          this.$store.commit('setLastUpdatedComponent', '');
      }, 3000);
    },
    noteValue: {
      handler(newValue) {
        newValue.note
          ? (this.showNoteError = false)
          : (this.showNoteError = true);
      },
      deep: true,
    },
  },
  computed: {
    checkBoxConfigGasWater() {
      const configName = this.$store.getters.getSelectedInstaller.internal
        ? 'internal'
        : 'external';
      return getInstallerInspectionGasConfig(configName);
    },
    checkBoxConfigElectricity() {
      const configName = this.$store.getters.getSelectedInstaller.internal
        ? 'internal'
        : 'external';
      return getInstallerInspectionElectricityConfig(configName);
    },
    userPersonalData() {
      return this.$store.getters.getUserId;
    },
    oldInstallerInspectionData() {
      return this.$store.getters.getOldInstallerInspectionData(
        this.selectedInstaller.internal
      );
    },
    installerInspectionData() {
      return this.$store.getters.getInstallerInspectionData(
        this.selectedInstaller.internal
      );
    },
    allDataElectricitySelected() {
      return this.$store.getters.getIfAllDataElectricitySelected(
        this.selectedInstaller.internal
      );
    },
    allDataGasWaterSelected() {
      return this.$store.getters.getIfAllDataGasWaterSelected(
        this.selectedInstaller.internal
      );
    },
    notifyEvent() {
      return this.$store.getters.getNotifyEvent
        ? this.$store.getters.getNotifyEvent
        : emptyEventData;
    },
    lastUpdatedComponent() {
      return this.$store.getters.getLastUpdatedComponent
        ? this.$store.getters.getLastUpdatedComponent
        : '';
    },
  },
  methods: {
    declineInspection() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
      this.showNoteError = false;
      this.noteValue = { note: '' };
    },
    closeModalAndSaveData() {
      if (this.noteValue.note === '') {
        this.showNoteError = true;
      } else {
        const noteObj = {
          id: this.selectedInstaller.id,
          user_id: this.selectedInstaller.user_id,
          title: 'Ablehnungsgrund',
          note: this.noteValue.note,
        };
        this.sendNote(noteObj);
        this.$store.commit(
          'setLastUpdatedComponent',
          'installerLicenseRefused'
        );
        const currentStatus = this.getChangedData();
        this.$store.commit('setOldValueObject', currentStatus);
        this.$store.commit('setChangedObject', {
          status: 'LICENSE_REQUEST_REFUSED',
        });
        this.updateStatus();
        this.isModalVisible = false;
        this.noteValue = { note: '' };
        this.showNoteError = false;
      }
    },
    setChecksForInstallerInspection() {
      if (this.selectedInstaller.concession_division.includes('electricity')) {
        this.setCompanyDataElectricitySelected(
          !!this.selectedInstaller.company_data_checked_electricity
        );
        this.setResponsibleSpecialistsElectricitySelected(
          !!this.selectedInstaller.responsible_specialists_checked_electricity
        );
        this.setDocumentsElectricitySelected(
          !!this.selectedInstaller.documents_checked_electricity
        );
        this.setProfessionalCompetenceElectricitySelected(
          !!this.selectedInstaller.professional_competence_checked_electricity
        );
        this.setInstallerIDElectricitySelected(
          !!this.selectedInstaller.installer_id_checked_electricity
        );
      }
      if (
        this.selectedInstaller.concession_division.includes('gas') ||
        this.selectedInstaller.concession_division.includes('water')
      ) {
        this.setCompanyDataGasWaterSelected(
          !!this.selectedInstaller.company_data_checked_gas_water
        );
        this.setResponsibleSpecialistsGasWaterSelected(
          !!this.selectedInstaller.responsible_specialists_checked_gas_water
        );
        this.setDocumentsGasWaterSelected(
          !!this.selectedInstaller.documents_checked_gas_water
        );
        this.setProfessionalCompetenceGasWaterSelected(
          !!this.selectedInstaller.professional_competence_checked_gas_water
        );
        this.setInstallerIDGasWaterSelected(
          !!this.selectedInstaller.installer_id_checked_gas_water
        );
      }
    },
    setCompanyDataElectricitySelected(value) {
      this.$store.commit('setCompanyDataElectricitySelected', value);
    },
    setResponsibleSpecialistsElectricitySelected(value) {
      this.$store.commit('setResponsibleSpecialistsElectricitySelected', value);
    },
    setDocumentsElectricitySelected(value) {
      this.$store.commit('setDocumentsElectricitySelected', value);
    },
    setProfessionalCompetenceElectricitySelected(value) {
      this.$store.commit('setProfessionalCompetenceElectricitySelected', value);
    },
    setInstallerIDElectricitySelected(value) {
      this.$store.commit('setInstallerIDElectricitySelected', value);
    },
    setCompanyDataGasWaterSelected(value) {
      this.$store.commit('setCompanyDataGasWaterSelected', value);
    },
    setResponsibleSpecialistsGasWaterSelected(value) {
      this.$store.commit('setResponsibleSpecialistsGasWaterSelected', value);
    },
    setDocumentsGasWaterSelected(value) {
      this.$store.commit('setDocumentsGasWaterSelected', value);
    },
    setProfessionalCompetenceGasWaterSelected(value) {
      this.$store.commit('setProfessionalCompetenceGasWaterSelected', value);
    },
    setInstallerIDGasWaterSelected(value) {
      this.$store.commit('setInstallerIDGasWaterSelected', value);
    },
    async sendNoteAndFile(value) {
      this.isNoteUploadInfo = true;
      const noteObj = {
        id: this.selectedInstaller.id,
        note: value.note,
        title: value.title,
        filename: value.filename,
        user_id: this.selectedInstaller.user_id,
      };
      this.$store.commit(
        'setLastUpdatedComponent',
        'installerNoteUploadFileComponent'
      );
      if (value.note) {
        try {
          const response = await this.sendNote(noteObj);

          // Has to get the "_persistent_event_id_" from addInstallerNote; used for s3-subfoldering
          if (value.filename) {
            const url = (
              await UserService.putInstallerDocumentGraphQL(
                `${this.selectedInstaller.id}/${response.addInstallerNote._persistent_event_id_}/${value.filename}`,
                process.env.VUE_APP_STAGE
              )
            ).uploadFile;
            await uploadFile(url, value.file);
            this.deleteNote = true;
          }
          this.isNoteUploadInfo = false;
        } catch (err) {
          this.isNoteUploadInfo = false;
          this.alertErrorNote = true;
          console.error(err);
        }
      }
    },
    async sendNote(noteObj) {
      try {
        const response = await noteService.addInstallerNote(
          noteObj,
          this.userPersonalData
        );
        this.deleteNote = true;
        return response;
      } catch (err) {
        this.alertErrorNote = true;
        console.error(err);
      }
    },
    updateInstallerInspectionValues(confirmed) {
      const changedObject = {
        id: this.selectedInstaller.id,
        user_id: this.selectedInstaller.user_id,
        old_value: this.oldInstallerInspectionData,
        new_value: this.installerInspectionData,
      };
      if (confirmed === true) {
        changedObject.status = 'completed';
        changedObject.old_value.update_expiry_dates = this.selectedInstaller
          .update_expiry_dates
          ? true
          : false;
        changedObject.new_value.update_expiry_dates = false;
      }
      this.$store.commit(
        'setLastUpdatedComponent',
        'installerSaveCurrentResult'
      );
      UserService.updateInstallerInspectionData(
        changedObject,
        this.userPersonalData
      )
        .then((res) => {
          if (res.error) {
            this.alertError = true;
          }
        })
        .catch((err) => {
          this.alertError = true;
          console.log(err);
        });
    },
    getChangedData() {
      const currentInstaller = JSON.parse(
        JSON.stringify(this.$store.getters.getSelectedInstaller)
      );
      const status = { status: currentInstaller.status };
      return status;
    },
    confirmInstallerInspection() {
      if (
        this.selectedInstaller.concession_division.length === 1 &&
        this.selectedInstaller.concession_division[0] === 'electricity'
      ) {
        if (this.allDataElectricitySelected === true) {
          this.updateInstallerInspectionValues(true);
        }
      } else {
        this.showError = true;
      }

      if (
        this.selectedInstaller.concession_division.includes('gas') ||
        this.selectedInstaller.concession_division.includes('water')
      ) {
        if (
          this.selectedInstaller.concession_division.includes('electricity')
        ) {
          if (
            this.allDataGasWaterSelected === true &&
            this.allDataElectricitySelected === true
          ) {
            this.updateInstallerInspectionValues(true);
          }
        } else if (this.allDataGasWaterSelected === true) {
          this.updateInstallerInspectionValues(true);
        }
      } else {
        this.showError = true;
      }
    },
    updateStatus() {
      this.showError = false;
      this.showLoading = true;
      const requestBody = {
        id: this.selectedInstaller.id,
        user_id: this.selectedInstaller.user_id,
        new_value: this.$store.getters.getChangedObject,
        old_value: this.$store.getters.getOldValueObject,
        remark: this.noteValue.note,
      };
      UserService.updateInstallerConcessionStatus(
        requestBody,
        this.userPersonalData
      )
        .then((res) => {
          this.showLoading = false;
          if (res.updateInstallerConcessionStatus) {
            this.$store.commit(
              'updateSelectedInstallerData',
              JSON.parse(res.updateInstallerConcessionStatus.new_value)
            );
          }
          if (res.error) {
            this.alertError = true;
          }
        })
        .catch((err) => {
          this.alertError = true;
          console.log(err);
        }),
        this.$store.commit('deleteChangedObject');
      this.$store.commit('deleteOldValueObject');
    },
  },
};
</script>

<style>
.flex-container {
  display: flex;
  color: #6e6e6e;
}
.note-contact-container {
  display: flex;
  flex-direction: column;
}
.flex-container-nested {
  display: flex;
  background-color: rgba(255, 255, 255, 0.9);
  min-height: 56px;
  flex-direction: row;
  padding: 16px;
  justify-content: space-between;
  display: flex;
  margin-bottom: 1px;
}

.buttons-label {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.btn-cancel-inspection {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 1px #00000029;
  cursor: pointer;
  padding: 0px;
  border-radius: 8px;
  height: 40px;
  width: 252px;
  border: none;
  opacity: 1;
  margin-left: 30px;
}

.btn-confirm-inspection {
  background: #9b004b 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 1px #00000029;
  cursor: pointer;
  padding: 0px;
  border-radius: 8px;
  height: 40px;
  width: 252px;
  border: none;
  opacity: 1;
}

.btn-save-current-state {
  border: none;
  font-size: 18px;
  cursor: pointer;
  background: transparent;
  text-decoration: underline;
  color: #6e6e6e !important;
  margin-left: 30px;
}

.btn-text {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6e6e6e;
  opacity: 1;
}

button:focus {
  outline: none;
}

.checkbox-list {
  display: flex;
  flex-direction: column;
  width: 100% !important;
}
.dialogTitle {
  font-size: 25px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: left;
}
.error_message {
  color: red;
  font-size: 16px;
  margin-bottom: 10px;
}
.footer_container {
  display: flex;
  flex-direction: column;
  margin-left: 70px;
}
.list {
  display: flex;
  flex-direction: column;
}
.title_checkbox_list {
  font-weight: bold;
}
.checkbox_icons {
  display: flex;
  flex-direction: row;
}
.error_message_dialog {
  color: red;
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 3px;
}
@media screen and (max-width: 1279px) and (min-width: 1024px) {
  .flex-container-nested {
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.9);
    min-height: 56px;
    padding: 16px;
    justify-content: space-between;
    margin-bottom: 1px;
  }
}

.container--note-upload-pending {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
