var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticStyle:{"justify-content":"flex-end","margin-right":"0px"}}),_c('single-raw',{staticClass:"single-raw",attrs:{"first":"","title":"Bestelldatum","editable":false}},[_vm._v(_vm._s(_vm._f("formatDate")(new Date(_vm.orderDetails.requestDate),'DD.MM.YYYY HH:mm')))]),_c('single-raw',{staticClass:"single-raw",attrs:{"title":_vm.$t('order_details.connection_address')},on:{"edit":function($event){return _vm.$emit('show-modal', {
        component: 'InstallationAddressComponent',
        method: 'updateInstallationAddress',
        location: 'orderDetailsComponent',
      })}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.orderDetails.installationAddress)}}),(
        this.notifyEvent.field === 'updateInstallationAddress' &&
        this.lastUpdatedLocation === 'orderDetailsComponent'
      )?_c('userfeedback-component'):_vm._e()],1),_c('single-raw',{staticClass:"single-raw",attrs:{"title":_vm.$t('order_details.connection_type')},on:{"edit":function($event){return _vm.$emit('show-modal', {
        component: 'ProductAvailabilityComponent',
        method: 'updateProduct',
        location: 'orderDetailsComponent',
      })}}},[_c('div',{domProps:{"innerHTML":_vm._s(((_vm.$t(_vm.orderDetails.product)) + " "))}}),(this.notifyEvent.field === 'updateProduct')?_c('userfeedback-component'):_vm._e()],1),(_vm.selectedOrder.journey !== 'eMobility')?_c('single-raw',{staticClass:"single-raw",attrs:{"title":_vm.$t('order_details.installations')},on:{"edit":function($event){return _vm.$emit('show-modal', {
        component: 'InstallationDialog',
        method: 'updateInstallations',
        location: 'orderDetailsComponent',
      })}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.orderDetails.selectedInstallations)}}),(this.notifyEvent.field === 'updateInstallations')?_c('userfeedback-component'):_vm._e()],1):_vm._e(),_c('single-raw',{staticClass:"single-raw",attrs:{"title":_vm.$t('order_details.total_price')},on:{"edit":function($event){return _vm.$emit('show-modal', {
        component: 'PricingComponent',
        method: 'updatePricingsData',
        location: 'orderDetailsComponent',
      })}}},[_c('pricing-details-component',{attrs:{"totalPrice":_vm.orderDetails.totalPrice,"priceDetails":_vm.orderDetails.priceDetails,"netWorkConnection":_vm.selectedOrder.preferred_network_connection}}),(this.notifyEvent.field === 'updatePricingsData')?_c('userfeedback-component'):_vm._e()],1),_c('order-file-row',{staticClass:"single-raw",attrs:{"title":_vm.$t('order_details.blueprints'),"filesArray":_vm.selectedFiles},on:{"edit":function($event){return _vm.$emit('show-modal', {
        component: 'OrderFileUploadComponent',
        method: 'updateFilesName',
        location: 'orderDetailsComponent',
        properties: { filesObject: $event },
      })}}},[(
        this.notifyEvent.field === 'updateFilesName' &&
        this.lastUpdatedLocation === 'orderDetailsComponent'
      )?_c('userfeedback-component'):_vm._e()],1),_c('single-raw',{staticClass:"single-raw",attrs:{"title":_vm.$t('order_details.appointment')},on:{"edit":function($event){return _vm.$emit('show-modal', {
        component: 'AppointmentDialog',
        method: 'updateAppointmentForConnection',
        location: 'orderDetailsComponent',
      })}}},[_vm._v(_vm._s(_vm.orderDetails.appointment)+" "),(this.notifyEvent.field === 'updateAppointmentForConnection')?_c('userfeedback-component'):_vm._e()],1),_c('single-raw',{staticClass:"single-raw",attrs:{"title":_vm.$t('order_details.installation_company')},on:{"edit":function($event){return _vm.$emit('show-modal', {
        component: 'InstallerCompaniesComponent',
        method: 'updateInstallationCompany',
        location: 'orderDetailsComponent',
      })}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.orderDetails.installationCompanies)}}),(this.notifyEvent.field === 'updateInstallationCompany')?_c('userfeedback-component'):_vm._e()],1),_c('single-raw',{staticClass:"single-raw",attrs:{"title":_vm.$t('order_details.applicant')},on:{"edit":function($event){return _vm.$emit('show-modal', {
        component: 'AppplicantComponent',
        method: 'updateApplicant',
        location: 'orderDetailsComponent',
      })}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.orderDetails.applicant)}}),(
        this.notifyEvent.field === 'updateApplicant' &&
        this.lastUpdatedLocation === 'orderDetailsComponent'
      )?_c('userfeedback-component'):_vm._e()],1),_c('single-raw',{staticClass:"single-raw",attrs:{"title":_vm.$t('order_details.alternative_invoice_address')},on:{"edit":function($event){return _vm.$emit('show-modal', {
        component: 'InvoiceAddressComponent',
        method: 'updateInvoiceAddress',
        location: 'orderDetailsComponent',
      })}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.orderDetails.invoiceReceiver)}}),(this.notifyEvent.field === 'updateInvoiceAddress')?_c('userfeedback-component'):_vm._e()],1),_c('single-raw',{staticClass:"single-raw",attrs:{"title":_vm.$t('order_details.additional_contact_person')},on:{"edit":function($event){return _vm.$emit('show-modal', {
        component: 'AdditionalContactComponent',
        method: 'updateAdditionalContactPerson',
        location: 'orderDetailsComponent',
      })}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.orderDetails.otherContacts)}}),(this.notifyEvent.field === 'updateAdditionalContactPerson')?_c('userfeedback-component'):_vm._e()],1),_c('single-raw',{staticClass:"single-raw",attrs:{"title":_vm.$t('order_details.landowner'),"last":!_vm.orderDetails.wallbox_location},on:{"edit":function($event){return _vm.$emit('show-modal', {
        component: 'LandownerComponent',
        method: 'updateLandowner',
        location: 'orderDetailsComponent',
      })}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.orderDetails.landowner)}}),(this.notifyEvent.field === 'updateLandowner')?_c('userfeedback-component'):_vm._e()],1),(_vm.orderDetails.wallbox_location)?_c('div',[_c('single-raw',{staticClass:"single-raw",attrs:{"title":_vm.$t('order_details.wallbox_location')},on:{"edit":function($event){return _vm.$emit('show-modal', {
          component: 'EmobilityWallboxLocationComponent',
          method: 'updateOrderEmobilityWallboxLocation',
          location: 'orderDetailsComponent',
        })}}},[_c('div',[_vm._v(_vm._s(_vm.orderDetails.wallbox_location))]),(_vm.notifyEvent.field === 'updateOrderEmobilityWallboxLocation')?_c('userfeedback-component'):_vm._e()],1),_c('single-raw',{staticClass:"single-raw",attrs:{"title":_vm.$t('emobility_details.charging_power')},on:{"edit":function($event){return _vm.$emit('show-modal', {
          component: 'EmobilityChargingPowerComponent',
          method: 'updateOrderEmobilityChargingPower',
          location: 'orderDetailsComponent',
        })}}},[_c('div',[_vm._v(_vm._s(_vm.orderDetails.e_mobility_installation))]),(_vm.notifyEvent.field === 'updateOrderEmobilityChargingPower')?_c('userfeedback-component'):_vm._e()],1),_c('single-raw',{staticClass:"single-raw",attrs:{"title":_vm.$t('order_details.emobility_plans'),"last":!!_vm.orderDetails.wallbox_location},on:{"edit":function($event){return _vm.$emit('show-modal', {
          component: 'OrderEmobilityFileUploadComponent',
          method: 'updateOrderEmobilityFilesName',
          location: 'orderDetailsComponent',
        })}}},[(!!_vm.orderDetails.emobility_plans)?_c('div',[_c('div',[_vm._v(" "+_vm._s(_vm.$t('emobility_details.site_plan'))+" "),_vm._l((_vm.orderDetails.emobility_plans),function(item,index){return _c('v-btn',{key:item,attrs:{"icon":""},on:{"click":function($event){return _vm.downloadOrderFile(item, index)}}},[_c('v-icon',[_vm._v("mdi-download")])],1)})],2)]):_c('div',[_c('div',[_vm._v("-")])]),(_vm.notifyEvent.field === 'updateOrderEmobilityFilesName')?_c('userfeedback-component'):_vm._e()],1)],1):_vm._e(),_c('ErrorDialog',{attrs:{"show-error-dialog":_vm.showErrorDialog},on:{"close":_vm.closeDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }