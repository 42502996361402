<template>
  <div>
    <selection-card
      :title="
        selectedOrderProduct === 'electricity&water&gas'
          ? $t('calendar_side_selection_component.montage_gas_water')
          : selectedOrderProduct === 'electricity&water'
          ? $t('calendar_side_selection_component.montage_water')
          : $t('calendar_side_selection_component.montage_gas')
      "
    >
      <template v-slot:icon>
        <montage-gas-icon
          class="mr-2"
          v-if="selectedOrderProduct.includes('gas')"
        />
        <montage-wasser-icon
          class="mr-2"
          v-if="selectedOrderProduct.includes('water')"
        />
      </template>
      <v-autocomplete
        :attach="true"
        outlined
        dense
        :label="$t('construction_work.company_name')"
        clearable
        return-object
        :items="filteredCompanies"
        v-model.lazy="selectedCompany"
        :item-text="companyText"
        class="ml-2"
        :error-messages="assemblyGasWaterCompanyError"
        item-value="company_name"
      >
        <template v-slot:no-data>
          <div
            class="pl-3"
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            "
          >
            {{ $t('construction_work.no_data_text') }}
          </div>
        </template>
      </v-autocomplete>
      <v-autocomplete
        v-if="selectedCompany"
        :attach="true"
        dense
        outlined
        color="#9B004B"
        :label="$t('construction_work.contact_person')"
        clearable
        :items="companyContactPersons"
        v-model.lazy="selectedContactPerson"
        :item-text="contactPersonText"
        item-value="name"
        class="ml-2"
        :error-messages="assemblyGasWaterContactPersonError"
        return-object
      >
        <template v-slot:no-data>
          <div
            class="pl-3"
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            "
          >
            {{ $t('construction_work.no_data_text') }}
          </div>
        </template>
      </v-autocomplete>
      <div class="ml-3">
        <date-time-component
          @save-hours-minutes="saveDate($event)"
          @save-dates="saveDate($event)"
          v-model="valueItem.construction_planned_date"
          :v="v.construction_planned_date"
        >
        </date-time-component>
      </div>
    </selection-card>
  </div>
</template>

<script>
import DateTimeComponent from './DateTimeComponent';
import SelectionCard from './SelectionCard';
import MontageGasIcon from '../../icons/MontageGasIcon';
import MontageWasserIcon from '../../icons/MontageWasserIcon';

export default {
  name: 'AssemblyGasWaterComponent',
  components: {
    MontageGasIcon,
    MontageWasserIcon,
    SelectionCard,
    DateTimeComponent,
  },
  props: {
    companies: {
      type: Array,
      required: true,
    },
    v: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      valueItem: {},
      companyContactPersons: [],
      filteredCompanies: [],
    };
  },
  created() {
    this.valueItem = { ...this.value };
  },
  mounted() {
    this.filterCompanyByRegionalTeam();
  },
  methods: {
    saveDate(object) {
      this.$store.commit('setMontageGasWasser' + object.key, object.value);
    },
    contactPersonText(value) {
      return value.name + ', ' + value.function;
    },
    companyText(value) {
      return (
        value.company_name +
        ', ' +
        value.street +
        ' ' +
        value.street_number +
        ', ' +
        value.zip +
        ' ' +
        value.city
      );
    },
    filterCompanyByRegionalTeam() {
      this.filteredCompanies = [];
      const selectedRegionalTeam = this.selectedRegionalTeam;
      this.companies.forEach(async (company) => {
        const isContactPersonForRegionalTeam =
          await company.contact_persons.some(function (contactPerson) {
            return contactPerson.regions.includes(selectedRegionalTeam);
          });
        if (isContactPersonForRegionalTeam) {
          this.filteredCompanies.push(company);
        }
      });
    },
    filterPersonsByRegionalTeamAndCompanyType(contactPersons) {
      const selectedRegionalTeam = this.selectedRegionalTeam;
      return contactPersons.filter(function (el) {
        return (
          el.regions.includes(selectedRegionalTeam) && el.assembly_gas_water
        );
      });
    },
    setContactPersonList() {
      const selectedCompanyObject = this.filteredCompanies.find(
        (company) => company.company_name === this.selectedCompany
      );
      if (selectedCompanyObject) {
        this.companyContactPersons =
          this.filterPersonsByRegionalTeamAndCompanyType(
            selectedCompanyObject.contact_persons
          );
      }
    },
  },
  watch: {
    selectedRegionalTeam(newVal) {
      if (newVal) {
        this.filterCompanyByRegionalTeam();
      }
    },
    companies() {
      this.filterCompanyByRegionalTeam();
    },
    filteredCompanies(newValue) {
      if (newValue && this.selectedCompany) {
        this.setContactPersonList();
      }
    },
  },
  computed: {
    assemblyGasWaterCompanyError() {
      const errors = [];
      if (!this.v.company_name.$dirty) return errors;
      !this.v.company_name.required && errors.push('Bitte Firma auswählen');
      return errors;
    },
    assemblyGasWaterContactPersonError() {
      const errors = [];
      if (!this.v.contact_person.$dirty) return errors;
      !this.v.contact_person.required &&
        errors.push('Bitte Ansprechpartner auswählen');
      return errors;
    },
    selectedRegionalTeam() {
      return this.$store.getters.getRegionalTeamShort;
    },
    selectedOrderProduct() {
      return this.$store.getters.getSelectedOrder.product;
    },
    selectedCompany: {
      get() {
        return this.valueItem.company_name;
      },
      set(newselectedMontageGasWasser) {
        if (newselectedMontageGasWasser) {
          this.valueItem.company_name =
            newselectedMontageGasWasser.company_name;
          this.companyContactPersons =
            this.filterPersonsByRegionalTeamAndCompanyType(
              newselectedMontageGasWasser.contact_persons
            );
          this.$store.commit(
            'setMontageGasWasser',
            newselectedMontageGasWasser.company_name
          );
        } else {
          this.valueItem.company_name = null;
          this.$store.commit('setMontageGasWasser', null);
        }
        this.montageStromContactPerson = null;
      },
    },
    selectedContactPerson: {
      get() {
        return this.valueItem.contact_person;
      },
      set(newmontagesgaswasserContactPerson) {
        const contactPersonName = newmontagesgaswasserContactPerson
          ? newmontagesgaswasserContactPerson.name
          : '';
        this.valueItem.contact_person = contactPersonName;
        this.$store.commit(
          'setMontageGasWasserContactPerson',
          contactPersonName
        );
      },
    },
  },
};
</script>
