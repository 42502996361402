import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
// eslint-disable-next-line
import styles from './scss/_main.scss';
import router from './router';
import i18n from './plugins/i18n';
import store from './store';
export const EventBus = new Vue();
import AWSAppSyncClient from 'aws-appsync';
import VueApollo from 'vue-apollo';
import appSyncConfig from './AppSync';
import auth from './cognito-auth/cognitoAuth';
import dayjs from './plugins/dayjs';
import '@/helpers/filters';
import Vuelidate from 'vuelidate';
import logger from './plugins/logger';
import { setLoggerInstance } from './services/LoggerService';

const config = {
  url: appSyncConfig.graphqlEndpoint,
  region: appSyncConfig.region,
  auth: {
    type: appSyncConfig.authenticationType,
    jwtToken: async () =>
      auth.auth.getSignInUserSession().getIdToken().jwtToken,
  },
};
const options = {
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
};

const client = new AWSAppSyncClient(config, options);
const appsyncProvider = new VueApollo({
  defaultClient: client,
});

Vue.config.productionTip = false;
Vue.use(VueApollo);
Vue.use(Vuelidate);
Vue.use(logger);
const loggerInstance = Vue.prototype.$frontendLogger;
setLoggerInstance(loggerInstance);

new Vue({
  vuetify,
  i18n,
  dayjs,
  store,
  router,
  provide: appsyncProvider.provide(),
  render: (h) => h(App),
}).$mount('#app');
