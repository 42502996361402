// use env variable in future
const isLoggingEnabled = true;
function useLogger() {
  // level: 'debug' | 'info' | 'warn' | 'error'
  function log(level, ...args) {
    if (isLoggingEnabled === true) {
      // eslint-disable-next-line no-console
      console[level](...args);
    }
  }

  return {
    debug: (...args) => log('debug', ...args),
    info: (...args) => log('info', ...args),
    warn: (...args) => log('warn', ...args),
    error: (...args) => log('error', ...args),
  };
}

export default {
  // eslint-disable-next-line no-unused-vars
  install(Vue, options) {
    const logger = useLogger();
    Vue.frontendLogger = logger;
    Vue.prototype.$frontendLogger = logger;
  },
};
