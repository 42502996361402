const addressObject = `
  {
    street
    street_number
    city
    country
    zip
  }`;

const personalDataObject = `
  title
  surname
  name`;

const contactDataObject = `
  phone_number
  email
  additional_phone_number
  preferred_contact_channel`;

const personDataObject = `
    ${personalDataObject}
    legal_title
    company_name
    city_name
    ${contactDataObject}
    address ${addressObject}
  `;

const electricInstallationsObject = `
  electric_installations {
    heat_pump_power_rating
    water_heaters_power_rating
    e_charging_station
    water_heater
    heat_pump_hot_water
    heat_pump
    selected_installations
  }`;

const gasInstallationsObject = `
  gas_installations {
    cooker_gas_rating
    heating_gas_rating
    gas_heating_hot_water
    gas_heating
    gas_cooker
    terrace_connection
    selected_heating
    selected_installations
  }`;

const waterInstallationsObject = `
  water_installations {  
    apartment_units_water_ratings
    self_supply
    file_name
  }`;

const connectionDataElectricityObject = `
  connection_data_electricity {
    measurement_voltage
    measuring_level
    ownership_limit_electricity
    voltage_level
  }`;

const connectionDataGasObject = `
  connection_data_gas {
    gas_group
    ownership_limit_gas
    pressure_level
    withdrawal_pressure
  }`;

const permissionsOrderObject = `
  permissions {
    perm_contract
    perm_customer_satisfaction
    perm_forward_personal_dat
  }`;

const combinedPricingsObject = `
  combined_pricings {
    total
    subtotal
    own_contribution
    possible_own_contribution
    electricity_pricings{
      price
    }
    gas_pricings{
      price
    }
    emobility_pricings{
      price
    }
    water_pricings{
      price
      water_building_costs
    }
  }`;

const plotNumberAddressObject = `
  product_installation_with_plot_number_address {
    district
    landing
    location
    new_building_area_name
    plot
    zip
  }`;

const plannedDateObject = `
  construction_planned_date {
    dates
    start_time {
      start_hour
      start_minute
    }
    end_time {
      end_hour
      end_minute
    }
  }`;

const companyObject = `
  company_name
  contact_person
  ${plannedDateObject}
`;

const regionalTeamObject = `
  regional_team {
    team_id
    team_name
  }`;

const userQuestionsObject = `
  index
  title
  note
  `;

const coordinatesObject = `
  {
    lat
    lng
  }`;

const emobilityPayloadObject = `
  emobility_payload {
    file_uploads
    e_mobility_installation
    charging_station_custom_value
    wallbox_location
    wallbox_location_custom_value
  }`;

const businessDataObject = `
  business_data {
    business_type
    electric_installations_kW
    gas_installations_kW
    water_installations_m3_h
  }`;

const emobChargingPowerObject = `
  emobility_charging_power_values {
    emobility_charging_power_inspection
    emobility_charging_power_custom_inspection
  }`;

const commonParamObject = `
  id
  user_id    
  journey               
  status                
  request_date   
  last_changed_by
  last_update 
  `;

const orderSpecificObject = `
  products
  own_contribution_selected
  preferred_network_connection
  `;

const storageInverterObject = `
  storage_inverter {
    active_power
    apparent_power
    rated_current
    shift_factor
  }`;

const energyStorageObject = `
energy_storage {
  id
  manufacturer
  type
  useful_capacity_wh
  coupled
  isolated
  current
  number_of_inverters
  number_of_phases
  conformity_certificate_file_name
  protection_certificate_file_name
  ${storageInverterObject}
  storage_loading_without_procurement
  storage_loading_with_procurement
  storage_discharge_without_procurement
  storage_discharge_with_procurement
  trickle_charge_deactivated
  function_test_completed
  has_power_flow_monitoring_certificate
  }`;

const existingSystems = `
existing_systems {
  pv_system_W
  plugin_pv_system_W
  cogeneration_VA
  wind_power_VA
  standby_system_VA
  other_system_name
  other_system_VA
  file_name
  pv_system_implementing_date
  plugin_pv_system_implementing_date
  cogeneration_implementing_date
  wind_power_implementing_date
  standby_system_implementing_date
  other_system_implementing_date
}`;

const accountDataObject = `
  account_data {
    account_owner
    iban
    bank
    bic
  }`;

const taxDataObject = `
  tax_data {
    tax_number
    vat_number
  }`;

const financeDataObject = `
finance_data {
  is_small_company
  is_reseller
  ust_proof_file_name
  assignment_notice_file_name
  ${accountDataObject}
  ${taxDataObject}
}`;

const landownerObject = `
  landowner {
    ${personalDataObject}
    company_name
    city_name
    address ${addressObject}
  }`;

const maStRObject = `
maStR {
  EEG_number
  storage_number
  EEG_implementing_date
  register_date
  }`;

const storageMaStRObject = `
storage_maStR {
  EEG_number
  storage_number
  EEG_implementing_date
  register_date
  }`;

const pvInspectionAdditionObject = `
  addition {
    title
    comment
  }
`;

const anfrageTechnicalInspectionObject = `
  pvrequest_inspection {
    voltage
    customer_station
    customer_station_plan_filename
    ${pvInspectionAdditionObject}
  }`;

const inbetriebnahmeTechnicalInspectionObject = `
  pvimplementation_inspection {
    measure_concept_checked
    e_plan_checked
    market_location_provider
    measuring_location_provider
    counting_method
    ${pvInspectionAdditionObject}
  }
  `;

const postalDataObject = `
  address ${addressObject}
  city_name
  company_name
  name
  surname
  title
  legal_title
`;

const alternateInvoiceRecipientObject = `
  alternate_invoice_recipient {
    ${postalDataObject}
  }
`;
const alternateDeliveryAddressObject = `
  alternate_delivery_address {
    ${postalDataObject}
  }
`;
const protokollTechnicalInspectionObject = `
  pvimplementationprotocol_inspection {
    technical_specifications_checked
    implementing_date_checked
    market_location
    counters {
      type_of_usage
      measuring_location
      counter_id
      valid_from
      counter_type
      charge_type
      current_direction
      mount_type
      measuring_device_type
      obis_key
      pre_decimal_places
      decimal_places
    }
    ${pvInspectionAdditionObject}
  }
  `;

const permissionsPVObject = `
  permissions {
    perm_customer_satisfaction
    perm_forward_personal_dat
    confirmation_of_documentation
    perm_of_cost_radio_ripple_control_receiver
    perm_of_cost_remote_control_system
  }`;

const PVInverterObject = `
pv_inverters {
  id
  manufacturer
  type
  number_of_inverters
  active_power
  apparent_power
  rated_current
  e4_cert_number
  e6_cert_number
  e4_certificate_file_name
  e6_certificate_file_name
}`;

const PVSystemInfosObject = `
pv_system_infos {
  pv_system_type
  pv_system_power_W
  modules_number
  single_module_performance_W
  inverter_performance_W
  inverters_number
  single_inverter_performance_W
  max_effective_power_W
  max_apparent_power_VA
}`;

const symmetryRequirementObject = `
  symmetry_requirement {
    type 
    outer_conductor_l1_VA
    outer_conductor_l2_VA
    outer_conductor_l3_VA
  }`;

const powerFlowMonitoringObject = `
  power_flow_monitoring {
    is_power_flow_monitored
    monitored_power_flow_W
  }`;

const systemProtectionObject = `
  system_protection {
    built_in_system_protection
    central_system_protection
    trip_test_completed
    trip_circuit_completed
  }`;

export const getOrderByIdAndDate = `
query getOrderByIdAndDate($id: String) { 
  getOrderByIdAndDate( id: $id) {
    ${commonParamObject}
    ${orderSpecificObject}
    applicant {
      alternative_invoice_address ${addressObject}
      ${personDataObject}
      login_new_email
    }
    alternative_invoice_recipient {
      ${personalDataObject}
      address ${addressObject}
    }
    alternative_invoice
    coordinates ${coordinatesObject}
    ${connectionDataElectricityObject}
    ${connectionDataGasObject}
    connection_data_checked
    blueprints_checked
    gis_checked
    customer_information_checked
    address_checked
    appointment_for_connection
    date_unit
    appointment_for_connection_presentable
    ${electricInstallationsObject}
    ${gasInstallationsObject}
    ${waterInstallationsObject}
    e_mobility_installation
    e_charging_station_controllable_selected
    number_of_charging_station
    number_of_charging_points
    installation_company_electricity_presentable
    installation_company_electricity
    installation_company_gas_presentable
    installation_company_gas
    journey
    number_of_charging_points
    number_of_charging_station	
    product
    product_installation_full_address ${addressObject}
    uploaded_file_names_blueprint
    uploaded_file_names_cadastral
    presentable_installations
    same_landowner
    ${landownerObject}
    additional_contact_person {
      ${personalDataObject}
      ${contactDataObject}
    }
    invoice_address {
      company_name
      city_name
      ${personalDataObject}
      address ${addressObject}
    }
    ${combinedPricingsObject}
    ${plotNumberAddressObject}
    assembly_electricity_company {
      ${companyObject}
    }
    assembly_gas_water_company {
      ${companyObject}
    }
    groundwork_company {
      groundwork_by_client
      ${companyObject}
    }
    internal_construction_management {
      construction_manager
      ${plannedDateObject}
      ${regionalTeamObject}
    }
    ${permissionsOrderObject}
    ${regionalTeamObject}
    user_questions {
      ${userQuestionsObject}
    }
    ${emobilityPayloadObject}
    ${emobChargingPowerObject}
    emobility_charging_power_checked
    residential_units
    business_units
    requires_grid_crosspoint
    ${businessDataObject}
  }
}`;

export const getOrdersWithFilter = `
query getOrdersWithFilter($limit: Int, $from_index: Int, $order_status: String, $regional_team: String, $journey: String, $search_word: String) { 
  getOrdersWithFilter( limit: $limit, from_index: $from_index, regional_team: $regional_team, order_status: $order_status, journey: $journey, search_word: $search_word ) {
    items {
      ${commonParamObject}
      ${orderSpecificObject}
      applicant {
        alternative_invoice_address ${addressObject}
        ${personDataObject}
      }               
      product_installation_full_address ${addressObject}               
      ${plotNumberAddressObject}
      ${regionalTeamObject}
  }
  count_orders
}
}`;

export const getPhotovoltaicsWithFilterQuery = `
query getPhotovoltaicsWithFilter($limit: Int, $from_index: Int, $order_status: [String], $journey: String, $min_pv_system_power_W: Int, $max_pv_system_power_W: Int, $search_word: String, $start_date: String, $end_date: String) {
  getPhotovoltaicsWithFilter(limit: $limit, from_index: $from_index, order_status: $order_status, journey: $journey, min_pv_system_power_W: $min_pv_system_power_W, max_pv_system_power_W: $max_pv_system_power_W, search_word: $search_word, start_date: $start_date, end_date: $end_date) {
    items {
      ${commonParamObject}
      applicant {
        ${personDataObject}
      }
      applicant_role
      plant_operator {
        ${personDataObject}
      }
      product_installation_full_address ${addressObject}
      ${plotNumberAddressObject}
    }
    count_orders
  }
}`;

export const getPhotovoltaicByIdQuery = `
query getPhotovoltaicById($id: String) { 
  getPhotovoltaicById( id: $id) {
    ${commonParamObject}
    applicant {
      ${personDataObject}
      login_new_email
    }
    service_provider {
      ${personDataObject}
    }
    appointment_for_connection
    applicant_role
    case_number
    coordinates ${coordinatesObject}
    counter_id
    counter_id_data {
      counter_id
      file_name
      two_way_measurement_built_in
    }
    counters_to_remove_data {
      counter_id
      file_name
    }
    new_counter_data {
      simultaneous_power_w
      consumption_prospect_wh
      commissioning_application_file_name
    }
    feed_in_compensation
    feedin_management
    feed_in_profile
    feed_in_prospect_wh
    parameter_set
    file_names
    has_existing_systems
    implementing_date
    installation_company_electricity
    installation_company_electricity_id
    is_storage_planned
    reactive_power_provision
    pv_location
    same_landowner
    tenant_electricity_surcharge
    measure_concept
    plant_operator {
      ${personDataObject}
    }
    product_installation_full_address ${addressObject}
    pv_location_coordinates ${coordinatesObject}
    pv_storage_key
    pv_system_key
    pv_request_questions {
      ${userQuestionsObject}
    }
    pv_impl_questions {
      ${userQuestionsObject}
    }
    pv_impl_protocol_questions {
      ${userQuestionsObject}
    }
    reactive_power_type
    ${landownerObject}
    ${plotNumberAddressObject}
    ${permissionsPVObject}
    ${PVSystemInfosObject}
    ${PVInverterObject}
    ${energyStorageObject}
    ${existingSystems}
    ${financeDataObject}
    ${maStRObject}
    ${storageMaStRObject}
    ${symmetryRequirementObject}
    ${powerFlowMonitoringObject}
    ${systemProtectionObject}
    ${anfrageTechnicalInspectionObject}
    ${inbetriebnahmeTechnicalInspectionObject}
    ${protokollTechnicalInspectionObject}
    ${alternateInvoiceRecipientObject}
    ${alternateDeliveryAddressObject}

  }
}`;
