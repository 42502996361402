import {
  changedByObject,
  formatDate,
  mapProductName,
  installationFullAddressObject,
} from '../../helpers/transformToPresentableData';

export function tableItemMapper(item) {
  return {
    itemId: item.id,
    date: formatDate(item.request_date, 'DD.MM.YYYY HH:mm'),
    company_name: item.company_data.company_name,
    energy_type: mapProductName(item.concession_division_en),
    status: item.status,
    badge_id: item.presentable_badge_id,
    lastChangedBy: changedByObject(item),
    address: installationFullAddressObject(item.company_data.company_address),
  };
}
