<template>
  <div>
    <div class="hint-box">
      <div class="hint-left-side">
        <p class="hint-title mb-1">{{ $t('technical_inspection.hint') }}</p>
        <div class="hints-container">
          <ol>
            <li
              v-if="data.same_landowner === false"
              class="first-hint hint-list new-test-first-hint"
            >
              {{ $t('technical_inspection.other_landowner') }}
            </li>
            <li
              v-if="data.preferred_network_connection === true"
              class="hint-list new-test-hint-list"
            >
              {{ $t('technical_inspection.preferred_network_connection') }}
            </li>
          </ol>
        </div>
      </div>
      <exclamation-triangle-icon class="hint-icon" />
    </div>
  </div>
</template>

<script>
import ExclamationTriangleIcon from '../../../icons/ExclamationTriangleIcon';

export default {
  name: 'HintComponent',
  components: {
    ExclamationTriangleIcon,
  },
  props: {
    data: {
      type: Object,
    },
  },
};
</script>

<style>
.hint-box {
  display: flex;
  flex-direction: row;
  background-color: #fcf8e0;
  padding-left: 15px;
  padding-top: 10px;
  border-radius: 8px;
  margin-bottom: 15px;
}
.hint-left-side {
  display: flex;
  flex-direction: column;
}
.hint-title {
  color: #6e6e6e;
  font-size: 20px;
}
.hints-container {
  display: flex;
  margin-bottom: 10px;
}
.first-hint {
  margin-right: 30px;
}
.hint-icon {
  margin-left: auto;
  margin-right: 130px;
  margin-top: 25px;
}
.hint-list {
  float: left;
}
</style>
