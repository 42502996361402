<template>
  <div class="foo-bar">
    <div
      id="foo-data"
      :style="
        first
          ? 'border-radius: 8px 0 0 0'
          : last
          ? 'border-radius: 0 0 0 8px'
          : ''
      "
    >
      <div id="title">{{ title }}</div>
      <div id="content"><slot></slot></div>
    </div>

    <div
      id="action"
      :style="
        first
          ? 'border-radius: 0 8px 0 0'
          : last
          ? 'border-radius: 0 0 8px 0'
          : ''
      "
    >
      <v-btn icon @click="$emit('edit', $event)" v-if="editable">
        <v-icon v-if="notEditable" :color="this.color">mdi-eye</v-icon>
        <edit-icon v-else />
      </v-btn>
    </div>
  </div>
</template>

<script>
import EditIcon from '../../icons/EditIcon';

export default {
  name: 'SingleRaw',
  components: {
    EditIcon,
  },
  props: {
    title: {
      type: String,
      default: '-',
    },
    editable: {
      type: Boolean,
      default: true,
    },
    first: {
      type: Boolean,
      default: false,
    },
    last: {
      type: Boolean,
      default: false,
    },
    notEditable: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
    },
  },
};
</script>

<style scoped>
#foo-data {
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-self: flex-start;
  width: 100%;
  padding: 18px 0 18px 25px;
  margin-right: 1px;
}
#title {
  width: 30%;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0;
  color: #6e6e6e;
  opacity: 1;
}

#content {
  width: 70%;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0;
  color: #6e6e6e;
  opacity: 1;
}
#action {
  min-height: 100%;
  width: 6%;
  min-width: 48px;
  justify-content: center;
  align-items: center;
  display: flex;
  filter: brightness(1.1);
  background-color: rgba(255, 255, 255, 0.6);
}

.foo-bar {
  display: flex;
  width: 100%;
  min-height: 48px;
}

.round-top-corner {
  border-radius: 8px 0 0 0;
}

.round-bottom-corner {
  border-radius: 0 0 0 8px;
}
</style>
