<template>
  <div>
    <selection-card
      :title="$t('calendar_side_selection_component.team_selection')"
    >
      <buttons-regional-team
        v-model="valueItem.regional_team"
        :v="v.regional_team"
        @save-regional-team="saveRegionalteam($event)"
      ></buttons-regional-team>
    </selection-card>
    <selection-card
      :title="$t('calendar_side_selection_component.construction_manager_NEW')"
    >
      <template v-slot:icon>
        <baubeabsichtigungstermin-icon class="mr-2" />
      </template>
      <v-autocomplete
        :attach="true"
        outlined
        dense
        :label="$t('construction_work.contact_person')"
        clearable
        return-object
        :items="filtered_construction_managers"
        v-model.lazy="constructionManager"
        :item-text="contactPersonText"
        item-value="name"
        :error-messages="constructionManagerError"
        class="ml-2"
      ></v-autocomplete>
      <div class="optional_date">
        <div class="mr-2">
          {{
            $t('calendar_side_selection_component.date_construction_intention')
          }}
        </div>
        <v-checkbox
          color="#9B004B"
          :label="$t('calendar_side_selection_component.label_optional')"
          style="width: 100px"
          v-model="construction_planned_date_selected"
        ></v-checkbox>
      </div>
      <div class="ml-3" v-if="construction_planned_date_selected">
        <date-time-component
          @save-hours-minutes="saveDate($event)"
          @save-dates="saveDate($event)"
          v-model="valueItem.construction_planned_date"
          :v="v.construction_planned_date"
        >
        </date-time-component>
      </div>
    </selection-card>
  </div>
</template>

<script>
import BaubeabsichtigungsterminIcon from '../../icons/BaubesichtigungsterminIcon';
import DateTimeComponent from './DateTimeComponent';
import ButtonsRegionalTeam from './ButtonsRegionalTeam';
import SelectionCard from './SelectionCard';

export default {
  name: 'InternalConstructionManagement',
  components: {
    BaubeabsichtigungsterminIcon,
    DateTimeComponent,
    ButtonsRegionalTeam,
    SelectionCard,
  },
  props: {
    managers: {
      type: Array,
      required: true,
    },
    v: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      valueItem: {},
      construction_planned_date_selected: true,
      filtered_construction_managers: [],
    };
  },
  created() {
    this.valueItem = { ...this.value };
  },
  methods: {
    saveDate(object) {
      this.$store.commit(
        'setInternalConstructionManagement' + object.key,
        object.value
      );
    },
    saveRegionalteam(regionalTeam) {
      this.$store.commit('setRegionalTeam', regionalTeam);
    },
    contactPersonText(value) {
      return value.name + ', ' + value.function;
    },
    filterConstructionManagers(selectedRegionalTeam) {
      this.filtered_construction_managers = this.managers.filter(function (el) {
        return el.region.includes(selectedRegionalTeam);
      });
    },
  },
  computed: {
    selectedRegionalTeam() {
      return this.$store.getters.getRegionalTeamShort;
    },
    constructionManager: {
      get() {
        return this.valueItem.construction_manager;
      },
      set(newconstructionManager) {
        this.valueItem.construction_manager = newconstructionManager
          ? newconstructionManager.name
          : '';
        this.$store.commit(
          'setConstructionManager',
          this.valueItem.construction_manager
        );
      },
    },
    constructionManagerError() {
      const errors = [];
      if (!this.v.construction_manager.$dirty) return errors;
      !this.v.construction_manager.required &&
        errors.push('Bitte Ansprechpartner auswählen');
      return errors;
    },
  },
  watch: {
    selectedRegionalTeam(newVal) {
      if (newVal) {
        this.filterConstructionManagers(newVal);
      }
    },
    managers(newVal) {
      if (newVal) {
        if (this.selectedRegionalTeam) {
          this.filterConstructionManagers(this.selectedRegionalTeam);
        }
      }
    },
  },
};
</script>

<style>
.optional_date {
  display: flex;
  flex-direction: row;
  margin-left: 11px;
}
</style>
