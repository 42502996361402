<template>
  <div>
    <div class="heading">{{ $t(title) }}</div>
    <div class="loading" v-if="loading">
      <v-progress-circular
        indeterminate
        :size="75"
        color="white"
      ></v-progress-circular>
    </div>
    <p v-if="!loading">{{ $t('mail_link_page.thank_you') }}</p>
    <p v-if="!loading">{{ $t(extra) }}</p>
  </div>
</template>

<script>
import ExecuteEmailActionService from '@/services/ExecuteEmailActionService';

export default {
  name: 'MailLinkPage',

  data() {
    this.usp = new URLSearchParams(window.location.search);
    return {
      title:
        'mail_link_page.message_name_' + (this.usp.get('message_name') || ''),
      extra:
        'mail_link_page.message_extra_' + (this.usp.get('message_name') || ''),
      loading: true,
    };
  },

  async mounted() {
    await ExecuteEmailActionService.executeEmailAction(window.location.search);
    this.loading = false;
  },
};
</script>
