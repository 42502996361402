import { COMPANY_TITLE, CITY_TITLE } from '@/helpers/constants';

export const emptyEventData = {
  changed_by: '',
  created: '',
  event_origin: '',
  field: '',
  id: '',
  new_value: '',
  note: '',
  old_value: '',
  payload: '',
};

export const feedInSystemsModule = {
  state: {
    selectedFeedInSystem: {},
    journeyFilter: 'alle',
    feedInStatusFilter: '',
    notifyEvent: emptyEventData,
    lastUpdatedComponent: '',
    lastUpdatedLocation: '',
    feedInSystemPerformanceFilter: '',
    minPVSystemPowerWFilter: null,
    maxPVSystemPowerWFilter: null,
    new_pv_file_uploaded: false,
  },
  mutations: {
    deleteSelectedFeedInSystem(state) {
      state.selectedFeedInSystem = {};
    },
    setSelectedFeedInSystem(state, selectedFeedInSystem) {
      state.selectedFeedInSystem = selectedFeedInSystem;
    },
    updateSelectedFeedInSystemData(state, newValue) {
      Object.keys(newValue).forEach((changedPart) => {
        state.selectedFeedInSystem[changedPart] = newValue[changedPart];
      });
    },
    updateLastChangedByInSelectedFeedInSystem(state, last_changed_by) {
      state.selectedFeedInSystem.last_changed_by = last_changed_by;
    },
    updateLastUpdateInSelectedFeedInSystem(state, last_update) {
      state.selectedFeedInSystem.last_update = last_update;
    },
    setFeedInSystemStatusFilter(state, feedInSystemStatusFilter) {
      state.feedInSystemStatusFilter = feedInSystemStatusFilter;
    },
    setSelectedFeedInSystemCalendar(state, selectedFeedInSystemCalendar) {
      state.selectedFeedInSystemCalendar = selectedFeedInSystemCalendar;
    },
    setPVperformanceFilter(state, feedInSystemPerformanceFilter) {
      state.feedInSystemPerformanceFilter = feedInSystemPerformanceFilter;
    },
    setMinPVSystemPowerWFilter(state, minPVSystemPowerWFilter) {
      state.minPVSystemPowerWFilter = minPVSystemPowerWFilter;
    },
    setMaxPVSystemPowerWFilter(state, maxPVSystemPowerWFilter) {
      state.maxPVSystemPowerWFilter = maxPVSystemPowerWFilter;
    },
    setPVFileChanged(state, newFileUploaded) {
      state.new_pv_file_uploaded =
        state.new_pv_file_uploaded || newFileUploaded;
    },
    resetPVFileChanged(state) {
      state.new_pv_file_uploaded = false;
    },
  },
  getters: {
    getSelectedFeedInSystem(state) {
      return state.selectedFeedInSystem;
    },
    getSelectedFeedInSystemCalendar(state) {
      return state.selectedFeedInSystemCalendar;
    },
    getFeedInSystemstatusFilter(state) {
      return state.feedInSystemStatusFilter;
    },
    getPVperformanceFilter(state) {
      return state.feedInSystemPerformanceFilter;
    },
    getMinPVSystemPowerWFilter(state) {
      return state.minPVSystemPowerWFilter;
    },
    getMaxPVSystemPowerWFilter(state) {
      return state.maxPVSystemPowerWFilter;
    },
    getSelectedFeedInSystemApplicantName(state) {
      let applicantName = '';
      if (state.selectedFeedInSystem.applicant) {
        if (state.selectedFeedInSystem.applicant.title === COMPANY_TITLE) {
          applicantName = state.selectedFeedInSystem.applicant.company_name;
        } else if (state.selectedFeedInSystem.applicant.title === CITY_TITLE) {
          applicantName = state.selectedFeedInSystem.applicant.city_name;
        } else {
          applicantName =
            (state.selectedFeedInSystem.applicant.title
              ? state.selectedFeedInSystem.applicant.title
              : '') +
            ' ' +
            state.selectedFeedInSystem.applicant.name +
            ' ' +
            state.selectedFeedInSystem.applicant.surname;
        }
      }
      return applicantName;
    },
    getSelectedFeedInSystemAddress(state) {
      let installationAddress = '';
      if (state.selectedFeedInSystem.product_installation_full_address) {
        installationAddress =
          state.selectedFeedInSystem.product_installation_full_address.street +
          ' ' +
          state.selectedFeedInSystem.product_installation_full_address
            .street_number +
          ', ' +
          state.selectedFeedInSystem.product_installation_full_address.zip +
          ' ' +
          state.selectedFeedInSystem.product_installation_full_address.city;
      } else if (
        state.selectedFeedInSystem.product_installation_with_plot_number_address
      ) {
        installationAddress =
          state.selectedFeedInSystem
            .product_installation_with_plot_number_address
            .new_building_area_name +
          ', ' +
          'Gemarkung: ' +
          state.selectedFeedInSystem
            .product_installation_with_plot_number_address.district +
          ', ' +
          'Flur: ' +
          state.selectedFeedInSystem
            .product_installation_with_plot_number_address.landing +
          ', ' +
          'Flurstück: ' +
          state.selectedFeedInSystem
            .product_installation_with_plot_number_address.plot +
          ', ' +
          state.selectedFeedInSystem
            .product_installation_with_plot_number_address.zip +
          ', ' +
          state.selectedFeedInSystem
            .product_installation_with_plot_number_address.location;
      }
      return installationAddress;
    },
    getSelectedFeedInSystemInspectionData(state) {
      const selectedFeedInSystem = state.selectedFeedInSystem;
      const inspectionData = {
        customer_information_checked:
          !!selectedFeedInSystem.customer_information_checked,
        blueprints_checked: !!selectedFeedInSystem.blueprints_checked,
        gis_checked: !!selectedFeedInSystem.gis_checked,
        address_checked: !!selectedFeedInSystem.address_checked,
        connection_data_checked: !!selectedFeedInSystem.connection_data_checked,
        connection_data_electricity:
          selectedFeedInSystem.connection_data_electricity,
        connection_data_gas: selectedFeedInSystem.connection_data_gas,
      };
      if (selectedFeedInSystem.emobility_payload) {
        inspectionData.emobility_charging_power_checked =
          !!selectedFeedInSystem.emobility_charging_power_checked;
        inspectionData.emobility_charging_power_values =
          selectedFeedInSystem.emobility_charging_power_values
            ? selectedFeedInSystem.emobility_charging_power_values
            : {
                emobility_charging_power_inspection: '',
                emobility_charging_power_custom_inspection: '',
              };
      }
      return inspectionData;
    },
    getFeedInSystemBlueprints(state) {
      return state.selectedFeedInSystem.uploaded_file_names_blueprint
        ? state.selectedFeedInSystem.uploaded_file_names_blueprint
        : [];
    },
    getFeedInSystemCadastrals(state) {
      return state.selectedFeedInSystem.uploaded_file_names_cadastral
        ? state.selectedFeedInSystem.uploaded_file_names_cadastral
        : [];
    },
    getFeedInSystemCoordinates(state) {
      return state.selectedFeedInSystem.coordinates
        ? state.selectedFeedInSystem.coordinates
        : { lat: null, lng: null };
    },
    getPVFileChanged(state) {
      return state.new_pv_file_uploaded;
    },
  },
};
