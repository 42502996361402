export const invoiceAddressModule = {
  state: {},
  mutations: {
    setInvoiceRecipientAddressChangedObject(state, changedData) {
      this.state.changed_object.invoice_address.address[changedData.key] =
        changedData.value;
    },
    setInvoiceRecipientChangedObject(state, object) {
      this.state.changed_object.invoice_address[object.key] = object.value;
    },
    setAlternativeInvoice(state, alternative_invoice) {
      this.state.changed_object.alternative_invoice = alternative_invoice;
    },
    set_form_alternative_invoice_recipient_address_zip_from_google(
      state,
      value
    ) {
      state.address_zip_from_google = value;
    },
    setChangedObjectInvoiceAddress(state, invoice_address) {
      this.state.changed_object.invoice_address = invoice_address;
    },
    set_form_invoice_address_address_zip_from_google(state, value) {
      state.invoice_address_address_zip_from_google = value;
    },
  },
  getters: {
    get_form_alternative_invoice_recipient_address_zip_from_google: (state) => {
      return state.invoice_address_address_zip_from_google;
    },
    getAlternativeInvoice: (state, getters, rootState) => {
      return rootState.changed_object.alternative_invoice;
    },
  },
  actions: {},
};
