<template>
  <v-dialog v-model="dialog" max-width="500">
    <div class="container">
      <v-btn id="closeBtn" icon @click="closeDialog"
        ><v-icon>mdi-close</v-icon></v-btn
      >
      <span class="first_info pb-2">
        {{ $t('error_dialog.could_not_upload_order') }}
      </span>
      <span class="pb-2">
        {{ $t('error_dialog.contact_netzportal') }}
        <a href="mailto: netzportal@new-netz.de">netzportal@new-netz.de</a>.
      </span>
      <span class="pb-2">
        {{ $t('error_dialog.we_will_contact_you') }}
      </span>
      <v-btn
        max-width="100px"
        style="align-self: center; margin-top: 6px"
        @click="closeDialog"
        >OK</v-btn
      >
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'ErrorDialog',
  data() {
    return {};
  },
  computed: {
    dialog() {
      return this.showErrorDialog;
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close');
    },
  },
  props: {
    showErrorDialog: Boolean,
  },
};
</script>

<style scoped>
.container {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  min-height: 160px;
  max-height: 360px;
  color: white;
  background-color: #9b004b;
}
#closeBtn {
  position: absolute;
  top: 0;
  right: 0;
  color: white;
}

a {
  color: white !important;
}

.first_info {
  margin-left: 12px;
  margin-right: 12px;
}
</style>
