<template>
  <v-btn-toggle
    class="pb-4"
    dense
    mandatory
    color="#9B004B"
    background-color="transparent"
    v-model="regionalTeam"
  >
    <div
      class="toggle-button-div"
      v-for="value in valuesList"
      :key="value.team_name"
    >
      <v-slide-x-reverse-transition leave-absolute>
        <v-btn
          class="ml-2 mdi-check-button"
          v-if="regionalTeam === value.team_name"
          rounded
          :color="value.color"
        >
          <v-icon color="white" size="24">mdi-check</v-icon>
        </v-btn>
      </v-slide-x-reverse-transition>
      <transition name="slide-fade"></transition>
      <v-btn
        class="ml-2 pill-button"
        rounded
        active-class="active-pill-button"
        min-width="48px"
        :color="value.color"
        :value="value.team_name"
      >
        <div class="button-content">{{ value.label }}</div>
      </v-btn>
    </div>
  </v-btn-toggle>
</template>

<script>
export default {
  name: 'ButtonsRegionalTeam',
  props: {
    valuesList: {
      default: () => [
        {
          label: 'Regionalteam VIE',
          team_name: 'RT Viersen',
          color: '#302c81',
          team_id: '0516603200014575',
          team_short: 'VIE',
        },
        {
          label: 'Regionalteam MG',
          team_name: 'RT Mönchengladbach',
          color: '#24478A',
          team_id: '0511600000006767',
          team_short: 'MG',
        },
        {
          label: 'Regionalteam HS',
          team_name: 'RT Heinsberg',
          color: '#006CAD',
          team_id: '0537000400001338',
          team_short: 'HS',
        },
        {
          label: 'Regionalteam RKN',
          team_name: 'RT R-K-N',
          color: '#7BBDEC',
          team_id: '0516200800001605',
          team_short: 'RKN',
        },
      ],
      type: Array,
      required: false,
    },
    v: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      valueItem: {},
      buttonActive: false,
    };
  },
  created() {
    this.valueItem = { ...this.value };
  },
  computed: {
    regionalTeam: {
      get() {
        return this.valueItem.team_name;
      },
      set(newValue) {
        const selectedTeam = this.valuesList.find(
          (element) => element.team_name === newValue
        );
        const regionalTeamObject = {
          team_name: newValue,
          team_id: selectedTeam.team_id,
        };
        this.valueItem.team_name = newValue;
        this.valueItem.team_id = selectedTeam.team_id;
        this.$store.commit('setRegionalTeamShort', selectedTeam.team_short);
        this.$emit('save-regional-team', regionalTeamObject);
      },
    },
  },
};
</script>

<style scoped>
.toggle-button-div:hover,
.toggle-button-div:focus {
  opacity: 0.8 !important;
  filter: brightness(90%);
}
.pill-button {
  height: 32px !important;
  border-radius: 30px !important;
  padding: 8px 16px !important;
  color: transparent !important;
}
.active-pill-button {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  margin-left: 1px !important;
  padding: 8px 16px 8px 8px !important;
}
.mdi-check-button {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  height: 32px !important;
  min-width: 32px !important;
  padding: 0px !important;
}
.button-content {
  color: white;
  letter-spacing: 0px;
}
.theme--light.v-btn {
  color: none !important;
}
</style>
