const emptyObject = {
  title: '',
  name: '',
  surname: '',
  phone_number: '',
  additional_phone_number: '',
  email: '',
  preferred_contact_channel: '',
};

export const emobilityAdditionalContactModule = {
  state: {
    additional_contact_person_state: false,
  },
  mutations: {
    setEmobilityAdditionalContactPerson(state, object) {
      this.state.changed_object.additional_contact_person[object.key] =
        object.value;
    },
    setEmobilityAdditionalContactPersonState(
      state,
      additional_contact_person_state
    ) {
      state.additional_contact_person_state = additional_contact_person_state;
    },
    resetEmobilityAdditionalContactPerson() {
      this.state.changed_object.additional_contact_person = emptyObject;
    },
  },
  getters: {
    getEmobilityAdditionalContactPersonState: (state) => {
      return state.additional_contact_person_state;
    },
  },
  actions: {},
};
