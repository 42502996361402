<template>
  <div>
    <div class="heading">Vielen Danke für Ihre Registrierung!</div>
    <p class="page_description">
      Wir prüfen gerade Ihre Zugangsberechtigung und werden Sie in Kürze per
      Email informieren.
    </p>
    <v-row align="center">
      <v-btn class="btn-new" @click="$router.push('/login')">
        <button>{{ $t('auth_page.go_back') }}</button>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'ErrorPage',
};
</script>

<style scoped>
.btn-new {
  border: none;
  cursor: pointer;
  border-radius: 8px;
  width: 158px;
  height: 38px;
  letter-spacing: 0px;
  font-size: 17px;
  margin-top: 16px;
}
</style>
