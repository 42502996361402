export const photovoltaicModule = {
  state: {
    nextToken: null,
    allPhotovoltaicsLoaded: false,
    numberOfAllPhotovoltaics: 0,
    selectedPhotovoltaic: {},
    allPhotovoltaics: [],
    photovoltaicStatusFilter: '',
    photovoltaicRegionalTeamFilter: '',
  },
  mutations: {
    deleteSelectedPhotovoltaic(state) {
      state.selectedPhotovoltaic = {};
    },
    setSelectedPhotovoltaic(state, selectedPhotovoltaic) {
      state.selectedPhotovoltaic = selectedPhotovoltaic;
    },
    updatePhotovoltaicAvailableProducts(state, availableProducts) {
      state.availableProducts = availableProducts;
    },
    updateSelectedPhotovoltaicData(state, newValue) {
      Object.keys(newValue).forEach((changedPart) => {
        state.selectedPhotovoltaic[changedPart] = newValue[changedPart];
      });
    },
    updateExactParamsInSelectedPhotovoltaic(state, newValue) {
      deepUpdate(state.selectedPhotovoltaic, newValue);
    },
    updateLastChangedByInSelectedPhotovoltaic(state, last_changed_by) {
      state.selectedPhotovoltaic.last_changed_by = last_changed_by;
    },
    updateLastUpdateInSelectedPhotovoltaic(state, last_update) {
      state.selectedPhotovoltaic.last_update = last_update;
    },
    setPhotovoltaicStatusFilter(state, photovoltaicStatusFilter) {
      state.photovoltaicStatusFilter = photovoltaicStatusFilter;
    },
    setPhotovoltaicRegionalTeamFilter(state, photovoltaicRegionalTeamFilter) {
      state.photovoltaicRegionalTeamFilter = photovoltaicRegionalTeamFilter;
    },
  },
  getters: {
    getSelectedPhotovoltaic(state) {
      return state.selectedPhotovoltaic;
    },
    getPhotovoltaicAvailableProducts(state) {
      return state.availableProducts;
    },
    getPhotovoltaicStatusFilter(state) {
      return state.photovoltaicStatusFilter;
    },
    getPhotovoltaicRegionalTeamFilter(state) {
      return state.photovoltaicRegionalTeamFilter;
    },
  },
  actions: {},
};

function deepUpdate(target, source) {
  Object.keys(source).forEach((key) => {
    if (
      typeof source[key] === 'object' &&
      source[key] !== null &&
      !Array.isArray(source[key])
    ) {
      // If it's a nested object, recursively update
      if (!target[key]) target[key] = {}; // Ensure target has the object
      deepUpdate(target[key], source[key]);
    } else {
      // Otherwise, directly assign the value
      target[key] = source[key];
    }
  });
}
