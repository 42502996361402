export const COUNTER_TYPE_LIST = [
  'AHZ',
  'WSZ',
  'MAZ',
  'MME',
  'EHZ',
  'LAZ',
  'IVA',
  'NMG',
];

export const CHARGE_TYPE_LIST = ['ETZ', 'ZTZ'];
export const CURRENT_DIRECTION_LIST = [
  { value: 'oneway', translation: 'Einrichtungszäher' },
  { value: 'twoway', translation: 'Zweirichtungszähler' },
];
export const MOUNT_TYPE_LIST = [
  { value: 'DPA', translation: 'DPA' },
  { value: 'DIN', translation: 'Hutschienen' },
  { value: 'BKE', translation: 'BKE' },
];
export const MEASURING_DEVICE_TYPE_LIST = ['MMR', 'AMR'];
export const OBIS_KEY_LIST = ['1.2.8.0', '1.2.29.0'];
export const type_of_usage_options = [
  { value: 'feedin', translation: 'Einspeisung' },
  { value: 'generation', translation: 'Erzeugung' },
];

export const provider_options = [
  'acteno energy GmbH',
  'Activis Energy GmbH & Co. KG',
  'Aplus Energy GmbH',
  'Auxilius Services GmbH',
  'BIGGE ENERGIE GmbH & Co. KG',
  'BlueMetering GmbH',
  'Braunschweiger Versorgungs-AG & Co. KG',
  'co.met GmbH',
  'Comgy GmbH',
  'COUNT+CARE GmbH',
  'Countrol GmbH',
  'Dirk Rossmann GmbH',
  'EE Infratec GmbH',
  'EGC Energie- und Gebäudetechnik Control GmbH & Co.',
  'EGT Energie GmbH',
  'EGT Energievertrieb GmbH',
  'EHA Energie-Handels-Gesellschaft mbH & Co. KG',
  'EnBW Energie Baden-Württemberg AG',
  'EnBW Energy Factory GmbH',
  'EnBW mobility+ AG & Co. KG',
  'EnergieRevolte GmbH',
  'ENOMETRIK Gesellschaft für Messdienstleistungen',
  'Enpal GmbH',
  'E.ON Energie Deutschland GmbH',
  'e.optimum AG',
  'EPR Metering GmbH',
  'GASAG Solution Plus GmbH',
  'GELSENWASSER AG',
  'Getec metering GmbH',
  'GETEC WÄRME & EFFIZIENZ GmbH Nord',
  'GSW Gemeinschaftsstadtwerke GmbH Kamen, Bönen, Bergkamen',
  'hausheld AG',
  'imovis GmbH',
  'inexogy smart metering GmbH & Co. KG',
  'in.power metering GmbH',
  'Kunde',
  'LCG Energy GmbH',
  'LichtBlick SE',
  'Mer Germany GmbH',
  'metiundo GmbH',
  'NEW Netz GmbH',
  'opti.metering GmbH',
  'OVE Objekt-Versorgung mit rationellem Energieeinsatz GmbH',
  'PASM Power and Air Condition Solution GmbH',
  'Rhegio Dienstleistungen GmbH',
  'Rhegio Natur GmbH',
  'RhönEnergie Fulda GmbH',
  'Sagemcom IMD Center GmbH',
  'SENEC GmbH',
  'skando energie GmbH',
  'Solandeo GmbH',
  'Soluvia Energy Services GmbH',
  'Stadtwerke Ahaus GmbH',
  'Stadtwerke Düsseldorf AG',
  'Stadtwerke Konstanz GmbH',
  'Stadtwerke Leipzig GmbH',
  'Stadtwerke Rosenheim Versorgungs GmbH',
  'Stadtwerke Schwäbisch Hall GmbH',
  'Stromkontor Netzgesellschaft mbH',
  'Sunvigo GmbH',
  'Symbiotic Services GmbH',
  'TEAG Thüringer Energie AG',
  'TEBO GmbH',
  'Techem Energy Services GmbH',
  'Teleseo GmbH',
  'Tengelmann Energie GmbH',
  'Trianel GmbH',
  'TRIBICON metering UG',
  'VALENDA Metering GmbH',
  'VEA Beratungs-GmbH',
  'VOLTARIS GmbH',
  'wattline GmbH',
  'Westenergie Metering GmbH',
  'Westnetz GmbH',
  'WSW Energie & Wasser AG',
  'Yello Strom GmbH',
  'Zählerhelden GmbH',
];

export const counting_method_options = ['SLP', 'RLM'];
