var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"full_width"},[_c('v-btn',{staticClass:"refresh-button",on:{"click":function($event){return _vm.refreshOrders()}}},[_c('v-icon',{attrs:{"color":"#6E6E6E"}},[_vm._v("mdi-sync")])],1),_c('div',{staticClass:"table-style"},[_c('div',{staticClass:"table-header"},[_c('div',{staticClass:"d-flex flex-row justify-space-between"},[_c('div',{staticClass:"d-flex flex-column justify-space-between"},[_c('div',{staticClass:"pill-radio-buttons-div"},[_c('PillRadioButtons',{attrs:{"selectedValue":_vm.statusSelection.toString(),"itemsList":_vm.statusValuesList},on:{"update-selected-value":function($event){_vm.statusSelection = $event}}})],1),_c('div',{staticClass:"pill-radio-buttons-div"},[_c('PillRadioButtons',{attrs:{"selectedValue":_vm.energyTypeSelection,"itemsList":_vm.energyTypeValuesList},on:{"update-selected-value":function($event){_vm.energyTypeSelection = $event}}})],1),_c('div',{staticClass:"pill-radio-buttons-div"},[_c('PillRadioButtons',{attrs:{"selectedValue":_vm.internalSelection,"itemsList":_vm.internalDateValuesList},on:{"update-selected-value":function($event){_vm.internalSelection = $event}}})],1)]),_c('InstallerDownloadData')],1),_c('div',{staticClass:"pill-radio-buttons-div"},[_c('v-text-field',{attrs:{"rounded":"","outlined":"","denseØ":"","full-width":"","prepend-inner-icon":"mdi-magnify","label":"Suche","single-line":"","hide-details":""},on:{"input":_vm.elasticSearchOnBackend},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}})],1)]),_c('v-data-table',{attrs:{"disable-sort":"","headers":_vm.headersAktive,"items":_vm.activeInstallersDisplayed,"item-key":"itemId","loading":_vm.loading,"loading-text":_vm.$t('overview_table.loading_text'),"no-data-text":this.isDataLoaded
          ? _vm.$t('overview_table.no_filter_data')
          : _vm.$t('overview_table.error_data'),"footer-props":{
        'items-per-page-options': [10],
      },"server-items-length":_vm.filteredActiveInstallersCount,"calculate-widths":"","options":_vm.options,"page":_vm.currentPage},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(props){return [_c('div',{staticClass:"possible_notice"},[_c('v-btn',{attrs:{"rounded":"","dark":"","color":props.item.status.color}},[_vm._v(_vm._s(props.item.status.label))]),(
              props.item.status.value === 'LICENSE_REQUEST_ACTIVE_RECHECK'
            )?_c('v-tooltip',{attrs:{"top":"","color":"primary","max-width":"150px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"ml-4 flex-center-align"},'div',attrs,false),on),[_c(_vm.IconAusrufungszeichen,{tag:"component"})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('feed_in_systems.order_data_changed')))])]):_vm._e()],1)]}},{key:"item.edit",fn:function(props){return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.showDetails(props.item.itemId)}}},[_c('v-icon',[_vm._v("mdi-pencil-box-outline")])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }