const initialState = {
  title: '',
  name: '',
  surname: '',
  company_name: '',
  city_name: '',
  address: {
    street: '',
    street_number: '',
    zip: '',
    city: '',
    country: '',
  },
  address_zip_from_google: false,
};

const getters = {
  getFeedInSystemsLandownerTitle: ({ title }) => title,
  getFeedInSystemsLandownerCompanyName: ({ company_name }) => company_name,
  getFeedInSystemsLandownerCityName: ({ city_name }) => city_name,
  getFeedInSystemsLandownerName: ({ name }) => name,
  getFeedInSystemsLandownerSurname: ({ surname }) => surname,
  getFeedInSystemsLandownerStreet: (state) => {
    return state.address.street;
  },
  getFeedInSystemsLandownerStreetNumber: (state) => {
    return state.address.street_number;
  },
  getFeedInSystemsLandownerZip: (state) => {
    return state.address.zip;
  },
  getFeedInSystemsLandownerCity: (state) => {
    return state.address.city;
  },
  getFeedInSystemsLandownerCountry: (state) => {
    return state.address.country;
  },
  // used dynamically in AddressDataComponent
  get_form_pvlandowner_address_zip_from_google: (state) => {
    return state.address_zip_from_google;
  },
};

const mutations = {
  initializeFeedInSystemsLandownerModule(state, values) {
    Object.assign(state, { ...state, ...values });
  },
  setFeedInSystemsLandownerPersonalData(state, changedData) {
    state[changedData.key] = changedData.value;
  },
  setFeedInSystemsLandownerAddressData(state, changedData) {
    state.address[changedData.key] = changedData.value;
  },
  setFeedInSystemsLandownerTitle(state, title) {
    state.title = title;
  },
  setFeedInSystemsLandownerName(state, name) {
    state.name = name;
  },
  setFeedInSystemsLandownerCompanyName(state, company_name) {
    state.company_name = company_name;
  },
  setFeedInSystemsLandownerCityName(state, city_name) {
    state.city_name = city_name;
  },
  setFeedInSystemsLandownerSurname(state, surname) {
    state.surname = surname;
  },
  setFeedInSystemsLandownerStreet(state, street) {
    state.address.street = street;
  },
  setFeedInSystemsLandownerStreetNumber(state, street_number) {
    state.address.street_number = street_number;
  },
  setFeedInSystemsLandownerZip(state, zip) {
    state.address.zip = zip;
  },
  setFeedInSystemsLandownerCity(state, city) {
    state.address.city = city;
  },
  setFeedInSystemsLandownerCountry(state, country) {
    state.address.country = country;
  },
  // used dynamically in AddressDataComponent
  set_form_pvlandowner_address_zip_from_google(state, value) {
    state.address_zip_from_google = value;
  },
  resetFeedInSystemsLandownerModule(state) {
    Object.assign(state, JSON.parse(JSON.stringify(initialState)));
  },
};

export const feedInSystemsLandownerModule = {
  state: JSON.parse(JSON.stringify(initialState)),
  mutations,
  getters,
};
