<template>
  <div class="full_width">
    <v-btn class="refresh-button" @click="refreshOrders()">
      <v-icon color="#6E6E6E">mdi-sync</v-icon>
    </v-btn>
    <div class="table-style">
      <div class="table-header">
        <div class="d-flex flex-row justify-space-between">
          <div class="d-flex flex-column justify-space-between">
            <div class="pill-radio-buttons-div">
              <PillRadioButtons
                :selectedValue="statusSelection.toString()"
                :itemsList="statusValuesList"
                @update-selected-value="statusSelection = $event"
              />
            </div>
            <div class="pill-radio-buttons-div">
              <PillRadioButtons
                :selectedValue="energyTypeSelection"
                :itemsList="energyTypeValuesList"
                @update-selected-value="energyTypeSelection = $event"
              />
            </div>
            <!-- <div class="pill-radio-buttons-div">
              <PillRadioButtons
                :selectedValue="expiryDateSelection"
                :itemsList="expiryDateValuesList"
                @update-selected-value="expiryDateSelection = $event"
              />
            </div> -->
            <div class="pill-radio-buttons-div">
              <PillRadioButtons
                :selectedValue="internalSelection"
                :itemsList="internalDateValuesList"
                @update-selected-value="internalSelection = $event"
              />
            </div>
          </div>
          <InstallerDownloadData />
        </div>
        <div class="pill-radio-buttons-div">
          <v-text-field
            rounded
            outlined
            denseØ
            full-width
            v-model="searchValue"
            @input="elasticSearchOnBackend"
            prepend-inner-icon="mdi-magnify"
            label="Suche"
            single-line
            hide-details
          />
        </div>
      </div>
      <v-data-table
        disable-sort
        :headers="headersAktive"
        :items="activeInstallersDisplayed"
        item-key="itemId"
        :loading="loading"
        :loading-text="$t('overview_table.loading_text')"
        :no-data-text="
          this.isDataLoaded
            ? $t('overview_table.no_filter_data')
            : $t('overview_table.error_data')
        "
        :footer-props="{
          'items-per-page-options': [10],
        }"
        :server-items-length="filteredActiveInstallersCount"
        calculate-widths
        :options.sync="options"
        :page="currentPage"
      >
        <template v-slot:[`item.status`]="props">
          <div class="possible_notice">
            <v-btn rounded dark :color="props.item.status.color">{{
              props.item.status.label
            }}</v-btn>
            <v-tooltip
              v-if="
                props.item.status.value === 'LICENSE_REQUEST_ACTIVE_RECHECK'
              "
              top
              color="primary"
              max-width="150px"
            >
              <template v-slot:activator="{ on, attrs }">
                <div class="ml-4 flex-center-align" v-bind="attrs" v-on="on">
                  <component :is="IconAusrufungszeichen" />
                </div>
              </template>
              <span>{{ $t('feed_in_systems.order_data_changed') }}</span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:[`item.edit`]="props">
          <v-btn icon @click="showDetails(props.item.itemId)">
            <v-icon>mdi-pencil-box-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { getInstallersWithFilter } from '../../services/InstallerService';
import { getInstallersTableConfig } from './installersTableConfig';
import InstallerDownloadData from './InstallerDownloadData';
import { tableItemMapper } from './transform_raw_data_to_installers_table_format';
import {
  PillRadioButtons,
  allIcons,
} from '@newnetzportal/netzportal-webapp-storybook-components';

export default {
  name: 'InstallersOverviewTable',
  components: {
    InstallerDownloadData,
    PillRadioButtons,
  },
  created() {
    this.loading = true;
    this.getInstallersWithFilter();
  },
  computed: {
    IconAusrufungszeichen() {
      return allIcons['IconAusrufungszeichen'];
    },
  },
  data() {
    return {
      searchValue: '',
      energyTypeSelection: this.$route.query.energy_type
        ? this.$route.query.energy_type
        : 'all',
      expiryDateSelection: this.$route.query.expiry
        ? this.$route.query.expiry
        : 'all',
      internalSelection: this.$route.query.internal
        ? this.$route.query.internal
        : 'all',
      currentPage: this.$route.query.page
        ? parseInt(this.$route.query.page)
        : 1,
      activeInstallers: [],
      loading: false,
      options: {},
      activeInstallersDisplayed: [],
      statusSelection: this.$route.query.status
        ? this.$route.query.status
        : 'all',
      statusValuesList: getInstallersTableConfig('statusValuesList'),
      energyTypeValuesList: getInstallersTableConfig('energyTypeValuesList'),
      // expiryDateValuesList: getInstallersTableConfig('expiryDateValuesList'),
      internalDateValuesList: getInstallersTableConfig(
        'internalDateValuesList'
      ),
      filteredActiveInstallersCount: 0,
      headersAktive: getInstallersTableConfig('headersAktive'),
      isDataLoaded: false,
    };
  },
  watch: {
    $route(to) {
      if (to.fullPath === '/installateure') {
        this.refreshOrders();
      }
    },
    energyTypeSelection(newValue) {
      this.currentPage = 1;
      if (newValue) {
        this.energyTypeSelection = newValue;
      }
      this.$store.commit('setInstallerenergy_typeFilter', newValue);
      this.updateURLParams({ name: 'energy_type', value: newValue });
      this.getInstallersWithFilter();
    },
    internalSelection(newValue) {
      this.currentPage = 1;
      if (newValue) {
        this.internalSelection = newValue;
      }
      this.$store.commit('setInstallerinternalFilter', newValue);
      this.updateURLParams({ name: 'internal', value: newValue });
      this.getInstallersWithFilter();
    },
    statusSelection(newValue) {
      this.currentPage = 1;
      if (newValue) {
        this.statusSelection = newValue;
      }
      this.$store.commit('setInstallerstatusFilter', newValue);
      this.updateURLParams({ name: 'status', value: newValue });
      this.getInstallersWithFilter();
    },
    expiryDateSelection(newValue) {
      this.currentPage = 1;
      if (newValue) {
        this.expiryDateSelection = newValue;
      }
      this.$store.commit('setInstallerexpiryFilter', newValue);
      this.updateURLParams({ name: 'expiry', value: newValue });
      this.getInstallersWithFilter();
    },
    options: {
      handler(event) {
        this.updateURLParams({ name: 'page', value: event.page });
        this.currentPage = event.page;
        this.getInstallersWithFilter();
      },
      deep: true,
    },
  },
  methods: {
    updateURLParams(param) {
      const params = this.$route.query;
      params[param.name] = param.value;
      history.replaceState(
        {},
        null,
        `${this.$route.path}?${new URLSearchParams(params)}`
      );
    },
    refreshOrders() {
      const params = this.$route.query;
      params.page = 1;
      params.status = '';
      params.expiry = '';
      params.internal = '';
      params.energy_type = '';
      history.replaceState(
        {},
        null,
        `${this.$route.path}?${new URLSearchParams(params)}`
      );
      this.statusSelection = '';
      this.expiryDateSelection = '';
      this.energyTypeSelection = '';
      this.internalSelection = '';
      this.currentPage = 1;
      this.getInstallersWithFilter();
    },
    elasticSearchOnBackend(value) {
      if (value.length === 0 || value.length > 2) {
        this.currentPage = 1;
        this.getInstallersWithFilter();
      }
    },
    getInstallersWithFilter(search_word) {
      const params = {
        limit: 10,
        from_index: (this.currentPage - 1) * 10,
        energy_type:
          this.energyTypeSelection === 'all' ? '' : this.energyTypeSelection,
        status: this.statusSelection === 'all' ? '' : this.statusSelection,
        search_word: search_word ? search_word : this.searchValue,
        expiry:
          this.expiryDateSelection === 'all' ? '' : this.expiryDateSelection,
        internal:
          this.internalSelection === 'all' ? '' : this.internalSelection,
      };
      getInstallersWithFilter(params)
        .then((res) => {
          if (res.getInstallersWithFilter) {
            this.filteredActiveInstallersCount =
              res.getInstallersWithFilter.count_orders;
            const installersWithStatusConfig = this.addStatusConfigForEachOrder(
              res.getInstallersWithFilter.items
            );
            this.activeInstallersDisplayed =
              installersWithStatusConfig.map(tableItemMapper);
            this.isDataLoaded = true;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.isDataLoaded = false;
          this.activeOrdersDisplayed = [];
          console.log(err);
        });
    },
    dateFormat(date, format) {
      return this.$dayjs(new Date(date)).format(format);
    },
    showDetails(installerId) {
      this.$store.commit('deleteSelectedInstaller');
      this.$router.push({
        name: 'InstallerDetailsPage',
        params: { id: installerId },
      });
    },
    addStatusConfigForEachOrder(ordersArray) {
      ordersArray.forEach((item) => {
        item.status = this.statusValuesList.find(
          (status) => status.value === item.status
        );
      });
      return ordersArray;
    },
  },
};
</script>
<style scoped>
.possible_notice {
  display: flex;
  flex-wrap: nowrap;
  align-items: end;
}
</style>
