const emptyContactObject = {
  title: '',
  name: '',
  surname: '',
  phone_number: '',
  additional_phone_number: '',
  email: '',
  preferred_contact_channel: '',
};
const emptyAddressObject = {
  street: '',
  street_number: '',
  zip: '',
  city: '',
  country: '',
};
const emptyObject = {
  title: '',
  name: '',
  company_name: '',
  city_name: '',
  surname: '',
  address: emptyAddressObject,
  additional_contact_person: emptyContactObject,
};

export const emobilityLandownerModule = {
  state: {
    emobility_landowner_address_zip_from_google: false,
    landowner_additional_contact_person_selected: false,
  },
  mutations: {
    setEmobilityLandownerChangedObject(state, object) {
      this.state.changed_object.landowner[object.key] = object.value;
    },
    setEmobilityLandownerAddressChangedObject(state, changedData) {
      this.state.changed_object.landowner.address[changedData.key] =
        changedData.value;
    },
    setEmobilityLandownerAdditionalContactPersonObject(state, changedData) {
      this.state.changed_object.landowner.additional_contact_person[
        changedData.key
      ] = changedData.value;
    },
    set_form_emobility_landowner_address_zip_from_google(state, value) {
      state.emobility_landowner_address_zip_from_google = value;
    },
    setEmobilityLandownerState(state, landownerState) {
      this.state.changed_object.same_landowner = landownerState;
    },
    resetEmobilityLandowner() {
      this.state.changed_object.landowner = emptyObject;
    },
    resetEmobilityLandownerAdditionalContactPerson() {
      this.state.changed_object.landowner.additional_contact_person =
        emptyContactObject;
    },
    setLandownerAdditionalContactPersonSelected(
      state,
      landowner_additional_contact_person_selected
    ) {
      state.landowner_additional_contact_person_selected =
        landowner_additional_contact_person_selected;
    },
  },
  getters: {
    get_form_emobility_landowner_address_zip_from_google: (state) => {
      return state.emobility_landowner_address_zip_from_google;
    },
    getLandownerAdditionalContactPersonSelected: (state) => {
      return state.landowner_additional_contact_person_selected;
    },
  },
  actions: {},
};
