import GraphQLService from './GraphQLService';

export default {
  async getOrderDocumentsHistory(requestBody) {
    return GraphQLService.requestWithOpenID(
      `
        query History( $id: String!, $limit: Int, $from_index: Int) { 
          orderDocumentsHistory( id: $id, limit: $limit, from_index: $from_index ) {
            changes {
              id
              event_origin
              changed_by
              created
              field
              payload
            }
            count_changes
          }
        }`,
      {
        id: requestBody.id,
        limit: requestBody.limit,
        from_index: requestBody.from_index,
      }
    );
  },
  async getInstallerDocumentsHistory(requestBody) {
    return GraphQLService.requestWithOpenID(
      `
        query History( $id: String!, $limit: Int, $from_index: Int) { 
          installerDocumentsHistory( id: $id, limit: $limit, from_index: $from_index ) {
            changes {
              id
              event_origin
              changed_by
              created
              field
              payload
            }
            count_changes
          }
        }`,
      {
        id: requestBody.id,
        limit: requestBody.limit,
        from_index: requestBody.from_index,
      }
    );
  },
  async getEmobilityDocumentsHistory(requestBody) {
    return GraphQLService.requestWithOpenID(
      `
        query History( $id: String!, $limit: Int, $from_index: Int) { 
          emobilityDocumentsHistory( id: $id, limit: $limit, from_index: $from_index ) {
            changes {
              id
              event_origin
              changed_by
              created
              field
              payload
            }
            count_changes
          }
        }`,
      {
        id: requestBody.id,
        limit: requestBody.limit,
        from_index: requestBody.from_index,
      }
    );
  },
  async getPhotovoltaicDocumentsHistory(requestBody) {
    return GraphQLService.requestWithOpenID(
      `
        query History( $id: String!, $limit: Int, $from_index: Int) { 
          photovoltaicDocumentsHistory( id: $id, limit: $limit, from_index: $from_index ) {
            changes {
              id
              event_origin
              changed_by
              created
              field
              payload
            }
            count_changes
          }
        }`,
      {
        id: requestBody.id,
        limit: requestBody.limit,
        from_index: requestBody.from_index,
      }
    );
  },
};
