const electricityConfig = {
  internal: [
    {
      title: 'installer_inspection.company_data_checked',
      method: 'setCompanyDataElectricitySelected',
      checkboxName: 'CompanyDataElectricity',
      required_text: 'installer_inspection.company_data_required',
      index: 1,
    },
    {
      title: 'installer_inspection.responsible_specialists_checked',
      method: 'setResponsibleSpecialistsElectricitySelected',
      checkboxName: 'ResponsibleSpecialistsElectricity',
      required_text: 'installer_inspection.responsible_specialists_required',
      index: 2,
    },
    {
      title: 'installer_inspection.documents_checked',
      method: 'setDocumentsElectricitySelected',
      checkboxName: 'DocumentsElectricity',
      required_text: 'installer_inspection.documents_required',
      index: 3,
    },
    {
      title: 'installer_inspection.professional_competence_checked',
      method: 'setProfessionalCompetenceElectricitySelected',
      checkboxName: 'ProfessionalCompetenceElectricity',
      required_text: 'installer_inspection.professional_competence_required',
      index: 4,
    },
  ],
  external: [
    {
      title: 'installer_inspection.company_data_checked',
      method: 'setCompanyDataElectricitySelected',
      checkboxName: 'CompanyDataElectricity',
      required_text: 'installer_inspection.company_data_required',
      index: 1,
    },
    {
      title: 'installer_inspection.responsible_specialists_checked',
      method: 'setResponsibleSpecialistsElectricitySelected',
      checkboxName: 'ResponsibleSpecialistsElectricity',
      required_text: 'installer_inspection.responsible_specialists_required',
      index: 2,
    },
    {
      title: 'installer_inspection.installer_id',
      method: 'setInstallerIDElectricitySelected',
      checkboxName: 'InstallerIDElectricity',
      required_text: 'installer_inspection.installer_id_required',
      index: 3,
    },
  ],
};

const gasConfig = {
  internal: [
    {
      title: 'installer_inspection.company_data_checked',
      method: 'setCompanyDataGasWaterSelected',
      checkboxName: 'CompanyDataGasWater',
      required_text: 'installer_inspection.company_data_required',
      index: 1,
    },
    {
      title: 'installer_inspection.responsible_specialists_checked',
      method: 'setResponsibleSpecialistsGasWaterSelected',
      checkboxName: 'ResponsibleSpecialistsGasWater',
      required_text: 'installer_inspection.responsible_specialists_required',
      index: 2,
    },
    {
      title: 'installer_inspection.documents_checked',
      method: 'setDocumentsGasWaterSelected',
      checkboxName: 'DocumentsGasWater',
      required_text: 'installer_inspection.documents_required',
      index: 3,
    },
    {
      title: 'installer_inspection.professional_competence_checked',
      method: 'setProfessionalCompetenceGasWaterSelected',
      checkboxName: 'ProfessionalCompetenceGasWater',
      required_text: 'installer_inspection.professional_competence_required',
      index: 4,
    },
  ],
  external: [
    {
      title: 'installer_inspection.company_data_checked',
      method: 'setCompanyDataGasWaterSelected',
      checkboxName: 'CompanyDataGasWater',
      required_text: 'installer_inspection.company_data_required',
      index: 1,
    },
    {
      title: 'installer_inspection.responsible_specialists_checked',
      method: 'setResponsibleSpecialistsGasWaterSelected',
      checkboxName: 'ResponsibleSpecialistsGasWater',
      required_text: 'installer_inspection.responsible_specialists_required',
      index: 2,
    },
    {
      title: 'installer_inspection.installer_id',
      method: 'setInstallerIDGasWaterSelected',
      checkboxName: 'InstallerIDGasWater',
      required_text: 'installer_inspection.installer_id_required',
      index: 3,
    },
  ],
};

export const getInstallerInspectionElectricityConfig = (configName) => {
  return JSON.parse(JSON.stringify(electricityConfig[configName]));
};

export const getInstallerInspectionGasConfig = (configName) => {
  return JSON.parse(JSON.stringify(gasConfig[configName]));
};
