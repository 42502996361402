var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"foo-bar"},[_c('div',{style:(_vm.first
        ? 'border-radius: 8px 0 0 0'
        : _vm.last
        ? 'border-radius: 0 0 0 8px'
        : ''),attrs:{"id":"foo-data"}},[_c('div',{attrs:{"id":"title"}},[_vm._v(_vm._s(_vm.title))]),_c('div',{attrs:{"id":"content"}},[_vm._t("default")],2)]),_c('div',{style:(_vm.first
        ? 'border-radius: 0 8px 0 0'
        : _vm.last
        ? 'border-radius: 0 0 8px 0'
        : ''),attrs:{"id":"action"}},[(_vm.editable)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('edit', $event)}}},[(_vm.notEditable)?_c('v-icon',{attrs:{"color":this.color}},[_vm._v("mdi-eye")]):_c('edit-icon')],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }