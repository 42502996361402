import { deleteAllEmptyFields } from '@/helpers/globalFunctions';

const emptyInverterObject = {
  id: '',
  inverterManufacturerNotListed: false,
  inverterManufacturer: null,
  inverterTypeList: null,
  inverterType: null,
  inverterAmount: null,
  activePower: null,
  apparentPower: null,
  inverterRatedCurrent: null,
  unitCertificateNumber: '',
  NAProtectionCertificateNumber: '',
  selectedFiles: [
    { file: '', file_name: '', position: 1 },
    { file: '', file_name: '', position: 2 },
  ],
  e4CertificateFileName: '',
  e6CertificateFileName: '',
};

const initialState = {
  pv_inverters: [JSON.parse(JSON.stringify(emptyInverterObject))],
  step_status: 'empty',
};

const getters = {
  getNumberOfInverters: (state) => state.pv_inverters.length,
  getInverterId: (state) => (index) => state.pv_inverters[index].id,
  getInverterManufacturer: (state) => (index) =>
    state.pv_inverters[index].inverterManufacturer,
  getInverterType: (state) => (index) => state.pv_inverters[index].inverterType,
  getInverterTypeList: (state) => (index) =>
    state.pv_inverters[index].inverterTypeList,
  getInverterAmount: (state) => (index) =>
    state.pv_inverters[index].inverterAmount,
  getInverterManufacturerNotListed: (state) => (index) =>
    state.pv_inverters[index].inverterManufacturerNotListed,
  getActivePower: (state) => (index) => state.pv_inverters[index].activePower,
  getApparentPower: (state) => (index) =>
    state.pv_inverters[index].apparentPower,
  getInverterRatedCurrent: (state) => (index) =>
    state.pv_inverters[index].inverterRatedCurrent,
  getUnitCertificateNumber: (state) => (index) =>
    state.pv_inverters[index].unitCertificateNumber,
  getNAProtectionCertificateNumber: (state) => (index) =>
    state.pv_inverters[index].NAProtectionCertificateNumber,
  getE4CertificateFileName: (state) => (index) =>
    state.pv_inverters[index].e4CertificateFileName,
  getE6CertificateFileName: (state) => (index) =>
    state.pv_inverters[index].e6CertificateFileName,
  getPVInverters: ({ pv_inverters }) => pv_inverters,
  getPVInvertersData: (state, getters) => {
    return state.pv_inverters.map((inverter, index) => {
      return deleteAllEmptyFields({
        id: getters.getInverterId(index),
        manufacturer: getters.getInverterManufacturer(index),
        type: getters.getInverterType(index),
        number_of_inverters: Number(getters.getInverterAmount(index)),
        active_power: Math.round(getters.getActivePower(index) * 1000),
        apparent_power: Math.round(getters.getApparentPower(index) * 1000),
        rated_current: Number(getters.getInverterRatedCurrent(index)),
        e4_cert_number: getters.getUnitCertificateNumber(index),
        e6_cert_number: getters.getNAProtectionCertificateNumber(index),
        e4_certificate_file_name: getters.getE4CertificateFileName(index),
        e6_certificate_file_name: getters.getE6CertificateFileName(index),
      });
    });
  },
  getStepStatus: ({ step_status }) => step_status,
};

const mutations = {
  setInverterId(state, { value, index }) {
    state.pv_inverters[index].id = value;
  },
  setInverterManufacturer(state, { value, index }) {
    state.pv_inverters[index].inverterManufacturer = value;
  },
  setInverterType(state, { value, index }) {
    state.pv_inverters[index].inverterType = value;
  },
  setInverterTypeList(state, { value, index }) {
    state.pv_inverters[index].inverterTypeList = value;
  },
  setInverterAmount(state, { value, index }) {
    state.pv_inverters[index].inverterAmount = value;
  },
  setInverterManufacturerNotListed(state, { value, index }) {
    state.pv_inverters[index].inverterManufacturerNotListed = value;
  },
  setActivePower(state, { value, index }) {
    state.pv_inverters[index].activePower = value;
  },
  setApparentPower(state, { value, index }) {
    state.pv_inverters[index].apparentPower = value;
  },
  setInverterRatedCurrent(state, { value, index }) {
    state.pv_inverters[index].inverterRatedCurrent = value;
  },
  setUnitCertificateNumber(state, { value, index }) {
    state.pv_inverters[index].unitCertificateNumber = value;
  },
  setNAProtectionCertificateNumber(state, { value, index }) {
    state.pv_inverters[index].NAProtectionCertificateNumber = value;
  },
  setE4CertificateFileName(state, { value, index }) {
    state.pv_inverters[index].e4CertificateFileName = value;
  },
  setE6CertificateFileName(state, { value, index }) {
    state.pv_inverters[index].e6CertificateFileName = value;
  },
  createNewInverter(state) {
    state.pv_inverters.push(JSON.parse(JSON.stringify(emptyInverterObject)));
  },
  deleteInverter(state, index) {
    state.pv_inverters.splice(index, 1);
  },
  updateStepStatus(state, value) {
    state.step_status = value;
  },
  resetInverterModelState(state) {
    Object.assign(state, JSON.parse(JSON.stringify(initialState)));
  },
};

const actions = {
  setExistingInverters({ commit }, inverters) {
    inverters.forEach((inverter, index) => {
      commit('setInverterId', { value: inverter.id, index });
      commit('setInverterManufacturerNotListed', {
        value: !inverter.id,
        index,
      });
      commit('setInverterManufacturer', {
        value: inverter.manufacturer,
        index,
      });
      commit('setInverterType', { value: inverter.type, index });
      commit('setInverterAmount', {
        value: inverter.number_of_inverters,
        index,
      });
      commit('setActivePower', { value: inverter.active_power / 1000, index });
      commit('setApparentPower', {
        value: inverter.apparent_power / 1000,
        index,
      });
      commit('setInverterRatedCurrent', {
        value: inverter.rated_current,
        index,
      });
      commit('setUnitCertificateNumber', {
        value: inverter.e4_cert_number,
        index,
      });
      commit('setNAProtectionCertificateNumber', {
        value: inverter.e6_cert_number,
        index,
      });
      commit('setE4CertificateFileName', {
        value: inverter.e4_certificate_file_name,
        index,
      });
      commit('setE6CertificateFileName', {
        value: inverter.e6_certificate_file_name,
        index,
      });
    });
  },
};

export const InverterModelModule = {
  state: JSON.parse(JSON.stringify(initialState)),
  getters,
  mutations,
  actions,
};
