export const installerUploadFilesModule = {
  state: {
    fileURLs: {},
    files: {},
    new_installer_file_uploaded: false,
  },
  mutations: {
    setInstallerFileFromBackend(state, fileObject) {
      state.files[fileObject.type] = fileObject.fileObject;
    },
    setInstallerFileHistoryFromBackend(state, file) {
      state.files[file.name] = file;
    },
    setInstallerFileURL(state, object) {
      state.fileURLs[object.imageName] = object.url;
    },
    setInstallerFileNamesChangedObject(state, file) {
      this.state.changed_object.file_names[file.type] = file.file
        ? `${file.file_name}`
        : '';
    },
    setInstallerFileChanged(state, newFileUploaded) {
      state.new_installer_file_uploaded =
        state.new_installer_file_uploaded || newFileUploaded;
    },
    resetInstallerFileChanged(state) {
      state.new_installer_file_uploaded = false;
    },
    resetInstallerFileAndFileURLs(state) {
      state.files = {};
      state.fileURLs = {};
    },
    deleteInstallerFileURL(state, file_name) {
      Object.keys(state.fileURLs).forEach((key) => {
        if (key === file_name) {
          delete state.fileURLs[key];
        }
      });
    },
    deleteInstallerFile(state, file) {
      delete state.files[file];
    },
  },
  getters: {
    getInstallerFileURLByName: (state) => (fileName) => {
      return state.fileURLs[fileName];
    },
    getInstallerFileFromBackend: (state) => (fileName) => {
      return state.files[fileName];
    },
    getInstallerFileChanged(state) {
      return state.new_installer_file_uploaded;
    },
  },
};
