<template>
  <div>
    <TechnicalInspectionComponent
      @confirm-check="confirmCheck()"
      @save-current-result="updateEmobilityInspectionValues"
      @send-inquiry="sendInquiry($event)"
      :contactDataArray="contactInspectionDataArray"
      @decline-check="openModal"
    >
      <template v-slot:button-loading v-if="showLoading">
        <v-progress-circular
          indeterminate
          color="white"
          class="ml-4"
        ></v-progress-circular>
      </template>
      <template v-slot:save-alert>
        <userfeedback-component
          v-if="lastUpdatedComponent === 'saveCurrentResultEmobility' && alert"
          class="ml-4"
        />
        <v-alert
          type="error"
          class="alert_error mt-4"
          max-width="270px"
          v-if="
            (lastUpdatedComponent === 'saveResultEmobility' ||
              lastUpdatedComponent === 'saveCurrentResultEmobility') &&
            alertError
          "
          text
          transition="scale-transition"
        >
          Speicherung fehlgeschlagen
        </v-alert>
      </template>
      <template v-if="notEditable" v-slot:footer>
        <button
          type="button"
          class="btn-confirm-inspection"
          @click="$emit('closeTechnicalInspection')"
        >
          <div class="btn-text" style="width: inherit; color: white">
            Schließen
          </div>
        </button>
      </template>
      <template v-slot:checkbox-list>
        <div>
          <div class=".flex-container-nested">
            <div class="checkbox-list">
              <div
                v-for="(checkboxActionData, index) in checkboxActionConfig"
                v-bind:key="checkboxActionData.title"
                class="mr-4"
              >
                <TextCheckboxActionComponent
                  :notEditable="notEditable"
                  :title="checkboxActionData.title"
                  @update-checkbox-value="checkboxActionData.method"
                  :index="index"
                  :checkboxName="checkboxActionData.checkboxName"
                  :requiredText="checkboxActionData.required_text"
                  :showError="showError"
                  @show-modal="$emit('show-modal', $event)"
                  :updateMethod="checkboxActionData.updateMethod"
                  :updateComponent="checkboxActionData.updateComponent"
                >
                </TextCheckboxActionComponent>
              </div>
              <div
                v-for="(checkbox, index) in checkBoxData"
                v-bind:key="checkbox.title"
                class="mr-4"
              >
                <TextCheckboxComponent
                  :title="checkbox.title"
                  @update-checkbox-value="checkbox.method"
                  :index="index"
                  :checkboxName="checkbox.checkboxName"
                  :requiredText="checkbox.required_text"
                  :showError="showError"
                  :indexData="checkbox.index"
                  :notEditable="notEditable"
                />
              </div>
              <ChargingPowerComponent
                :type="'Emobility'"
                :index="5"
                :notEditable="notEditable"
                class="mr-4"
              />
              <div
                class="error-dialog mr-4"
                v-if="showErrors && !allDataSelected"
              >
                {{ $t('technical_inspection.error_required') }}
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:hint-component>
        <HintComponent
          v-if="selectedEmobility.same_landowner === false"
          :data="selectedEmobility"
        />
      </template>
      <template v-slot:note-document-upload>
        <div v-if="!isNoteUploadInfo">
          <NEWNoteUploadComponent
            @send-note-and-uploadfile="sendNoteAndFile($event)"
            @note-data-deleted="deleteNote = false"
            :deleteNote="deleteNote"
            :labels="noteLabels"
          >
            <template v-slot:status-note-alert>
              <userfeedback-component
                v-if="
                  lastUpdatedComponent === 'emobilityNoteUploadFileComponent' &&
                  alert
                "
              />
              <v-alert
                type="error"
                class="alert_error mt-4"
                max-width="270px"
                v-if="
                  lastUpdatedComponent === 'emobilityNoteUploadFileComponen' &&
                  alertError
                "
                text
                transition="scale-transition"
              >
                Speicherung fehlgeschlagen
              </v-alert>
            </template>
          </NEWNoteUploadComponent>
        </div>
        <div v-else class="container--note-upload-pending">
          <v-progress-circular
            indeterminate
            color="primary"
            :size="60"
            :width="5"
          />
          <NewAlert
            class="mt-4"
            color="INFO"
            :message="$t('emobility_inspection.info_file_upload')"
          />
        </div>
      </template>

      <template v-slot:status-alert>
        <userfeedback-component
          v-if="lastUpdatedComponent === 'emobilityNoteComponent' && alert"
        />
        <v-alert
          type="error"
          class="alert_error mt-4"
          max-width="270px"
          v-if="lastUpdatedComponent === 'emobilityNoteComponent' && alertError"
          text
          transition="scale-transition"
        >
          Speicherung fehlgeschlagen
        </v-alert>
      </template>
    </TechnicalInspectionComponent>

    <modal-dialog
      v-if="isModalVisible"
      :value="noteValue"
      role="dialog"
      @cancel="closeModal"
      @save="closeModalAndSaveData"
    >
      <template v-slot:body>
        <div class="dialogTitle">Prüfung ablehnen</div>
      </template>
      <template v-slot:footer>
        <div class="footer_container">
          <div v-if="showNoteError" class="error_message_dialog">
            Bitte Ablehnungsgrund eingeben.
          </div>
          <div class="button_container">
            <button
              type="button"
              class="btn-save"
              @click="closeModalAndSaveData"
            >
              Prüfung ablehnen
            </button>
            <button type="button" class="btn-cancel" @click="closeModal">
              abbrechen
            </button>
            <v-alert
              type="error"
              class="alert_error mt-4"
              max-width="270px"
              v-if="
                lastUpdatedComponent === 'emobilityOrderRefused' && alertError
              "
              text
              transition="scale-transition"
            >
              Ablehnen fehlgeschlagen
            </v-alert>
          </div>
        </div>
      </template>
    </modal-dialog>
  </div>
</template>

<script>
import TextCheckboxComponent from '../technical-inspection-component/TextCheckboxComponent.vue';
import ChargingPowerComponent from './ChargingPowerComponent.vue';
import TechnicalInspectionComponent from '@bit/new-netzportal.netzportal.technical-inspection-component';
import HintComponent from '../shared-components/HintComponent/HintComponent.vue';
import documentService from '../../services/DocumentsService';
import { emptyChargingPowerValues } from '../../store/emobilityInspectionModule';
import emobilityService from '../../services/EmobilityService';
import noteService from '../../services/NoteService';
import ModalDialog from '@bit/new-netzportal.netzportal.modal-dialog';
import { uploadFile } from '../../services/FileUploadService';
import UserfeedbackComponent from '../emobility-details/UserfeedbackComponent.vue';
import { emptyEventData } from '../../store/ordersModule';
import TextCheckboxActionComponent from '../order-steps-components/TextCheckboxActionComponent';
import { NewAlert } from '@bit/new-netzportal.netzportal.alert';
import { getTranslations } from '@/helpers/globalFunctions';
import { NEWNoteUploadComponent } from '@newnetzportal/netzportal-webapp-storybook-components';

export default {
  name: 'EmobilityInspectionComponent',
  components: {
    TextCheckboxComponent,
    ChargingPowerComponent,
    TechnicalInspectionComponent,
    NEWNoteUploadComponent,
    HintComponent,
    ModalDialog,
    UserfeedbackComponent,
    TextCheckboxActionComponent,
    NewAlert,
  },
  props: {
    notEditable: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.$store.commit('resetChecksForEmobilityInspection');
    this.changedObject = this.getChangedData();
    this.$store.commit(
      'setOldValueObject',
      JSON.parse(JSON.stringify(this.changedObject))
    );
    this.setChecksForInspection();
  },
  data() {
    return {
      showError: false,
      showErrors: false,
      deleteNote: false,
      noteValue: { note: '' },
      isModalVisible: false,
      showNoteError: false,
      showLoading: false,
      alert: false,
      alertError: false,
      isNoteUploadInfo: false,
      selectedEmobility: this.$store.getters.getSelectedEmobility,
      checkboxActionConfig: [
        {
          title: 'emobility_inspection.address_checked',
          method: this.setEmobilityAdressSelected,
          checkboxName: 'EmobilityAdress',
          required_text: 'emobility_inspection.address_required',
          updateComponent: 'EmobilityInstallationAddressComponent',
          updateMethod: 'updateEmobilityInstallationAddress',
        },
        {
          title: 'emobility_inspection.customer_data_checked',
          method: this.setEmobilityCustomerDataSelected,
          checkboxName: 'EmobilityCustomerData',
          required_text: 'emobility_inspection.customer_data_required',
          updateComponent: 'EmobilityAppplicantComponent',
          updateMethod: 'updateEmobilityApplicant',
        },
      ],
      checkBoxData: [
        {
          title: 'emobility_inspection.gis_checked',
          method: this.setEmobilityGisSelected,
          checkboxName: 'EmobilityGis',
          required_text: 'emobility_inspection.gis_required',
          index: 3,
        },
      ],
      contactInspectionDataArray: [
        {
          title: 'Rückfrage an den Kunden',
          contactCanal: 'phone',
          name: 'kunden',
          note: '',
        },
        {
          title: 'Rückfrage an das Regionalteam',
          contactCanal: 'phone',
          name: 'regionalteam',
          note: '',
        },
      ],
      noteLabels: getTranslations('note_upload'),
    };
  },
  computed: {
    allDataSelected() {
      return this.$store.getters.getIfAllDataEmobilitySelected;
    },
    emobilityInspectionData() {
      return this.$store.getters.getEmobilityInspectionData;
    },
    userPersonalData() {
      return this.$store.getters.getUserId;
    },
    lastUpdatedComponent() {
      return this.$store.getters.getLastUpdatedComponent
        ? this.$store.getters.getLastUpdatedComponent
        : '';
    },
    notifyEvent() {
      return this.$store.getters.getNotifyEvent
        ? this.$store.getters.getNotifyEvent
        : emptyEventData;
    },
  },
  watch: {
    notifyEvent(newValue) {
      if (newValue !== null) {
        this.alert = true;
        setTimeout(() => {
          this.alert = false;
          this.$store.commit('setNotifyEvent', emptyEventData);
        }, 3000);
      }
    },
  },
  methods: {
    openModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
      this.showNoteError = false;
      this.noteValue = { note: '' };
    },
    closeModalAndSaveData() {
      if (this.noteValue.note === '') {
        this.showNoteError = true;
      } else {
        const noteObj = {
          id: this.selectedEmobility.id,
          user_id: this.selectedEmobility.user_id,
          title: 'Ablehnungsgrund',
          note: this.noteValue.note,
        };
        this.sendNote(noteObj);
        this.$store.commit('setLastUpdatedComponent', 'emobilityOrderRefused');
        this.$store.commit('setChangedObject', { status: 'ORDER_CANCELLED' });
        this.changeStatus(this.noteValue.note);
        this.isModalVisible = false;
        this.noteValue = { note: '' };
        this.showNoteError = false;
      }
    },

    setEmobilityAdressSelected(value) {
      this.$store.commit('setEmobilityAdressSelected', value);
    },
    setEmobilityCustomerDataSelected(value) {
      this.$store.commit('setEmobilityCustomerDataSelected', value);
    },
    setEmobilityGisSelected(value) {
      this.$store.commit('setEmobilityGisSelected', value);
    },
    setChecksForInspection() {
      this.$store.commit(
        'setEmobilityAdressSelected',
        !!this.selectedEmobility.emobility_address_checked
      );
      this.$store.commit(
        'setEmobilityCustomerDataSelected',
        !!this.selectedEmobility.emobility_customer_data_checked
      );
      this.$store.commit(
        'setEmobilityGisSelected',
        !!this.selectedEmobility.emobility_gis_checked
      );
      this.$store.commit(
        'setEmobilityChargingPowerSelected',
        !!this.selectedEmobility.emobility_charging_power_checked
      );
      this.$store.commit(
        'setEmobilityChargingPowerValues',
        this.selectedEmobility.emobility_charging_power_values
          ? this.selectedEmobility.emobility_charging_power_values
          : emptyChargingPowerValues
      );
    },
    async sendNote(noteObj, name) {
      try {
        const response = await noteService.addEmobilityNote(
          noteObj,
          this.userPersonalData
        );
        if (name) {
          this.contactInspectionDataArray.forEach((contactData) => {
            if (contactData.name === name) {
              contactData.note = '';
            }
          });
        }

        return response;
      } catch (err) {
        this.isNoteUploadInfo = false;
        this.alertError = true;
        console.error(err);
      }
    },
    sendInquiry(value) {
      if (value.note) {
        const noteObj = {
          id: this.selectedEmobility.id,
          user_id: this.selectedEmobility.user_id,
          note: value.note,
          title: value.title,
        };
        this.sendNote(noteObj, value.name);
        this.$store.commit('setLastUpdatedComponent', 'emobilityNoteComponent');
      }
    },
    async sendNoteAndFile(value) {
      this.isNoteUploadInfo = true;
      const noteObj = {
        id: this.selectedEmobility.id,
        note: value.note,
        title: value.title,
        filename: value.filename,
        user_id: this.selectedEmobility.user_id,
      };
      this.$store.commit(
        'setLastUpdatedComponent',
        'emobilityNoteUploadFileComponent'
      );
      if (value.note) {
        try {
          const response = await this.sendNote(noteObj);

          // Has to get the "_persistent_event_id_" from addEmobilityNote; used for s3-subfoldering
          if (value.filename) {
            const url = (
              await documentService.putEmobilityDocumentGraphQL(
                `${this.selectedEmobility.id}/${response.addEmobilityNote._persistent_event_id_}/${value.filename}`,
                process.env.VUE_APP_STAGE
              )
            ).uploadFile;
            await uploadFile(url, value.file);
            this.deleteNote = true;
          }
          this.isNoteUploadInfo = false;
        } catch (err) {
          this.isNoteUploadInfo = false;
          this.alertErrorNote = true;
          console.error(err);
        }
      }
    },
    updateEmobilityInspectionValues(confirmed) {
      const oldValue = {
        emobility_address_checked:
          !!this.selectedEmobility.emobility_address_checked,
        emobility_customer_data_checked:
          !!this.selectedEmobility.emobility_customer_data_checked,
        emobility_gis_checked: !!this.selectedEmobility.emobility_gis_checked,
        emobility_charging_power_checked:
          !!this.selectedEmobility.emobility_charging_power_checked,
        emobility_charging_power_values:
          this.selectedEmobility.emobility_charging_power_values,
      };
      const changedObject = {
        id: this.selectedEmobility.id,
        request_date: this.selectedEmobility.request_date,
        old_value: oldValue,
        new_value: this.emobilityInspectionData,
      };
      if (confirmed) {
        changedObject.status = 'completed';
      }
      this.$store.commit(
        'setLastUpdatedComponent',
        'saveCurrentResultEmobility'
      );
      emobilityService
        .updateEmobilityInspectionData(changedObject, this.userPersonalData)
        .then(() => {
          this.$store.commit('setLastUpdatedComponent', 'saveResultEmobility');
          if (confirmed) {
            this.$store.commit('updateSelectedEmobilityData', {
              status: 'ORDER_CONFIRMED',
            });
          }
        })
        .catch((err) => {
          this.alertError = true;
          console.log(err);
        });
    },
    confirmCheck() {
      if (this.allDataSelected) {
        this.showLoading = true;
        this.updateEmobilityInspectionValues(true);
      } else {
        this.showErrors = true;
      }
    },
    getChangedData() {
      const currentEmobility = JSON.parse(
        JSON.stringify(this.$store.getters.getSelectedEmobility)
      );
      return { status: currentEmobility.status };
    },
    changeStatus(note) {
      const requestBody = {
        id: this.selectedEmobility.id,
        request_date: this.selectedEmobility.request_date,
        new_value: this.$store.getters.getChangedObject,
        old_value: { status: this.$store.getters.getSelectedEmobility.status },
        remark: note ? note : '',
      };
      emobilityService
        .updateEmobilityStatus(requestBody, this.userPersonalData)
        .then((res) => {
          if (res.updateEmobilityStatus) {
            this.$store.commit(
              'updateSelectedEmobilityData',
              JSON.parse(res.updateEmobilityStatus.new_value)
            );
          } else {
            this.alertError = true;
          }
        })
        .catch((err) => {
          console.log(err);
          this.alertError = true;
        });
      this.$store.commit('deleteChangedObject');
      this.$store.commit('deleteOldValueObject');
    },
  },
};
</script>

<style scoped>
.checkbox-list {
  display: flex;
  flex-direction: column;
  width: 100% !important;
}

.container--note-upload-pending {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
