var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.selectedOrder && Object.keys(_vm.selectedOrder).length !== 0)?_c('div',[_c('div',[_c('header-details',{staticClass:"default-mb"}),(_vm.selectedOrderStatus === 'ORDER_TECHNICAL_FEASIBILITY')?_c('NewGlassCard',{staticClass:"default-mb",attrs:{"title":_vm.$t('technical_inspection.title')}},[_c('technical-inspection',{on:{"show-modal":function($event){return _vm.showModal($event)}}})],1):_vm._e(),(
          _vm.selectedOrderStatus === 'ORDER_APPOINTMENT_PENDING' ||
          this.showConstructionComponent === true
        )?_c('construction-component',{attrs:{"showConstructionComponent":_vm.showConstructionComponent},on:{"show-component":function($event){_vm.showConstructionComponent = false}}}):_vm._e(),(
          _vm.selectedOrderStatus === 'ORDER_APPOINTMENT_CONFIRMED' &&
          this.showConstructionComponent === false
        )?_c('NewGlassCard',{staticClass:"default-mb",attrs:{"title":_vm.$t('construction_work.title')}},[_c('construction-dashboard',{on:{"edit":function($event){_vm.showConstructionComponent = true}}})],1):_vm._e(),(
          _vm.selectedOrderStatus === 'ORDER_CONFIRMED' ||
          _vm.selectedOrderStatus === 'ORDER_APPOINTMENT_PENDING' ||
          _vm.selectedOrderStatus === 'ORDER_APPOINTMENT_CONFIRMED'
        )?_c('NewGlassCard',{staticClass:"default-mb",attrs:{"title":_vm.$t('technical_inspection.title')}},[(_vm.showTechnicalInspectionresult)?_c('technical-inspection',{attrs:{"notEditable":true},on:{"closeTechnicalInspection":function($event){_vm.showTechnicalInspectionresult = false}}}):_c('single-raw',{staticClass:"single-raw",attrs:{"title":_vm.formatDate(this.historyDate),"notEditable":true},on:{"edit":function($event){_vm.showTechnicalInspectionresult = true}}},[_c('div',{staticClass:"technical_inspection_container"},[_c('div',[_vm._v(_vm._s(_vm.$t('details_page.technical_inspection_confirmed')))]),_c('v-icon',{staticClass:"icon",attrs:{"color":"green","x-large":""}},[_vm._v("mdi-check-bold")])],1)])],1):_vm._e()],1),_c('div',{class:this.$vuetify.breakpoint.xl ? 'xl-design-details-page' : ''},[_c('div',[_c('NewGlassCard',{staticClass:"z-index-0",class:this.$vuetify.breakpoint.xl
              ? 'xl-design-details-page-element default-mb'
              : 'default-mb',attrs:{"id":"order-details","title":_vm.$t('order_details.title')}},[_c('order-details',{attrs:{"emob_file_names":_vm.selectedEmobFiles,"water_file_name":_vm.selectedWaterFiles},on:{"show-modal":function($event){return _vm.showModal($event)}}})],1)],1),_c('div',[(_vm.selectedOrder.user_questions)?_c('NewGlassCard',{staticClass:"z-index-0",class:this.$vuetify.breakpoint.xl
              ? 'xl-design-details-page-element default-mb ml-2'
              : 'default-mb',attrs:{"id":"order-questions","title":_vm.$t('order_questions.title')}},[_c('OrderQuestions',{attrs:{"orderQuestionsArray":_vm.orderQuestionsArray}})],1):_vm._e(),_c('NewGlassCard',{staticClass:"z-index-0",class:this.$vuetify.breakpoint.xl
              ? 'xl-design-details-page-element default-mb ml-2'
              : 'default-mb',attrs:{"title":_vm.$t('history.title')}},[_c('history-table',{attrs:{"historyType":'Order'}})],1),_c('NewGlassCard',{staticClass:"z-index-0",class:this.$vuetify.breakpoint.xl
              ? 'xl-design-details-page-element default-mb ml-2'
              : 'default-mb',attrs:{"title":_vm.$t('document_history.title')}},[_c('document-history-component',{attrs:{"historyType":'Order'}})],1)],1)]),(_vm.isModalVisible)?_c('NewModalDialog',{attrs:{"value":_vm.noteValue,"role":"dialog"},on:{"update-note-data":_vm.updateNoteChangedObject,"cancel-data":_vm.closeModal},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('dynamic-component',{staticClass:"body-style",attrs:{"type":_vm.currentComponent,"componentProperties":_vm.componentProperties}}),(_vm.showModalUpdateError)?_c('div',{staticClass:"error-dialog ml-8",staticStyle:{"width":"fit-content"}},[_vm._v(" "+_vm._s(_vm.$t('feed_in_system_details.saving_failed_please_reload'))+" ")]):_vm._e()]},proxy:true},{key:"footer",fn:function(){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSpinner),expression:"showSpinner"}],staticClass:"update-event-spinner"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":60,"width":5}})],1),(!_vm.showSpinner)?_c('div',[_c('button',{staticClass:"btn-save",attrs:{"type":"button"},on:{"click":_vm.closeModalAndSaveData}},[_vm._v(" Speichern ")]),_c('button',{staticClass:"btn-cancel",attrs:{"type":"button"},on:{"click":_vm.closeModal}},[_vm._v(" abbrechen ")])]):_vm._e(),(_vm.alert)?_c('v-alert',{staticClass:"alert_error ml-4",attrs:{"type":"error","text":"","transition":"scale-transition"}},[_vm._v(" Speicherung fehlgeschlagen ")]):_vm._e()]},proxy:true}],null,false,1858300051)}):_vm._e()],1):_vm._e(),(_vm.isServerError)?_c('div',[_c('h1',[_vm._v(" "+_vm._s(_vm.$t('order_details.loading_error'))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('order_details.error_hint'))+" ")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }