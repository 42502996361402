var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticStyle:{"justify-content":"flex-end","margin-right":"0px"}}),_c('single-raw',{staticClass:"single-raw",attrs:{"first":"","title":_vm.$t('installer_details.date'),"editable":false}},[_vm._v(_vm._s(_vm.formatDate(_vm.selectedEmobility.request_date)))]),_c('single-raw',{staticClass:"single-raw",attrs:{"title":_vm.$t('order_details.connection_address')},on:{"edit":function($event){return _vm.$emit('show-modal', {
        component: 'EmobilityInstallationAddressComponent',
        method: 'updateEmobilityInstallationAddress',
      })}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.emobilityDetails.installationAddress)}}),(_vm.notifyEvent.field === 'updateEmobilityInstallationAddress')?_c('userfeedback-component'):_vm._e()],1),_c('single-raw',{staticClass:"single-raw",attrs:{"title":_vm.$t('emobility_details.location')},on:{"edit":function($event){return _vm.$emit('show-modal', {
        component: 'EmobilityWallboxLocationComponent',
        method: 'updateEmobilityWallboxLocation',
      })}}},[_c('div',[_vm._v(_vm._s(_vm.emobilityDetails.wallbox_location))]),(_vm.notifyEvent.field === 'updateEmobilityWallboxLocation')?_c('userfeedback-component'):_vm._e()],1),_c('single-raw',{staticClass:"single-raw",attrs:{"title":_vm.$t('emobility_details.charging_power')},on:{"edit":function($event){return _vm.$emit('show-modal', {
        component: 'EmobilityChargingPowerComponent',
        method: 'updateEmobilityChargingPower',
      })}}},[_c('div',[_vm._v(_vm._s(_vm.emobilityDetails.e_mobility_installation))]),(_vm.notifyEvent.field === 'updateEmobilityChargingPower')?_c('userfeedback-component'):_vm._e()],1),_c('single-raw',{staticClass:"single-raw",attrs:{"title":_vm.$t('order_details.blueprints')},on:{"edit":function($event){return _vm.$emit('show-modal', {
        component: 'EmobilityFileUploadComponent',
        method: 'updateEmobilityFilesName',
      })}}},[(_vm.selectedEmobility.plans_available)?_c('div',[_c('div',{staticClass:"blueprints_component_content"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('emobility_details.site_plan'))+" "),_vm._l((_vm.emobilityDetails.blueprints),function(item){return _c('v-btn',{key:item,attrs:{"icon":""},on:{"click":function($event){return _vm.downloadEmobilityFile(item)}}},[_c('v-icon',[_vm._v("mdi-download")])],1)})],2)])]):_c('div',[_c('div',[_vm._v("-")])]),(_vm.notifyEvent.field === 'updateEmobilityFilesName')?_c('userfeedback-component'):_vm._e()],1),_c('single-raw',{staticClass:"single-raw",attrs:{"title":_vm.$t('order_details.applicant')},on:{"edit":function($event){return _vm.$emit('show-modal', {
        component: 'EmobilityAppplicantComponent',
        method: 'updateEmobilityApplicant',
      })}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.emobilityDetails.applicant)}}),(_vm.notifyEvent.field === 'updateEmobilityApplicant')?_c('userfeedback-component'):_vm._e()],1),_c('single-raw',{staticClass:"single-raw",attrs:{"title":_vm.$t('order_details.additional_contact_person')},on:{"edit":function($event){return _vm.$emit('show-modal', {
        component: 'EmobilityAdditionalContactComponent',
        method: 'updateEmobilityAdditionalContactPerson',
      })}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.emobilityDetails.otherContacts)}}),(_vm.notifyEvent.field === 'updateEmobilityAdditionalContactPerson')?_c('userfeedback-component'):_vm._e()],1),_c('single-raw',{staticClass:"single-raw",attrs:{"title":_vm.$t('order_details.landowner'),"last":""},on:{"edit":function($event){return _vm.$emit('show-modal', {
        component: 'EmobilityLandownerComponent',
        method: 'updateEmobilityLandowner',
      })}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.emobilityDetails.landowner)}}),(_vm.notifyEvent.field === 'updateEmobilityLandowner')?_c('userfeedback-component'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }