import { EMOBILITY_STATUSES, REGIONAL_TEAMS } from '../../helpers/constants';
import i18n from '../../plugins/i18n';

const configurations = {
  statusValuesList: getStatusesConfig(),
  headersAktive: [
    { text: 'Datum', value: 'date' },
    { text: 'Antragsteller', value: 'applicant' },
    { text: 'Anschlussadresse', value: 'address' },
    { text: 'zul. bearbeitet von', value: 'lastChangedBy' },
    {
      text: 'Status',
      value: 'status',
      filterable: true,
      filter: (value) =>
        this.statusSelection === 'all' || value === this.statusSelection,
    },
    { value: 'edit' },
    {
      value: 'regional_team',
      class: 'hide-column',
      align: ' d-none',
      filterable: true,
      filter: (value) =>
        this.regionalTeamSelection.value === 'all' ||
        value === this.regionalTeamSelection.value,
    },
  ],
  regionalTeams: getRTConfig(),
};

export const getEmobilityTableConfig = (configName) => {
  return JSON.parse(JSON.stringify(configurations[configName]));
};

function getStatusesConfig() {
  let statusesArray = [];
  statusesArray = Object.keys(EMOBILITY_STATUSES).map((key) => {
    const { label, value, color } = EMOBILITY_STATUSES[key];
    return { label, value, color };
  });
  statusesArray.forEach((item) => {
    item.label = i18n.t(`emobility_statuses.${item.value}`);
  });
  statusesArray.unshift({ label: 'alle', value: 'all', color: '#999999' });
  return statusesArray;
}

function getRTConfig() {
  let statusesArray = [];
  statusesArray = Object.keys(REGIONAL_TEAMS).map((key) => {
    const { label, value, color } = REGIONAL_TEAMS[key];
    return { label, value, color };
  });

  statusesArray.unshift({ label: 'alle', value: 'all', color: '#999999' });
  return statusesArray;
}
