import Vue from 'vue';

export const initialCounterData = {
  measuring_location: null,
  type_of_usage: 'feedin',
  counter_id: null,
  valid_from: null,
  counter_type: null,
  charge_type: null,
  current_direction: null,
  mount_type: null,
  measuring_device_type: null,
  obis_key: null,
  pre_decimal_places: null,
  decimal_places: null,
};

const initialState = {
  selected_voltage: '',
  selected_voltage_kv_value: '',
  selected_customer_station: '',
  customer_station_plan: '',
  technical_inspection_file_name_with_UUID: '',
  note_title: '',
  note_comment: '',
  technical_specifications_checked: false,
  implementing_date_checked: false,
  measure_concept_checked: null,
  e_plan_checked: null,
  pv_inb_measurement_data_checked: false,
  pv_inb_measurement_data: {
    market_location_provider: null,
    measuring_location_provider: null,
    counting_method: null,
  },
  pv_inb_protokoll_measurement_data_checked: false,
  pv_inb_protokoll_measurement_data: {
    market_location: null,
  },
  data_file_is_ready: false,
  counter_data_checked: [false, false],
  counters: [JSON.parse(JSON.stringify(initialCounterData))],
  voltage_checked: false,
  show_spinner_technical_inspection: false,
  show_error_message_when_technical_inspection_fails: false,
};

const getters = {
  getSelectedVoltage: ({ selected_voltage }) => selected_voltage,
  getSelectedVoltageKVValue: ({ selected_voltage_kv_value }) =>
    selected_voltage_kv_value,
  getSelectedCustomerStation: ({ selected_customer_station }) =>
    selected_customer_station,
  getCustomerStationPlan: ({ customer_station_plan }) => customer_station_plan,
  getTechnicalInspectionFileNameWithUUID: ({
    technical_inspection_file_name_with_UUID,
  }) => technical_inspection_file_name_with_UUID,
  getNoteTitle: ({ note_title }) => note_title,
  getNoteComment: ({ note_comment }) => note_comment,
  getTechnicalSpecificationsChecked: ({ technical_specifications_checked }) =>
    technical_specifications_checked,
  getImplementingDateChecked: ({ implementing_date_checked }) =>
    implementing_date_checked,
  getMeasureConceptChecked: ({ measure_concept_checked }) =>
    measure_concept_checked,
  getEPlanChecked: ({ e_plan_checked }) => e_plan_checked,
  getDataFileIsReady: ({ data_file_is_ready }) => data_file_is_ready,
  getCounterDataChecked: ({ counter_data_checked }) => counter_data_checked,
  getCountersData: ({ counters }) => counters,
  getCountersDataRequest: (state) => {
    const countersData = JSON.parse(JSON.stringify(state.counters));
    countersData.forEach((counterData) => {
      counterData.pre_decimal_places = counterData.pre_decimal_places
        ? Number(counterData.pre_decimal_places)
        : null;
      counterData.decimal_places = counterData.decimal_places
        ? Number(counterData.decimal_places)
        : null;
    });
    return countersData;
  },
  getPVInbMeasurementDataChecked: ({ pv_inb_measurement_data_checked }) =>
    pv_inb_measurement_data_checked,
  getPVInbMeasurementData: ({ pv_inb_measurement_data }) =>
    pv_inb_measurement_data,
  getPVInbProtokollMeasurementDataChecked: ({
    pv_inb_protokoll_measurement_data_checked,
  }) => pv_inb_protokoll_measurement_data_checked,
  getPVInbProtokollMeasurementData: ({ pv_inb_protokoll_measurement_data }) =>
    pv_inb_protokoll_measurement_data,
  getShowSpinnerInTechnicalInspection: ({
    show_spinner_technical_inspection,
  }) => show_spinner_technical_inspection,
  getShowErrorMessageWhenTechnicalInspectionFails: ({
    show_error_message_when_technical_inspection_fails,
  }) => show_error_message_when_technical_inspection_fails,
};

const mutations = {
  resetFeedInSystemsTechincalInspectionsModule(state) {
    Object.assign(state, JSON.parse(JSON.stringify(initialState)));
  },
  setSelectedVoltage(state, value) {
    state.selected_voltage = value;
  },
  setSelectedVoltageKVValue(state, value) {
    state.selected_voltage_kv_value = value;
  },
  setSelectedCustomerStation: (state, value) => {
    state.selected_customer_station = value;
  },
  setCustomerStationPlan: (state, value) => {
    state.customer_station_plan = value;
  },
  setTechnicalInspectionFileNameWithUUID: (state, value) => {
    const parts = value.split('/');
    const uuidAndFileName = parts.slice(1).join('/');
    // Save only the uuid/file_name part without the ID
    state.technical_inspection_file_name_with_UUID = uuidAndFileName;
  },
  setNoteTitle: (state, value) => {
    state.note_title = value;
  },
  setNoteComment: (state, value) => {
    state.note_comment = value;
  },
  setTechnicalSpecificationsChecked: (state, value) => {
    state.technical_specifications_checked = value;
  },
  setImplementingDateChecked: (state, value) => {
    state.implementing_date_checked = value;
  },
  setMeasureConceptChecked: (state, value) => {
    state.measure_concept_checked = value;
  },
  setEPlanChecked: (state, value) => {
    state.e_plan_checked = value;
  },
  setDataFileIsReady: (state, value) => {
    state.data_file_is_ready = value;
  },
  setCounterDataChecked: (state, valueObject) => {
    Vue.set(state.counter_data_checked, valueObject.index, valueObject.value);
  },
  updateCountersData: (state, valueObject) => {
    state.counters = valueObject;
  },
  updateCounterData: (state, valueObject) => {
    const { index, key, value } = valueObject;
    if (state.counters[index] && key in state.counters[index]) {
      state.counters[index][key] = value;
    }
  },
  addCounterDataObject: (state) => {
    state.counters.push(JSON.parse(JSON.stringify(initialCounterData)));
  },
  removeCounterDataObject: (state) => {
    state.counters.pop();
  },
  setPVInbMeasurementDataChecked: (state, value) => {
    state.pv_inb_measurement_data_checked = value;
  },
  updatePVInbMeasurementData: (state, valueObject) => {
    state.pv_inb_measurement_data[valueObject.key] = valueObject.value;
  },
  setPVInbProtokollMeasurementDataChecked: (state, value) => {
    state.pv_inb_protokoll_measurement_data_checked = value;
  },
  updatePVInbProtokollMeasurementData: (state, valueObject) => {
    state.pv_inb_protokoll_measurement_data[valueObject.key] =
      valueObject.value;
  },
  setVoltageChecked: (state, value) => {
    state.voltage_checked = value;
  },
  setShowSpinnerInTechnicalInspection: (state, value) => {
    state.show_spinner_technical_inspection = value;
    setTimeout(() => {
      state.show_spinner_technical_inspection = false;
    }, 30000);
  },
  setShowErrorMessageWhenTechnicalInspectionFails: (state, value) => {
    state.show_error_message_when_technical_inspection_fails = value;
  },
};

const actions = {
  async addCounterDataObject({ commit }) {
    commit('addCounterDataObject');
  },
};

export const feedInSystemsTechnicalInspectionModule = {
  state: JSON.parse(JSON.stringify(initialState)),
  mutations,
  getters,
  actions,
};
