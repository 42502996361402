<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="21"
    viewBox="0 0 24 21"
  >
    <path
      id="Icon_awesome-exclamation-triangle"
      data-name="Icon awesome-exclamation-triangle"
      d="M23.73,18.047A1.97,1.97,0,0,1,22,21H2A1.97,1.97,0,0,1,.27,18.047l10-17.064a2.017,2.017,0,0,1,3.465,0ZM12,14.52a1.887,1.887,0,1,0,1.917,1.887A1.9,1.9,0,0,0,12,14.52ZM10.18,7.738l.309,5.578a.5.5,0,0,0,.5.465h2.023a.5.5,0,0,0,.5-.465l.309-5.578a.5.5,0,0,0-.5-.519H10.679A.5.5,0,0,0,10.18,7.738Z"
      fill="#9b004b"
    />
  </svg>
</template>

<script>
export default {
  name: 'ExclamationTriangle',
  props: {
    width: {
      type: [Number, String],
      default: 30,
    },
    height: {
      type: [Number, String],
      default: 30,
    },
  },
};
</script>

<style scoped></style>
