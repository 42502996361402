import { CITY_TITLE, COMPANY_TITLE } from './constants';
import i18n from '../plugins/i18n';
import i18nData from '../assets/i18n/de.json';
import { transformI18nDataToLabelsObject } from '@newnetzportal/netzportal-webapp-storybook-components';

export const deleteAllEmptyFields = (objectData) => {
  Object.keys(objectData).forEach((el) => {
    if (
      objectData[el] === undefined ||
      objectData[el] === null ||
      objectData[el] === '' ||
      (typeof objectData[el] === 'object' &&
        Object.keys(objectData[el]).length === 0) // Check for empty objects
    ) {
      delete objectData[el];
    } else if (typeof objectData[el] === 'object' && objectData[el] !== null) {
      // Recursively apply the deleteAllEmptyFields function for nested objects
      objectData[el] = deleteAllEmptyFields(objectData[el]);
      if (Object.keys(objectData[el]).length === 0) {
        delete objectData[el];
      }
    }
  });
  return objectData;
};

export const removeNullFromArray = (dataArray) => {
  return dataArray.filter((item) => item !== null);
};

export const deleteAllEmptyFieldsIncludingEmptyArrayValues = (objectData) => {
  Object.keys(objectData).forEach((el) => {
    if (
      objectData[el] === undefined ||
      objectData[el] === null ||
      objectData[el] === '' ||
      (typeof objectData[el] === 'object' &&
        Object.keys(objectData[el]).length === 0) // Check for empty objects
    ) {
      delete objectData[el];
    } else if (typeof objectData[el] === 'object' && objectData[el] !== null) {
      // Recursively apply the deleteAllEmptyFields function for nested objects
      objectData[el] = deleteAllEmptyFields(objectData[el]);
      if (Array.isArray(objectData[el])) {
        // If the property is an array and its key is 'counter_id_data', remove null values
        objectData[el] = removeNullFromArray(objectData[el]);
      }
      if (Object.keys(objectData[el]).length === 0) {
        delete objectData[el];
      }
    }
  });
  return objectData;
};

export const areAllPropsNullUndefinedOrBlank = (obj) => {
  for (const key in obj) {
    const value = obj[key];
    if (
      value !== null &&
      value !== undefined &&
      !(typeof value === 'string' && value.trim() === '')
    ) {
      return false;
    }
  }
  return true;
};

export const deletePersonalParametersBasedOnTitle = (objectData) => {
  if (objectData.title === COMPANY_TITLE) {
    delete objectData.name;
    delete objectData.surname;
    delete objectData.city_name;
  } else if (objectData.title === CITY_TITLE) {
    delete objectData.name;
    delete objectData.surname;
    delete objectData.company_name;
  } else {
    delete objectData.company_name;
    delete objectData.city_name;
  }
  return objectData;
};

export function germanNumberFormat(value) {
  if (typeof value !== 'number') {
    return value;
  }
  return value.toLocaleString('de-DE');
}

export const removeAttributes = (obj, attributesToRemove) => {
  if (!obj || typeof obj !== 'object') return obj;

  const newObj = { ...obj };

  attributesToRemove.forEach((attr) => {
    // eslint-disable-next-line no-prototype-builtins
    if (newObj.hasOwnProperty(attr)) {
      delete newObj[attr];
    }
  });

  return newObj;
};

export const getTranslations = (translationValue) => {
  return {
    ...transformI18nDataToLabelsObject(
      i18nData,
      translationValue,
      i18n.t.bind(i18n)
    ),
  };
};

export const removeUUIDFromFileName = (fileNameWithUUID) => {
  if (!fileNameWithUUID) {
    return '';
  }
  return fileNameWithUUID.split('/').pop();
};
