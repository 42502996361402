export const emptyChargingPowerValues = {
  emobility_charging_power_inspection: '',
  emobility_charging_power_custom_inspection: '',
};

export const emobilityInspectionModule = {
  state: {
    emobility_address_checked: false,
    emobility_customer_data_checked: false,
    emobility_gis_checked: false,
    emobility_charging_power_checked: false,
    emobility_charging_power_values: emptyChargingPowerValues,
  },
  mutations: {
    setEmobilityAdressSelected(state, emobility_address_checked) {
      state.emobility_address_checked = emobility_address_checked;
    },
    setEmobilityCustomerDataSelected(state, emobility_customer_data_checked) {
      state.emobility_customer_data_checked = emobility_customer_data_checked;
    },
    setEmobilityGisSelected(state, emobility_gis_checked) {
      state.emobility_gis_checked = emobility_gis_checked;
    },
    setEmobilityChargingPowerSelected(state, emobility_charging_power_checked) {
      state.emobility_charging_power_checked = emobility_charging_power_checked;
    },
    setEmobilityChargingPowerInspection(
      state,
      emobility_charging_power_inspection
    ) {
      state.emobility_charging_power_values.emobility_charging_power_inspection =
        emobility_charging_power_inspection;
    },
    setEmobilityChargingStationCustomValueInspection(
      state,
      emobility_charging_power_custom_inspection
    ) {
      state.emobility_charging_power_values.emobility_charging_power_custom_inspection =
        emobility_charging_power_custom_inspection;
    },
    resetChecksForEmobilityInspection(state) {
      state.emobility_address_checked = false;
      state.emobility_customer_data_checked = false;
      state.emobility_gis_checked = false;
      state.emobility_charging_power_checked = false;
      state.emobility_charging_power_values = emptyChargingPowerValues;
    },
    setEmobilityChargingPowerValues(state, emobility_charging_power_values) {
      state.emobility_charging_power_values = emobility_charging_power_values;
    },
  },
  getters: {
    getEmobilityAdressSelected: (state) => {
      return state.emobility_address_checked;
    },
    getEmobilityCustomerDataSelected: (state) => {
      return state.emobility_customer_data_checked;
    },
    getEmobilityGisSelected: (state) => {
      return state.emobility_gis_checked;
    },
    getEmobilityChargingPowerSelected: (state) => {
      return state.emobility_charging_power_checked;
    },
    getEmobilityInspectionData: (state) => {
      const data = {
        emobility_address_checked: state.emobility_address_checked,
        emobility_customer_data_checked: state.emobility_customer_data_checked,
        emobility_gis_checked: state.emobility_gis_checked,
        emobility_charging_power_checked:
          state.emobility_charging_power_checked,
        emobility_charging_power_values: state.emobility_charging_power_values,
      };
      return data;
    },
    getChargingPowerInspection: (state) => {
      return state.emobility_charging_power_values
        .emobility_charging_power_inspection;
    },
    getChargingStationCustomValueInspection: (state) => {
      return state.emobility_charging_power_values
        .emobility_charging_power_custom_inspection;
    },
    getEmobilityIfDataChargingPowerSelected: (state) => {
      const valueSelected = state.emobility_charging_power_values
        .emobility_charging_power_inspection
        ? true
        : false;
      return valueSelected;
    },
    getIfAllDataEmobilitySelected: (state) => {
      const valueSelected =
        state.emobility_address_checked &&
        state.emobility_customer_data_checked &&
        state.emobility_gis_checked &&
        state.emobility_charging_power_checked;
      return valueSelected;
    },
  },
};
