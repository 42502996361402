import GraphQLService from './GraphQLService';
import {
  getPhotovoltaicByIdQuery,
  getPhotovoltaicsWithFilterQuery,
} from './GraphQLQueries';

export async function getPhotovoltaicsWithFilter(requestBody) {
  return GraphQLService.requestWithOpenID(getPhotovoltaicsWithFilterQuery, {
    limit: requestBody.limit,
    from_index: requestBody.from_index,
    order_status: requestBody.order_status,
    journey: requestBody.journey,
    search_word: requestBody.search_word,
    min_pv_system_power_W: requestBody.min_pv_system_power_W,
    max_pv_system_power_W: requestBody.max_pv_system_power_W,
    start_date: requestBody.start_date,
    end_date: requestBody.end_date,
  });
}

export async function getPhotovoltaicsSummaryForPipeline(requestBody) {
  return GraphQLService.requestWithOpenID(
    `
      query getPhotovoltaicsSummaryForPipeline($journey: String) { 
        getPhotovoltaicsSummaryForPipeline(journey: $journey) {
          count_all,
          count_order_created,
          count_order_technical_feasibility,
          count_order_confirmed,
          count_order_in_implementation,
          count_order_in_implementation_inspection,
          count_order_in_implementation_confirmed,
          count_order_fulfilled,
          count_order_fulfilled_inspection,
          count_order_fulfilled_confirmed,
          count_order_transmitted,
          count_order_declined,
          count_order_cancelled,
          count_order_protocol_cancelled,
        }
      }`,
    {
      journey: requestBody.journey,
    }
  );
}

export async function getPhotovoltaicsSummaryForDiagram() {
  return GraphQLService.requestWithOpenID(
    `
      query getPhotovoltaicsSummaryForDiagram { 
        getPhotovoltaicsSummaryForDiagram {
          count_orders_all
          count_orders_photovoltaik
          count_orders_pv_inbetriebnahme
          count_orders_pv_inbetriebnahme_protokoll
        }
      }`
  );
}

export async function getPhotovoltaicsSummary() {
  return GraphQLService.requestWithOpenID(
    `
      query getPhotovoltaicsSummary { 
        getPhotovoltaicsSummary {
          count_orders_all
          count_orders_photovoltaik
          count_orders_pv_inbetriebnahme
          count_orders_pv_inbetriebnahme_protokoll
        }
      }`
  );
}

export async function getPhotovoltaicById(orderId) {
  return GraphQLService.requestWithOpenID(getPhotovoltaicByIdQuery, {
    id: orderId,
  });
}

export async function updatePVParameterSet(requestObject, user) {
  return GraphQLService.requestWithOpenID(
    `
      mutation updatePVParameterSet($id: String!, $param: PVParameterSetPayload, $event_origin: String!, $changed_by: String! ) { 
        updatePVParameterSet(id: $id, payload: $param, event_origin: $event_origin, changed_by: $changed_by ){
          id
          changed_by
          event_origin
          created
          field 
          old_value
          new_value
          note
        }
      }`,
    {
      id: requestObject.id,
      param: requestObject,
      event_origin: 'BACKOFFICE',
      changed_by: user,
    }
  );
}

export async function updatePVCounterId(requestObject, user) {
  return GraphQLService.requestWithOpenID(
    `
      mutation updatePVCounterId($id: String!, $counter_id_object: PVCounterIdDataPayload, $event_origin: String!, $changed_by: String! ) { 
        updatePVCounterId(id: $id, payload: $counter_id_object, event_origin: $event_origin, changed_by: $changed_by ){
          id
          changed_by
          event_origin
          created
          field 
          old_value
          new_value
          note
        }
      }`,
    {
      id: requestObject.id,
      counter_id_object: requestObject,
      event_origin: 'BACKOFFICE',
      changed_by: user,
    }
  );
}

export async function updatePhotovoltaics(updateEvent, requestObject, user) {
  requestObject = JSON.parse(JSON.stringify(requestObject)); // perform a deep copy
  if (requestObject.old_value) {
    requestObject.old_value = JSON.stringify(requestObject.old_value);
  }
  if (requestObject.new_value) {
    requestObject.new_value = JSON.stringify(requestObject.new_value);
  }

  return GraphQLService.requestWithOpenID(
    `
      mutation updatePhotovoltaics($update_event: String!, $id: String!, $payload: UpdatePayload!, $event_origin: String!, $changed_by: String! ) { 
        updatePhotovoltaics(update_event: $update_event, id: $id, payload: $payload, event_origin: $event_origin, changed_by: $changed_by ){
          id
          changed_by
          event_origin
          created
          field 
          old_value
          new_value
          note
        }
      }`,
    {
      update_event: updateEvent,
      id: requestObject.id,
      payload: requestObject,
      event_origin: 'BACKOFFICE',
      changed_by: user,
    }
  );
}

export async function updatePVMaStRData(requestObject, user) {
  return GraphQLService.requestWithOpenID(
    `
      mutation updatePVMaStRData($id: String!, $mastr_object: PVMaStRDataPayload, $event_origin: String!, $changed_by: String! ) { 
        updatePVMaStRData(id: $id, payload: $mastr_object, event_origin: $event_origin, changed_by: $changed_by ){
          id
          changed_by
          event_origin
          created
          field 
          old_value
          new_value
          note
        }
      }`,
    {
      id: requestObject.id,
      mastr_object: requestObject,
      event_origin: 'BACKOFFICE',
      changed_by: user,
    }
  );
}

export async function updatePVStorageMaStRData(requestObject, user) {
  return GraphQLService.requestWithOpenID(
    `
      mutation updatePVStorageMaStRData($id: String!, $storage_mastr_object: PVStorageMaStRDataPayload, $event_origin: String!, $changed_by: String! ) { 
        updatePVStorageMaStRData(id: $id, payload: $storage_mastr_object, event_origin: $event_origin, changed_by: $changed_by ){
          id
          changed_by
          event_origin
          created
          field 
          old_value
          new_value
          note
        }
      }`,
    {
      id: requestObject.id,
      storage_mastr_object: requestObject,
      event_origin: 'BACKOFFICE',
      changed_by: user,
    }
  );
}

export async function updatePVExistingSystems(requestObject, user) {
  return GraphQLService.requestWithOpenID(
    `
      mutation updatePVExistingSystems($id: String!, $existing_systems_object: PVExistingSystemsDataPayload, $event_origin: String!, $changed_by: String! ) { 
        updatePVExistingSystems(id: $id, payload: $existing_systems_object, event_origin: $event_origin, changed_by: $changed_by ){
          id
          changed_by
          event_origin
          created
          field
          old_value
          new_value
          note
        }
      }`,
    {
      id: requestObject.id,
      existing_systems_object: requestObject,
      event_origin: 'BACKOFFICE',
      changed_by: user,
    }
  );
}

export async function updatePVRequestInspectionData(requestObject, user) {
  return GraphQLService.requestWithOpenID(
    `
      mutation updatePVRequestInspectionData($id: String!, $technical_inspection: PVRequestInspectionDataPayload, $event_origin: String!, $changed_by: String! ) { 
        updatePVRequestInspectionData(id: $id, payload: $technical_inspection, event_origin: $event_origin, changed_by: $changed_by ){
          id
          changed_by
          event_origin
          created
          field
          old_value
          new_value
          status
        }
      }`,
    {
      id: requestObject.id,
      technical_inspection: requestObject,
      event_origin: 'BACKOFFICE',
      changed_by: user,
    }
  );
}

export async function updatePVFeedInManagement(requestObject, user) {
  return GraphQLService.requestWithOpenID(
    `
      mutation updatePVFeedInManagement($id: String!, $feedin_management: PVFeedInManagementPayload, $event_origin: String!, $changed_by: String!) {
        updatePVFeedInManagement(id: $id, payload: $feedin_management, event_origin: $event_origin, changed_by: $changed_by) {
          id
          changed_by
          event_origin
          created
          field
          old_value
          new_value
          note
        }
      }`,
    {
      id: requestObject.id,
      feedin_management: requestObject,
      event_origin: 'BACKOFFICE',
      changed_by: user,
    }
  );
}

export async function deliverPVConfirmation(requestObject, user) {
  return GraphQLService.requestWithOpenID(
    `
      mutation deliverPVConfirmation($id: String!, $payload: DeliverPVConfirmationPayload, $event_origin: String!, $changed_by: String!) {
        deliverPVConfirmation(id: $id, payload: $payload, event_origin: $event_origin, changed_by: $changed_by) {
          id
          changed_by
          event_origin
          created
          field
        }
      }`,
    {
      id: requestObject.id,
      payload: requestObject,
      event_origin: 'BACKOFFICE',
      changed_by: user,
    }
  );
}

export async function updatePVFeedInProspect(requestObject, user) {
  return GraphQLService.requestWithOpenID(
    `
      mutation updatePVFeedInProspect($id: String!, $feed_in_prospect_wh: PVFeedInProspectDataPayload, $event_origin: String!, $changed_by: String! ) {
        updatePVFeedInProspect(id: $id, payload: $feed_in_prospect_wh, event_origin: $event_origin, changed_by: $changed_by ){
          id
          changed_by
          event_origin
          created
          field
          old_value
          new_value
          note
        }
      }`,
    {
      id: requestObject.id,
      feed_in_prospect_wh: requestObject,
      event_origin: 'BACKOFFICE',
      changed_by: user,
    }
  );
}

export async function updatePVLocation(requestObject, user) {
  return GraphQLService.requestWithOpenID(
    `
      mutation updatePVLocation($id: String!, $location_object: PVLocationDataPayload, $event_origin: String!, $changed_by: String! ) { 
        updatePVLocation(id: $id, payload: $location_object, event_origin: $event_origin, changed_by: $changed_by ){
          id
          changed_by
          event_origin
          created
          field
          old_value
          new_value
          note
        }
      }`,
    {
      id: requestObject.id,
      location_object: requestObject,
      event_origin: 'BACKOFFICE',
      changed_by: user,
    }
  );
}

export async function updatePVFeedInCompensation(requestObject, user) {
  return GraphQLService.requestWithOpenID(
    `
      mutation updatePVFeedInCompensation($id: String!, $feed_in_compensation: PVFeedInCompensationDataPayload, $event_origin: String!, $changed_by: String! ) {
        updatePVFeedInCompensation(id: $id, payload: $feed_in_compensation, event_origin: $event_origin, changed_by: $changed_by ){
          id
          changed_by
          event_origin
          created
          field
          old_value
          new_value
          note
        }
      }`,
    {
      id: requestObject.id,
      feed_in_compensation: requestObject,
      event_origin: 'BACKOFFICE',
      changed_by: user,
    }
  );
}

export async function updatePVMeasurementConcepts(requestObject, user) {
  return GraphQLService.requestWithOpenID(
    `
      mutation updatePVMeasurementConcepts($id: String!, $measurement_concepts_object: PVMeasurementConceptsDataPayload, $event_origin: String!, $changed_by: String! ) {
        updatePVMeasurementConcepts(id: $id, payload: $measurement_concepts_object, event_origin: $event_origin, changed_by: $changed_by ){
          id
          changed_by
          event_origin
          created
          field
          old_value
          new_value
          note
        }
      }`,
    {
      id: requestObject.id,
      measurement_concepts_object: requestObject,
      event_origin: 'BACKOFFICE',
      changed_by: user,
    }
  );
}

export async function updatePVSystemInfos(requestObject, user) {
  return GraphQLService.requestWithOpenID(
    `
      mutation updatePVSystemInfos($id: String!, $pv_system_infos: PVSystemInfosDataPayload, $event_origin: String!, $changed_by: String! ) { 
        updatePVSystemInfos(id: $id, payload: $pv_system_infos, event_origin: $event_origin, changed_by: $changed_by ){
          id
          changed_by
          event_origin
          created
          field 
          old_value
          new_value
          note
        }
      }`,
    {
      id: requestObject.id,
      pv_system_infos: requestObject,
      event_origin: 'BACKOFFICE',
      changed_by: user,
    }
  );
}

export async function updatePVInverter(requestObject, user) {
  return GraphQLService.requestWithOpenID(
    `
      mutation updatePVInverter($id: String!, $inverter_object: PVInverterDataPayload, $event_origin: String!, $changed_by: String! ) { 
        updatePVInverter(id: $id, payload: $inverter_object, event_origin: $event_origin, changed_by: $changed_by ){
          id
          changed_by
          event_origin
          created
          field
          old_value
          new_value
          note
        }
      }`,
    {
      id: requestObject.id,
      inverter_object: requestObject,
      event_origin: 'BACKOFFICE',
      changed_by: user,
    }
  );
}

export async function updatePVEnergyStorage(requestObject, user) {
  return GraphQLService.requestWithOpenID(
    `
      mutation updatePVEnergyStorage($id: String!, $energy_storage_object: PVEnergyStorageDataPayload, $event_origin: String!, $changed_by: String! ) { 
        updatePVEnergyStorage(id: $id, payload: $energy_storage_object, event_origin: $event_origin, changed_by: $changed_by ){
          id
          changed_by
          event_origin
          created
          field
          old_value
          new_value
          note
        }
      }`,
    {
      id: requestObject.id,
      energy_storage_object: requestObject,
      event_origin: 'BACKOFFICE',
      changed_by: user,
    }
  );
}

export async function updatePVContactData(requestObject, user) {
  return GraphQLService.requestWithOpenID(
    `
      mutation updatePVContactData($id: String!, $contact_data_object: PVContactDataPayload, $event_origin: String!, $changed_by: String! ) { 
        updatePVContactData(id: $id, payload: $contact_data_object, event_origin: $event_origin, changed_by: $changed_by ){
          id
          changed_by
          event_origin
          created
          field
          old_value
          new_value
          note
        }
      }`,
    {
      id: requestObject.id,
      contact_data_object: requestObject,
      event_origin: 'BACKOFFICE',
      changed_by: user,
    }
  );
}

export async function updatePVInstallationAddress(requestObject, user) {
  return GraphQLService.requestWithOpenID(
    `
        mutation updatePVInstallationAddress($id: String!, $InstallationAddressObject: PVInstallationAddressDataPayload, $event_origin: String!, $changed_by: String! ) { 
          updatePVInstallationAddress(id: $id, payload: $InstallationAddressObject, event_origin: $event_origin, changed_by: $changed_by ){
            id
            changed_by
            event_origin
            created
            field 
            old_value
            new_value
            note
          }
        }`,
    {
      id: requestObject.id,
      InstallationAddressObject: requestObject,
      event_origin: 'BACKOFFICE',
      changed_by: user,
    }
  );
}

export async function updatePVFileNames(requestObject, user) {
  return GraphQLService.requestWithOpenID(
    `
      mutation updatePVFileNames($id: String!, $pv_file_names: PVFileNamesDataPayload, $event_origin: String!, $changed_by: String! ) { 
        updatePVFileNames(id: $id, payload: $pv_file_names, event_origin: $event_origin, changed_by: $changed_by ){
          id
          changed_by
          event_origin
          created
          field 
          old_value
          new_value
          note
        }
      }`,
    {
      id: requestObject.id,
      pv_file_names: requestObject,
      event_origin: 'BACKOFFICE',
      changed_by: user,
    }
  );
}

export async function updatePVFinanceData(requestObject, user) {
  return GraphQLService.requestWithOpenID(
    `
      mutation updatePVFinanceData($id: String!, $finance_data_object: PVFinanceDataPayload, $event_origin: String!, $changed_by: String! ) {
        updatePVFinanceData(id: $id, payload: $finance_data_object, event_origin: $event_origin, changed_by: $changed_by ){
          id
          changed_by
          event_origin
          created
          field
          old_value
          new_value
          note
        }
      }`,
    {
      id: requestObject.id,
      finance_data_object: requestObject,
      event_origin: 'BACKOFFICE',
      changed_by: user,
    }
  );
}

export async function updatePVElectricInstallerCompany(requestObject, user) {
  return GraphQLService.requestWithOpenID(
    `
      mutation updatePVElectricInstallerCompany($id: String!, $electric_installer_company_object: PVElectricInstallerCompanyDataPayload, $event_origin: String!, $changed_by: String! ) {
        updatePVElectricInstallerCompany(id: $id, payload: $electric_installer_company_object, event_origin: $event_origin, changed_by: $changed_by ){
          id
          changed_by
          event_origin
          created
          field
          old_value
          new_value
          note
        }
      }`,
    {
      id: requestObject.id,
      electric_installer_company_object: requestObject,
      event_origin: 'BACKOFFICE',
      changed_by: user,
    }
  );
}

export async function updatePVImplementationAppointment(requestObject, user) {
  return GraphQLService.requestWithOpenID(
    `
      mutation updatePVImplementationAppointment($id: String!, $implementation_appointment: PVImplementationAppointmentDataPayload, $event_origin: String!, $changed_by: String! ) {
        updatePVImplementationAppointment(id: $id, payload: $implementation_appointment, event_origin: $event_origin, changed_by: $changed_by ){
          id
          changed_by
          event_origin
          created
          field
          old_value
          new_value
          note
        }
      }`,
    {
      id: requestObject.id,
      implementation_appointment: requestObject,
      event_origin: 'BACKOFFICE',
      changed_by: user,
    }
  );
}

export async function updatePVStatus(requestObject, user) {
  return GraphQLService.requestWithOpenID(
    `
    mutation updatePVStatus($id: String!, $PVStatusDataPayload: PVStatusDataPayload, $event_origin: String!, $changed_by: String!) { 
      updatePVStatus(id: $id, payload: $PVStatusDataPayload, event_origin: $event_origin, changed_by: $changed_by){
          id
          changed_by
          event_origin
          created
          field
          old_value
          new_value
          note
        }
      }`,
    {
      id: requestObject.id,
      PVStatusDataPayload: requestObject,
      event_origin: 'BACKOFFICE',
      changed_by: user,
    }
  );
}

export async function updatePVImplementationInspectionData(
  requestObject,
  user
) {
  return GraphQLService.requestWithOpenID(
    `
      mutation updatePVImplementationInspectionData($id: String!, $technical_inspection: PVImplementationInspectionDataPayload, $event_origin: String!, $changed_by: String! ) { 
        updatePVImplementationInspectionData(id: $id, payload: $technical_inspection, event_origin: $event_origin, changed_by: $changed_by ){
          id
          changed_by
          event_origin
          created
          field
          old_value
          new_value
          status
        }
      }`,
    {
      id: requestObject.id,
      technical_inspection: requestObject,
      event_origin: 'BACKOFFICE',
      changed_by: user,
    }
  );
}
export async function updatePVImplementationProtocolInspectionData(
  requestObject,
  user
) {
  return GraphQLService.requestWithOpenID(
    `
      mutation updatePVImplementationProtocolInspectionData($id: String!, $technical_inspection: PVImplementationProtocolInspectionDataPayload, $event_origin: String!, $changed_by: String! ) { 
        updatePVImplementationProtocolInspectionData(id: $id, payload: $technical_inspection, event_origin: $event_origin, changed_by: $changed_by ){
          id
          changed_by
          event_origin
          created
          field
          old_value
          new_value
          status
        }
      }`,
    {
      id: requestObject.id,
      technical_inspection: requestObject,
      event_origin: 'BACKOFFICE',
      changed_by: user,
    }
  );
}

export async function updatePVAlternateDeliveryAddress(requestObject, user) {
  return GraphQLService.requestWithOpenID(
    `
      mutation updatePVAlternateDeliveryAddress($id: String!, $payload: PVAlternateDeliveryAddressDataPayload, $event_origin: String!, $changed_by: String!) { 
        updatePVAlternateDeliveryAddress(id: $id, payload: $payload, event_origin: $event_origin, changed_by: $changed_by) {
          id
          changed_by
          event_origin
          created
          field 
          old_value
          new_value
          note
        }
      }`,
    {
      id: requestObject.id,
      payload: {
        id: requestObject.id,
        user_id: requestObject.user_id,
        old_value: requestObject.old_value,
        new_value: requestObject.new_value,
        note: requestObject.note,
      },
      event_origin: 'BACKOFFICE',
      changed_by: user,
    }
  );
}

export async function updatePVAlternateInvoiceRecipient(requestObject, user) {
  return GraphQLService.requestWithOpenID(
    `
      mutation updatePVAlternateInvoiceRecipient($id: String!, $payload: PVAlternateInvoiceRecipientDataPayload, $event_origin: String!, $changed_by: String!) { 
        updatePVAlternateInvoiceRecipient(id: $id, payload: $payload, event_origin: $event_origin, changed_by: $changed_by) {
          id
          changed_by
          event_origin
          created
          field 
          old_value
          new_value
          note
        }
      }`,
    {
      id: requestObject.id,
      payload: {
        id: requestObject.id,
        user_id: requestObject.user_id,
        old_value: requestObject.old_value,
        new_value: requestObject.new_value,
        note: requestObject.note,
      },
      event_origin: 'BACKOFFICE',
      changed_by: user,
    }
  );
}
