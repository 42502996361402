var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.selectedEmobility && Object.keys(_vm.selectedEmobility).length !== 0)?_c('div',[_c('div',{staticClass:"page_title"},[_vm._v("Infrastruktur E-Mobilität")]),_c('NewGlassCard',{staticClass:"card-without-title default-mb",attrs:{"title":""}},[_c('div',{staticClass:"header_container"},[_c('v-btn',{staticClass:"back-button",on:{"click":function($event){return _vm.goOnePageBack()}}},[_c('v-icon',{attrs:{"color":"#6E6E6E"}},[_vm._v("mdi-arrow-left")])],1),_c('div',{staticClass:"item_container"},[_c('div',{staticClass:"company_data",domProps:{"innerHTML":_vm._s(this.selectedEmobilityApplicant)}}),_c('div',{staticClass:"company_address",domProps:{"innerHTML":_vm._s(this.selectedEmobilityAdress)}})]),_c('v-btn',{staticClass:"ml-10 confirm-order-button",attrs:{"rounded":"","color":_vm.translateEmobilityStatus[1]}},[_vm._v(" "+_vm._s(_vm.translateEmobilityStatus[0])+" ")]),_c('div',{staticClass:"start-exam-button"},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedEmobility.status === 'ORDER_CREATED'),expression:"selectedEmobility.status === 'ORDER_CREATED'"}],staticClass:"mr-4",attrs:{"color":"primary"},on:{"click":function($event){return _vm.changeStatus('ORDER_TECHNICAL_FEASIBILITY')}}},[_vm._v(" Prüfung starten ")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.selectedEmobility.status === 'ORDER_CONFIRMED' ||
              _vm.selectedEmobility.status === 'ORDER_CANCELLED'
            ),expression:"\n              selectedEmobility.status === 'ORDER_CONFIRMED' ||\n              selectedEmobility.status === 'ORDER_CANCELLED'\n            "}],staticClass:"mr-4",attrs:{"color":"primary"},on:{"click":function($event){return _vm.changeStatus('ORDER_TECHNICAL_FEASIBILITY')}}},[_vm._v(" "+_vm._s(_vm.$t('header_details.check_again'))+" ")])],1)],1)]),(_vm.selectedEmobility.status === 'ORDER_TECHNICAL_FEASIBILITY')?_c('NewGlassCard',{staticClass:"default-mb",attrs:{"title":_vm.$t('technical_inspection.title')}},[_c('emobility-inspection-component',{on:{"show-modal":function($event){return _vm.showModal($event)}}})],1):_vm._e(),(_vm.selectedEmobility.status === 'ORDER_CONFIRMED')?_c('NewGlassCard',{staticClass:"default-mb",attrs:{"title":"Technische Prüfung für E-Mobilität"}},[(_vm.showEmobilityInspectionresult)?_c('emobility-inspection-component',{attrs:{"notEditable":true},on:{"closeTechnicalInspection":function($event){_vm.showEmobilityInspectionresult = false}}}):_c('single-raw',{staticClass:"single-raw",attrs:{"title":_vm.formatDate(this.historyDate),"notEditable":true},on:{"edit":function($event){_vm.showEmobilityInspectionresult = true}}},[_c('div',{staticClass:"technical_inspection_container"},[_c('div',[_vm._v(_vm._s(_vm.$t('details_page.technical_inspection_confirmed')))]),_c('v-icon',{staticClass:"icon",attrs:{"color":"green","x-large":""}},[_vm._v("mdi-check-bold")])],1)])],1):_vm._e(),_c('NewGlassCard',{staticClass:"default-mb z-index-0",attrs:{"id":"emobility-details","title":_vm.$t('emobility_details.title')}},[_c('emobility-details',{attrs:{"file_names":_vm.selectedFiles},on:{"show-modal":function($event){return _vm.showModal($event)}}})],1),_c('div',{class:this.$vuetify.breakpoint.xl ? 'xl-design-details-page' : ''},[_c('NewGlassCard',{staticClass:"z-index-0",class:this.$vuetify.breakpoint.xl
            ? 'xl-design-details-page-element default-mb ml-2'
            : 'default-mb',attrs:{"title":_vm.$t('history.title')}},[_c('history-table',{attrs:{"historyType":'Emobility'}})],1),_c('NewGlassCard',{staticClass:"z-index-0",class:this.$vuetify.breakpoint.xl
            ? 'xl-design-details-page-element default-mb ml-2'
            : 'default-mb',attrs:{"title":_vm.$t('document_history.title')}},[_c('document-history-component',{attrs:{"historyType":'Emobility'}})],1)],1),(_vm.isModalVisible)?_c('NewModalDialog',{attrs:{"value":_vm.noteValue,"role":"dialog"},on:{"update-note-data":_vm.updateNoteChangedObject,"cancel-data":_vm.closeModal},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('dynamic-component',{staticClass:"body-style",attrs:{"type":_vm.currentComponent}}),(_vm.showModalUpdateError)?_c('div',{staticClass:"error-dialog ml-8",staticStyle:{"width":"fit-content"}},[_vm._v(" "+_vm._s(_vm.$t('feed_in_system_details.saving_failed_please_reload'))+" ")]):_vm._e()]},proxy:true},{key:"footer",fn:function(){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSpinner),expression:"showSpinner"}],staticClass:"update-event-spinner"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":60,"width":5}})],1),(!_vm.showSpinner)?_c('div',[_c('button',{staticClass:"btn-save",attrs:{"type":"button"},on:{"click":_vm.closeModalAndSaveData}},[_vm._v(" Speichern ")]),_c('button',{staticClass:"btn-cancel",attrs:{"type":"button"},on:{"click":_vm.closeModal}},[_vm._v(" abbrechen ")])]):_vm._e(),(_vm.alert)?_c('v-alert',{staticClass:"alert_error ml-4",attrs:{"type":"error","text":"","transition":"scale-transition"}},[_vm._v(" Speicherung fehlgeschlagen ")]):_vm._e()]},proxy:true}],null,false,2915830725)}):_vm._e()],1):_vm._e(),(_vm.isServerError)?_c('div',[_c('h1',[_vm._v(" "+_vm._s(_vm.$t('emobility_details.loading_error'))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('emobility_details.error_hint'))+" ")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }