<template>
  <div v-if="checkboxListConfig">
    <TechnicalInspectionComponent
      @save-current-result="updateTechnicalInspectionValues"
      @confirm-check="confirmCheck()"
      @decline-check="openModal"
      @sendNoteAndFile="sendNoteAndUploadFile"
      @handleRadioChange="updateSelectedRadio"
      @updateHeadlineNote="handleNoteTitleUpdate"
      @updateCommentNote="handleNoteCommentUpdate"
      @onFileSelected="(file) => this.fileSelected(file)"
      @onFileDeleted="fileDeleted($event)"
      @update-checkbox-value="updateCheckbox($event)"
      @show-inspection-success-alert="
        technicalInspectionAlert.showInspectionSuccessAlert = $event
      "
      @show-inspection-error-alert="
        technicalInspectionAlert.showInspectionErrorAlert = $event
      "
      @show-note-success-alert="noteUpload.showNoteSuccessAlert = $event"
      @show-note-error-alert="noteUpload.showNoteErrorAlert = $event"
      @update-delete-note="noteUpload.deleteNote = $event"
      @updateInputValue="updateInputData"
      @childFormValidated="updateCheckboxStateAndValidation"
      @new-counter-clicked="updateNewCounterProps"
      :noteTitleAndComment="noteTitleAndCommentValues"
      :noteUpload="noteUpload"
      :isNoteUploading="isNoteUploading"
      :buttonsLabels="buttonsLabels"
      :technicalInspectionAlert="technicalInspectionAlert"
      :showSpinnerDuringUpload="showSpinnerWhileUploading"
      :showErrorMessageWhenUpdateFails="
        showErrorMessageWhenTechnicalInspectionFails
      "
      :NEWNoteWithHeadlineLabels="NEWNoteWithHeadlineLabels"
      :showErrors="showErrors"
      :noDataChangedError="hasNoDataChangedError"
      :atLeastOneGenerationCounterError="atLeastOneGenerationCounterError"
      :feedInTypeOfUsageError="feedInTypeOfUsageError"
      :checkboxListConfig="checkboxListConfig"
      :newAlerLabels="newAlerLabels"
      :errorComponentLabels="errorComponentLabels"
    >
    </TechnicalInspectionComponent>
    <modal-dialog
      v-if="declineOrderSettings.isModalVisible"
      :value="declineOrderSettings.noteValue"
      role="dialog"
      @cancel="closeModal"
      @save="() => {}"
    >
      <template v-slot:body>
        <div class="dialogTitle">
          {{ $t('technical_inspection.decline_order_dialog_title') }}
        </div>
      </template>
      <template v-slot:footer>
        <div class="dialog-footer-container">
          <div>
            <div
              v-if="!declineOrderSettings.showLoading"
              class="button_container"
            >
              <button
                type="button"
                :disabled="
                  !declineOrderSettings.noteValue.note ||
                  !declineOrderSettings.noteValue.note.trim()
                "
                :class="{
                  'btn-save': true,
                  'btn-disabled':
                    !declineOrderSettings.noteValue.note ||
                    !declineOrderSettings.noteValue.note.trim(),
                }"
                @click="declineOrder"
              >
                {{ $t('technical_inspection_buttons.decline_check') }}
              </button>
              <button type="button" class="btn-cancel" @click="closeModal">
                {{ $t('modal_dialog.cancel') }}
              </button>
            </div>
            <div v-else class="button_container">
              <v-progress-circular
                indeterminate
                color="primary"
                class="ml-4"
              ></v-progress-circular>
            </div>
          </div>
          <div>
            <v-alert
              type="error"
              class="alert_error mt-4"
              v-if="declineOrderSettings.showDeclineModalError"
              text
              transition="scale-transition"
            >
              {{
                $t(
                  `technical_inspection.error_messages.${
                    !declineOrderSettings.noteValue.note ||
                    !declineOrderSettings.noteValue.note.trim()
                      ? 'cancellation_reason_field_empty'
                      : 'cancellation_failed'
                  }`
                )
              }}
            </v-alert>
          </div>
        </div>
      </template>
    </modal-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import isEqual from 'lodash.isequal';
import { TechnicalInspectionComponent } from '@newnetzportal/netzportal-webapp-storybook-components';
import noteService from '../../services/NoteService';
import DocumentsService from '../../services/DocumentsService';
import { uploadFile } from '../../services/FileUploadService';
import { getTranslations } from '@/helpers/globalFunctions';
import {
  deleteAllEmptyFields,
  areAllPropsNullUndefinedOrBlank,
} from '@/helpers/globalFunctions';
import { replaceSpecialChars } from '@bit/new-netzportal.netzportal.utils';
import { uploadPVFiles } from '@/helpers/PVFiles';
import {
  updatePVRequestInspectionData,
  updatePVImplementationInspectionData,
  updatePVImplementationProtocolInspectionData,
  updatePVStatus,
} from '@/services/FeedInSystemsService';
import { enableScrolling } from '../feed-in-system-steps-components/PVEnableScrollingMethod';
import { emptyEventData } from '../../store/ordersModule';
import { FEED_IN_STATUSES, PVJOURNEYS } from '@/helpers/constants';
import ModalDialog from '@bit/new-netzportal.netzportal.modal-dialog';
import {
  COUNTER_TYPE_LIST,
  CHARGE_TYPE_LIST,
  CURRENT_DIRECTION_LIST,
  MOUNT_TYPE_LIST,
  MEASURING_DEVICE_TYPE_LIST,
  OBIS_KEY_LIST,
  type_of_usage_options,
  provider_options,
  counting_method_options,
} from './FeedInTechnicalInspectionConstants';
import { initialCounterData } from '../../store/feedInSystemsTechnicalInspectionModule';

export default {
  name: 'FeedInTechnicalInspection',
  components: {
    TechnicalInspectionComponent,
    ModalDialog,
  },
  props: {},
  data() {
    return {
      selectedFiles: [{ file: '', file_name: '', position: 1 }],
      hasNoDataChangedError: false,
      showErrors: false,
      atLeastOneGenerationCounterError: false,
      feedInTypeOfUsageError: false,
      technicalInspectionAlert: {
        showInspectionSuccessAlert: false,
        showInspectionErrorAlert: false,
      },
      isNoteUploading: false,
      noteUpload: {
        labels: getTranslations('updated_note_upload'),
        deleteNote: false,
        showNoteContainerStyle: true,
        fileUploadColor: null,
        showNoteSuccessAlert: false,
        showNoteErrorAlert: false,
      },
      newAlerLabels: getTranslations('new_alert_labels'),
      errorComponentLabels: getTranslations('error_component_labels'),
      checkboxListConfig: null,
      declineOrderSettings: {
        isModalVisible: false,
        showLoading: false,
        showDeclineModalError: false,
        noteValue: { note: '' },
      },
      addCounterConfig: {
        iconComponent: 'NewIconPlus',
        textValue:
          'Sind es mehrere Zähler? Dann fügen Sie hier einen weiteren hinzu',
      },
      removeCounterConfig: {
        iconComponent: 'NewIconMinus',
        textValue: 'Es ist kein weiterer Zähler vorhanden.',
      },
    };
  },
  created() {
    // Reset store to have a clean start. ID_11750, UUID wasn't deleted and taken from previous inspections
    this.$store.commit('resetFeedInSystemsTechincalInspectionsModule');
    this.setExistingValues();
    this.updateCheckboxListConfig();
    this.showErrorMessageWhenTechnicalInspectionFails = false;
  },
  mounted() {
    if (this.getPhotovoltaicFilesInitialized) {
      this.getExistingFiles();
    }
  },
  computed: {
    ...mapGetters([
      'getPhotovoltaicFilesInitialized',
      'getSelectedVoltage',
      'getSelectedVoltageKVValue',
      'getSelectedCustomerStation',
      'getSelectedPhotovoltaic',
      'getCustomerStationPlan',
      'getTechnicalInspectionFileNameWithUUID',
      'getNoteTitle',
      'getNoteComment',
      'getPVFileChanged',
      'getNotifyEvent',
      'getLastUpdatedComponent',
      'getMeasureConceptChecked',
      'getEPlanChecked',
      'getPVInbMeasurementDataChecked',
      'getPVInbMeasurementData',
      'getPVInbProtokollMeasurementDataChecked',
      'getPVInbProtokollMeasurementData',
      'getTechnicalSpecificationsChecked',
      'getImplementingDateChecked',
      'getDataFileIsReady',
      'getUserId',
      'getCounterDataChecked',
      'getCountersData',
      'getCountersDataRequest',
      'getShowSpinnerInTechnicalInspection',
      'getShowErrorMessageWhenTechnicalInspectionFails',
    ]),
    propsToWatch() {
      return [
        this.selectedVoltage,
        this.selectedVoltageKVValue,
        this.customerStationAvailability,
        this.customerStationPlanFileName,
        this.measureConceptChecked,
        this.ePlanChecked,
        this.technicalSpecificationsChecked,
        this.implementingDateChecked,
        this.dataFileIsReady,
        this.showErrors,
      ];
    },
    selectedPhotovoltaic() {
      return this.getSelectedPhotovoltaic;
    },
    pvJourney() {
      return this.selectedPhotovoltaic.journey;
    },
    selectedVoltage: {
      get() {
        return this.getSelectedVoltage;
      },
      set(value) {
        this.setSelectedVoltage(value);
      },
    },
    selectedVoltageKVValue: {
      get() {
        return this.getSelectedVoltageKVValue;
      },
      set(value) {
        this.setSelectedVoltageKVValue(value);
      },
    },
    customerStationAvailability: {
      get() {
        return this.getSelectedCustomerStation;
      },
      set(value) {
        this.setSelectedCustomerStation(value);
      },
    },
    customerStationPlanFileName: {
      get() {
        return this.getCustomerStationPlan;
      },
      set(value) {
        this.setCustomerStationPlan(value);
      },
    },
    measureConceptChecked: {
      get() {
        return this.getMeasureConceptChecked;
      },
      set(value) {
        this.setMeasureConceptChecked(value);
      },
    },
    ePlanChecked: {
      get() {
        return this.getEPlanChecked;
      },
      set(value) {
        this.setEPlanChecked(value);
      },
    },
    PVInbMeasurementDataChecked: {
      get() {
        return this.getPVInbMeasurementDataChecked;
      },
      set(value) {
        this.setPVInbMeasurementDataChecked(value);
      },
    },
    PVInbProtokollMeasurementDataChecked: {
      get() {
        return this.getPVInbProtokollMeasurementDataChecked;
      },
      set(value) {
        this.setPVInbProtokollMeasurementDataChecked(value);
      },
    },
    technicalSpecificationsChecked: {
      get() {
        return this.getTechnicalSpecificationsChecked;
      },
      set(value) {
        this.setTechnicalSpecificationsChecked(value);
      },
    },
    implementingDateChecked: {
      get() {
        return this.getImplementingDateChecked;
      },
      set(value) {
        this.setImplementingDateChecked(value);
      },
    },
    dataFileIsReady: {
      get() {
        return this.getDataFileIsReady;
      },
      set(value) {
        this.setDataFileIsReady(value);
      },
    },
    counterDataChecked: {
      get() {
        return this.getCounterDataChecked;
      },
      set(value) {
        this.setCounterDataChecked(value);
      },
    },
    PVInbMeasurementData: {
      get() {
        return this.getPVInbMeasurementData;
      },
      set(value) {
        this.updatePVInbMeasurementData(value);
      },
    },
    PVInbProtokollMeasurementData: {
      get() {
        return this.getPVInbProtokollMeasurementData;
      },
      set(value) {
        this.updatePVInbProtokollMeasurementData(value);
      },
    },
    showSpinnerWhileUploading: {
      get() {
        return this.getShowSpinnerInTechnicalInspection;
      },
      set(value) {
        this.setShowSpinnerInTechnicalInspection(value);
      },
    },
    showErrorMessageWhenTechnicalInspectionFails: {
      get() {
        return this.getShowErrorMessageWhenTechnicalInspectionFails;
      },
      set(value) {
        this.setShowErrorMessageWhenTechnicalInspectionFails(value);
      },
    },
    technicalInspectionFileUUID() {
      return this.getTechnicalInspectionFileNameWithUUID;
    },
    NEWNoteWithHeadlineLabels() {
      return getTranslations('new_note_with_headline');
    },
    //
    buttonsLabels() {
      return getTranslations('technical_inspection_buttons');
    },
    validateRadios() {
      return (
        this.selectedVoltage === 'low' ||
        (!!this.selectedVoltageKVValue && !!this.customerStationAvailability)
      );
    },
    validateInbetriebnahmeCheckboxes() {
      return (
        this.measureConceptChecked &&
        this.ePlanChecked &&
        this.PVInbMeasurementDataChecked
      );
    },
    validateProtokollCheckboxesResult() {
      return this.validateProtokollCheckboxes();
    },
    fileNameChanged() {
      const fullFileName =
        this.selectedPhotovoltaic?.pvrequest_inspection
          ?.customer_station_plan_filename;

      if (fullFileName) {
        const parts = fullFileName.split('/');
        const uuidFileName = parts[parts.length - 1];
        return uuidFileName !== this.customerStationPlanFileName;
      } else {
        return true;
      }
    },
    noteTitle: {
      get() {
        return this.getNoteTitle;
      },
      set(value) {
        this.setNoteTitle(value);
      },
    },
    noteComment: {
      get() {
        return this.getNoteComment;
      },
      set(value) {
        this.setNoteComment(value);
      },
    },
    countersData: {
      get() {
        return this.getCountersData;
      },
      set(value) {
        this.updateCountersData(value);
      },
    },
    noteTitleAndCommentValues() {
      return {
        title: this.noteTitle,
        comment: this.noteComment,
      };
    },
    notifyEvent() {
      return this.getNotifyEvent || emptyEventData;
    },
    lastUpdatedComponent() {
      return this.getLastUpdatedComponent;
    },
    selectedVoltageConfig() {
      return {
        title: this.$t('technical_inspection.voltage_option_title'),
        checkboxState: this.validateRadios,
        requiredText: this.$t('technical_inspection.voltage_option_required'),
        content_component: 'PVVoltageOptionsComponent',
        props_component: {
          labels: getTranslations('technical_inspection'),
          selectedVoltageOption: this.selectedVoltage,
          selectedVoltageKVValueOption: this.selectedVoltageKVValue,
          customerStationAvailabilityOption: this.customerStationAvailability,
          dataIsReady: this.dataFileIsReady,
          selectedFiles: this.selectedFiles,
        },
        component_completed: this.validateRadios,
        showError: this.showErrors && !this.validateRadios,
        mutation: 'setVoltageChecked',
      };
    },
    configurations() {
      return {
        photovoltaik: [this.selectedVoltageConfig],
        'pv-inbetriebnahme': [
          {
            title: this.$t('technical_inspection.measure_concept_checked'),
            checkboxState: this.measureConceptChecked,
            requiredText: this.$t(
              'technical_inspection.measure_concept_required'
            ),
            showError: this.showErrors && !this.measureConceptChecked,
            mutation: 'setMeasureConceptChecked',
          },
          {
            title: this.$t('technical_inspection.e_plan_checked'),
            checkboxState: this.ePlanChecked,
            requiredText: this.$t('technical_inspection.e_plan_required'),
            showError: this.showErrors && !this.ePlanChecked,
            mutation: 'setEPlanChecked',
          },
          this.selectedVoltageConfig,
          {
            title: this.$t('technical_inspection.measurement_data.title'),
            checkboxState: this.PVInbMeasurementDataChecked,
            requiredText: this.$t(
              'technical_inspection.measurement_data.step_is_required'
            ),
            showError: this.showErrors && !this.PVInbMeasurementDataChecked,
            mutation: 'setPVInbMeasurementDataChecked',
            content_component: 'DynamicInputsComponent',
            component_completed: false,
            props_component: {
              itemsConfig: [
                {
                  inputType: 'selectField',
                  itemsArray: provider_options,
                  placeholder: this.$t(
                    'technical_inspection.measurement_data.fields.market_location_provider'
                  ),
                  value: this.PVInbMeasurementData.market_location_provider,
                  paramName: 'market_location_provider',
                  rules: [
                    (value) =>
                      !!value ||
                      this.$t(
                        'technical_inspection.measurement_data.fields.market_location_provider_validation_errors.required'
                      ),
                  ],
                },
                {
                  inputType: 'selectField',
                  itemsArray: provider_options,
                  placeholder: this.$t(
                    'technical_inspection.measurement_data.fields.measuring_location_provider'
                  ),
                  value: this.PVInbMeasurementData.measuring_location_provider,
                  paramName: 'measuring_location_provider',
                  rules: [
                    (value) =>
                      !!value ||
                      this.$t(
                        'technical_inspection.measurement_data.fields.measuring_location_provider_validation_errors.required'
                      ),
                  ],
                },
                {
                  inputType: 'selectField',
                  itemsArray: counting_method_options,
                  placeholder: this.$t(
                    'technical_inspection.measurement_data.fields.counting_method'
                  ),
                  value: this.PVInbMeasurementData.counting_method,
                  paramName: 'counting_method',
                  rules: [
                    (value) =>
                      !!value ||
                      this.$t(
                        'technical_inspection.measurement_data.fields.counting_method_validation_errors.required'
                      ),
                  ],
                },
              ],
              validateForm: false,
            },
          },
        ],
        'pv-inbetriebnahme-protokoll': [
          {
            title: this.$t(
              'technical_inspection.technical_specifications_checked'
            ),
            checkboxState: this.technicalSpecificationsChecked,
            requiredText: this.$t(
              'technical_inspection.technical_specifications_required'
            ),
            showError: this.showErrors && !this.technicalSpecificationsChecked,
            mutation: 'setTechnicalSpecificationsChecked',
          },
          {
            title: this.$t('technical_inspection.implementing_date_checked'),
            checkboxState: this.implementingDateChecked,
            requiredText: this.$t(
              'technical_inspection.implementing_date_required'
            ),
            showError: this.showErrors && !this.implementingDateChecked,
            mutation: 'setImplementingDateChecked',
          },
          {
            title: this.$t('technical_inspection.measurement_data.title'),
            checkboxState: this.PVInbProtokollMeasurementDataChecked,
            requiredText: this.$t(
              'technical_inspection.measurement_data.step_is_required'
            ),
            showError:
              this.showErrors && !this.PVInbProtokollMeasurementDataChecked,
            mutation: 'setPVInbProtokollMeasurementDataChecked',
            content_component: 'DynamicInputsComponent',
            component_completed: false,
            props_component: {
              itemsConfig: [
                {
                  inputType: 'textField',
                  placeholder: this.$t(
                    'technical_inspection.measurement_data.fields.market_location'
                  ),
                  label: this.$t(
                    'technical_inspection.measurement_data.fields.market_location'
                  ),
                  value: this.PVInbProtokollMeasurementData.market_location,
                  paramName: 'market_location',
                  isNumber: true,
                  rules: [
                    (value) =>
                      !!value ||
                      this.$t(
                        'technical_inspection.measurement_data.fields.market_location_validation_errors.required'
                      ),
                    (value) =>
                      /^\d{11}$/.test(value) ||
                      this.$t(
                        'technical_inspection.measurement_data.fields.market_location_validation_errors.length'
                      ),
                  ],
                },
              ],
            },
          },
          {
            ...this.getCounterDataConfig(0),
            showNewCounterComponent: true,
            activeNewCounterComponentConfig: {
              iconComponent: 'NewIconPlus',
              textValue:
                'Sind es mehrere Zähler? Dann fügen Sie hier einen weiteren hinzu',
            },
            isNewCounterAdded: false,
          },
        ],
      };
    },
    numberOfCounters() {
      return this.selectedPhotovoltaic.pvimplementationprotocol_inspection
        ?.counters.length;
    },
  },
  methods: {
    ...mapMutations([
      'setSelectedVoltage',
      'setSelectedVoltageKVValue',
      'setSelectedCustomerStation',
      'setCustomerStationPlan',
      'setNoteTitle',
      'setNoteComment',
      'setTechnicalInspectionFileNameWithUUID',
      'setMeasureConceptChecked',
      'setEPlanChecked',
      'setPVInbMeasurementDataChecked',
      'updatePVInbMeasurementData',
      'setPVInbProtokollMeasurementDataChecked',
      'updatePVInbProtokollMeasurementData',
      'setTechnicalSpecificationsChecked',
      'setImplementingDateChecked',
      'setPVFileChanged',
      'setPhotovoltaicFiles',
      'setDataFileIsReady',
      'setNotifyEvent',
      'setLastUpdatedComponent',
      'setCounterDataChecked',
      'updateCountersData',
      'updateCounterData',
      'removeCounterDataObject',
      'setShowSpinnerInTechnicalInspection',
      'setShowErrorMessageWhenTechnicalInspectionFails',
    ]),
    handleKeyPress(event, paramName) {
      if (paramName === 'measuring_location' && event.key === ' ') {
        event.preventDefault();
      }
    },
    setExistingValues() {
      switch (this.pvJourney) {
        case PVJOURNEYS.pv:
          this.setPVAnfrageValue();
          break;
        case PVJOURNEYS.pvi:
          this.setPVAnfrageValue();
          this.setPVInbetriebnahmeValue();
          break;
        case PVJOURNEYS.pvip:
          this.setPVProtokolValue();
          break;
        default:
          break;
      }
    },
    setPVAnfrageValue() {
      const pvRequestInspectionData = JSON.parse(
        JSON.stringify(this.selectedPhotovoltaic?.pvrequest_inspection)
      );
      this.selectedVoltage = pvRequestInspectionData?.voltage
        ? pvRequestInspectionData?.voltage === 'low'
          ? pvRequestInspectionData?.voltage
          : 'medium'
        : '';
      this.selectedVoltageKVValue =
        pvRequestInspectionData?.voltage === 'low'
          ? ''
          : pvRequestInspectionData?.voltage;
      this.customerStationAvailability =
        pvRequestInspectionData?.customer_station || '';
      this.customerStationPlanFileName =
        pvRequestInspectionData?.customer_station_plan_filename;
      if (this.pvJourney === PVJOURNEYS.pv) {
        this.noteTitle = pvRequestInspectionData?.addition?.title;
        this.noteComment = pvRequestInspectionData?.addition?.comment;
      }
    },
    setPVInbetriebnahmeValue() {
      const pvImplementationInspectionData = JSON.parse(
        JSON.stringify(this.selectedPhotovoltaic.pvimplementation_inspection)
      );

      this.measureConceptChecked =
        pvImplementationInspectionData?.measure_concept_checked;
      this.ePlanChecked = pvImplementationInspectionData?.e_plan_checked;
      //save existing measurement_data
      const {
        market_location_provider,
        measuring_location_provider,
        counting_method,
      } = pvImplementationInspectionData || {};
      Object.entries({
        market_location_provider,
        measuring_location_provider,
        counting_method,
      }).forEach(([key, value]) => {
        this.$store.commit('updatePVInbMeasurementData', { key, value });
      });
      if (this.pvJourney === PVJOURNEYS.pvi) {
        this.noteTitle = pvImplementationInspectionData?.addition?.title;
        this.noteComment = pvImplementationInspectionData?.addition?.comment;
      }
    },
    setPVProtokolValue() {
      const pvImplementationProtocolInspectionData = JSON.parse(
        JSON.stringify(
          this.selectedPhotovoltaic.pvimplementationprotocol_inspection
        )
      );
      const { market_location } = pvImplementationProtocolInspectionData || {};

      Object.entries({
        market_location,
      }).forEach(([key, value]) => {
        this.$store.commit('updatePVInbProtokollMeasurementData', {
          key,
          value,
        });
      });

      this.technicalSpecificationsChecked =
        pvImplementationProtocolInspectionData?.technical_specifications_checked;
      this.implementingDateChecked =
        pvImplementationProtocolInspectionData?.implementing_date_checked;
      if (pvImplementationProtocolInspectionData?.counters) {
        this.countersData = pvImplementationProtocolInspectionData?.counters;
        this.countersData.forEach((counter) => {
          if (!counter.type_of_usage) {
            counter.type_of_usage = 'feedin';
          }
        });
      } else {
        this.countersData = [JSON.parse(JSON.stringify(initialCounterData))];
      }
      if (this.pvJourney === PVJOURNEYS.pvip) {
        this.noteTitle =
          pvImplementationProtocolInspectionData?.addition?.title;
        this.noteComment =
          pvImplementationProtocolInspectionData?.addition?.comment;
      }
    },
    updateSelectedRadio(event) {
      this.$store.commit(event.mutation, event.value);
    },
    handleNoteTitleUpdate(value) {
      this.noteTitle = value;
    },
    handleNoteCommentUpdate(value) {
      this.noteComment = value;
    },
    getExistingFiles() {
      this.dataFileIsReady = false;
      const existingFile = this.$store.getters.getPVUploadFiles['pv_ti']?.[0];
      if (existingFile) {
        this.selectedFiles = [
          {
            file: existingFile,
            file_name: existingFile.name,
          },
        ];
      }
      this.dataFileIsReady = true;
    },
    changeFileName(selectedFile) {
      const fileName = replaceSpecialChars(selectedFile.name);

      Object.defineProperty(selectedFile, 'name', {
        writable: true,
        value: fileName,
      });
      return selectedFile;
    },
    fileSelected(selectedFile) {
      this.setPVFileChanged(selectedFile.isNewFileSelected);
      // if new file add prefix
      if (selectedFile.isNewFileSelected) {
        selectedFile = this.changeFileName(selectedFile);
      }
      delete selectedFile.isNewFileSelected;
      this.selectedFiles = [
        { file: selectedFile, file_name: selectedFile.name, position: 1 },
      ];
      this.updateFileName();
    },
    async fileDeleted() {
      this.selectedFiles[0].file = '';
      this.selectedFiles[0].file_name = '';
      this.setTechnicalInspectionFileNameWithUUID('');

      this.updateFileName();
    },
    updateFileName() {
      const planFile = this.selectedFiles[0];

      if (planFile) {
        this.customerStationPlanFileName = planFile.file_name;
      }
    },
    async uploadAndUpdate(requestBody) {
      await uploadPVFiles(this.selectedFiles, true).then(async () => {
        if (this.fileNameChanged && this.technicalInspectionFileUUID) {
          if (!requestBody.new_value.pvrequest_inspection) {
            requestBody.new_value.pvrequest_inspection = {};
          }
          requestBody.new_value.pvrequest_inspection.customer_station_plan_filename =
            this.technicalInspectionFileUUID;
        }
        await this.updatePVRequestInspectionDataWithResponse(
          requestBody,
          this.getUserId
        );
        this.saveFilesToStore();
      });
    },
    saveFilesToStore() {
      const file = this.selectedFiles[0].file;
      this.setPhotovoltaicFiles({
        key: 'pv_ti',
        files: file,
      });
    },
    async updateTechnicalInspectionValues(confirmed) {
      let pvRequestObject = {};
      switch (this.pvJourney) {
        case PVJOURNEYS.pv:
          pvRequestObject = this.getPVAnfrageRequest();
          break;
        case PVJOURNEYS.pvi:
          pvRequestObject = this.getPVInbetriebnahmeRequest();
          break;
        case PVJOURNEYS.pvip:
          pvRequestObject = this.getPVProtokolRequest();
          break;
      }

      const requestBody = {
        id: this.selectedPhotovoltaic.id,
        ...pvRequestObject,
      };

      if (confirmed) {
        requestBody.status = 'completed';
      }

      if (
        !this.fileNameChanged &&
        !confirmed &&
        isEqual(requestBody.old_value, requestBody.new_value)
      ) {
        this.hasNoDataChangedError = true;
      } else {
        this.hasNoDataChangedError = false;
        if (
          this.fileNameChanged &&
          this.selectedPhotovoltaic.status !==
            FEED_IN_STATUSES.fulfilled_insp.value
        ) {
          await this.uploadAndUpdate(requestBody);
        } else {
          await this.updatePVRequestInspectionDataWithResponse(
            requestBody,
            this.getUserId
          );
        }
        enableScrolling();
      }
    },
    getPVAnfrageRequest() {
      const pvRequestInspectionObject =
        this.selectedPhotovoltaic.pvrequest_inspection;
      const technical_inspection_anfrage_new_values = {
        pvrequest_inspection: {
          voltage:
            this.selectedVoltage === 'low'
              ? this.selectedVoltage
              : this.selectedVoltageKVValue,
          customer_station: this.customerStationAvailability,
          customer_station_plan_filename: this.fileNameChanged
            ? this.technicalInspectionFileUUID
            : pvRequestInspectionObject?.customer_station_plan_filename,
          addition:
            this.pvJourney === PVJOURNEYS.pv
              ? {
                  title: this.noteTitle,
                  comment: this.noteComment,
                }
              : {
                  title: pvRequestInspectionObject?.addition?.title,
                  comment: pvRequestInspectionObject?.addition?.comment,
                },
        },
      };

      const technical_inspection_anfrage_old_values = {
        pvrequest_inspection: {
          voltage: pvRequestInspectionObject?.voltage,
          customer_station: pvRequestInspectionObject?.customer_station,
          customer_station_plan_filename:
            pvRequestInspectionObject?.customer_station_plan_filename,
          addition: pvRequestInspectionObject?.addition
            ? {
                title: pvRequestInspectionObject?.addition?.title,
                comment: pvRequestInspectionObject?.addition?.comment,
              }
            : null,
        },
      };

      return {
        new_value: deleteAllEmptyFields(
          technical_inspection_anfrage_new_values
        ),
        old_value: deleteAllEmptyFields(
          technical_inspection_anfrage_old_values
        ),
      };
    },
    getPVInbetriebnahmeRequest() {
      const requestObject = this.getPVAnfrageRequest();
      const pvImplementationInspectionObject =
        this.selectedPhotovoltaic.pvimplementation_inspection;

      requestObject.new_value.pvimplementation_inspection =
        deleteAllEmptyFields({
          measure_concept_checked: this.measureConceptChecked,
          e_plan_checked: this.ePlanChecked,
          market_location_provider:
            this.PVInbMeasurementData?.market_location_provider,
          measuring_location_provider:
            this.PVInbMeasurementData?.measuring_location_provider,
          counting_method: this.PVInbMeasurementData?.counting_method,
          addition: {
            title: this.noteTitle,
            comment: this.noteComment,
          },
        });

      //If every child prop of requestObject.old_value is Null Undefined Or Blank, then do not use  deleteAllEmptyFields() because it would remove it from the reqObj. old_value is required by the backend
      if (
        !pvImplementationInspectionObject ||
        areAllPropsNullUndefinedOrBlank(pvImplementationInspectionObject)
      )
        return requestObject;
      //old_values contains at least one non null/undefined/blank prop, it is safe to use deleteAllEmptyFields()
      requestObject.old_value.pvimplementation_inspection =
        deleteAllEmptyFields({
          measure_concept_checked:
            pvImplementationInspectionObject.measure_concept_checked,
          e_plan_checked: pvImplementationInspectionObject.e_plan_checked,
          market_location_provider:
            pvImplementationInspectionObject.market_location_provider,
          measuring_location_provider:
            pvImplementationInspectionObject.measuring_location_provider,
          counting_method: pvImplementationInspectionObject.counting_method,
          addition: pvImplementationInspectionObject.addition
            ? {
                title: pvImplementationInspectionObject.addition?.title,
                comment: pvImplementationInspectionObject.addition?.comment,
              }
            : null,
        });
      return deleteAllEmptyFields(requestObject);
    },
    getPVProtokolRequest() {
      const pvImplementationProtocolInspection =
        this.selectedPhotovoltaic.pvimplementationprotocol_inspection;

      const technical_inspection_protokoll_new_values = {
        pvimplementationprotocol_inspection: {
          technical_specifications_checked: this.technicalSpecificationsChecked,
          implementing_date_checked: this.implementingDateChecked,
          market_location: this.PVInbProtokollMeasurementData?.market_location,
          addition: {
            title: this.noteTitle,
            comment: this.noteComment,
          },
          counters: this.getCountersDataRequest,
        },
      };

      const technical_inspection_protokoll_old_values = {
        pvimplementationprotocol_inspection: {
          technical_specifications_checked:
            pvImplementationProtocolInspection?.technical_specifications_checked,
          implementing_date_checked:
            pvImplementationProtocolInspection?.implementing_date_checked,
          market_location: pvImplementationProtocolInspection?.market_location,
          addition: pvImplementationProtocolInspection?.addition
            ? {
                title: pvImplementationProtocolInspection?.addition?.title,
                comment: pvImplementationProtocolInspection?.addition?.comment,
              }
            : null,
          counters: pvImplementationProtocolInspection?.counters,
        },
      };
      return {
        new_value: deleteAllEmptyFields(
          technical_inspection_protokoll_new_values
        ),
        old_value: deleteAllEmptyFields(
          technical_inspection_protokoll_old_values
        ),
      };
    },
    confirmCheck() {
      switch (this.selectedPhotovoltaic.status) {
        case FEED_IN_STATUSES.tech_feas.value:
          if (this.validateRadios) {
            this.updateTechnicalInspectionValues(true);
          } else {
            this.showErrors = true;
          }
          break;
        case FEED_IN_STATUSES.in_impl_insp.value:
          if (this.validateRadios && this.validateInbetriebnahmeCheckboxes) {
            this.checkboxListConfig[3].props_component.validateForm = true;
            this.updateTechnicalInspectionValues(true);
          } else {
            this.showErrors = true;
          }
          break;
        case FEED_IN_STATUSES.fulfilled_insp.value:
          if (
            this.validateProtokollCheckboxes() &&
            this.atLeastOneGenerationCounter() &&
            this.feedInTypeOfUsage()
          ) {
            this.updateTechnicalInspectionValues(true);
            this.atLeastOneGenerationCounterError = false;
            this.feedInTypeOfUsageError = false;
          } else {
            this.atLeastOneGenerationCounterError =
              !this.atLeastOneGenerationCounter();
            this.feedInTypeOfUsageError = !this.feedInTypeOfUsage();

            if (!this.validateProtokollCheckboxes()) {
              this.showErrors = true;
            } else {
              this.showErrors = false;
            }
            this.checkboxListConfig[2].props_component.validateForm = true;
            if (this.checkboxListConfig[3]) {
              this.checkboxListConfig[3].props_component.validateForm = true;
            }
          }
      }
    },
    async updatePVRequestInspectionDataWithResponse(requestBody, userId) {
      if (requestBody.status === 'completed') {
        this.showSpinnerWhileUploading = true;
      }
      const handleInspection = async (inspectionUpdateFunction) => {
        try {
          await inspectionUpdateFunction(requestBody, userId);
          this.technicalInspectionAlert.showInspectionSuccessAlert = true;
        } catch (error) {
          this.technicalInspectionAlert.showInspectionErrorAlert = true;
          this.showErrorMessageWhenTechnicalInspectionFails = true;
        }
      };

      switch (this.selectedPhotovoltaic.status) {
        case FEED_IN_STATUSES.tech_feas.value:
          await handleInspection(updatePVRequestInspectionData);
          break;
        case FEED_IN_STATUSES.in_impl_insp.value:
          await handleInspection(updatePVImplementationInspectionData);
          break;
        case FEED_IN_STATUSES.fulfilled_insp.value:
          await handleInspection(updatePVImplementationProtocolInspectionData);
          break;
      }
    },
    async sendNoteAndUploadFile(value) {
      this.isNoteUploading = true;
      const noteObj = {
        id: this.selectedPhotovoltaic.id,
        note: value.note,
        title: value.title,
        filename: value.filename,
        user_id: this.selectedPhotovoltaic.user_id,
      };

      //TODO: add slot to tehnical inspection in storybook
      this.setLastUpdatedComponent('pvNoteUploadFileComponent');

      if (value.note) {
        try {
          const userResponse = await noteService.addPhotovoltaicNote(
            noteObj,
            this.getUserId
          );

          // Has to get the "_persistent_event_id_" from addPhotovoltaicNote; used for s3-subfoldering
          if (value.filename) {
            const url = (
              await DocumentsService.putPhotovoltaicDocumentGraphQL(
                `${this.selectedPhotovoltaic.id}/${userResponse.addPhotovoltaicNote._persistent_event_id_}/${value.filename}`,
                process.env.VUE_APP_STAGE
              )
            ).uploadFile;
            await uploadFile(url, value.file);
            this.noteUpload.deleteNote = true;
          }
          this.isNoteUploading = false;
          this.noteUpload.deleteNote = true;
        } catch (err) {
          this.isNoteUploading = true;
          console.error(err);
          this.noteUpload.showNoteErrorAlert = true;
          setTimeout(() => {
            this.setNotifyEvent(emptyEventData);
            this.setLastUpdatedComponent('');
          }, 3000);
        }
      }
    },
    getFeedinTechnicalInspectionConfig(configName) {
      return this.configurations[configName];
    },
    updateCheckboxListConfig() {
      this.checkboxListConfig = this.getFeedinTechnicalInspectionConfig(
        this.pvJourney
      );
      if (this.countersData.length === 2) {
        this.updateNewCounterProps(this.checkboxListConfig[3]);
      }
    },
    updateCheckbox(event) {
      event.checkboxData.checkboxState = event.value;
      this.$store.commit(
        event.checkboxData.mutation,
        event.checkboxData.index || event.checkboxData.index === 0
          ? { index: event.checkboxData.index, value: event.value }
          : event.value
      );
    },
    async updatePhotovoltaicStatus(status) {
      const currentFeedInSystem = JSON.parse(
        JSON.stringify(this.$store.getters.getSelectedFeedInSystem)
      );

      try {
        const res = await updatePVStatus(
          {
            id: currentFeedInSystem.id,
            old_value: {
              status: currentFeedInSystem.status,
            },
            new_value: {
              status: status,
            },
            remark: this.declineOrderSettings.noteValue.note,
          },
          this.$store.getters.getUserId
        );

        if (res) {
          const new_status = { status: status };
          this.$store.commit('updateSelectedPhotovoltaicData', new_status);
        }
      } catch (err) {
        console.log(err);
        throw err; // Re-throw error to propagate it to the caller
      }
    },
    async declineOrder() {
      const noteObj = {
        id: this.selectedPhotovoltaic.id,
        note: this.declineOrderSettings.noteValue.note,
        title: this.$t('technical_inspection.cancellation_reason'),
        user_id: this.selectedPhotovoltaic.user_id,
      };

      try {
        this.declineOrderSettings.showLoading = true;
        await this.updatePhotovoltaicStatus(FEED_IN_STATUSES.declined.value);
        await noteService.addPhotovoltaicNote(noteObj, this.getUserId);
        this.closeModal();
        this.declineOrderSettings.showLoading = false;
      } catch (err) {
        console.log(err);
        this.declineOrderSettings.showLoading = false;
        this.declineOrderSettings.showDeclineModalError = true;
      }
    },
    openModal() {
      this.declineOrderSettings.isModalVisible = true;
    },
    closeModal() {
      this.declineOrderSettings.isModalVisible = false;
      this.declineOrderSettings.noteValue = { note: '' };
    },
    updateInputData(event) {
      const { index, value } = event.value;
      const itemData = event.checkboxData.props_component.itemsConfig[index];
      itemData.value = value;

      switch (this.pvJourney) {
        case PVJOURNEYS.pv:
          // TODO: mit Marion besprechen
          break;
        case PVJOURNEYS.pvi:
          this.updatePVInbMeasurementData({ key: itemData.paramName, value });
          break;
        case PVJOURNEYS.pvip:
          if (
            event.checkboxData.mutation ===
            'setPVInbProtokollMeasurementDataChecked'
          ) {
            this.updatePVInbProtokollMeasurementData({
              key: itemData.paramName,
              value,
            });
          } else if (event.checkboxData.mutation === 'setCounterDataChecked') {
            this.updateCounterData({
              index: event.checkboxData.index,
              key: itemData.paramName,
              value,
            });
          }

          break;
        default:
          break;
      }
    },
    updateCheckboxStateAndValidation(event) {
      this.updateCheckbox(event);
      event.checkboxData.props_component.validateForm = false;
      if (event.value) {
        event.checkboxData.checkboxState = true;
        event.checkboxData.component_completed = true;
        event.checkboxData.showError = false;
      } else {
        event.checkboxData.checkboxState = false;
        event.checkboxData.component_completed = false;
        event.checkboxData.showError = true;
      }
    },
    updateNewCounterProps(checkboxData) {
      checkboxData.isNewCounterAdded = !checkboxData.isNewCounterAdded;
      if (checkboxData.isNewCounterAdded) {
        checkboxData.activeNewCounterComponentConfig = this.removeCounterConfig;
        if (this.countersData.length < 2) {
          this.$store.dispatch('addCounterDataObject').then(() => {
            this.checkboxListConfig.push(this.getCounterDataConfig(1));
          });
        } else {
          this.checkboxListConfig.push(this.getCounterDataConfig(1));
        }
      } else {
        checkboxData.activeNewCounterComponentConfig = this.addCounterConfig;
        this.checkboxListConfig.pop();
        this.$store.commit('removeCounterDataObject');
      }
    },
    getCounterDataConfig(index) {
      return {
        index: index,
        title: this.$t('technical_inspection.counter_data', {
          index: index + 1,
        }),
        checkboxState: this.counterDataChecked[index],
        requiredText: this.$t('technical_inspection.counter_data_required'),
        showError: this.showErrors && !this.counterDataChecked[index],
        mutation: 'setCounterDataChecked',
        content_component: 'DynamicInputsComponent',
        component_completed: false,
        props_component: {
          itemsConfig: [
            {
              inputType: 'selectField',
              itemsArray: type_of_usage_options,
              placeholder: this.$t('technical_inspection.type_of_usage'),
              value: this.countersData[index].type_of_usage,
              paramName: 'type_of_usage',
              rules: [
                (value) =>
                  !!value ||
                  this.$t(
                    'technical_inspection.type_of_usage_validation_errors.required'
                  ),
              ],
            },
            {
              inputType: 'textField',
              placeholder: this.$t('technical_inspection.measuring_location'),
              label: this.$t('technical_inspection.measuring_location'),
              value: this.countersData[index].measuring_location,
              paramName: 'measuring_location',
              onKeyPress: (event) =>
                this.handleKeyPress(event, 'measuring_location'),
              rules: [
                (value) =>
                  (!!value &&
                    (typeof value === 'string' ? value.trim() !== '' : true)) ||
                  this.$t(
                    'technical_inspection.measuring_location_validation_errors.required'
                  ),
              ],
            },
            {
              inputType: 'textField',
              placeholder: this.$t('counter_availability_step.placeholder'),
              label: this.$t('counter_availability_step.placeholder'),
              value: this.countersData[index].counter_id,
              paramName: 'counter_id',
              rules: [
                (value) =>
                  !!value ||
                  this.$t('technical_inspection.counter_id_required'),
              ],
            },
            {
              inputType: 'datePicker',
              placeholder: this.$t('technical_inspection.valid_from'),
              label: this.$t('technical_inspection.valid_from'),
              value: this.countersData[index].valid_from,
              paramName: 'valid_from',
              rules: [
                (value) =>
                  !!value || this.$t('technical_inspection.date_required'),
              ],
            },
            {
              inputType: 'selectField',
              itemsArray: COUNTER_TYPE_LIST,
              placeholder: this.$t('technical_inspection.couter_type'),
              value: this.countersData[index].counter_type,
              paramName: 'counter_type',
              rules: [
                (value) =>
                  !!value ||
                  this.$t('technical_inspection.couter_type_required'),
              ],
            },
            {
              inputType: 'selectField',
              itemsArray: CHARGE_TYPE_LIST,
              placeholder: this.$t('technical_inspection.charge_type'),
              value: this.countersData[index].charge_type,
              paramName: 'charge_type',
              rules: [
                (value) =>
                  !!value ||
                  this.$t('technical_inspection.charge_type_required'),
              ],
            },
            {
              inputType: 'selectField',
              itemsArray: CURRENT_DIRECTION_LIST,
              placeholder: this.$t('technical_inspection.current_direction'),
              value: this.countersData[index].current_direction,
              paramName: 'current_direction',
              rules: [
                (value) =>
                  !!value ||
                  this.$t('technical_inspection.current_direction_required'),
              ],
            },
            {
              inputType: 'selectField',
              itemsArray: MOUNT_TYPE_LIST,
              placeholder: this.$t('technical_inspection.mount_type'),
              value: this.countersData[index].mount_type,
              paramName: 'mount_type',
              rules: [
                (value) =>
                  !!value ||
                  this.$t('technical_inspection.mount_type_required'),
              ],
            },
            {
              inputType: 'selectField',
              itemsArray: MEASURING_DEVICE_TYPE_LIST,
              placeholder: this.$t(
                'technical_inspection.measuring_device_type'
              ),
              value: this.countersData[index].measuring_device_type,
              paramName: 'measuring_device_type',
              rules: [
                (value) =>
                  !!value ||
                  this.$t(
                    'technical_inspection.measuring_device_type_required'
                  ),
              ],
            },
            {
              inputType: 'selectField',
              itemsArray: OBIS_KEY_LIST,
              placeholder: this.$t('technical_inspection.obis_key'),
              value: this.countersData[index].obis_key,
              paramName: 'obis_key',
              rules: [
                (value) =>
                  !!value || this.$t('technical_inspection.obis_key_required'),
              ],
            },
            {
              inputType: 'textField',
              placeholder: this.$t('technical_inspection.pre_decimal_places'),
              label: this.$t('technical_inspection.pre_decimal_places'),
              value: this.countersData[index].pre_decimal_places,
              paramName: 'pre_decimal_places',
              isNumber: true,
              rules: [
                (value) =>
                  !!value ||
                  this.$t('technical_inspection.pre_decimal_places_required'),
                (value) =>
                  (!isNaN(parseFloat(value)) && value >= 0) ||
                  this.$t(
                    'technical_inspection.pre_decimal_places_positiv_number'
                  ),
              ],
            },
            {
              inputType: 'textField',
              placeholder: this.$t('technical_inspection.decimal_places'),
              label: this.$t('technical_inspection.decimal_places'),
              value: this.countersData[index].decimal_places,
              paramName: 'decimal_places',
              isNumber: true,
              rules: [
                (value) =>
                  !!value ||
                  this.$t('technical_inspection.decimal_places_required'),
                (value) =>
                  (!isNaN(parseFloat(value)) && value >= 0) ||
                  this.$t('technical_inspection.decimal_places_positiv_number'),
              ],
            },
          ],
          validateForm: false,
        },
      };
    },
    validateProtokollCheckboxes() {
      return (
        this.technicalSpecificationsChecked &&
        this.implementingDateChecked &&
        this.PVInbProtokollMeasurementDataChecked &&
        this.counterDataChecked[0] &&
        (this.checkboxListConfig && this.checkboxListConfig[4]
          ? this.counterDataChecked[1]
          : true)
      );
    },
    atLeastOneGenerationCounter() {
      if (this.countersData.length > 1) {
        return this.countersData.some(
          (counter) => counter.type_of_usage === 'generation'
        );
      } else {
        return true;
      }
    },
    feedInTypeOfUsage() {
      if (this.countersData.length === 1) {
        return this.countersData.every(
          (counter) => counter.type_of_usage === 'feedin'
        );
      } else {
        return true;
      }
    },
  },
  watch: {
    countersData: {
      handler() {
        this.showErrors = false;
        this.atLeastOneGenerationCounterError = false;
        this.feedInTypeOfUsageError = false;
      },
      deep: true,
    },
    propsToWatch() {
      if (this.pvJourney !== PVJOURNEYS.pvip) {
        this.updateCheckboxListConfig();
      }
    },
    validateRadios() {
      this.showErrors = false;
      this.hasNoDataChangedError = false;
    },
    validateInbetriebnahmeCheckboxes() {
      this.showErrors = false;
    },
    validateProtokollCheckboxesResult: {
      handler(newValue) {
        if (newValue) {
          this.showErrors = false;
        }
      },
      deep: true,
    },
    getPhotovoltaicFilesInitialized(initialized) {
      if (initialized) {
        this.getExistingFiles();
      }
    },
    'declineOrderSettings.noteValue': {
      handler(newValue) {
        this.declineOrderSettings.showDeclineModalError = false;
        newValue.note
          ? (this.declineOrderSettings.showDeclineModalError = false)
          : (this.declineOrderSettings.showDeclineModalError = true);
      },
      deep: true,
    },
    async notifyEvent(newValue) {
      if (
        newValue.field === 'addPhotovoltaicNote' &&
        this.lastUpdatedComponent === 'pvNoteUploadFileComponent'
      ) {
        this.noteUpload.showNoteSuccessAlert = true;
      }
    },
  },
};
</script>

<style scoped>
.btn-disabled {
  background: gray;
  cursor: auto;
}

.dialog-footer-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}
</style>
