<template>
  <v-row class="fill-height">
    <v-col>
      <v-sheet height="64">
        <v-toolbar flat>
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
            Heute
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small> mdi-chevron-left </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
          <v-spacer></v-spacer>

          <v-spacer></v-spacer>
          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right> mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="
                  type = 'day';
                  showCalendar();
                "
              >
                <v-list-item-title>Tag</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="
                  type = 'week';
                  showCalendar();
                "
              >
                <v-list-item-title>Woche</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="
                  type = 'month';
                  showCalendar();
                "
              >
                <v-list-item-title>Monat</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="
                  type = '4day';
                  showCalendar();
                "
              >
                <v-list-item-title>4 Tage</v-list-item-title>
              </v-list-item>
              <v-list-item @click="showList">
                <v-list-item-title>Liste</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <list-component v-if="!ishiddenList" :events="events"></list-component>
      <v-sheet height="600">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          event-color="#F7F7F7"
          :type="type"
          :events="events"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          v-if="ishiddenList"
          locale="de"
          @change="getEvents"
        >
          <template v-slot:event="{ event }">
            <div class="event_container">
              <tiefbau-icon
                :width="15"
                :height="15"
                class="ml-1 event_icon"
                v-if="event.details === 'groundwork_company'"
              />
              <baubesichtigungstermin-icon
                :width="15"
                :height="15"
                class="ml-1 event_icon"
                v-if="event.details === 'internal_construction_management'"
              />
              <montage-strom-icon
                :width="15"
                :height="15"
                class="ml-1 event_icon"
                v-if="event.details === 'assembly_electricity_company'"
              />
              <montage-gas-icon
                :width="15"
                :height="15"
                class="ml-1 event_icon"
                v-if="event.details === 'assembly_gas_water_company'"
              />
              <montage-wasser-icon
                :width="15"
                :height="15"
                class="ml-5 event_icon"
                v-if="event.details === 'assembly_gas_water_company'"
              />
              <div
                v-if="
                  event.details === 'assembly_electricity_company' ||
                  event.details === 'internal_construction_management' ||
                  event.details === 'groundwork_company'
                "
                class="event_content"
              >
                {{ event.name }}
              </div>
              <div
                v-if="event.details === 'assembly_gas_water_company'"
                class="event_content_exception"
              >
                {{ event.name }}
              </div>
            </div>
          </template>
        </v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
          right
          offset-y
          top
        >
          <calendar-details-component
            :date="this.date"
            @close-dialog="closeDialog"
            :selectedEventId="selectedEvent"
          />
        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import ListComponent from './ListComponent';
import orderService from '../../services/OrderService';
import CalendarDetailsComponent from './CalendarDetailsComponent';
import TiefbauIcon from '../../icons/TiefbauIcon';
import MontageWasserIcon from '../../icons/MontageWasserIcon';
import MontageGasIcon from '../../icons/MontageGasIcon';
import MontageStromIcon from '../../icons/MontageStromIcon';
import BaubesichtigungsterminIcon from '../../icons/BaubesichtigungsterminIcon';

export default {
  name: 'calendar-component',
  props: ['value'],
  components: {
    ListComponent,
    CalendarDetailsComponent,
    TiefbauIcon,
    MontageWasserIcon,
    MontageStromIcon,
    MontageGasIcon,
    BaubesichtigungsterminIcon,
  },
  data: () => ({
    date: '',
    menu: '',
    focus: '',
    events: [],
    type: 'month',
    typeToLabel: {
      month: 'Monat',
      week: 'Woche',
      day: 'Tag',
      '4day': '4 Tage',
    },
    ishiddenList: true,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    dialog: false,
    currentComponent: null,
    allOrdersByRegionalTeam: [],
    activeOrders: [],
  }),
  mounted() {
    this.$refs.calendar.checkChange();
  },
  created() {
    this.getOrdersFromBackend();
  },
  watch: {
    selectedRegionalTeam(newVal) {
      if (newVal) {
        if (newVal === 'HS') {
          this.getOrdersFromBackend({
            limit: 20,
            regional_team: 'RT Heinsberg',
            nextToken: null,
          });
        }
        if (newVal === 'MG') {
          this.getOrdersFromBackend({
            limit: 20,
            regional_team: 'RT Mönchengladbach',
            nextToken: null,
          });
        }
        if (newVal === 'RKN') {
          this.getOrdersFromBackend({
            limit: 20,
            regional_team: 'RT R-K-N',
            nextToken: null,
          });
        }
        if (newVal === 'VIE') {
          this.getOrdersFromBackend({
            limit: 20,
            regional_team: 'RT Viersen',
            nextToken: null,
          });
        }
      }
    },
  },
  computed: {
    selectedOrder() {
      return this.$store.getters.getSelectedOrder;
    },
    selectedRegionalTeam() {
      return this.$store.getters.getRegionalTeamShort;
    },
  },
  methods: {
    closeDialog() {
      this.selectedOpen = false;
    },
    getOrdersFromBackend(params) {
      this.allOrdersByRegionalTeam = [];
      if (!params) {
        params = {
          limit: 20,
          regional_team: this.selectedOrder.regional_team.team_name,
          nextToken: null,
        };
        this.allOrdersByRegionalTeam = [];
      }
      orderService
        .getAppointmentsByRegionalTeam(params)
        .then((res) => {
          if (res.getAppointmentsByRegionalTeam) {
            this.allOrdersByRegionalTeam = this.allOrdersByRegionalTeam.concat(
              res.getAppointmentsByRegionalTeam.items
            );
            this.getEvents();
          }
          if (res.getAppointmentsByRegionalTeam.nextToken) {
            params.nextToken = res.getAppointmentsByRegionalTeam.nextToken;
            this.getOrdersFromBackend(params);
            this.getEvents();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = 'day';
    },
    setToday() {
      this.focus = '';
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event, day }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        this.date = day.date;
        setTimeout(() => {
          orderService
            .getOrderByIdForCalendar(this.selectedEvent.id)
            .then((res) => {
              const currentOrder = res.getOrderByIdAndDate;
              this.$store.commit('setSelectedOrderCalendar', currentOrder);
              this.selectedOpen = true;
            })
            .catch((err) => {
              console.log(err);
            });
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }
    },
    showList() {
      this.ishiddenList = false;
    },
    showCalendar() {
      this.ishiddenList = true;
    },
    getEvents() {
      const events1 = [];
      this.allOrdersByRegionalTeam.map((order) => {
        if (order.payload !== 'null') {
          events1.push({
            name: order.title ? order.title : '',
            details: order.appointment_type,
            id: order.id,
            start:
              JSON.parse(order.payload).construction_planned_date.dates[0] +
              ' ' +
              JSON.parse(order.payload).construction_planned_date.start_time
                .start_hour +
              ':' +
              JSON.parse(order.payload).construction_planned_date.start_time
                .start_minute,
            end:
              (JSON.parse(order.payload).construction_planned_date.dates[1]
                ? JSON.parse(order.payload).construction_planned_date.dates[1]
                : JSON.parse(order.payload).construction_planned_date
                    .dates[0]) +
              ' ' +
              JSON.parse(order.payload).construction_planned_date.end_time
                .end_hour +
              ':' +
              JSON.parse(order.payload).construction_planned_date.end_time
                .end_minute,
          });
          this.events = events1;
        }
      });
    },
  },
};
</script>

<style>
.event_content {
  color: #6e6e6e;
  padding-right: 2px;
  margin-left: 22px;
}
.event_container {
  display: flex;
  align-items: center;
}
.event_icon {
  position: absolute;
  margin-right: 5px;
  padding-bottom: 1px;
}
.event_content_exception {
  margin-left: 40px !important;
  color: #6e6e6e;
  padding-right: 2px;
}
.v-event.v-event-start.v-event-end.white--text {
  border: 1px solid #999999 !important;
  border-radius: 10px;
}
.v-event.v-event-start.white--text {
  border: 1px solid #999999 !important;
  border-radius: 10px;
}
.v-event.v-event-end.white--text {
  border: 1px solid #999999 !important;
  border-radius: 10px;
}
.v-event.white--text {
  border: 1px solid #999999 !important;
  border-radius: 10px;
}
</style>
