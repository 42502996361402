import graphql from '@newnetzportal/netzportal-webapps-graphql-client';
import auth from '../cognito-auth/cognitoAuth';

export default {
  async requestWithOpenID(query, variables) {
    const extraHeaders = {
      Authorization: auth.auth.getSignInUserSession().getIdToken().jwtToken,
    };
    return graphql.request(query, variables, extraHeaders);
  },
};
