const emptyAddressObject = {
  street: '',
  street_number: '',
  zip: '',
  city: '',
  country: '',
};
const emptyLandownerObject = {
  title: '',
  name: '',
  surname: '',
  city_name: '',
  company_name: '',
  address: emptyAddressObject,
};

export const landownerModule = {
  state: {},
  mutations: {
    setLandownerChangedObject(state, object) {
      this.state.changed_object.landowner[object.key] = object.value;
    },
    setLandownerAddressChangedObject(state, changedData) {
      this.state.changed_object.landowner.address[changedData.key] =
        changedData.value;
    },
    set_form_landowner_address_zip_from_google(state, value) {
      state.landowner_address_zip_from_google = value;
    },
    setLandownerState(state, landownerState) {
      this.state.changed_object.same_landowner = landownerState;
    },
    resetLandowner() {
      this.state.changed_object.landowner = JSON.parse(
        JSON.stringify(emptyLandownerObject)
      );
    },
  },
  getters: {
    get_form_landowner_address_zip_from_google: (state) => {
      return state.landowner_address_zip_from_google;
    },
  },
  actions: {},
};
