export const installationAddressModule = {
  state: {
    is_with_plot_number: false,
  },
  mutations: {
    setIsWithPlotNumber(state, is_with_plot_number) {
      state.is_with_plot_number = is_with_plot_number;
    },
    setIsAddressOutsideTheNetworkArea(state, addressOutsideTheNetworkArea) {
      state.addressOutsideTheNetworkArea = addressOutsideTheNetworkArea;
    },
    setProductInstallationChangedObject(state, changedData) {
      this.state.changed_object.product_installation_full_address[
        changedData.key
      ] = changedData.value;
    },
    setWithPlotNumberChangedObject(state, changedData) {
      this.state.changed_object.product_installation_with_plot_number_address[
        changedData.key
      ] = changedData.value;
    },
    set_form_full_address_zip_from_google(state, address_zip_from_google) {
      state.address_zip_from_google = address_zip_from_google;
    },
  },
  getters: {
    getIsWithPlotNumber: (state) => {
      return state.is_with_plot_number;
    },
    getIsAddressOutsideTheNetworkArea: (state) => {
      return state.addressOutsideTheNetworkArea;
    },
    get_form_full_address_zip_from_google: (state) => {
      return state.address_zip_from_google;
    },
  },
};
