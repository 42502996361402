<template>
  <div>
    <div v-if="selectedOrder && Object.keys(selectedOrder).length !== 0">
      <div>
        <header-details class="default-mb" />
        <NewGlassCard
          v-if="selectedOrderStatus === 'ORDER_TECHNICAL_FEASIBILITY'"
          class="default-mb"
          :title="$t('technical_inspection.title')"
        >
          <technical-inspection
            @show-modal="showModal($event)"
          ></technical-inspection>
        </NewGlassCard>
        <construction-component
          v-if="
            selectedOrderStatus === 'ORDER_APPOINTMENT_PENDING' ||
            this.showConstructionComponent === true
          "
          :showConstructionComponent="showConstructionComponent"
          @show-component="showConstructionComponent = false"
        />
        <NewGlassCard
          :title="$t('construction_work.title')"
          class="default-mb"
          v-if="
            selectedOrderStatus === 'ORDER_APPOINTMENT_CONFIRMED' &&
            this.showConstructionComponent === false
          "
        >
          <construction-dashboard @edit="showConstructionComponent = true" />
        </NewGlassCard>
        <NewGlassCard
          v-if="
            selectedOrderStatus === 'ORDER_CONFIRMED' ||
            selectedOrderStatus === 'ORDER_APPOINTMENT_PENDING' ||
            selectedOrderStatus === 'ORDER_APPOINTMENT_CONFIRMED'
          "
          class="default-mb"
          :title="$t('technical_inspection.title')"
        >
          <technical-inspection
            @closeTechnicalInspection="showTechnicalInspectionresult = false"
            :notEditable="true"
            v-if="showTechnicalInspectionresult"
          ></technical-inspection>
          <single-raw
            v-else
            :title="formatDate(this.historyDate)"
            class="single-raw"
            :notEditable="true"
            @edit="showTechnicalInspectionresult = true"
          >
            <div class="technical_inspection_container">
              <div>{{ $t('details_page.technical_inspection_confirmed') }}</div>
              <v-icon color="green" x-large class="icon">mdi-check-bold</v-icon>
            </div>
          </single-raw>
        </NewGlassCard>
      </div>

      <div :class="this.$vuetify.breakpoint.xl ? 'xl-design-details-page' : ''">
        <div>
          <NewGlassCard
            id="order-details"
            :class="
              this.$vuetify.breakpoint.xl
                ? 'xl-design-details-page-element default-mb'
                : 'default-mb'
            "
            class="z-index-0"
            :title="$t('order_details.title')"
          >
            <order-details
              @show-modal="showModal($event)"
              :emob_file_names="selectedEmobFiles"
              :water_file_name="selectedWaterFiles"
            />
          </NewGlassCard>
        </div>
        <div>
          <NewGlassCard
            id="order-questions"
            :class="
              this.$vuetify.breakpoint.xl
                ? 'xl-design-details-page-element default-mb ml-2'
                : 'default-mb'
            "
            class="z-index-0"
            :title="$t('order_questions.title')"
            v-if="selectedOrder.user_questions"
          >
            <OrderQuestions :orderQuestionsArray="orderQuestionsArray" />
          </NewGlassCard>
          <NewGlassCard
            :class="
              this.$vuetify.breakpoint.xl
                ? 'xl-design-details-page-element default-mb ml-2'
                : 'default-mb'
            "
            class="z-index-0"
            :title="$t('history.title')"
          >
            <history-table :historyType="'Order'"></history-table>
          </NewGlassCard>
          <NewGlassCard
            :class="
              this.$vuetify.breakpoint.xl
                ? 'xl-design-details-page-element default-mb ml-2'
                : 'default-mb'
            "
            class="z-index-0"
            :title="$t('document_history.title')"
          >
            <document-history-component :historyType="'Order'" />
          </NewGlassCard>
        </div>
      </div>

      <NewModalDialog
        v-if="isModalVisible"
        :value="noteValue"
        role="dialog"
        @update-note-data="updateNoteChangedObject"
        @cancel-data="closeModal"
      >
        <template v-slot:body>
          <dynamic-component
            class="body-style"
            :type="currentComponent"
            :componentProperties="componentProperties"
          />
          <div
            v-if="showModalUpdateError"
            style="width: fit-content"
            class="error-dialog ml-8"
          >
            {{ $t('feed_in_system_details.saving_failed_please_reload') }}
          </div>
        </template>
        <template v-slot:footer>
          <div v-show="showSpinner" class="update-event-spinner">
            <v-progress-circular
              indeterminate
              color="primary"
              :size="60"
              :width="5"
            />
          </div>
          <div v-if="!showSpinner">
            <button
              type="button"
              class="btn-save"
              @click="closeModalAndSaveData"
            >
              Speichern
            </button>
            <button type="button" class="btn-cancel" @click="closeModal">
              abbrechen
            </button>
          </div>
          <v-alert
            type="error"
            class="alert_error ml-4"
            v-if="alert"
            text
            transition="scale-transition"
          >
            Speicherung fehlgeschlagen
          </v-alert>
        </template>
      </NewModalDialog>
    </div>
    <div v-if="isServerError">
      <h1>
        {{ $t('order_details.loading_error') }}
      </h1>
      <p>
        {{ $t('order_details.error_hint') }}
      </p>
    </div>
  </div>
</template>

<script>
import {
  NewGlassCard,
  NewModalDialog,
} from '@newnetzportal/netzportal-webapp-storybook-components';
import OrderDetails from '../components/order-details/OrderDetails';
import OrderQuestions from '../components/order-details/OrderQuestions';
import HistoryTable from '../components/history-table/HistoryTable';
import DocumentHistoryComponent from '../components/order-details/DocumentHistoryComponent';
import HeaderDetails from '../components/order-details/HeaderDetails';
import TechnicalInspection from '../components/technical-inspection-component/TechnicalInspection';
import DynamicComponent from '../components/order-steps-components/DynamicDialogBody';
import { EventBus } from '@/main';
import userService from '../services/UserService';
import SingleRaw from '../components/order-details/SingleRaw';
import ConstructionComponent from '../components/construction-work-component/ConstructionComponent';
import ConstructionDashboard from '../components/construction-work-component/ConstructionDashboard';
import isEqual from 'lodash.isequal';
import { formatOrderObject } from '@/components/clerk-orders-overview/selectedOrderMapper';
import orderService from '../services/OrderService';
import gql from 'graphql-tag';
import { mapMutations } from 'vuex';

export default {
  name: 'DetailsPage',
  components: {
    OrderDetails,
    NewGlassCard,
    DynamicComponent,
    NewModalDialog,
    HistoryTable,
    DocumentHistoryComponent,
    HeaderDetails,
    TechnicalInspection,
    SingleRaw,
    ConstructionComponent,
    ConstructionDashboard,
    OrderQuestions,
  },
  data() {
    return {
      isModalVisible: false,
      noteValue: { note: '' },
      showConstructionComponent: false,
      alert: false,
      showTechnicalInspectionresult: false,
      order_id: this.$route.params.id ? this.$route.params.id : '',
      isServerError: false,
    };
  },
  async created() {
    // for Firefox
    window.addEventListener('scroll', () => {
      document.documentElement.style.setProperty(
        '--scroll-y',
        `${window.scrollY}px`
      );
    });
    if (this.order_id) {
      await this.getOrderFromBackend(this.order_id);
    }
  },
  computed: {
    showSpinner: {
      get() {
        return this.$store.getters.getShowSpinnerInModal;
      },
      set(value) {
        this.$store.commit('setShowSpinnerInModal', value);
      },
    },
    showModalUpdateError: {
      get() {
        return this.$store.getters.getShowModalUpdateError;
      },
      set(value) {
        this.$store.commit('setShowModalUpdateError', value);
      },
    },
    selectedOrderStatus() {
      return this.$store.getters.getSelectedOrder.status;
    },
    selectedOrder: {
      get() {
        return this.$store.getters.getSelectedOrder;
      },
      set(newValue) {
        this.$store.commit('setSelectedOrder', newValue);
      },
    },
    userPersonalData() {
      return this.$store.getters.getUserId;
    },
    historyDate() {
      return this.$store.getters.getOrderHistoryData
        ? this.$store.getters.getOrderHistoryData[0]
          ? this.$store.getters.getOrderHistoryData[0].date
          : null
        : null;
    },
    selectedEmobFiles() {
      const emobFilesArray = this.selectedOrder.emobility_payload?.file_uploads
        ? this.selectedOrder.emobility_payload.file_uploads
        : [];
      const emobObject = {};
      emobFilesArray.forEach((element, index) => {
        emobObject[`cadastral_em_${index + 1}`] = element;
      });
      return emobObject;
    },
    selectedWaterFiles() {
      const waterFileName = this.selectedOrder.water_installations?.file_name
        ? this.selectedOrder.water_installations?.file_name
        : null;

      return { water_1: waterFileName };
    },
    orderQuestionsArray() {
      return [
        {
          title: this.$t('order_questions.title'),
          questions: this.selectedOrder.user_questions,
        },
      ];
    },
  },
  methods: {
    ...mapMutations([
      'initModuleGas',
      'initModuleElectric',
      'UPDATE_INSTALLATIONS_MODULE',
    ]),
    getOrderFromBackend(orderId) {
      // get selected order from backend
      orderService
        .getOrderByIdAndDate(orderId)
        .then((res) => {
          const currentOrder = res.getOrderByIdAndDate;
          this.selectedOrder = formatOrderObject(currentOrder);
          this.$store.commit(
            'setAdditionalContactPersonState',
            !!this.selectedOrder.additional_contact_person
          );
          this.setRegionalTeamShortValue(
            this.selectedOrder.regional_team.team_name
          );
        })
        .catch((err) => {
          console.log(err);
          this.isServerError = true;
        });
    },
    setRegionalTeamShortValue(regional_team_name) {
      let short_name = '';
      switch (regional_team_name) {
        case 'RT Viersen':
          short_name = 'VIE';
          break;
        case 'RT Mönchengladbach':
          short_name = 'MG';
          break;
        case 'RT Heinsberg':
          short_name = 'HS';
          break;
        case 'RT R-K-N':
          short_name = 'RKN';
          break;
      }
      this.$store.commit('setRegionalTeamShort', short_name);
    },
    formatDate(date) {
      return (
        new Date(date).toLocaleDateString('de', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }) +
        ' ' +
        new Date(date).toLocaleString('de', {
          hour: '2-digit',
          minute: '2-digit',
        })
      );
    },
    showModal(data) {
      this.currentComponent = data.component;
      this.backendMethod = data.method;
      this.componentProperties = data.properties ? data.properties : {};

      document.body.classList.add('modal-open');
      this.disableScrolling();
      this.noteValue.note = '';
      this.$store.commit('deleteChangedObject');
      this.$store.commit('deleteOldValueObject');
      this.updateNoteChangedObject('');
      this.isModalVisible = true;
      this.$store.commit('setLastUpdatedComponent', data.component);
      this.$store.commit('setLastUpdatedLocation', data.location);
      this.showSpinner = false;
      this.showModalUpdateError = false;
    },
    closeModal() {
      // delete changed_object values in vuex
      this.$store.commit('deleteChangedObject');
      this.$store.commit('deleteOldValueObject');
      this.noteValue.note = '';
      this.updateNoteChangedObject('');

      document.body.classList.remove('modal-open');
      this.enableScrolling();
      this.isModalVisible = false;
      this.alert = false;
      this.showSpinner = false;
      this.showModalUpdateError = false;
    },
    closeModalAndSaveData() {
      if (this.$store.getters.getCurrentComponentStatus === 'finished') {
        this.showSpinner = true;
        if (
          isEqual(
            this.$store.getters.getChangedObject,
            this.$store.getters.getOldValueObject
          ) &&
          ((this.backendMethod !== 'updateFilesName' &&
            this.backendMethod !== 'updateOrderEmobilityFilesName') ||
            ((this.backendMethod === 'updateFilesName' ||
              this.backendMethod === 'updateOrderEmobilityFilesName') &&
              !this.$store.getters.getOrderFileChanged))
        ) {
          EventBus.$emit(`show-same-data-errors-${this.currentComponent}`);
          this.showSpinner = false;
          this.showModalUpdateError = false;
        } else {
          switch (this.backendMethod) {
            case 'updateFilesName':
              EventBus.$emit('start-order-file-upload');
              break;
            case 'updateOrderEmobilityFilesName':
              EventBus.$emit('start-order-emobility-file-upload');
              break;
            case 'updateInstallations':
              if (this.selectedOrder.journey === 'mehrfamilienhaus') {
                EventBus.$emit('start-water-file-upload');
              }
              break;
            case 'updateInvoiceAddress':
              if (!this.$store.getters.getChangedObject.alternative_invoice) {
                delete this.$store.getters.getChangedObject.invoice_address;
              }
              if (!this.$store.getters.getOldValueObject.alternative_invoice) {
                delete this.$store.getters.getOldValueObject.invoice_address;
              }
              break;
            default:
              break;
          }
          const requestBody = {
            id: this.selectedOrder.id,
            request_date: this.selectedOrder.request_date,
            new_value: this.$store.getters.getChangedObject,
            old_value: this.$store.getters.getOldValueObject,
            note: this.$store.getters.getNoteChangedObject,
          };
          this.uploadChangesToBackend(requestBody);
        }
      } else {
        EventBus.$emit(`show-errors-${this.currentComponent}`);
      }
    },
    disableScrolling() {
      const scrollY =
        document.documentElement.style.getPropertyValue('--scroll-y');
      document.getElementById('order-details').style.zIndex = 1;
      const body = document.body;
      body.style.position = 'fixed';
      body.style.width = '-webkit-fill-available';
      body.style.top = `-${scrollY}`;
    },
    enableScrolling() {
      document.getElementById('order-details').style.zIndex = 0;
      const body = document.body;
      const scrollY = body.style.top;
      body.style.position = '';
      body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    },
    updateNoteChangedObject(value) {
      this.$store.commit('updateNoteChangedObject', value);
    },
    uploadChangesToBackend(changedObject) {
      userService[this.backendMethod](changedObject, this.userPersonalData)
        .then(() => {
          document.body.classList.remove('modal-open');
          this.enableScrolling();
          this.isModalVisible = false;
          this.noteValue.note = '';
          this.showSpinner = false;
          this.showModalUpdateError = false;
        })
        .catch((err) => {
          console.log(err);
          setTimeout(() => {
            this.alert = true;
            this.showSpinner = false;
            this.showModalUpdateError = true;
          }, 3000);
        });
    },
    getChangedValue(valueName, responseObject) {
      let obj = null;
      if (responseObject[valueName]) {
        obj = JSON.parse(responseObject[valueName]);
      } else if (responseObject.workflow_payload) {
        const tmp = JSON.parse(responseObject.workflow_payload);
        valueName === 'new_value'
          ? (obj = { status: tmp.new_status })
          : (obj = { status: tmp.old_status });
      }
      return obj;
    },
  },
  watch: {
    selectedOrder(order) {
      this.initModuleElectric({
        e_charging_station: order.electric_installations?.e_charging_station,
        water_heater: order.electric_installations?.water_heater,
        heat_pump: order.electric_installations?.heat_pump,
        heat_pump_hot_water: order.electric_installations?.heat_pump_hot_water,
      });
      this.initModuleGas({
        gas_cooker: order.gas_installations?.gas_cooker,
        gas_heating_hot_water: order.gas_installations?.gas_heating_hot_water,
        gas_heating: order.gas_installations?.gas_heating,
        terrace_connection: order.gas_installations?.terrace_connection,
      });
    },
  },
  apollo: {
    $subscribe: {
      tags: {
        query: gql`
          subscription onOrderHistoryChanged($orderId: String) {
            onOrderHistoryChanged(id: $orderId) {
              id
              changed_by
              event_origin
              created
              field
              title
              old_value
              new_value
              workflow_payload
              note
              status
              filename
            }
          }
        `,
        variables() {
          return {
            orderId: this.order_id,
          };
        },
        result({ data }) {
          const payload = {
            id: data.onOrderHistoryChanged.id,
            new_value: this.getChangedValue(
              'new_value',
              data.onOrderHistoryChanged
            ),
            old_value: this.getChangedValue(
              'old_value',
              data.onOrderHistoryChanged
            ),
            title: data.onOrderHistoryChanged.title,
            note: data.onOrderHistoryChanged.note,
            filename: data.onOrderHistoryChanged.filename,
            status: JSON.parse(data.onOrderHistoryChanged.status),
          };
          data.onOrderHistoryChanged.payload = JSON.stringify(payload);
          if (data.onOrderHistoryChanged.field === 'updatePricingsData') {
            this.$store.commit('updateSelectedOrderData', {
              combined_pricings: {
                total: this.$store.getters.getLastPrice,
              },
            });
          }
          this.$store.commit('setNotifyEvent', data.onOrderHistoryChanged);
          if (
            [
              'updateOrderEmobilityChargingPower',
              'updateOrderEmobilityWallboxLocation',
              'updateOrderEmobilityFilesName',
            ].includes(data.onOrderHistoryChanged.field)
          ) {
            payload.new_value
              ? this.$store.commit(
                  'updateSelectedOrderEmobilityData',
                  payload.new_value
                )
              : '';
          } else {
            payload.new_value
              ? this.$store.commit('updateSelectedOrderData', payload.new_value)
              : '';
          }

          this.$store.commit(
            'updateLastChangedByInSelectedOrder',
            data.onOrderHistoryChanged.changed_by
          );
          this.$store.commit(
            'updateLastUpdateInSelectedOrder',
            data.onOrderHistoryChanged.created
          );
          EventBus.$emit(
            'update-history-table-order',
            data.onOrderHistoryChanged
          );
        },
      },
    },
  },
};
</script>

<style>
.xl-design-details-page {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.xl-design-details-page-element {
  width: 900px;
}
.body-style {
  padding: 28px 38px 28px 38px !important;
}
.mfh-residential-component .body-style {
  padding: 28px 0px 28px 38px !important;
}

.technical_inspection_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.icon {
  margin-left: 10px;
}
.alert_error {
  height: 15px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.z-index-0 {
  z-index: 0 !important;
}
</style>
