const initialState = {
  title: null,
  name: null,
  surname: null,
  company_name: null,
  city_name: null,
  address: {
    street: '',
    street_number: '',
    zip: '',
    city: '',
    country: '',
  },
  phone_number: '',
  additional_phone_number: null,
  email: '',
  preferred_contact_channel: null,
  address_zip_from_google: false,
};

const getters = {
  getFeedInSystemsApplicantTitle: (state) => {
    return state.title;
  },
  getFeedInSystemsApplicantName: (state) => {
    return state.name;
  },
  getFeedInSystemsApplicantSurname: (state) => {
    return state.surname;
  },
  getFeedInSystemsApplicantCompanyName: (state) => {
    return state.company_name;
  },
  getFeedInSystemsApplicantCityName: (state) => {
    return state.city_name;
  },
  getFeedInSystemsApplicantStreet: (state) => {
    return state.address.street;
  },
  getFeedInSystemsApplicantStreetNumber: (state) => {
    return state.address.street_number;
  },
  getFeedInSystemsApplicantZip: (state) => {
    return state.address.zip;
  },
  getFeedInSystemsApplicantCity: (state) => {
    return state.address.city;
  },
  getFeedInSystemsApplicantCountry: (state) => {
    return state.address.country;
  },
  getFeedInSystemsApplicantPhoneNumber: (state) => {
    return state.phone_number;
  },
  getFeedInSystemsApplicantAdditionalPhoneNumber: (state) => {
    return state.additional_phone_number;
  },
  getFeedInSystemsApplicantEmail: (state) => {
    return state.email;
  },
  getFeedInSystemsApplicantPreferredContactChannel: (state) => {
    return state.preferred_contact_channel;
  },
  // used dynamically in AddressDataComponent
  get_form_pvapplicant_address_zip_from_google: (state) => {
    return state.address_zip_from_google;
  },
};

const mutations = {
  initializeFeedInSystemsApplicantModule(state, values) {
    Object.assign(state, { ...state, ...values });
  },
  // used dynamically in AddressDataComponent
  set_form_pvapplicant_address_zip_from_google(state, value) {
    state.address_zip_from_google = value;
  },
  resetFeedInSystemsApplicantModule(state) {
    Object.assign(state, JSON.parse(JSON.stringify(initialState)));
  },
  setFeedInSystemsApplicantPersonalData(state, changedData) {
    state[changedData.key] = changedData.value;
  },
  setFeedInSystemsApplicantAddressData(state, changedData) {
    state.address[changedData.key] = changedData.value;
  },
};

export const feedInSystemsApplicantModule = {
  state: JSON.parse(JSON.stringify(initialState)),
  mutations,
  getters,
};
