import Vue from 'vue';
import VueRouter from 'vue-router';
import ConnectionPage from './pages/ConnectionPage';
import DashboardPage from './pages/DashboardPage';
//import NEWLoginMethods from './helpers/NEWLoginhelper.js'
import AuthPage from './pages/AuthPage';
import DetailsPage from './pages/DetailsPage';
import InstallersPage from './pages/InstallersPage';
import MailLinkPage from '@/pages/MailLinkPage';
import InstallerDetailsPage from './pages/InstallerDetailsPage';
import ErrorPage from './pages/ErrorPage';
import EMobilityPage from './pages/EMobilityPage';
import EMobilityDetailsPage from './pages/EMobilityDetailsPage';
import FeedInSystemsPage from './pages/FeedInSystemsPage';
import FeedInSystemsDetailsPage from './pages/FeedInSystemsDetailsPage';
import auth from './cognito-auth/cognitoAuth';
import UserInfoStore from './cognito-auth/user-info-store';
import UserInfoApi from './cognito-auth/user-info-api';
import store from './store/index.js'; // path to your Vuex store
import jwt_decode from 'jwt-decode';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: DashboardPage,
    beforeEnter: requireAuth,
  },
  {
    path: '/netzanschluesse',
    name: 'ConnectionPage',
    component: ConnectionPage,
    beforeEnter: requireAuth,
  },
  {
    path: '/netzanschluss/:id',
    name: 'Details',
    component: DetailsPage,
    props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/installateure',
    name: 'InstallersPage',
    component: InstallersPage,
    beforeEnter: requireAuth,
  },
  {
    path: '/installateur/:id',
    name: 'InstallerDetailsPage',
    component: InstallerDetailsPage,
    props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/emobilitaet',
    name: 'EMobilityPage',
    component: EMobilityPage,
    beforeEnter: requireAuth,
  },
  {
    path: '/emobilitaet/:id',
    name: 'EMobilityDetailsPage',
    component: EMobilityDetailsPage,
    props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/einspeiseanlagen',
    name: 'FeedInSystemsPage',
    component: FeedInSystemsPage,
    beforeEnter: requireAuth,
  },
  {
    path: '/einspeiseanlagen/:id',
    name: 'FeedInSystemsDetailsPage',
    component: FeedInSystemsDetailsPage,
    props: true,
    beforeEnter: requireAuth,
  },
  { path: '/login', name: 'login', component: AuthPage },
  { path: '/error', name: 'error', component: ErrorPage },
  {
    path: '/mail_link',
    name: 'mail_link',
    component: MailLinkPage,
    beforeEnter: requireAuth,
  },
  {
    path: '/login/oauth2/code/cognito',
    beforeEnter() {
      const currUrl = window.location.href;
      auth.auth.parseCognitoWebResponse(currUrl);
    },
  },
];

const router = new VueRouter({
  routes: routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: 'history',
});

router.beforeEach((to, from, next) => {
  if (store.getters.getMustBeNotifiedOfChangedData) {
    //do not navigate, we need to remind the user in Details Page to send Notification Email
    store.commit('setShowConfirmationDialog', true);
    store.commit('setPendingNavigation', to.path);
  } else {
    next();
  }
});

function requireAuth(to, from, next) {
  store.commit(
    'setPreviousPage',
    `${from.path}?${new URLSearchParams(from.query)}`
  );
  if (!auth.auth.isUserSignedIn()) {
    localStorage.removeItem('vuex');
    UserInfoStore.setLoggedIn(false);
    next({
      path: '/login',
      query: { redirect: to.fullPath },
    });
  } else {
    UserInfoApi.getUserInfo().then((response) => {
      let user_id = '';
      const token = auth.auth.getSignInUserSession().getIdToken().jwtToken;
      const identities = jwt_decode(token).identities;
      if (identities) {
        const provider = 'login.new-' + process.env.VUE_APP_STAGE;
        user_id = identities.find(
          (item) => item.providerName === provider
        ).userId;
      }
      UserInfoStore.setLoggedIn(true);
      store.commit('setUserFirstName', response['name']);
      store.commit('setUserLastName', response['family_name']);
      store.commit('setUserEmail', response['email']);
      store.commit('setUserId', user_id);
      store.commit('setUserGroups', jwt_decode(token)['cognito:groups']);
      store.commit(
        'setUserAccessOrdersW',
        response['custom:access-bo-orders-w'] === '1'
      );
      UserInfoStore.setCognitoInfo(response);
      next();
    });
  }
}

export default router;
