const initialState = {
  files: {},
  fileURLs: {},
  initialized: false,
};

export const pvUploadFilesModule = {
  state: JSON.parse(JSON.stringify(initialState)),
  mutations: {
    setPhotovoltaicFiles(state, { key, files }) {
      if (!Array.isArray(files)) {
        files = [files];
      }
      state.files[key] = files;
    },
    addPhotovoltaicFiles(state, { key, files }) {
      if (!Array.isArray(files)) {
        files = [files];
      }
      state.files[key] = state.files[key]?.length
        ? [...state.files[key], ...files]
        : files;
    },
    setPhotovoltaicFileURL(state, object) {
      state.fileURLs[object.imageName] = object.url;
    },
    setPhotovoltaicFilesInitialized(state, value) {
      state.initialized = value;
    },
    resetPVUploadFilesModule(state) {
      Object.assign(state, JSON.parse(JSON.stringify(initialState)));
    },
    setPhotovoltaicFileHistoryFromBackend(state, file) {
      state.files[file.name] = file;
    },
    removePhotovoltaicFile(state, imageName) {
      delete state.files[imageName];
    },
    removePhotovoltaicFileWithKey(state, { key, imageName }) {
      if (state.files[key] && Array.isArray(state.files[key])) {
        const fileIndex = state.files[key].findIndex(
          (file) => file.name === imageName
        );
        if (fileIndex !== -1) {
          state.files[key].splice(fileIndex, 1);
        } else {
          console.error(`File ${imageName} not found in ${key}.`);
        }
      }
    },
  },
  getters: {
    getPVUploadFiles: (state) => {
      return state.files;
    },
    getPhotovoltaicFilesInitialized: (state) => {
      return state.initialized;
    },
    getPhotovoltaicFileFromBackend: (state) => (imageName) => {
      return state.files[imageName];
    },
    getPhotovoltaicFileURLByName: (state) => (imageName) => {
      return state.fileURLs[imageName];
    },
  },
};
