var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticStyle:{"justify-content":"flex-end","margin-right":"0px"}}),_c('single-raw',{staticClass:"single-raw",attrs:{"first":"","title":_vm.$t('installer_details.date'),"editable":false}},[_vm._v(_vm._s(_vm.formatDate(_vm.selectedInstaller.request_date)))]),_c('single-raw',{staticClass:"single-raw",attrs:{"title":_vm.$t('installer_details.company_data')},on:{"edit":function($event){return _vm.$emit('show-modal', {
        component: 'InstallerCompanyDataComponent',
        method: 'updateInstallerCompanyData',
      })}}},[_c('div',[_vm._v(_vm._s(_vm.selectedInstaller.company_data.company_name))]),_c('div',[_vm._v(_vm._s(_vm.selectedInstallerAddress))]),_c('div',[_vm._v("Telefon: "+_vm._s(_vm.selectedInstaller.company_data.phone_number))]),_c('div',[_vm._v(" Entstörrufnummer: "+_vm._s(_vm.selectedInstaller.company_data.troubleshooting_number ? _vm.selectedInstaller.company_data.troubleshooting_number : 'nicht angegeben')+" ")]),_c('div',[_vm._v("E-Mail: "+_vm._s(_vm.selectedInstaller.company_data.email))]),(_vm.notifyEvent.field === 'updateInstallerCompanyData')?_c('userfeedback-component'):_vm._e()],1),_c('single-raw',{staticClass:"single-raw",attrs:{"title":_vm.$t('installer_details.responsible_specialist')},on:{"edit":function($event){return _vm.$emit('show-modal', {
        component: 'ResponisibleSpecialistsStepperComponent',
        method: 'updateInstallerPeople',
      })}}},[_c('div',[_vm._l((_vm.selectedInstaller.responsible_specialists),function(item,index){return _c('div',{key:item.name},[_c('div',[_vm._v(" "+_vm._s(item.name)+" "+_vm._s(item.surname)+" ("+_vm._s(item.function_in_company)+") ")]),_c('div',[_vm._v(" Fachkraft für: "+_vm._s(_vm.transformConcessionDivision(item.energy_types))+" ")]),_c('div',[_vm._v(" Telefon: "+_vm._s(item.phone_number ? item.phone_number : 'nicht angegeben')+" ")]),_c('div',[_vm._v(" Mobilnummer: "+_vm._s(item.mobile_number ? item.mobile_number : 'nicht angegeben')+" ")]),_c('div',[_vm._v("Email: "+_vm._s(item.email ? item.email : 'nicht angegeben'))]),(
            index !== _vm.selectedInstaller.responsible_specialists.length - 1
          )?_c('div',[_c('br')]):_vm._e()])}),(_vm.notifyEvent.field === 'updateInstallerPeople')?_c('userfeedback-component'):_vm._e()],2)]),_c('single-raw',{staticClass:"single-raw",attrs:{"title":_vm.$t('installer_details.contact_person')},on:{"edit":function($event){return _vm.$emit('show-modal', {
        component: 'InstallerContactPersonComponent',
        method: 'updateInstallerContactPerson',
      })}}},[(_vm.selectedInstaller.contact_person)?_c('div',[_c('div',[_vm._v(" "+_vm._s(_vm.selectedInstaller.contact_person.title)+" "+_vm._s(_vm.selectedInstaller.contact_person.name)+" "+_vm._s(_vm.selectedInstaller.contact_person.surname)+" ("+_vm._s(_vm.selectedInstaller.contact_person.function_in_company)+") ")]),_c('div',[_vm._v(" Telefon: "+_vm._s(_vm.selectedInstaller.contact_person.phone_number ? _vm.selectedInstaller.contact_person.phone_number : 'nicht angegeben')+" ")]),_c('div',[_vm._v(" Mobilnummer: "+_vm._s(_vm.selectedInstaller.contact_person.mobile_number ? _vm.selectedInstaller.contact_person.mobile_number : 'nicht angegeben')+" ")]),_c('div',[_vm._v(" Email: "+_vm._s(_vm.selectedInstaller.contact_person.email ? _vm.selectedInstaller.contact_person.email : 'nicht angegeben')+" ")])]):_c('div',[_c('div',[_vm._v("-")])]),(_vm.notifyEvent.field === 'updateInstallerContactPerson')?_c('userfeedback-component'):_vm._e()],1),_c('single-raw',{staticClass:"single-raw",attrs:{"title":_vm.$t('installer_details.concession_division')},on:{"edit":function($event){return _vm.$emit('show-modal', {
        component: 'InstallerConcessionDivisionComponent',
        method: 'updateInstallerConcessionDivision',
      })}}},[_c('div',[_vm._v(" "+_vm._s(_vm.transformConcessionDivision(_vm.selectedInstaller.concession_division))+" ")]),(_vm.notifyEvent.field === 'updateInstallerConcessionDivision')?_c('userfeedback-component'):_vm._e()],1),_c('installer-file-row',{staticClass:"single-raw",attrs:{"title":_vm.$t('installer_details.professional_proof'),"last":"","filesArray":_vm.selectedFiles},on:{"edit":function($event){return _vm.$emit('show-modal', {
        component: 'InstallerFileUploadComponent',
        method: 'updateInstallerFilesName',
        properties: { file_data: $event },
      })}}},[(_vm.notifyEvent.field === 'updateInstallerFilesName')?_c('userfeedback-component'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }