<template>
  <div class="mb-2">
    <v-card class="new-element-border-radius" outlined>
      <div
        :class="notEditable ? '' : 'clickable'"
        :v-ripple="!notEditable"
        @click="changeCheckboxValue"
      >
        <v-list-item three-line>
          <v-avatar
            class="mr-5"
            style="cursor: pointer"
            color="#6E6E6E"
            size="30"
          >
            <span class="white--text headline">{{ indexData }}</span>
          </v-avatar>
          <v-list-item-content
            :class="
              $vuetify.breakpoint.xs
                ? 'checkbox_title_xs'
                : 'checkbox_title_default'
            "
          >
            {{ $t(title) }}
          </v-list-item-content>
          <v-list-item-action
            class="ma-0"
            style="align-self: center"
            @keydown.space="changeCheckboxValue"
          >
            <v-checkbox
              color="#9B004B"
              v-model="checkboxState"
              :disabled="notEditable"
              @click.stop=""
            >
            </v-checkbox>
          </v-list-item-action>
        </v-list-item>
      </div>
    </v-card>
    <div class="mb-2" v-if="showError && !checkboxState" style="color: #c20016">
      {{ $t(requiredText) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextCheckboxComponent',
  props: [
    'title',
    'checkboxName',
    'showError',
    'index',
    'requiredText',
    'indexData',
    'notEditable',
  ],
  data() {
    return {};
  },
  computed: {
    checkboxState: {
      get() {
        return this.$store.getters['get' + this.checkboxName + 'Selected'];
      },
      set(value) {
        this.$store.commit('set' + this.checkboxName + 'Selected', value);
        this.$emit('update-checkbox-value', value);
      },
    },
  },
  methods: {
    changeCheckboxValue() {
      if (!this.notEditable) {
        this.checkboxState = !this.checkboxState;
      }
    },
  },
};
</script>

<style>
.clickable {
  cursor: pointer;
  box-shadow: 0px 1px 2px #00000029;
  position: relative;
}

.checkbox_title_default {
  font-size: 20px;
  line-height: 24px;
}
.checkbox_title_xs {
  font-size: 18px;
  line-height: 21px;
}
</style>
