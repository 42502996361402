import { INSTALLER_STATUSES } from '../../helpers/constants';
import i18n from '../../plugins/i18n';

const configurations = {
  statusValuesList: getStatusesConfig(),
  headersAktive: [
    { text: 'Datum', value: 'date' },
    { text: 'Antragsteller', value: 'company_name', sortable: true },
    { text: 'Energieart', value: 'energy_type' },
    { text: 'Adresse', value: 'address' },
    { text: 'Ausweisnummer', value: 'badge_id' },
    { text: 'zul. bearbeitet von, am', value: 'lastChangedBy' },
    { text: 'Status', value: 'status' },
    { value: 'edit' },
  ],
  headersArchiv: [
    { text: 'Datum', value: 'date' },
    { text: 'Antragsteller', value: 'applicant' },
    { text: 'Produktgruppe', value: 'product' },
    { text: 'Anschlussadresse', value: 'address' },
    { text: 'zul. bearbeitet von', value: 'lastChangedBy' },
    {
      text: 'Status',
      value: 'status',
      filterable: true,
      filter: (value) =>
        this.archivStatus === 'all' || value === this.archivStatus,
    },
    { value: 'edit' },
  ],
  energyTypeValuesList: [
    { label: 'alle', value: 'all', color: '#999999' },
    { label: 'Strom', value: 'electricity', color: '#1D1B4F' },
    { label: 'Erdgas', value: 'gas', color: '#172D57' },
    { label: 'Trinkwasser', value: 'water', color: '#284C7A' },
  ],
  expiryDateValuesList: [
    { label: 'alle', value: 'all', color: '#999999' },
    {
      label: 'Installateurausweis bis 31.12.2024',
      value: '2024-12-31',
      color: '#CB80A2',
    },
    {
      label: 'Installateurausweis bis 31.12.2023',
      value: '2023-12-31',
      color: '#BF658D',
    },
    {
      label: 'Installateurausweis bis 31.12.2022',
      value: '2022-12-31',
      color: '#a72f62',
    },
  ],
  internalDateValuesList: [
    { label: 'alle', value: 'all', color: '#999999' },
    {
      label: 'Intern',
      value: 'true',
      color: '#CB80A2',
    },
    {
      label: 'Extern',
      value: 'false',
      color: '#BF658D',
    },
  ],
};

export const getInstallersTableConfig = (configName) => {
  return JSON.parse(JSON.stringify(configurations[configName]));
};

function getStatusesConfig() {
  let statusesArray = [];
  statusesArray = Object.keys(INSTALLER_STATUSES).map((key) => {
    const { label, value, color } = INSTALLER_STATUSES[key];
    return { label, value, color };
  });
  statusesArray.forEach((item) => {
    item.label = i18n.t(`installer_statuses.${item.value}`);
  });
  statusesArray.unshift({ label: 'alle', value: 'all', color: '#999999' });
  return statusesArray;
}
