import {
  applicantObject,
  installationAddressObject,
  landownerObject,
  additionalContactPersonObject,
  wallboxLocationObject,
  emobilityInstallationObject,
} from '../../helpers/transformToPresentableData';

export function formatEmobilityData(rawData) {
  return {
    requestDate: rawData.request_date,
    installationAddress: installationAddressObject(rawData),
    blueprints: (rawData.uploaded_file_names_cadastral || []).filter(
      (el) => el !== ''
    ),
    wallbox_location: wallboxLocationObject(rawData),
    e_mobility_installation: emobilityInstallationObject(rawData),
    applicant: applicantObject(rawData.applicant),
    otherContacts: additionalContactPersonObject(rawData),
    landowner: landownerObject(rawData),
  };
}
