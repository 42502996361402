<template>
  <div>
    <div>
      <div v-for="(filename, index) in filenames" v-bind:key="index">
        <edit-document-component
          @maximize-image="maximizeImage(filename)"
          @edit-document="downloadBlob(filename)"
          @send-document="prepareSendDocument(filename)"
          :showMailButton="true"
          :showMaximazeButton="true"
          class="mb-2"
        >
          <template v-slot:component-content>
            <div class="container--filename-date">
              <div>
                {{ filename.date }}
              </div>
              <div class="div-title">{{ filename.file_name }}</div>
              <div>{{ filename.changed_by }}</div>
            </div>
            <div>
              <v-alert
                type="error"
                class="alert_error ml-4"
                v-if="filename.showErrorAlert"
                text
                transition="scale-transition"
              >
                {{ $t('document_history.document_not_found') }}
              </v-alert>
            </div>
          </template>
        </edit-document-component>
      </div>
      <div v-if="isServerError">
        <h3>
          {{ $t('document_history.loading_error') }}
        </h3>
        <h5>
          {{ $t('document_history.error_hint') }}
        </h5>
      </div>
      <v-dialog max-width="720px" v-model="dialog">
        <v-btn @click="dialog = false" icon class="dialog--btn-close">
          <v-icon color="primary" size="40pt">mdi-close</v-icon>
        </v-btn>
        <template v-if="!isError">
          <v-img :src="imgSrc" v-if="!isPdf"></v-img>
          <div v-if="isPdf">
            <VuePdfEmbed :source="imgSrc" />
          </div>
        </template>
        <template v-else>
          <NewAlert color="ERROR" :message="$t('document_history.error')" />
        </template>
      </v-dialog>
      <new-modal-dialog
        max-width="720px"
        v-if="showMailDialog"
        :value="noteValue"
        :enable-note="false"
        role="dialog"
        @cancel-data="showMailDialog = false"
        @save-data="validateAndSendMail"
        cancel-button-text="Abbrechen"
        save-button-text="Senden"
      >
        <template #body>
          <NewMailDialog
            :showError="showErrorInMailDialog"
            :file="fileToBePreviewed"
            @mail-content="handleIncomingMailData"
            :max-recipients="2"
          />
        </template>
      </new-modal-dialog>
    </div>
  </div>
</template>

<script>
import EditDocumentComponent from '@bit/new-netzportal.netzportal.edit-document-component';
import axios from 'axios';
import fileMethods from '../../helpers/fileMethods';
import documentHistoryService from '../../services/DocumentHistoryService';
import documentsService from '../../services/DocumentsService';
import { EventBus } from '@/main';
import { transformRawDataToDocumentsHistoryTableFormat } from './transform_raw_data_to_documents_history_table_format';
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
import { NewAlert } from '@bit/new-netzportal.netzportal.alert';
import { mapMutations } from 'vuex';
import { NewModalDialog } from '@newnetzportal/netzportal-webapp-storybook-components';
import { NewMailDialog } from '@newnetzportal/netzportal-webapp-storybook-components';

export default {
  name: 'DocumentHistoryComponent',
  components: {
    EditDocumentComponent,
    VuePdfEmbed,
    NewAlert,
    NewModalDialog,
    NewMailDialog,
  },
  data() {
    return {
      dialog: false,
      filenames: [],
      imgSrc: '',
      isPdf: false,
      isError: false,
      isServerError: false,
      // toggles MailDialog on/off
      showMailDialog: false,
      // no additional note in modal.
      noteValue: { note: '' },
      // Toggles big error Message in MailDialog on/off.
      showErrorInMailDialog: false,
      // Contains the file to be previewed in the mail dialog.
      fileToBePreviewed: null,
      // contains the file infos with name, URL etc.
      fileObjectWithName: null,
    };
  },
  props: {
    historyType: {
      type: String,
      required: true,
    },
  },
  created() {
    this.getDocumentHistory();
    EventBus.$on(
      'update-history-table-' + this.historyType.toLowerCase(),
      (event) => {
        if (event.field === `add${this.historyType}FileHistoryEntry`) {
          this.filenames.splice(
            0,
            0,
            transformRawDataToDocumentsHistoryTableFormat([event])[0]
          );
        }
      }
    );
  },
  computed: {
    selectedObject() {
      return this.$store.getters[`getSelected${this.historyType}`];
    },
  },
  methods: {
    ...mapMutations(['addNotification']),
    getDocumentHistory() {
      const params = {
        id: this.selectedObject.id,
        from_index: 0,
        limit: 50,
      };
      documentHistoryService[`get${this.historyType}DocumentsHistory`](params)
        .then((res) => {
          this.filenames = transformRawDataToDocumentsHistoryTableFormat(
            res[`${this.historyType.toLowerCase()}DocumentsHistory`].changes
          );
        })
        .catch((err) => {
          console.error(err);
          this.isServerError = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getHistoryDocument(file) {
      return await documentsService[`get${this.historyType}DocumentGraphQL`](
        `${this.selectedObject.id}/${file.full_path}`,
        process.env.VUE_APP_STAGE
      )
        .then(async (res) => {
          await this.downloadFromPresignedURL(res.downloadFile, file.full_path);
          return true;
        })
        .catch((err) => {
          this.isError = true;
          console.error('Error:', err);
          file.showErrorAlert = true;
          setTimeout(() => {
            file.showErrorAlert = false;
          }, 3000);
          return false;
        });
    },
    async downloadFromPresignedURL(fileURL, file_name) {
      const downloadResponse = await axios({
        url: fileURL,
        method: 'GET',
        headers: {
          Accept: '*',
          'Content-Type': 'image/png;charset=utf-8',
        },
        responseType: 'arraybuffer',
      });
      const product = this.historyType + 'HistoryFile';
      const id = `${this.selectedObject.id}_${file_name.substring(
        0,
        file_name.indexOf('_')
      )}`;

      const fileObject = fileMethods.convertToBlobAndReturnFileObject(
        downloadResponse.data,
        file_name,
        product,
        id
      );
      this.$store.commit(`set${this.historyType}FileURL`, fileObject);
      const file =
        this.$store.getters[`get${this.historyType}FileFromBackend`](file_name);
      // Make file available for preview in mail dialog.
      this.fileToBePreviewed = file;
      this.isPdf = file.type === 'application/pdf';
    },
    showFilePreview(file_name) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = async (event) => {
          try {
            const response = event.target.result;
            resolve(response);
          } catch (err) {
            reject(err);
          }
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(
          this.$store.getters[`get${this.historyType}FileFromBackend`](
            file_name
          )
        );
      });
    },
    async maximizeImage(file) {
      this.isError = false;
      const success = await this.getHistoryDocument(file);
      if (!success) return;
      try {
        const res = await this.showFilePreview(file.full_path);
        this.imgSrc = res;
      } catch (err) {
        this.isError = true;
        console.error(err);
      }

      this.dialog = true;
    },
    async downloadBlob(file) {
      const success = await this.getHistoryDocument(file);
      if (!success) return;
      fileMethods.downloadFile(file.full_path, this.historyType);
    },
    /**
     * Checks whether the mail data is valid and sends the mail or shows an error.
     * @returns {Promise<void>}
     */
    async validateAndSendMail() {
      if (!this.mailData.valid) {
        this.showErrorInMailDialog = true;
        return;
      }
      this.showMailDialog = false;

      try {
        await documentsService.resendDocument(
          this.historyType,
          this.$route.params.id,
          this.mailData.recipients,
          this.mailData.additionalComment,
          this.fileObjectWithName.full_path,
          this.$store.getters.getUserId
        );
        this.addNotification(
          this.$t('document_history.document_sent_successfully')
        );
        console.info('Document successfully sent.');
      } catch (e) {
        console.error('Error sending document:', e);
      }
    },
    /**
     * Receives continuously the mail data from the child component NewMailDialog.
     * @param data {valid: boolean, recipients: string[], additionalComment: string}
     */
    handleIncomingMailData(data) {
      this.mailData = data;
      if (this.mailData.valid) this.showErrorInMailDialog = false;
    },

    /**
     * fetches the file to be previewed and opens MailDialog.
     * @param file
     * @returns {Promise<void>}
     */
    async prepareSendDocument(file) {
      const success = await this.getHistoryDocument(file);
      if (!success) return;
      this.fileObjectWithName = file;
      this.showMailDialog = true;
    },
  },
};
</script>

<style scoped>
.div-title {
  font-family: 'DIN Next LT W04 Medium', Roboto, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}

.dialog--btn-close {
  position: absolute;
  top: 5px;
  right: 5px;
}

.container--filename-date {
  display: flex;
  justify-content: space-between;
}
</style>
