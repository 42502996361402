<template>
  <div>
    <v-row style="justify-content: flex-end; margin-right: 0px"> </v-row>
    <div v-for="(item, index) in orderQuestionsArray" :key="item.title">
      <single-raw
        :first="index === 0"
        :last="index === orderQuestionsArray.length - 1"
        class="single-raw"
        :title="item.title"
        :editable="false"
      >
        <div v-html="transformOrderQuestions(item.questions)"></div>
      </single-raw>
    </div>
  </div>
</template>

<script>
import SingleRaw from './SingleRaw';
import { transformOrderQuestions } from '../../helpers/transformToPresentableData';

export default {
  name: 'OrderQuestions',
  components: {
    SingleRaw,
  },
  props: {
    orderQuestionsArray: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    transformOrderQuestions,
  },
};
</script>

<style scoped>
.single-raw {
  margin: 0 0 1px 0;
}
</style>
