<template>
  <v-alert
    class="alert_success"
    style="background-color: #e4f0d6 !important"
    max-width="270px"
    max-height="35px"
    text
    transition="scale-transition"
  >
    <template v-slot:append>
      <v-icon color="green" class="ml-2">mdi-check</v-icon>
    </template>
    Erfolgreich gespeichert
  </v-alert>
</template>

<script>
export default {
  name: 'UserfeedbackComponent',
};
</script>
