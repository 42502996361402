import GraphQLService from './GraphQLService';
import store from '../store/index.js';

export async function getEmobilityWithFilter(requestBody) {
  return GraphQLService.requestWithOpenID(
    `
      query getEmobilityWithFilter($limit: Int, $from_index: Int, $order_status: String, $regional_team: String, $search_word: String) { 
        getEmobilityWithFilter ( limit: $limit, from_index: $from_index, order_status: $order_status, regional_team: $regional_team, search_word: $search_word ) {
          items {
            id
            applicant {
              title
              surname
              name
              phone_number
              company_name
              city_name
              email
              additional_phone_number
              preferred_contact_channel
              address {
                street
                street_number
                city
                country
                zip
              }
            }                
            user_id              
            product_installation_full_address{
              street
              street_number
              city
              country
              zip
            }
            status                
            request_date               
            product_installation_with_plot_number_address {
              district
              landing
              location
              new_building_area_name
              plot
              zip
            }
            last_changed_by
            last_update
            regional_team {
              team_id
              team_name
            }
        }
        count_emobilities
        }
      }`,
    {
      limit: requestBody.limit,
      from_index: requestBody.from_index,
      order_status: requestBody.order_status,
      regional_team: requestBody.regional_team,
      search_word: requestBody.search_word,
    }
  );
}

export async function getEmobilitySummaryForPipeline(status) {
  return GraphQLService.requestWithOpenID(
    `
      query getEmobilitySummaryForPipeline($status: String) { 
        getEmobilitySummaryForPipeline(status: $status) {
          count_order_created
          count_order_cancelled
          count_order_technical_feasibility
          count_order_confirmed
          count_all
        }
      }`,
    {
      status: status,
    }
  );
}

export async function getEmobilitySummaryForDiagram() {
  return GraphQLService.requestWithOpenID(
    `
      query getEmobilitySummaryForDiagram { 
        getEmobilitySummaryForDiagram {
          count_orders 
        }
      }`
  );
}

export async function getEmobilityById(id) {
  return GraphQLService.requestWithOpenID(
    `
      query getEmobilityById($id: String) { 
        getEmobilityById (id: $id) {
            id
            applicant {
              title
              surname
              name
              phone_number
              email
              city_name
              company_name
              additional_phone_number
              preferred_contact_channel
              address {
                street
                street_number
                city
                country
                zip
              }
            }
            additional_contact_person {
              name
              surname
              title
              email
              phone_number
              additional_phone_number
              preferred_contact_channel
            } 
            charging_station_custom_value
            coordinates {
              lat
              lng
            } 
            e_mobility_installation   
            wallbox_location       
            wallbox_location_custom_value   
            user_id              
            product_installation_full_address{
              street
              street_number
              city
              country
              zip
            }
            landowner{
              title
              surname
              name
              company_name
              city_name
              address{
                street
                street_number
                city
                country
                zip
              }
              additional_contact_person {
                name
                surname
                title
                email
                phone_number
                additional_phone_number
                preferred_contact_channel
              } 
            }
            permissions {
              data_transfer_selected
              consent_customer_survey_selected
            }
            status                
            request_date               
            product_installation_with_plot_number_address {
              district
              landing
              location
              new_building_area_name
              plot
              zip
            }
            emobility_address_checked
            emobility_customer_data_checked
            emobility_gis_checked
            emobility_charging_power_checked
            emobility_charging_power_values {
              emobility_charging_power_inspection
              emobility_charging_power_custom_inspection
            }
            same_landowner
            plans_available
            last_changed_by
            last_update
            uploaded_file_names_cadastral
            regional_team {
              team_id
              team_name
            }
          }
      }`,
    {
      id: id,
    }
  );
}

export default {
  async updateEmobilityApplicant(requestObject, user) {
    requestObject.user_id = store.getters.getSelectedEmobility.user_id;
    return GraphQLService.requestWithOpenID(
      `
        mutation updateEmobilityApplicant($id: String!, $ApplicantObject: EmobilityApplicantDataPayload, $event_origin: String!, $changed_by: String! ) { 
          updateEmobilityApplicant(id: $id, payload: $ApplicantObject, event_origin: $event_origin, changed_by: $changed_by ){
            id
            changed_by
            event_origin
            created
            field 
            old_value
            new_value
            note
          }
        }`,
      {
        id: requestObject.id,
        ApplicantObject: requestObject,
        event_origin: 'BACKOFFICE',
        changed_by: user,
      }
    );
  },
  async updateEmobilityInstallationAddress(requestObject, user) {
    requestObject.user_id = store.getters.getSelectedEmobility.user_id;
    return GraphQLService.requestWithOpenID(
      `
        mutation updateEmobilityInstallationAddress($id: String!, $InstallationAddressObject: EmobilityInstallationAddressDataPayload, $event_origin: String!, $changed_by: String! ) { 
          updateEmobilityInstallationAddress(id: $id, payload: $InstallationAddressObject, event_origin: $event_origin, changed_by: $changed_by ){
            id
            changed_by
            event_origin
            created
            field 
            old_value
            new_value
            note
          }
        }`,
      {
        id: requestObject.id,
        InstallationAddressObject: requestObject,
        event_origin: 'BACKOFFICE',
        changed_by: user,
      }
    );
  },
  async updateEmobilityAdditionalContactPerson(requestObject, user) {
    requestObject.user_id = store.getters.getSelectedEmobility.user_id;
    return GraphQLService.requestWithOpenID(
      `
        mutation updateEmobilityAdditionalContactPerson($id: String!, $ContactPersonObject: EmobilityAdditionalContactPersonPayload, $event_origin: String!, $changed_by: String! ) { 
          updateEmobilityAdditionalContactPerson(id: $id, payload: $ContactPersonObject, event_origin: $event_origin, changed_by: $changed_by ){
            id
            changed_by
            event_origin
            created
            field 
            old_value
            new_value
            note
          }
        }`,
      {
        id: requestObject.id,
        ContactPersonObject: requestObject,
        event_origin: 'BACKOFFICE',
        changed_by: user,
      }
    );
  },
  async updateEmobilityWallboxLocation(requestObject, user) {
    requestObject.user_id = store.getters.getSelectedEmobility.user_id;
    return GraphQLService.requestWithOpenID(
      `
        mutation updateEmobilityWallboxLocation($id: String!, $WallboxLocationObject: EmobilityWallboxLocationPayload, $event_origin: String!, $changed_by: String! ) { 
          updateEmobilityWallboxLocation(id: $id, payload: $WallboxLocationObject, event_origin: $event_origin, changed_by: $changed_by ){
            id
            changed_by
            event_origin
            created
            field 
            old_value
            new_value
            note
          }
        }`,
      {
        id: requestObject.id,
        WallboxLocationObject: requestObject,
        event_origin: 'BACKOFFICE',
        changed_by: user,
      }
    );
  },
  async updateEmobilityLandowner(requestObject, user) {
    requestObject.user_id = store.getters.getSelectedEmobility.user_id;
    return GraphQLService.requestWithOpenID(
      `
        mutation updateEmobilityLandowner($id: String!, $LandownerObject: EmobilityLandownerPayload, $event_origin: String!, $changed_by: String! ) { 
          updateEmobilityLandowner(id: $id, payload: $LandownerObject, event_origin: $event_origin, changed_by: $changed_by ){
            id
            changed_by
            event_origin
            created
            field 
            old_value
            new_value
            note
          }
        }`,
      {
        id: requestObject.id,
        LandownerObject: requestObject,
        event_origin: 'BACKOFFICE',
        changed_by: user,
      }
    );
  },
  async updateEmobilityChargingPower(requestObject, user) {
    requestObject.user_id = store.getters.getSelectedEmobility.user_id;
    return GraphQLService.requestWithOpenID(
      `
        mutation updateEmobilityChargingPower($id: String!, $ChargingPowerObject: EmobilityChargingPowerPayload, $event_origin: String!, $changed_by: String! ) { 
          updateEmobilityChargingPower(id: $id, payload: $ChargingPowerObject, event_origin: $event_origin, changed_by: $changed_by ){
            id
            changed_by
            event_origin
            created
            field 
            old_value
            new_value
            note
          }
        }`,
      {
        id: requestObject.id,
        ChargingPowerObject: requestObject,
        event_origin: 'BACKOFFICE',
        changed_by: user,
      }
    );
  },
  async updateEmobilityFilesName(requestObject, user) {
    requestObject.user_id = store.getters.getSelectedEmobility.user_id;
    return GraphQLService.requestWithOpenID(
      `
        mutation updateEmobilityFilesName($id: String!, $FilesNameObject: EmobilityFilesNamePayload, $event_origin: String!, $changed_by: String! ) { 
          updateEmobilityFilesName(id: $id, payload: $FilesNameObject, event_origin: $event_origin, changed_by: $changed_by ){
            id
            changed_by
            event_origin
            created
            field 
            old_value
            new_value
            note
          }
        }`,
      {
        id: requestObject.id,
        FilesNameObject: requestObject,
        event_origin: 'BACKOFFICE',
        changed_by: user,
      }
    );
  },
  async updateEmobilityStatus(requestObject, user) {
    requestObject.user_id = store.getters.getSelectedEmobility.user_id;
    return GraphQLService.requestWithOpenID(
      `
        mutation updateEmobilityStatus($id: String!, $EmobilityStatusObject: EmobilityStatusPayload, $event_origin: String!, $changed_by: String! ) { 
          updateEmobilityStatus(id: $id, payload: $EmobilityStatusObject, event_origin: $event_origin, changed_by: $changed_by ){
            id
            changed_by
            event_origin
            created
            field 
            old_value
            new_value
          }
        }`,
      {
        id: requestObject.id,
        EmobilityStatusObject: requestObject,
        event_origin: 'BACKOFFICE',
        changed_by: user,
      }
    );
  },
  async updateEmobilityInspectionData(requestObject, user) {
    return GraphQLService.requestWithOpenID(
      `
        mutation updateEmobilityInspectionData($id: String!, $emobilityInspectionObject: EmobilityInspectionDataPayload, $event_origin: String!, $changed_by: String! ) { 
          updateEmobilityInspectionData(id: $id, payload: $emobilityInspectionObject, event_origin: $event_origin, changed_by: $changed_by ){
            id
            changed_by
            event_origin
            status
            created
            field 
            old_value
            new_value
          }
        }`,
      {
        id: requestObject.id,
        emobilityInspectionObject: requestObject,
        event_origin: 'BACKOFFICE',
        changed_by: user,
      }
    );
  },
};
