<template>
  <div class="full_width">
    <v-btn class="refresh-button" @click="refreshOrders()">
      <v-icon color="#6E6E6E">mdi-sync</v-icon>
    </v-btn>
    <div class="table-style">
      <div class="table-header">
        <div class="pill-radio-buttons-div">
          <PillRadioButtons
            :selectedValue="statusSelection"
            :itemsList="computedOrderFilters"
            @update-selected-value="statusSelection = $event"
          />
        </div>
        <div class="pill-radio-buttons-div">
          <PillRadioButtons
            :selectedValue="pVPerformanceSelection"
            :itemsList="performanceValuesList"
            @update-selected-value="pVPerformanceSelection = $event"
          />
        </div>
        <template>
          <v-container fluid>
            <v-row class="flex-column flex-sm-row align-center">
              <v-col
                cols="12"
                sm
                class="mb-4 mr-8 mb-sm-0 pr-sm-10"
                style="max-width: 700px"
              >
                <v-text-field
                  rounded
                  outlined
                  dense
                  full-width
                  v-model="searchValue"
                  prepend-inner-icon="mdi-magnify"
                  @input="elasticSearchOnBackend"
                  label="Suche"
                  single-line
                  hide-details
                />
              </v-col>
              <v-col cols="12" sm="auto">
                <PeriodDatePicker @date-change="onDateChange" />
              </v-col>
            </v-row>
          </v-container>
        </template>
      </div>
      <v-data-table
        disable-sort
        :headers="headersAktive"
        :items="activeOrdersDisplayed"
        item-key="orderId"
        :loading="loading"
        :loading-text="$t('overview_table.loading_text')"
        :no-data-text="
          this.isDataLoaded
            ? $t('overview_table.no_filter_data')
            : $t('overview_table.error_data')
        "
        :footer-props="{
          'items-per-page-options': [10],
        }"
        :server-items-length="filteredActiveOrdersCount"
        calculate-widths
        :options.sync="options"
        :page="currentPage"
      >
        <template v-slot:[`item.date`]="{ item }">
          <span>{{ item.date | formatDate('DD.MM.YYYY HH:mm') }}</span>
        </template>
        <template v-slot:[`item.status`]="props">
          <div class="flex-row-status">
            <v-btn
              rounded
              dark
              :style="{
                //color here is for text color
                color: props.item.status.textColor
                  ? props.item.status.textColor
                  : 'white',
                pointerEvents: 'none',
              }"
              :color="
                //color here is for background-color
                props.item.status ? props.item.status.color : ''
              "
              >{{ props.item.status ? props.item.status.label : '' }}</v-btn
            >
            <v-tooltip
              v-if="
                props.item.status.value ===
                FEED_IN_STATUSES.critical_created.value
              "
              top
              color="primary"
              max-width="150px"
            >
              <template v-slot:activator="{ on, attrs }">
                <div class="ml-4 flex-center-align" v-bind="attrs" v-on="on">
                  <component :is="IconAusrufungszeichen" />
                </div>
              </template>
              <span>{{ $t('feed_in_systems.active_order_exists') }}</span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:[`item.edit`]="props">
          <v-btn icon @click="showDetails(props.item.orderId)">
            <v-icon>mdi-pencil-box-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { getPhotovoltaicsWithFilter } from '@/services/FeedInSystemsService';
import { getFeedInSystemsTableConfig } from './photovoltaicOrdersTableConfig';
import { tableOrderMapper } from './transform_raw_data_to_orders_table_format';
import {
  PillRadioButtons,
  allIcons,
  PeriodDatePicker,
} from '@newnetzportal/netzportal-webapp-storybook-components';
import {
  FEED_IN_STATUSES,
  CANCELLED_PSEUDO_STATUS,
} from '../../helpers/constants';

export default {
  name: 'FeedInSystemsOverviewTable',
  components: {
    PillRadioButtons,
    PeriodDatePicker,
  },
  created() {
    this.loading = true;
    this.updateMixAndMaxPerformance(this.pVPerformanceSelection);
    this.getPhotovoltaicsWithFilter();
  },
  data() {
    return {
      timeoutId: null,
      DateFiltersReady: false,
      filterStartDate: null,
      filterEndDate: null,
      // filterStartDate: '2024-07-08',
      // filterEndDate: '2024-07-10',
      filterJourney: this.$route.query.journey
        ? this.$route.query.journey
        : 'all',
      statusSelection: this.$route.query.status
        ? this.$route.query.status
        : 'all',
      pVPerformanceSelection: this.$route.query.performance
        ? this.$route.query.performance
        : 'all',
      activeOrdersDisplayed: [],
      currentPage: this.$route.query.page
        ? parseInt(this.$route.query.page)
        : 1,
      loading: false,
      options: {},
      searchValue: '',
      filteredActiveOrdersCount: 0,
      statusValuesList: getFeedInSystemsTableConfig('statusValuesList'),
      performanceValuesList: getFeedInSystemsTableConfig(
        'performanceValuesList'
      ),
      headersAktive: getFeedInSystemsTableConfig('headersAktive'),
      isDataLoaded: false,
      FEED_IN_STATUSES: FEED_IN_STATUSES,
    };
  },
  computed: {
    dateFilters() {
      return {
        start: this.filterStartDate,
        end: this.filterEndDate,
      };
    },
    computedOrderFilters() {
      // we do not want the user to distinguish between the 2 subtypes of the type "Auftrag abgelehnt".
      const targets = [
        FEED_IN_STATUSES.declined.value,
        FEED_IN_STATUSES.protocol_cancelled.value,
      ];
      const filteredStatuses = this.statusValuesList.filter(
        (status) => !targets.includes(status.value)
      );
      return [...filteredStatuses, CANCELLED_PSEUDO_STATUS];
    },
    journeyFilter() {
      return this.$store.state.orders.journeyFilter;
    },
    minPVSystemPowerW: {
      get() {
        return this.$store.getters.getMinPVSystemPowerWFilter;
      },
      set(value) {
        this.$store.commit('setMinPVSystemPowerWFilter', value);
      },
    },
    maxPVSystemPowerW: {
      get() {
        return this.$store.getters.getMaxPVSystemPowerWFilter;
      },
      set(value) {
        this.$store.commit('setMaxPVSystemPowerWFilter', value);
      },
    },
    IconAusrufungszeichen() {
      return allIcons.IconAusrufungszeichen;
    },
  },
  watch: {
    journeyFilter(newValue) {
      this.currentPage = 1;
      if (newValue !== 'alle') {
        this.filterJourney = newValue;
      } else {
        this.filterJourney = '';
      }
      this.updateURLParams({ name: 'journey', value: this.filterJourney });
      this.getPhotovoltaicsWithFilter();
    },
    dateFilters() {
      this.getPhotovoltaicsWithFilter();
    },
    $route(to) {
      if (to.fullPath === '/einspeiseanlagen') {
        this.refreshOrders();
      }
    },
    statusSelection(newValue) {
      this.currentPage = 1;
      if (newValue !== 'all') {
        this.statusSelection = newValue;
      }
      this.$store.commit('setFeedInSystemStatusFilter', newValue);
      this.updateURLParams({ name: 'status', value: newValue });
      this.getPhotovoltaicsWithFilter();
    },
    pVPerformanceSelection(newValue) {
      this.currentPage = 1;
      if (newValue !== 'all') {
        this.pVPerformanceSelection = newValue;
      }
      this.updateMixAndMaxPerformance(newValue);
      this.updateURLParams({ name: 'performance', value: newValue });
      this.getPhotovoltaicsWithFilter();
    },
    options: {
      handler(event) {
        this.updateURLParams({ name: 'page', value: event.page });
        this.currentPage = event.page;
        this.getPhotovoltaicsWithFilter();
      },
      deep: true,
    },
  },
  methods: {
    onDateChange(values) {
      this.filterStartDate = values.startDate;
      this.filterEndDate = values.endDate;
    },
    updateURLParams(param) {
      const params = this.$route.query;
      params[param.name] = param.value;
      history.replaceState(
        {},
        null,
        `${this.$route.path}?${new URLSearchParams(params)}`
      );
    },
    refreshOrders() {
      const params = this.$route.query;
      params.page = 1;
      params.status = '';
      params.journey = '';
      params.performance = '';
      history.replaceState(
        {},
        null,
        `${this.$route.path}?${new URLSearchParams(params)}`
      );
      this.statusSelection = '';
      this.pVPerformanceSelection = '';
      this.filterJourney = '';
      this.currentPage = 1;
      this.getPhotovoltaicsWithFilter();
    },
    elasticSearchOnBackend(value) {
      if (value.length === 0 || value.length > 2) {
        this.currentPage = 1;
        this.getPhotovoltaicsWithFilter();
      }
    },
    getPhotovoltaicsWithFilter(search_word) {
      let order_status = [];
      if (this.statusSelection && this.statusSelection !== 'all') {
        if (this.statusSelection === CANCELLED_PSEUDO_STATUS.value) {
          order_status = order_status.concat([
            FEED_IN_STATUSES.declined.value,
            FEED_IN_STATUSES.protocol_cancelled.value,
          ]);
        } else {
          order_status.push(this.statusSelection);
        }
      }
      const params = {
        limit: 10,
        order_status,
        journey:
          !this.filterJourney || this.filterJourney === 'all'
            ? ''
            : this.filterJourney,
        from_index: (this.currentPage - 1) * 10,
        search_word: search_word ? search_word : this.searchValue,
        min_pv_system_power_W:
          this.pVPerformanceSelection === 'all' ? null : this.minPVSystemPowerW,
        max_pv_system_power_W:
          this.pVPerformanceSelection === 'all' ? null : this.maxPVSystemPowerW,
        start_date: this.filterStartDate,
        end_date: this.filterEndDate,
      };
      this.loading = true;
      getPhotovoltaicsWithFilter(params)
        .then((res) => {
          if (res.getPhotovoltaicsWithFilter) {
            this.filteredActiveOrdersCount =
              res.getPhotovoltaicsWithFilter.count_orders;
            const ordersWithStatusConfig = this.addStatusConfigForEachOrder(
              res.getPhotovoltaicsWithFilter.items
            );
            this.activeOrdersDisplayed =
              ordersWithStatusConfig.map(tableOrderMapper);
            this.loading = false;
            this.isDataLoaded = true;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.isDataLoaded = false;
          this.activeOrdersDisplayed = [];
          console.log(err);
        });
    },
    showDetails(orderId) {
      this.$store.commit('deleteSelectedFeedInSystem');
      this.$router.push({
        name: 'FeedInSystemsDetailsPage',
        params: { id: orderId },
      });
    },
    addStatusConfigForEachOrder(ordersArray) {
      ordersArray.forEach((item) => {
        item.status = this.statusValuesList.find(
          (status) => status.value === item.status
        );
      });
      // just for test purposes
      /*       ordersArray[0].status = {
        value: 'CRITICAL_ORDER_CREATED',
        label: 'Neu, mit besonderer Beachtung',
        color: 'red',
        textColor: 'white',
      }; */

      return ordersArray;
    },
    updateMixAndMaxPerformance(value) {
      switch (value) {
        case 'LESS_THAN_25':
          this.minPVSystemPowerW = null;
          this.maxPVSystemPowerW = 24999;
          break;
        case '25_TO_100':
          this.minPVSystemPowerW = 25000;
          this.maxPVSystemPowerW = 100000;
          break;
        case 'MORE_THAN_100':
          this.minPVSystemPowerW = 100001;
          this.maxPVSystemPowerW = null;
          break;
        default:
          this.minPVSystemPowerW = null;
          this.maxPVSystemPowerW = null;
          break;
      }
    },
  },
};
</script>

<style scoped>
.flex-row-status {
  display: flex;
  flex-direction: row;
}
.flex-center-align {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
