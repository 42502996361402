export const feedInSystemsMarketDataRegisterModule = {
  state: {},
  mutations: {
    setMarketRegisterNumberEEG(state, EEG_number) {
      this.state.changed_object.maStR.EEG_number = EEG_number;
    },
    setMarketRegisterNumberSEE(state, storage_number) {
      this.state.changed_object.maStR.storage_number = storage_number;
    },
    setEEGCommissioningDate(state, EEG_implementing_date) {
      this.state.changed_object.maStR.EEG_implementing_date =
        EEG_implementing_date;
    },
    setMarketRegistrationDate(state, register_date) {
      this.state.changed_object.maStR.register_date = register_date;
    },
  },
  getters: {
    getMarketRegisterNumberEEG: (state, getters, rootState) => {
      return rootState.changed_object.maStR?.EEG_number || '';
    },
    getMarketRegisterNumberSEE: (state, getters, rootState) => {
      return rootState.changed_object.maStR?.storage_number || '';
    },
    getEEGCommissioningDate: (state, getters, rootState) => {
      return rootState.changed_object.maStR?.EEG_implementing_date || '';
    },
    getMarketRegistrationDate: (state, getters, rootState) => {
      return rootState.changed_object.maStR?.register_date || '';
    },
  },
};
