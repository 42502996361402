<template>
  <component
    :is="component"
    v-if="component"
    v-bind="componentProperties"
    :index="index"
  />
</template>

<script>
export default {
  name: 'DynamicComponent',
  props: ['type', 'index', 'componentProperties'],
  data() {
    return {
      component: null,
    };
  },
  computed: {
    loader() {
      return () => import(`./${this.type}`);
    },
  },
  mounted() {
    this.loader()
      .then(() => {
        this.component = () => this.loader();
      })
      .catch((err) => {
        console.log(err);
        // TODO give feedback to user
      });
  },
};
</script>

<style scoped>
.component {
  width: 100%;
}
</style>
