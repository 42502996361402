export const constructionWorkModule = {
  state: {
    regional_team_short: '',
  },
  mutations: {
    setDateUnit(state, dateUnit) {
      this.state.changed_object.date_unit = dateUnit;
    },
    setAppointmentDate(state, appointment_for_connection) {
      this.state.changed_object.appointment_for_connection =
        appointment_for_connection;
    },
    setImplementingDate(state, implementing_date) {
      this.state.changed_object.implementing_date = implementing_date;
    },
    setRegionalTeam(state, selectedTeam) {
      this.state.changed_object.internal_construction_management.regional_team =
        selectedTeam;
    },
    setConstructionManager(state, selectedManager) {
      this.state.changed_object.internal_construction_management.construction_manager =
        selectedManager;
    },
    setConstructionSupervisor(state, selectedSupervisor) {
      this.state.changed_object.internal_construction_management.construction_supervision =
        selectedSupervisor;
    },
    setInternalConstructionManagementStartHour(
      state,
      internalConstructionManagementStartHour
    ) {
      this.state.changed_object.internal_construction_management.construction_planned_date.start_time.start_hour =
        internalConstructionManagementStartHour;
    },
    setInternalConstructionManagementEndHour(
      state,
      internalConstructionManagementEndHour
    ) {
      this.state.changed_object.internal_construction_management.construction_planned_date.end_time.end_hour =
        internalConstructionManagementEndHour;
    },
    setInternalConstructionManagementStartMinute(
      state,
      internalConstructionManagementStartMinute
    ) {
      this.state.changed_object.internal_construction_management.construction_planned_date.start_time.start_minute =
        internalConstructionManagementStartMinute;
    },
    setInternalConstructionManagementEndMinute(
      state,
      internalConstructionManagementEndMinute
    ) {
      this.state.changed_object.internal_construction_management.construction_planned_date.end_time.end_minute =
        internalConstructionManagementEndMinute;
    },
    setInternalConstructionManagementDates(
      state,
      internalConstructionManagementDates
    ) {
      this.state.changed_object.internal_construction_management.construction_planned_date.dates =
        internalConstructionManagementDates;
    },
    setGroundworkStartHour(state, groundworkStartHour) {
      this.state.changed_object.groundwork_company.construction_planned_date.start_time.start_hour =
        groundworkStartHour;
    },
    setGroundworkEndHour(state, groundworkEndHour) {
      this.state.changed_object.groundwork_company.construction_planned_date.end_time.end_hour =
        groundworkEndHour;
    },
    setGroundworkStartMinute(state, groundworkStartMinute) {
      this.state.changed_object.groundwork_company.construction_planned_date.start_time.start_minute =
        groundworkStartMinute;
    },
    setGroundworkEndMinute(state, groundworkEndMinute) {
      this.state.changed_object.groundwork_company.construction_planned_date.end_time.end_minute =
        groundworkEndMinute;
    },
    setGroundworkDates(state, groundworkDates) {
      this.state.changed_object.groundwork_company.construction_planned_date.dates =
        groundworkDates;
    },
    setGroundWorkByClient(state, groundworkByClient) {
      this.state.changed_object.groundwork_company.groundwork_by_client =
        groundworkByClient;
    },
    setTiefbau(state, tiefbau) {
      this.state.changed_object.groundwork_company.company_name = tiefbau;
    },
    setTiefbauContactPerson(state, tiefbauContactPerson) {
      this.state.changed_object.groundwork_company.contact_person =
        tiefbauContactPerson;
    },
    setMontageStrom(state, montageStrom) {
      this.state.changed_object.assembly_electricity_company.company_name =
        montageStrom;
    },
    setMontageStromStartHour(state, montageStromStartHour) {
      this.state.changed_object.assembly_electricity_company.construction_planned_date.start_time.start_hour =
        montageStromStartHour;
    },
    setMontageStromEndHour(state, montageStromEndHour) {
      this.state.changed_object.assembly_electricity_company.construction_planned_date.end_time.end_hour =
        montageStromEndHour;
    },
    setMontageStromStartMinute(state, montageStromStartMinute) {
      this.state.changed_object.assembly_electricity_company.construction_planned_date.start_time.start_minute =
        montageStromStartMinute;
    },
    setMontageStromEndMinute(state, montageStromEndMinute) {
      this.state.changed_object.assembly_electricity_company.construction_planned_date.end_time.end_minute =
        montageStromEndMinute;
    },
    setMontageStromDates(state, montageStromDates) {
      this.state.changed_object.assembly_electricity_company.construction_planned_date.dates =
        montageStromDates;
    },
    setMontageStromContactPerson(state, montageStromContactPerson) {
      this.state.changed_object.assembly_electricity_company.contact_person =
        montageStromContactPerson;
    },
    setMontageGasWasser(state, montageGasWasser) {
      this.state.changed_object.assembly_gas_water_company.company_name =
        montageGasWasser;
    },
    setMontageGasWasserContactPerson(state, montageGasWasserContactPerson) {
      this.state.changed_object.assembly_gas_water_company.contact_person =
        montageGasWasserContactPerson;
    },
    setMontageGasWasserStartHour(state, montageGasWasserStartHour) {
      this.state.changed_object.assembly_gas_water_company.construction_planned_date.start_time.start_hour =
        montageGasWasserStartHour;
    },
    setMontageGasWasserEndHour(state, montageGasWasserEndHour) {
      this.state.changed_object.assembly_gas_water_company.construction_planned_date.end_time.end_hour =
        montageGasWasserEndHour;
    },
    setMontageGasWasserStartMinute(state, montageGasWasserStartMinute) {
      this.state.changed_object.assembly_gas_water_company.construction_planned_date.start_time.start_minute =
        montageGasWasserStartMinute;
    },
    setMontageGasWasserEndMinute(state, montageGasWasserEndMinute) {
      this.state.changed_object.assembly_gas_water_company.construction_planned_date.end_time.end_minute =
        montageGasWasserEndMinute;
    },
    setMontageGasWasserDates(state, montageGasWasserDates) {
      this.state.changed_object.assembly_gas_water_company.construction_planned_date.dates =
        montageGasWasserDates;
    },
    setMontage(state, montage) {
      this.state.changed_object.montage.company_name = montage;
    },
    setMontageContactPerson(state, montageContactPerson) {
      this.state.changed_object.montage.contact_person = montageContactPerson;
    },
    setRegionalTeamChangedObject(state, changedData) {
      this.state.changed_object.internal_construction_management
        ? ''
        : (this.state.changed_object.internal_construction_management = {});
      this.state.changed_object.internal_construction_management.regional_team =
        changedData.regional_team;
    },
    setRegionalTeamOldValueObject(state, old_value_object) {
      this.state.old_value_object.internal_construction_management.regional_team =
        old_value_object.regional_team;
    },
    setRegionalTeamShort(state, team_short) {
      state.regional_team_short = team_short;
    },
  },
  getters: {
    getRegionalTeamChangedObject: (state, getters, rootState) => {
      return rootState.changed_object.internal_construction_management
        ? rootState.changed_object.internal_construction_management
            .regional_team
        : null;
    },
    getRegionalTeamShort: (state) => {
      return state.regional_team_short;
    },
  },
};
