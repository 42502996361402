<template>
  <div>
    <div class="pipeline-background">
      <NEWPipelineComponent :statuses="other_status" />
      <NEWPipelineComponent :statuses="status_all" />
    </div>
    <installers-overview-table />
  </div>
</template>

<script>
import InstallersOverviewTable from './InstallersOverviewTable';
import { NEWPipelineComponent } from '@newnetzportal/netzportal-webapp-storybook-components';
import { getInstallersSummary } from '../../services/InstallerService';

export default {
  name: 'InstallersOverviewComponent',
  components: {
    InstallersOverviewTable,
    NEWPipelineComponent,
  },
  created() {
    this.getInstallersSummaryFromBackend();
  },
  data() {
    return {
      installersSummary: {},
    };
  },
  computed: {
    status_all() {
      return [
        {
          name: 'alle',
          color: '#999999',
          amount: this.installersSummary.count_installers,
          percentage: 100,
        },
      ];
    },
    other_status() {
      return [
        {
          name: 'neu',
          color: '#d79bb8',
          amount: this.installersSummary.license_request_created,
          percentage:
            (this.installersSummary.license_request_created /
              this.installersSummary.count_installers) *
            100,
        },
        {
          name: 'in Prüfung',
          color: '#CB80A2',
          amount: this.installersSummary.license_request_checking,
          percentage:
            (this.installersSummary.license_request_checking /
              this.installersSummary.count_installers) *
            100,
        },
        {
          name: 'aktiv',
          color: '#a72f62',
          amount: this.installersSummary.license_request_active,
          percentage:
            (this.installersSummary.license_request_active /
              this.installersSummary.count_installers) *
            100,
        },
        {
          name: 'aktiv, erneut prüfen',
          color: '#a72f62',
          amount: this.installersSummary.license_request_active_recheck,
          percentage:
            (this.installersSummary.license_request_active_recheck /
              this.installersSummary.count_installers) *
            100,
        },
        {
          name: 'inaktiv',
          color: '#767676',
          amount: this.installersSummary.license_request_expired,
          percentage:
            (this.installersSummary.license_request_expired /
              this.installersSummary.count_installers) *
            100,
        },
        {
          name: 'Prüfung abgelehnt',
          color: '#525252',
          amount: this.installersSummary.license_request_refused,
          percentage:
            (this.installersSummary.license_request_refused /
              this.installersSummary.count_installers) *
            100,
        },
      ];
    },
  },
  methods: {
    getInstallersSummaryFromBackend() {
      getInstallersSummary()
        .then((res) => {
          this.installersSummary = res.getInstallersSummary;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
