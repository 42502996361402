<template>
  <div>
    <!-- Menu - small screen -->
    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
      class="d-flex"
      v-if="showMenu && ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)"
    >
      <div class="ma-2 d-flex justify-end">
        <v-btn icon @click="drawer = false">
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </div>

      <div class="flex-column pl-8 mt-8">
        <nav-link to="/" title="Dashboard" />
      </div>
      <v-divider class="my-8" />
      <div class="flex-column pl-8">
        <p>Impressum</p>
        <p>AGBs</p>
      </div>
    </v-navigation-drawer>

    <v-card tile class="d-flex justify-space-between" :class="navBarStyle">
      <!-- Menu Button - small screen -->
      <div
        class="d-flex justify-start"
        v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
      >
        <menu-button
          @click.stop="drawer = !drawer"
          class="align-self-center"
          :large="$vuetify.breakpoint.sm"
        />
      </div>

      <nav-logo />

      <div class="d-flex justify-end">
        <!-- Menu - M && L screen -->
        <nav-menu
          v-if="
            showMenu &&
            ($vuetify.breakpoint.md ||
              $vuetify.breakpoint.lg ||
              $vuetify.breakpoint.xl)
          "
        />

        <div
          v-if="userGroups.includes('dev-team')"
          style="
            display: inline-block;
            margin: auto;
            text-align: center;
            color: white;
            font-weight: bold;
            font-size: 70%;
            padding: 5px;
            background: rgb(155, 0, 75);
            border-radius: 5px;
          "
          title="Sie sind als Entwickler eingeloggt."
        >
          DEV-Team<br />Member
        </div>

        <!-- Logout button -->
        <v-btn
          class="navbar-logout-button"
          v-if="showMenu || (showLogout && isUserSignedIn)"
          icon
        >
          <v-icon @click="cognitoLogout" class="ml-4">mdi-logout</v-icon>
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import NavLink from './NavLink';
import MenuButton from './MenuButton';
import NavMenu from './NavMenu';
import NavLogo from './NavLogo';
import auth from '../../cognito-auth/cognitoAuth';

export default {
  name: 'NavBar',
  data() {
    return {
      drawer: false,
    };
  },
  components: {
    NavMenu,
    MenuButton,
    NavLink,
    NavLogo,
  },
  computed: {
    showMenu() {
      return this.$route.name !== 'login' && this.$route.name !== 'error';
    },
    showLogout() {
      return this.$route.name === 'error';
    },
    isUserSignedIn() {
      return auth.auth.isUserSignedIn();
    },
    navBarStyle() {
      if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.xl) {
        return 'nav-bar-style px-12';
      } else if (this.$vuetify.breakpoint.sm) {
        return 'nav-bar-style_sm px-4';
      } else if (this.$vuetify.breakpoint.lg) {
        return 'nav-bar-style_lg';
      } else {
        return 'nav-bar-style_xs px-2';
      }
    },
    userGroups() {
      return this.$store.getters.getUserGroups;
    },
  },
  methods: {
    cognitoLogout() {
      auth.logout();
    },
  },
};
</script>

<style scoped>
.nav-bar-style_xs {
  height: 56px;
}
.nav-bar-style_sm {
  height: 64px;
}
.nav-bar-style {
  height: 80px;
}
.nav-bar-style_lg {
  height: 80px;
  padding-right: 80px;
  padding-left: 80px;
}

.navbar-logout-button {
  display: flex;
  align-self: center;
  margin-left: 20px;
}

.active-class {
  border-style: none none solid none;
  border-radius: 0;
  border-color: #9b004b;
  background-color: transparent;
  color: #9b004b !important;
}

.active-class:before {
  opacity: 0 !important;
}
</style>
