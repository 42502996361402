<template>
  <div class="mr-6 mt-1">
    <div class="container_title">
      <slot name="icon"></slot>
      <div class="container_icon"></div>
      <div class="mt-1 ml-1">
        {{ title }}
      </div>
    </div>
    <div class="nested-div">
      <div class="container-company-data">
        <div style="display: flex">
          <slot name="icon2"></slot>
          <div
            v-if="companyName"
            :class="
              secondIcon ? 'company_name ml-2 mt-2' : 'company_name ml-11'
            "
          >
            {{ companyName ? companyName : '' }}
          </div>
        </div>
        <div :class="secondIcon ? 'mt-2 ml-11' : 'mt-4 ml-11'">
          {{ contactPerson }}
        </div>
      </div>

      <div class="container_dates">
        <v-icon class="mb-12" medium> mdi-calendar </v-icon>
        <div class="mr-2 mb-4 ml-1">
          {{ $t('construction_work_dashboard.start_date') }}
          <div class="container_date">
            <div class="mr-1">
              {{ formatDate(constructionDate.dates[0]) }}
            </div>
            <div>
              {{ constructionDate.start_time.start_hour }}:{{
                constructionDate.start_time.start_minute
              }}
              Uhr
            </div>
          </div>
        </div>
        <div>
          {{ $t('construction_work_dashboard.end_date') }}
          <div class="container_date">
            <div class="mr-1">
              {{
                constructionDate.dates[1]
                  ? formatDate(constructionDate.dates[1])
                  : formatDate(constructionDate.dates[0])
              }}
            </div>
            <div class="mr-1">
              {{ constructionDate.end_time.end_hour }}:{{
                constructionDate.end_time.end_minute
              }}
              Uhr
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConstructionDashboardDataComponent',
  props: {
    title: {
      type: String,
      required: true,
    },
    companyName: {
      type: String,
      required: false,
    },
    contactPerson: {
      type: String,
      required: true,
    },
    constructionDate: {
      type: Object,
      required: true,
    },
    secondIcon: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    selectedOrder() {
      return this.$store.getters.getSelectedOrder;
    },
  },
  methods: {
    formatDate(date) {
      return this.$dayjs(date).format('DD. MM. YYYY');
    },
  },
};
</script>

<style>
.container_title {
  display: flex;
}
.container_dates {
  display: flex;
  flex-direction: row;
  margin-top: 25px;
}
.container_date {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}
.company_name {
  width: 70%;
}

.container-company-data {
  min-height: 88px;
}

@media screen and (max-width: 1024px) {
  .nested-div {
    display: flex;
    min-height: 56px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .container_dates {
    width: 50%;
    margin-top: 0px;
  }

  .container-company-data {
    width: 50%;
  }
}
</style>
