<template>
  <div>
    <h1>Upcoming dates</h1>
  </div>
</template>

<script>
export default {
  props: ['events'],
  name: 'list-component',
  created: function () {
    console.log(this.events);
  },
};
</script>
