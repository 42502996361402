import i18n from '../../plugins/i18n';
import { FEED_IN_STATUSES } from '../../helpers/constants';

const configurations = {
  statusValuesList: getStatusesConfig(true),
  performanceValuesList: [
    { label: 'alle', value: 'all', color: '#999999' },
    {
      label: i18n.t('feed_in_systems.less_than_25'),
      value: 'LESS_THAN_25',
      color: '#1D1B4F',
    },
    {
      label: i18n.t('feed_in_systems.25_to_100'),
      value: '25_TO_100',
      color: '#172D57',
    },
    {
      label: i18n.t('feed_in_systems.more_than_100'),
      value: 'MORE_THAN_100',
      color: '#6195BA',
    },
  ],
  headersAktive: [
    { text: i18n.t('pv_table_headers.date'), value: 'date' },
    { text: i18n.t('pv_table_headers.applicant'), value: 'applicant' },
    {
      text: i18n.t('pv_table_headers.product'),
      value: 'journey',
    },
    {
      text: i18n.t('pv_table_headers.plant_operator'),
      value: 'plant_operator',
    },
    { text: i18n.t('pv_table_headers.connection_address'), value: 'address' },
    {
      text: i18n.t('pv_table_headers.last_edited_from'),
      value: 'lastChangedBy',
    },
    {
      text: i18n.t('pv_table_headers.status'),
      value: 'status',
      filterable: true,
      filter: (value) =>
        this.statusSelection === 'all' || value === this.statusSelection,
    },
    { value: 'edit' },
  ],
  countOrderStatuses: getStatusesConfig(false),
};

export const getFeedInSystemsTableConfig = (configName) => {
  return JSON.parse(JSON.stringify(configurations[configName]));
};

function getStatusesConfig(isAllItemNeeded) {
  let statusesArray = [];
  statusesArray = Object.keys(FEED_IN_STATUSES).map((key) => {
    const { label, value, color, textColor } = FEED_IN_STATUSES[key];
    return { label, value, color, textColor };
  });
  statusesArray.forEach((item) => {
    item.label = i18n.t(`feed_in_statuses.${item.value}`);
  });
  if (isAllItemNeeded) {
    statusesArray.unshift({ label: 'alle', value: 'all', color: '#999999' });
  }
  return statusesArray;
}
