export const emobilityApplicantModule = {
  state: {
    applicant_address_zip_from_google: undefined,
  },
  mutations: {
    setEmobilityApplicantTitle(state, title) {
      this.state.changed_object.applicant.title = title;
    },
    setEmobilityApplicantName(state, name) {
      this.state.changed_object.applicant.name = name;
    },
    setEmobilityApplicantSurname(state, surname) {
      this.state.changed_object.applicant.surname = surname;
    },
    setEmobilityApplicantCompanyName(state, company_name) {
      this.state.changed_object.applicant.company_name = company_name;
    },
    setEmobilityApplicantStreet(state, street) {
      this.state.changed_object.applicant.address.street = street;
    },
    setEmobilityApplicantStreetNumber(state, street_number) {
      this.state.changed_object.applicant.address.street_number = street_number;
    },
    setEmobilityApplicantZip(state, zip) {
      this.state.changed_object.applicant.address.zip = zip;
    },
    setEmobilityApplicantCity(state, city) {
      this.state.changed_object.applicant.address.city = city;
    },
    setEmobilityApplicantCountry(state, country) {
      this.state.changed_object.applicant.address.country = country;
    },
    setEmobilityApplicantChangedObject(state, object) {
      this.state.changed_object.applicant[object.key] = object.value;
    },
    setEmobilityApplicantAddressChangedObject(state, changedData) {
      this.state.changed_object.applicant.address[changedData.key] =
        changedData.value;
    },
    set_form_emobility_applicant_address_zip_from_google(state, value) {
      state.applicant_address_zip_from_google = value;
    },
  },
  getters: {
    get_form_emobility_applicant_address_zip_from_google: (state) => {
      return state.applicant_address_zip_from_google;
    },
  },
  actions: {},
};
