<template>
  <div>
    <selection-card
      :title="$t('calendar_side_selection_component.montage_electricity')"
    >
      <template v-slot:icon>
        <montage-strom-icon class="mr-2" />
      </template>
      <v-autocomplete
        :attach="true"
        outlined
        dense
        :label="$t('construction_work.company_name')"
        clearable
        return-object
        :items="filteredCompanies"
        v-model.lazy="selectedCompany"
        :item-text="companyText"
        class="ml-2"
        :error-messages="assemblyElectricityCompanyError"
        item-value="company_name"
      >
        <template v-slot:no-data>
          <div
            class="pl-3"
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            "
          >
            {{ $t('construction_work.no_data_text') }}
          </div>
        </template>
      </v-autocomplete>
      <v-autocomplete
        v-if="selectedCompany"
        :attach="true"
        dense
        outlined
        color="#9B004B"
        :label="$t('construction_work.contact_person')"
        clearable
        :items="companyContactPersons"
        v-model.lazy="selectedContactPerson"
        class="ml-2"
        :item-text="contactPersonText"
        item-value="name"
        :error-messages="assemblyElectricityContactPersonError"
        return-object
      >
        <template v-slot:no-data>
          <div
            class="pl-3"
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            "
          >
            {{ $t('construction_work.no_data_text') }}
          </div>
        </template>
      </v-autocomplete>
      <div class="ml-3">
        <date-time-component
          @save-hours-minutes="saveDate($event)"
          @save-dates="saveDate($event)"
          v-model="valueItem.construction_planned_date"
          :v="v.construction_planned_date"
        >
        </date-time-component>
      </div>
    </selection-card>
  </div>
</template>

<script>
import DateTimeComponent from './DateTimeComponent';
import SelectionCard from './SelectionCard';
import MontageStromIcon from '../../icons/MontageStromIcon';

export default {
  name: 'AssemblyElectricityComponent',
  components: {
    MontageStromIcon,
    SelectionCard,
    DateTimeComponent,
  },
  props: {
    companies: {
      type: Array,
      required: true,
    },
    v: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      valueItem: {},
      companyContactPersons: [],
      filteredCompanies: [],
    };
  },
  created() {
    this.valueItem = { ...this.value };
  },
  mounted() {
    this.filterCompanyByRegionalTeam();
  },
  methods: {
    saveDate(object) {
      this.$store.commit('setMontageStrom' + object.key, object.value);
    },
    contactPersonText(value) {
      return value.name + ', ' + value.function;
    },
    companyText(value) {
      return (
        value.company_name +
        ', ' +
        value.street +
        ' ' +
        value.street_number +
        ', ' +
        value.zip +
        ' ' +
        value.city
      );
    },
    filterCompanyByRegionalTeam() {
      this.filteredCompanies = [];
      const selectedRegionalTeam = this.selectedRegionalTeam;
      this.companies.forEach(async (company) => {
        const isContactPersonForRegionalTeam =
          await company.contact_persons.some(function (contactPerson) {
            return contactPerson.regions.includes(selectedRegionalTeam);
          });
        if (isContactPersonForRegionalTeam) {
          this.filteredCompanies.push(company);
        }
      });
    },
    filterPersonsByRegionalTeamAndCompanyType(contactPersons) {
      const selectedRegionalTeam = this.selectedRegionalTeam;
      return contactPersons.filter(function (el) {
        return (
          el.regions.includes(selectedRegionalTeam) && el.assembly_electricity
        );
      });
    },
    setContactPersonList() {
      const selectedCompanyObject = this.filteredCompanies.find(
        (company) => company.company_name === this.selectedCompany
      );
      if (selectedCompanyObject) {
        this.companyContactPersons =
          this.filterPersonsByRegionalTeamAndCompanyType(
            selectedCompanyObject.contact_persons
          );
      }
    },
  },
  watch: {
    selectedRegionalTeam(newVal) {
      if (newVal) {
        this.filterCompanyByRegionalTeam();
      }
    },
    companies() {
      this.filterCompanyByRegionalTeam();
    },
    filteredCompanies(newValue) {
      if (newValue && this.selectedCompany) {
        this.setContactPersonList();
      }
    },
  },
  computed: {
    selectedRegionalTeam() {
      return this.$store.getters.getRegionalTeamShort;
    },
    assemblyElectricityCompanyError() {
      const errors = [];
      if (!this.v.company_name.$dirty) return errors;
      !this.v.company_name.required && errors.push('Bitte Firma auswählen');
      return errors;
    },
    assemblyElectricityContactPersonError() {
      const errors = [];
      if (!this.v.contact_person.$dirty) return errors;
      !this.v.contact_person.required &&
        errors.push('Bitte Ansprechpartner auswählen');
      return errors;
    },
    selectedCompany: {
      get() {
        return this.valueItem.company_name;
      },
      set(newselectedMontageStrom) {
        if (newselectedMontageStrom) {
          this.valueItem.company_name = newselectedMontageStrom.company_name;
          this.companyContactPersons =
            this.filterPersonsByRegionalTeamAndCompanyType(
              newselectedMontageStrom.contact_persons
            );
          this.$store.commit(
            'setMontageStrom',
            newselectedMontageStrom.company_name
          );
        } else {
          this.valueItem.company_name = null;
          this.$store.commit('setMontageStrom', null);
        }
        this.montageStromContactPerson = null;
      },
    },
    selectedContactPerson: {
      get() {
        return this.valueItem.contact_person;
      },
      set(newmontagestromContactPerson) {
        const contactPersonName = newmontagestromContactPerson
          ? newmontagestromContactPerson.name
          : '';
        this.valueItem.contact_person = contactPersonName;
        this.$store.commit('setMontageStromContactPerson', contactPersonName);
      },
    },
  },
};
</script>
