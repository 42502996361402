import axios from 'axios';

const VUE_APP_LOGGER_URL = process.env.VUE_APP_LOGGER_URL;
const VUE_APP_LOGGER_AUTH = process.env.VUE_APP_LOGGER_AUTH;

let loggerInstance = null;

export function setLoggerInstance(logger) {
  loggerInstance = logger;
}

function rewriteError(message) {
  if (message instanceof Error) {
    const result = {};
    const rewriter = function (source, target, key) {
      if (key in source) {
        target[key.charAt(0).toUpperCase() + key.slice(1)] = source[key];
      }
    };

    rewriter(message, result, 'name');
    rewriter(message, result, 'message');
    rewriter(message, result, 'description');
    rewriter(message, result, 'stack');
    rewriter(message, result, 'fileName');
    rewriter(message, result, 'lineNumber');
    return result;
  }
  return { message };
}

async function log(logLevel, message) {
  if (!loggerInstance) {
    throw new Error(
      'Logger is not available. Ensure that the loggerInstance plugin is properly registered.'
    );
  }
  if (!(VUE_APP_LOGGER_URL && VUE_APP_LOGGER_AUTH)) {
    loggerInstance.warn(
      'Backend logging is disabled, required env settings are missing.'
    );
    loggerInstance[logLevel](message);
    return;
  }

  if (
    location.href.startsWith('http://localhost') ||
    location.href.startsWith('https://dev.')
  ) {
    loggerInstance.warn(
      'Backend logging is disabled, running on dev stage or on localhost.'
    );
    loggerInstance[logLevel](message);
    return;
  }

  try {
    await axios({
      method: 'POST',
      url: `${VUE_APP_LOGGER_URL}/${logLevel}/`,
      data: rewriteError(message),
      headers: {
        Authorization: VUE_APP_LOGGER_AUTH,
        'Content-Type': 'application/json',
      },
    });
  } catch (error) {
    loggerInstance.error('Failed to log to the backend:', error);
  }
}

export const loggerService = {
  debug: (...args) => log('debug', ...args),
  info: (...args) => log('info', ...args),
  warn: (...args) => log('warn', ...args),
  error: (...args) => log('error', ...args),
};
