<template>
  <div>
    <div class="scheduling_note">
      <note-icon class="note_icon" />
      <textarea v-model="note" class="note_text" placeholder="Notiz">
      </textarea>
    </div>
    <div class="container_buttons">
      <div v-if="showErrors" class="error_message">
        Bitte bestätigen Sie die obigen Angaben
      </div>
      <div class="footer_scheduling">
        <button class="button_save" @click="$emit('save-data')">
          <v-progress-circular
            v-if="showLoading"
            indeterminate
            color="white"
            class="mr-4"
          ></v-progress-circular>
          Termin bestätigen
        </button>
        <button class="button_cancel" @click="$emit('save-current-data')">
          Zwischenstand speichern
        </button>
        <button class="button_cancel">abbrechen</button>
        <v-alert
          class="alert_success ml-4"
          max-width="270px"
          max-height="35px"
          text
          transition="scale-transition"
          v-if="alert"
        >
          <template v-slot:append>
            <v-icon color="green" class="ml-2">mdi-check</v-icon>
          </template>
          Erfolgreich gespeichert
        </v-alert>
        <v-alert
          type="error"
          class="alert_error ml-4"
          max-width="270px"
          v-if="alertError"
          text
          transition="scale-transition"
        >
          Speicherung fehlgeschlagen
        </v-alert>
      </div>
    </div>
  </div>
</template>

<script>
import NoteIcon from './../../icons/NoteIcon';
import { emptyEventData } from '../../store/ordersModule';

export default {
  name: 'FooterScheduling',
  components: {
    NoteIcon,
  },
  data() {
    return {
      valueItem: {},
      alert: false,
    };
  },
  created() {
    this.valueItem = { ...this.value };
  },
  props: {
    showErrors: {
      type: Boolean,
      required: false,
    },
    value: {
      type: Object,
      required: true,
    },
    alertError: {
      type: Boolean,
    },
    showLoading: {
      type: Boolean,
    },
  },
  watch: {
    notifyEvent(newValue) {
      if (newValue.field === 'updateConstructionData') this.alert = true;
      setTimeout(() => {
        (this.alert = false),
          this.$store.commit('setNotifyEvent', emptyEventData);
      }, 3000);
    },
  },
  computed: {
    getGasWaterCompany() {
      return this.$store.getters.getChangedObject.assembly_gas_water_company
        .company_name;
    },
    notifyEvent() {
      return this.$store.getters.getNotifyEvent
        ? this.$store.getters.getNotifyEvent
        : emptyEventData;
    },
    note: {
      get() {
        return this.valueItem.note;
      },
      set(value) {
        this.valueItem.note = value;
        this.$emit('update-note-data', value);
      },
    },
  },
};
</script>

<style>
.footer_scheduling {
  background-color: #f4f4f4;
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.button_save {
  color: white;
  border: none;
  background: #9b004b 0% 0% no-repeat padding-box;
  cursor: pointer;
  color: white !important;
  border-radius: 8px;
  width: 200px;
  height: 38px;
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}
.button_cancel {
  border: none;
  font-size: 18px;
  cursor: pointer;
  background: transparent;
  text-decoration: underline;
  color: #6e6e6e !important;
  margin-left: 30px;
  outline: none;
}
.scheduling_note {
  background-color: #f4f4f4;
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}
.note_text {
  border-radius: 8px;
  padding: 16px;
  border: 1px solid #999999;
  width: 90%;
  height: 96px;
  background-color: white;
  outline: none;
}
.note_icon {
  margin-bottom: 60px;
  margin-right: 10px;
}
.error_message {
  background-color: #ffe8e6;
  color: red;
  height: 40px;
  width: 97%;
  display: flex;
  align-items: center;
  border-radius: 12px;
  padding-left: 10px;
}
.container_buttons {
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 0px 0px 30px 85px;
  background-color: #f4f4f4;
}
</style>
