<template>
  <svg
    id="Icon_Monatge_Wasser"
    data-name="Icon Monatge Wasser"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
  >
    <path
      id="Pfad_237"
      data-name="Pfad 237"
      d="M16,0A16,16,0,1,1,0,16,16,16,0,0,1,16,0Z"
      fill="#006cad"
    />
    <path
      id="Icon_open-droplet"
      data-name="Icon open-droplet"
      d="M6.443,0l-.73.73C5.477.967,0,6.508,0,11.212a6.443,6.443,0,1,0,12.887,0C12.887,6.529,7.41.967,7.174.73L6.443,0ZM3.222,10.138A1.063,1.063,0,0,1,4.3,11.212,2.154,2.154,0,0,0,6.443,13.36a1.074,1.074,0,0,1,0,2.148,4.308,4.308,0,0,1-4.3-4.3,1.063,1.063,0,0,1,1.074-1.074Z"
      transform="translate(9.931 6.246)"
      fill="#fff"
    />
  </svg>
</template>

<script>
export default {
  name: 'BaubeabsichtigungsterminIcon',
  props: {
    width: {
      type: [Number, String],
      default: 30,
    },
    height: {
      type: [Number, String],
      default: 30,
    },
  },
};
</script>

<style scoped></style>
