import i18n from '../../plugins/i18n';

export const translateOrderStatus = (order_status) => {
  switch (order_status) {
    case 'ORDER_CREATED':
      return [i18n.t('order_statuses.count_order_created'), '#d79bb8'];
    case 'ORDER_TECHNICAL_FEASIBILITY':
      return [
        i18n.t('order_statuses.count_order_technical_feasibility'),
        '#CF8AA9',
      ];
    case 'ORDER_CONFIRMED':
      return [i18n.t('order_statuses.count_order_confirmed'), '#C7799A'];
    case 'ORDER_APPOINTMENT_PENDING':
      return [
        i18n.t('order_statuses.count_order_appointment_pending'),
        '#BF678B',
      ];
    case 'ORDER_APPOINTMENT_CONFIRMED':
      return [
        i18n.t('order_statuses.count_order_appointment_confirmed'),
        '#B7567B',
      ];
    case 'ORDER_IN_EXECUTION':
      return [i18n.t('order_statuses.count_order_in_execution'), '#AE436C'];
    case 'ORDER_FULFILLED':
      return [i18n.t('order_statuses.count_order_fulfilled'), '#A52F5C'];
    case 'ORDER_MANUAL':
      return [i18n.t('order_statuses.count_order_manual'), '#A52F5C'];
    case 'ORDER_BILLED':
      return [i18n.t('order_statuses.count_order_billed'), '#9B144C'];
    case 'ORDER_CANCELLED':
      return [i18n.t('order_statuses.count_order_cancelled'), '#525252'];
    default:
      return [];
  }
};
