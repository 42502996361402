import GraphQLService from './GraphQLService';

export async function getConstructionCompanies() {
  return GraphQLService.requestWithOpenID(
    `
      query constructionCompanies { 
        constructionCompanies {
          construction_managers {
            function
            name
            phone
            email
            group_email
            region 
          }
          construction_companies {
            company_name
            street
            street_number
            website
            zip
            city
            contact_persons {
              function
              name
              phone
              mobile_phone
              email
              regions
              groundwork
              assembly_electricity
              assembly_gas_water
            }
          }
        }
      }`
  );
}
