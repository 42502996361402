export const emobilityWallboxLocationModule = {
  state: {},
  mutations: {
    setWallboxLocationCustomValue(state, wallbox_location_custom_value) {
      this.state.changed_object.wallbox_location_custom_value =
        wallbox_location_custom_value;
    },
    setWallboxLocation(state, wallbox_location) {
      this.state.changed_object.wallbox_location = wallbox_location;
    },
  },
  getters: {
    getWallboxLocationCustomValue: (state, getters, rootState) => {
      return rootState.changed_object.wallbox_location_custom_value;
    },
    getWallboxLocation: (state, getters, rootState) => {
      return rootState.changed_object.wallbox_location;
    },
  },
};
