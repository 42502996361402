<template>
  <svg
    id="Icon_Tiefbau"
    data-name="Icon Tiefbau"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
  >
    <path
      id="Pfad_232"
      data-name="Pfad 232"
      d="M16,0A16,16,0,1,1,0,16,16,16,0,0,1,16,0Z"
      fill="#c93701"
    />
    <g id="Bagger" transform="translate(2.47 7.873)">
      <path
        id="Pfad_228"
        data-name="Pfad 228"
        d="M34.9,37.182v-3.8H29.571V27.09H24.376l-1.886,1.8v4.489l-3.673-4.424a1.714,1.714,0,0,0,.033-.426,2.052,2.052,0,0,0-4.1,0,2.506,2.506,0,0,0,.066.492L11.571,34.3a1.167,1.167,0,0,0-1.158,1.147,1.363,1.363,0,0,0,.132.524c-.6,1.016-2.879,5.538,2.713,5.9h1.588L12.266,36.4a1.1,1.1,0,0,0,.463-.917,1.4,1.4,0,0,0-.066-.393l3.706-4.555a.924.924,0,0,0,.3.033l4.8,6.652H34.9ZM23.847,29.416,25,28.3h3.176v3.866H23.847ZM12.3,35.544a.685.685,0,0,1-.695.623.7.7,0,0,1-.695-.688.675.675,0,0,1,.364-.59.563.563,0,0,1,.331-.1.7.7,0,0,1,.695.688C12.332,35.511,12.3,35.511,12.3,35.544Zm4.533-5.636a1.344,1.344,0,0,1-.728-.2,1.374,1.374,0,0,1-.662-1.18,1.387,1.387,0,0,1,2.713-.393,1.63,1.63,0,0,1,.066.393A1.391,1.391,0,0,1,16.832,29.908Z"
        transform="translate(-9.579 -26.5)"
        fill="#fff"
      />
      <path
        id="Pfad_229"
        data-name="Pfad 229"
        d="M52.506,60.6H42.587A1.592,1.592,0,0,0,41,62.187v.291a1.592,1.592,0,0,0,1.587,1.587h9.919a1.592,1.592,0,0,0,1.587-1.587v-.291A1.592,1.592,0,0,0,52.506,60.6Zm-8.94,2.539a.82.82,0,1,1,.82-.82A.861.861,0,0,1,43.566,63.139Zm2.645,0a.82.82,0,1,1,.82-.82A.824.824,0,0,1,46.211,63.139Zm2.672,0a.82.82,0,1,1,.82-.82A.842.842,0,0,1,48.882,63.139Zm2.645,0a.82.82,0,1,1,.82-.82A.824.824,0,0,1,51.527,63.139Z"
        transform="translate(-27.61 -48.618)"
        fill="#fff"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'BaubeabsichtigungsterminIcon',
  props: {
    width: {
      type: [Number, String],
      default: 30,
    },
    height: {
      type: [Number, String],
      default: 30,
    },
  },
};
</script>

<style scoped></style>
