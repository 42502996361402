import store from '../store/index.js'; // path to your Vuex store
import documentsService from '../services/DocumentsService';

const photovoltaicOrderID = store.getters.getSelectedFeedInSystem.id;

export default {
  getFileType(fileName) {
    const n = fileName.lastIndexOf('.');
    const result = fileName.substring(n + 1).toLowerCase();
    switch (result) {
      case 'jpg':
      case 'jpeg':
      case 'jfif':
      case 'pjpeg':
      case 'pjp':
      case 'tif':
        return 'image/jpeg';
      case 'pdf':
        return 'application/pdf';
      case 'png':
        return 'image/png';
      case 'svg':
        return 'image/svg+xml';
      case 'webp':
        return 'image/webp';
      case 'gif':
        return 'image/gif';
      case 'avif':
        return 'image/avif';
      case 'apng':
        return 'image/apng';
      default:
        break;
    }
  },
  createFileObject(blob, fileName, product, id, fileKey) {
    const fileType = this.getFileType(fileName);
    const fileFromBackend = new File([blob], fileName, { type: fileType });
    // add files to vuex
    switch (product) {
      case 'installer':
        store.commit('setInstallerFileFromBackend', {
          fileObject: fileFromBackend,
          type: fileKey,
        });
        break;
      case 'order':
        store.commit('setOrderFileFromBackend', {
          fileObject: fileFromBackend,
          type: fileKey,
        });
        break;
      case 'OrderHistoryFile':
        store.commit('setOrderFileHistoryFromBackend', fileFromBackend);
        break;
      case 'InstallerHistoryFile':
        store.commit('setInstallerFileHistoryFromBackend', fileFromBackend);
        break;
      case 'EmobilityHistoryFile':
        store.commit('setEmobilityFileHistoryFromBackend', fileFromBackend);
        break;
      case 'PhotovoltaicHistoryFile':
        store.commit('setPhotovoltaicFileHistoryFromBackend', fileFromBackend);
        break;
      case 'PhotovoltaicFiles':
        store.commit('addPhotovoltaicFiles', {
          key: fileKey,
          files: fileFromBackend,
        });
        break;
      default:
        store.commit('setEmobilityFileFromBackend', {
          fileObject: fileFromBackend,
          type: fileKey,
        });
        break;
    }
  },
  convertToBlobAndReturnFileObject(string, fileName, product, id, fileKey) {
    const bytes = new Uint8Array(string);
    const fileType = this.getFileType(fileName);
    const blob = new Blob([bytes], { type: fileType });
    this.createFileObject(blob, fileName, product, id, fileKey);
    return {
      url: window.URL.createObjectURL(blob),
      imageName: fileName,
    };
  },
  downloadFile(fileName, type) {
    const fileURL = store.getters['get' + type + 'FileURLByName'](fileName);
    const fileLink = document.createElement('a');
    fileLink.href = fileURL;
    const lastIndex = fileName.lastIndexOf('/');
    fileLink.setAttribute('download', fileName.substring(lastIndex + 1));
    document.body.appendChild(fileLink);
    fileLink.click();
  },
  async uploadFileGraphQL(file_name, method, extendedUUID) {
    if (extendedUUID) {
      return documentsService[method](
        file_name,
        process.env.VUE_APP_STAGE,
        photovoltaicOrderID
      )
        .then(async (res) => {
          store.commit(
            'setTechnicalInspectionFileNameWithUUID',
            res.uploadFileExtended.key
          );
          return new Promise((resolve) => resolve(res.uploadFileExtended.url));
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      return documentsService[method](file_name, process.env.VUE_APP_STAGE)
        .then(async (res) => new Promise((resolve) => resolve(res.uploadFile)))
        .catch((err) => {
          console.log(err);
        });
    }
  },
};
