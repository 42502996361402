<template>
  <svg
    id="Icon_Montage_Gas"
    data-name="Icon Montage_Gas"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
  >
    <path
      id="Pfad_236"
      data-name="Pfad 236"
      d="M16,0A16,16,0,1,1,0,16,16,16,0,0,1,16,0Z"
      fill="#c20016"
    />
    <path
      id="Icon_ionic-ios-flame"
      data-name="Icon ionic-ios-flame"
      d="M12.463,3.375c1.231,5.712-4.63,5.6-4.588,11.349.034,4.711,5.025,6.307,6.133,6.307a6.478,6.478,0,0,0,6.073-6.307C20.382,10.208,16.613,5.9,12.463,3.375Zm3.153,15.054a1.68,1.68,0,0,1-3.242,0,2.877,2.877,0,0,1-.1-.743c0-1.74,1.723-3.747,1.723-3.747s1.715,2.007,1.715,3.747A2.67,2.67,0,0,1,15.616,18.429Z"
      transform="translate(2.057 4.349)"
      fill="#fff"
    />
  </svg>
</template>

<script>
export default {
  name: 'BaubeabsichtigungsterminIcon',
  props: {
    width: {
      type: [Number, String],
      default: 30,
    },
    height: {
      type: [Number, String],
      default: 30,
    },
  },
};
</script>

<style scoped></style>
