<template>
  <div class="container_filter_product--btn">
    <v-btn-toggle
      class="toggle_button"
      :style="{ '--toggle-width': toggleWidth }"
      v-model="filterJourney"
    >
      <div
        class="page_background"
        id="page_background"
        v-for="value in valuesList"
        :key="value.id"
      >
        <new-glas-card title="" class="frosted_glas_filter">
          <v-btn
            class="filter_button"
            active-class="active_button"
            :value="value.id"
            @click="setActiveButton(value.id)"
          >
            <p class="button_content">{{ value.label }}</p>
            <div class="count_orders" v-if="value.value">
              {{ value.value }}
            </div>
          </v-btn>
        </new-glas-card>
      </div>
    </v-btn-toggle>
  </div>
</template>

<script>
import { NewGlasCard } from '@bit/new-netzportal.netzportal.new-glas-card';

export default {
  name: 'FilterProductButton',
  components: {
    NewGlasCard,
  },
  created() {
    console.log('valueslist', this.valuesList);
    this.$route.query.journey
      ? (this.filterJourney = this.$route.query.journey)
      : '';
  },
  data() {
    return {
      ordersSummary: [],
    };
  },
  props: {
    valuesList: {
      type: Array,
      required: true,
    },
    toggleWidth: {
      type: String,
      default: '33.33%',
    },
  },
  methods: {
    setActiveButton(journeyFilter) {
      this.$store.commit('setJourneyFilter', journeyFilter);
    },
  },
  computed: {
    filterJourney: {
      get() {
        return this.$store.getters.getJourneyFilter;
      },
      set(value) {
        this.$store.commit('setJourneyFilter', value);
      },
    },
  },
  watch: {
    journeyFilter(newValue) {
      this.filterJourney = newValue;
    },
  },
};
</script>
<style>
#page_background {
  padding: 0px !important;
  margin-right: 3px;
}

.frosted_glas_filter {
  height: 78px;
  border-radius: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.filter_button {
  width: 445px !important;
  height: 78px !important;
  display: flex;
  justify-content: center;
  color: white !important;
  background-color: transparent !important;
  outline: none !important;
  border-radius: 0 !important;
}

.active_button {
  border-bottom: 6px solid white;
}
.button_content {
  margin: 0px !important;
}
.count_orders {
  background-color: #d79bb8;
  height: 29px;
  width: 59px;
  border-radius: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}
.toggle_button {
  display: flex;
  padding: 12px 0px;
  margin: 0;
  flex-wrap: wrap;
}
.toggle_button > * {
  flex-basis: calc(
    var(--toggle-width) - 5px
  ); /* Adjust the flex-basis based on your needs */
  margin: 2px; /* Adjust the margin based on your needs */
  flex-grow: 0;
}

@media only screen and (max-width: 1024px) {
  .container {
    max-width: 928px;
  }
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) {
  background: none !important;
}

.theme--light.v-btn-toggle:not(.v-btn-toggle--group)
  .v-btn.v-btn.active_button {
  border-color: white !important;
}
</style>
