<template>
  <div>
    <div class="selection_calendar_background">
      <div class="title_card_container">
        <slot name="icon"></slot>
        <div class="title">{{ title }}</div>
      </div>
      <slot class="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectionCard',
  props: {
    title: {
      type: String,
      default: '',
      required: false,
    },
  },
};
</script>

<style>
.selection_calendar_background {
  background-color: #f4f4f4;
  height: auto;
  width: auto;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: left;
  margin: 15px 0px 0px 20px;
  color: #6e6e6e;
  padding: 15px 10px 0px 5px;
}
.content {
  display: flex;
}
.v-application .title {
  font-size: 18px !important;
  font-weight: 400 !important;
}
.v-input__slot {
  width: 95%;
  margin-left: 11px;
  margin-right: 11px;
}
.title_card_container {
  display: flex;
  flex-direction: row;
  margin: 10px 0px 15px 11px;
}

@media screen and (max-width: 1024px) {
  .selection_calendar_background {
    background-color: #f4f4f4;
    height: auto;
    width: auto;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: left;
    margin: 15px 0px !important;
    color: #6e6e6e;
    padding: 15px 10px 0px 5px;
  }
}
</style>
