/* eslint-disable */
import { CognitoAuth, StorageHelper } from 'amazon-cognito-auth-js';
import IndexRouter from '../router';
import UserInfoStore from './user-info-store';
import UserInfoApi from './user-info-api';
import store from '../store/index.js';
import jwt_decode from 'jwt-decode';

const CLIENT_ID = process.env.VUE_APP_COGNITO_CLIENT_ID;
const APP_DOMAIN = process.env.VUE_APP_COGNITO_APP_DOMAIN;
const REDIRECT_URI = process.env.VUE_APP_COGNITO_REDIRECT_URI;
const USERPOOL_ID = process.env.VUE_APP_COGNITO_USERPOOL_ID;
const REDIRECT_URI_SIGNOUT = process.env.VUE_APP_COGNITO_REDIRECT_URI_SIGNOUT;
const APP_URL = process.env.VUE_APP_APP_URL;

var authData = {
  ClientId: CLIENT_ID,
  AppWebDomain: APP_DOMAIN,
  TokenScopesArray: ['openid', 'email', 'profile'],
  RedirectUriSignIn: REDIRECT_URI,
  RedirectUriSignOut: REDIRECT_URI_SIGNOUT,
  UserPoolId: USERPOOL_ID,
};

var auth = new CognitoAuth(authData);

auth.userhandler = {
  onSuccess: function (result) {
    UserInfoStore.setLoggedIn(true);
    UserInfoApi.getUserInfo().then((response) => {
      let token = result.idToken.jwtToken;
      var identities = jwt_decode(token).identities;
      let user_id = '';
      if (identities) {
        let provider = 'login.new-' + process.env.VUE_APP_STAGE;
        user_id = identities.find(
          (item) => item.providerName === provider
        ).userId;
      }
      store.commit('setUserFirstName', response['name']);
      store.commit('setUserLastName', response['family_name']);
      store.commit('setUserEmail', response['email']);
      store.commit('setUserId', user_id);
      store.commit('setUserGroups', jwt_decode(token)['cognito:groups']);
      store.commit(
        'setUserAccessOrdersW',
        response['custom:access-bo-orders-w'] === '1'
      );
      IndexRouter.push('/');
    });
  },
  onFailure: function (err) {
    UserInfoStore.setLoggedOut();
    IndexRouter.push('/error');
  },
};

function getUserInfoStorageKey() {
  var keyPrefix = 'CognitoIdentityServiceProvider.' + auth.getClientId();
  var tokenUserName = auth.signInUserSession.getAccessToken().getUsername();
  var userInfoKey = keyPrefix + '.' + tokenUserName + '.userInfo';
  return userInfoKey;
}

var storageHelper = new StorageHelper();
var storage = storageHelper.getStorage();
export default {
  auth: auth,
  login() {
    auth.getSession();
  },
  logout() {
    if (auth.isUserSignedIn()) {
      var userInfoKey = this.getUserInfoStorageKey();
      auth.signOut();
      storage.removeItem(userInfoKey);
      store.commit('setUserFirstName', null);
      store.commit('setUserLastName', null);
      store.commit('setUserEmail', null);
      store.commit('setUserId', null);
      store.commit('setUserGroups', []);
      store.commit('setUserAccessOrdersW', false);
      localStorage.removeItem('vuex');
    }
  },
  getUserInfoStorageKey,
};
