var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"details_dialog"},[_c('div',{staticClass:"header_dialog"},[_c('edit-icon',{staticClass:"mt-4",attrs:{"width":"20px","height":"20px"}}),_c('v-icon',{staticClass:"ml-4 mt-4",attrs:{"large":""},on:{"click":function($event){return _vm.$emit('close-dialog')}}},[_vm._v("mdi-close-circle-outline")])],1),_c('div',{staticClass:"date mt-2"},[_c('v-icon',{staticClass:"mr-4"},[_vm._v("mdi-calendar")]),_c('div',[_vm._v(_vm._s(_vm.formatDate(_vm.date)))])],1),_c('div',{staticClass:"applicant_data"},[_c('div',[_vm._v(" "+_vm._s(this.selectedOrderCalendar.journey === 'einfamilienhaus' ? 'Netzanschluss Einfamilienhaus' : 'Netzanschluss E-Mobilität')+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.applicantNameObject(this.selectedOrderCalendar))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.installationAddressObject(this.selectedOrderCalendar))+" ")])]),_c('construction-dashboard-data-component',{attrs:{"contactPerson":this.selectedOrderCalendar.internal_construction_management
        .construction_manager,"title":_vm.$t('calendar_side_selection_component.construction_manager_NEW'),"constructionDate":this.selectedOrderCalendar.internal_construction_management
        .construction_planned_date},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('baubesichtigungstermin-icon',{staticClass:"mr-2"})]},proxy:true}])}),_c('construction-dashboard-data-component',{attrs:{"companyName":this.selectedOrderCalendar.groundwork_company.company_name,"contactPerson":this.selectedOrderCalendar.groundwork_company.contact_person,"title":_vm.$t('construction_work.civil_engineering'),"constructionDate":this.selectedOrderCalendar.groundwork_company.construction_planned_date},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('tiefbau-icon',{staticClass:"mr-2"})]},proxy:true}])}),(
      _vm.selectedOrderProduct.includes('electricity') ||
      this.selectedOrderProduct.includes('eMobility')
    )?_c('construction-dashboard-data-component',{attrs:{"companyName":this.selectedOrderCalendar.assembly_electricity_company.company_name,"contactPerson":this.selectedOrderCalendar.assembly_electricity_company.contact_person,"title":_vm.$t('calendar_side_selection_component.montage_electricity'),"constructionDate":this.selectedOrderCalendar.assembly_electricity_company
        .construction_planned_date},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('montage-strom-icon',{staticClass:"mr-2"})]},proxy:true}],null,false,1300854946)}):_vm._e(),(
      _vm.selectedOrderProduct.includes('gas') ||
      this.selectedOrderProduct.includes('water')
    )?_c('construction-dashboard-data-component',{attrs:{"companyName":this.selectedOrderCalendar.assembly_gas_water_company.company_name,"contactPerson":this.selectedOrderCalendar.assembly_gas_water_company.contact_person,"title":_vm.selectedOrderProduct === 'electricity&water&gas'
        ? _vm.$t('calendar_side_selection_component.montage_gas_water')
        : _vm.selectedOrderProduct === 'electricity&water'
        ? _vm.$t('calendar_side_selection_component.montage_water')
        : _vm.$t('calendar_side_selection_component.montage_gas'),"constructionDate":this.selectedOrderCalendar.assembly_gas_water_company
        .construction_planned_date,"secondIcon":_vm.selectedOrderProduct === 'electricity&water&gas'
        ? (_vm.secondIcon = true)
        : false},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(_vm.selectedOrderProduct.includes('gas'))?_c('montage-gas-icon',{staticClass:"mr-1"}):_vm._e(),(_vm.selectedOrderProduct === 'electricity&water')?_c('montage-wasser-icon',{staticClass:"mr-1 mt-2"}):_vm._e()]},proxy:true},(_vm.selectedOrderProduct === 'electricity&water&gas')?{key:"icon2",fn:function(){return [_c('montage-wasser-icon',{staticClass:"mr-2"})]},proxy:true}:null],null,true)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }