<template>
  <div>
    <div class="pipeline-background">
      <NEWPipelineComponent :statuses="other_statuses" />
      <NEWPipelineComponent :statuses="status_all" />
    </div>
    <clerk-orders-overview-table />
  </div>
</template>

<script>
import ClerkOrdersOverviewTable from './ClerkOrdersOverviewTable';
import { NEWPipelineComponent } from '@newnetzportal/netzportal-webapp-storybook-components';
import orderService from '../../services/OrderService';
import { getOrdersTableConfig } from './ordersTableConfig';

export default {
  name: 'ClerkOrdersOverviewComponent',
  components: {
    ClerkOrdersOverviewTable,
    NEWPipelineComponent,
  },
  created() {
    this.getOrdersSummaryFromBackend();
    this.getOrdersSummaryForPipelineFromBackend();
  },
  data() {
    return {
      ordersSummary: {},
      ordersSummaryForPipeline: {},
      filterJourney: this.$route.params.journey
        ? this.$route.params.journey
        : this.$store.state.orders.journeyFilter === 'alle'
        ? ''
        : this.$store.state.orders.journeyFilter,
      countOrderStatuses: getOrdersTableConfig('countOrderStatuses'),
    };
  },
  watch: {
    journeyFilter(newValue) {
      if (newValue !== 'alle') {
        this.filterJourney = newValue;
      } else {
        this.filterJourney = '';
      }
      this.getOrdersSummaryForPipelineFromBackend();
    },
  },
  computed: {
    journeyFilter() {
      return this.$store.state.orders.journeyFilter;
    },
    status_all() {
      const summaryAllOrders = [
        {
          name: 'alle',
          color: '#999999',
          amount: this.ordersSummary.count_orders,
          percentage: 100,
        },
      ];
      if (
        this.journeyFilter === 'einfamilienhaus' ||
        this.journeyFilter === 'eMobility'
      ) {
        summaryAllOrders[0].amount =
          this.ordersSummary[`count_orders_${this.journeyFilter}`];
      }
      return summaryAllOrders;
    },
    other_statuses() {
      const otherStatuses = [];
      let count_all_orders = this.ordersSummary.count_orders;
      if (
        this.journeyFilter === 'einfamilienhaus' ||
        this.journeyFilter === 'eMobility'
      ) {
        count_all_orders =
          this.ordersSummary[`count_orders_${this.journeyFilter}`];
      }
      for (const element of this.countOrderStatuses) {
        otherStatuses.push({
          name: this.$t(`order_statuses.${element.name}`),
          color: element.color,
          amount: this.ordersSummaryForPipeline[element.name],
          percentage:
            (this.ordersSummaryForPipeline[element.name] / count_all_orders) *
            100,
        });
      }
      return otherStatuses;
    },
  },
  methods: {
    getOrdersSummaryFromBackend() {
      orderService
        .getOrdersSummary('')
        .then((res) => {
          this.ordersSummary = res.getOrdersSummary;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getOrdersSummaryForPipelineFromBackend() {
      const params = {
        journey:
          !this.filterJourney || this.filterJourney === 'all'
            ? ''
            : this.filterJourney,
      };
      orderService
        .getOrdersSummaryForPipeline(params)
        .then((res) => {
          this.ordersSummaryForPipeline = res.getOrdersSummaryForPipeline;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
