import * as axios from 'axios';

export default {
  async executeEmailAction(queryString) {
    if (queryString.substr(0, 1) != '?') {
      queryString = '?' + queryString;
    }
    return await axios.get(process.env.VUE_APP_EEA_URL + queryString);
  },
};
