import { COMPANY_TITLE, CITY_TITLE } from '@/helpers/constants';

export const formatOrderObject = (currentOrder) => {
  if (currentOrder.alternative_invoice === 'address') {
    currentOrder.invoice_address = {
      ...personalDataObject(currentOrder.applicant),
      address: addressDataObject(
        currentOrder.applicant.alternative_invoice_address
      ),
    };
  } else if (currentOrder.alternative_invoice === 'recipient') {
    currentOrder.invoice_address = currentOrder.alternative_invoice_recipient;
  } else {
    currentOrder.invoice_address = {
      ...personalDataObject(currentOrder.applicant),
      address: addressDataObject(currentOrder.applicant.address),
    };
  }
  if (currentOrder.same_landowner) {
    currentOrder.landowner = {
      ...personalDataObject(currentOrder.applicant),
      address: addressDataObject(currentOrder.applicant.address),
    };
  }
  return JSON.parse(JSON.stringify(currentOrder));
};

function addressDataObject(address) {
  return {
    zip: address.zip,
    street: address.street,
    street_number: address.street_number,
    city: address.city,
    country: address.country,
  };
}

function personalDataObject(personalData) {
  const personalObject = { title: personalData.title };
  switch (personalData.title) {
    case COMPANY_TITLE:
      personalObject.company_name = personalData.company_name;
      break;
    case CITY_TITLE:
      personalObject.city_name = personalData.city_name;
      break;
    default:
      personalObject.name = personalData.name;
      personalObject.surname = personalData.surname;
      break;
  }
  return personalObject;
}
