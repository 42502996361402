<template>
  <div class="foo-bar">
    <v-row no-gutters>
      <div class="title-col">
        {{ title }}
      </div>
      <div class="body-col">
        <v-row
          no-gutters
          :style="
            (index === 0 && index === filesArray.length - 1) ||
            index === filesArray.length - 1
              ? 'min-height: 56px'
              : 'margin-bottom: 1px'
          "
          v-for="(file, index) in filesArray"
          :key="file.type"
        >
          <div
            class="content-col"
            :style="
              index === 0
                ? 'padding-top: 18px'
                : index === filesArray.length - 1
                ? 'padding-bottom: 18px'
                : ''
            "
          >
            <div>
              {{ $t(`file_types.${file.type}`) }}
              <v-btn
                v-for="file_name in file.file_names"
                :key="file_name"
                icon
                style="min-height: 36px; min-width: 36px; margin-right: 5px"
                @click="downloadOrderFile(file_name)"
              >
                <v-icon v>mdi-download</v-icon>
              </v-btn>
            </div>
            <slot v-if="index === filesArray.length - 1"></slot>
          </div>
          <div
            class="button-col"
            :style="
              first && index === 0
                ? 'border-radius: 0 8px 0 0'
                : last && index === filesArray.length - 1
                ? 'border-radius: 0 0 8px 0'
                : ''
            "
          >
            <v-btn icon @click="$emit('edit', file)">
              <edit-icon />
            </v-btn>
          </div>
        </v-row>
      </div>
    </v-row>
  </div>
</template>

<script>
import EditIcon from '../../icons/EditIcon';
import fileMethods from '../../helpers/fileMethods';
import downloadOrderFileMethods from './downloadOrderFilesMethods';

export default {
  name: 'OrderFileRow',
  components: {
    EditIcon,
  },
  props: {
    title: {
      type: String,
      default: '-',
    },
    first: {
      type: Boolean,
      default: false,
    },
    last: {
      type: Boolean,
      default: false,
    },
    filesArray: {
      type: Array,
    },
  },
  data() {
    return {
      selectedOrder: this.$store.getters.getSelectedOrder,
    };
  },
  created() {
    this.filesArray.forEach((file) => {
      file.file_names.forEach((file_name, index) => {
        downloadOrderFileMethods.getOrderDocument(
          this.selectedOrder.id,
          file_name,
          file.type + `_${index + 1}`
        );
      });
    });
  },
  methods: {
    downloadOrderFile(item) {
      fileMethods.downloadFile(item, 'Order');
    },
  },
};
</script>

<style scoped>
.title-col {
  padding: 18px 0 18px 25px;
  width: 29%;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0;
  color: #6e6e6e;
  background-color: rgba(255, 255, 255, 0.9);
  opacity: 1;
}

.body-col {
  width: 71%;
}
.content-col {
  background-color: rgba(255, 255, 255, 0.9);
  width: 92%;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0;
  color: #6e6e6e;
  opacity: 1;
  border-right: 1px solid #999999;
}
.button-col {
  width: 8%;
  min-width: 48px;
  justify-content: center;
  align-items: center;
  display: flex;
  filter: brightness(1.1);
  background-color: rgba(255, 255, 255, 0.6);
}
</style>
