import { render, staticRenderFns } from "./TextCheckboxActionComponent.vue?vue&type=template&id=7197536a"
import script from "./TextCheckboxActionComponent.vue?vue&type=script&lang=js"
export * from "./TextCheckboxActionComponent.vue?vue&type=script&lang=js"
import style0 from "./TextCheckboxActionComponent.vue?vue&type=style&index=0&id=7197536a&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
installComponents(component, {VAlert,VAvatar,VBtn,VCard,VCheckbox,VIcon,VListItem,VListItemAction,VListItemContent})
