export const installerConcessionDivisonModule = {
  state: {},
  mutations: {
    setInstallerConcessionDivision(state, concession_division) {
      this.state.changed_object.concession_division = concession_division;
    },
  },
  getters: {
    getInstallerConcessionDivision: (state, getters, rootState) => {
      return rootState.changed_object.concession_division;
    },
  },
};
