import * as dayjs from 'dayjs';

export function transformRawDataToDocumentsHistoryTableFormat(rawArray) {
  const transformedArray = [];
  rawArray.forEach((element) => {
    transformedArray.push({
      date: dayjs(new Date(element.created)).format('DD.MM.YYYY HH:mm'),
      file_name: JSON.parse(element.payload).filename
        ? fileNameObject(JSON.parse(element.payload).filename)
        : null,
      full_path: JSON.parse(element.payload).filename,
      changed_by: element.changed_by,
      imgSrc: '',
      numPages: null,
      showErrorAlert: false,
    });
  });
  return transformedArray;
}

function fileNameObject(filename) {
  let parsedFileName = '';
  const lastIndex = filename.lastIndexOf('/');
  parsedFileName = filename.substring(lastIndex + 1);
  return parsedFileName;
}
