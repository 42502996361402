const initialState = {
  notifications: [],
};

export const notificationsModule = {
  state: JSON.parse(JSON.stringify(initialState)),
  mutations: {
    addNotification(state, value) {
      state.notifications.push(value);
    },
    completeNotification(state) {
      state.notifications.shift();
    },
  },
  getters: {
    getNotifications: (state) => {
      return state.notifications;
    },
  },
};
