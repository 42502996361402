export const emobilityInstallationAddressModule = {
  state: {
    is_with_plot_number: false,
  },
  mutations: {
    setIsEmobilityWithPlotNumber(state, is_with_plot_number) {
      state.is_with_plot_number = is_with_plot_number;
    },
    setEmobilityProductInstallationChangedObject(state, changedData) {
      this.state.changed_object.product_installation_full_address[
        changedData.key
      ] = changedData.value;
    },
    setEmobilityWithPlotNumberChangedObject(state, changedData) {
      this.state.changed_object.product_installation_with_plot_number_address[
        changedData.key
      ] = changedData.value;
    },
  },
  getters: {
    getIsEmobilityWithPlotNumber: (state) => {
      return state.is_with_plot_number;
    },
  },
  actions: {},
};
