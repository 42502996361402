import Vue from 'vue';
import dayjs from 'dayjs';
import * as weekOfYear from 'dayjs/plugin/weekOfYear';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import * as isoWeek from 'dayjs/plugin/isoWeek';
import * as updateLocale from 'dayjs/plugin/updateLocale';
import 'dayjs/locale/de';

dayjs.locale('de');
dayjs.extend(weekOfYear);
dayjs.extend(localizedFormat);
dayjs.extend(isoWeek);
dayjs.extend(updateLocale);

dayjs.updateLocale('de', {
  weekdays: [
    'Sonntag',
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
  ],
  months: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ],
  monthsShort: [
    'Jan.',
    'Feb.',
    'März',
    'Apr.',
    'Mai',
    'Jun.',
    'Jul.',
    'Aug.',
    'Sept.',
    'OKt.',
    'Nov.',
    'Dez.',
  ],
  formats: {
    LT: 'hh:mm',
    L: 'DD.MM.YYYY',
    ll: 'dddd DD. MMM YYYY',
  },
});

Vue.filter('formatDate', function (date, format) {
  return dayjs(date).format(format);
});

Vue.prototype.$dayjs = dayjs;

export default new dayjs();
