import GraphQLService from './GraphQLService';
import { getOrderByIdAndDate, getOrdersWithFilter } from './GraphQLQueries';

export default {
  async getOrdersWithFilter(requestBody) {
    return GraphQLService.requestWithOpenID(getOrdersWithFilter, {
      limit: requestBody.limit,
      from_index: requestBody.from_index,
      regional_team: requestBody.regional_team,
      order_status: requestBody.order_status,
      journey: requestBody.journey,
      search_word: requestBody.search_word,
    });
  },
  async getOrderHistory(requestBody) {
    return GraphQLService.requestWithOpenID(
      `
        query History( $id: String!, $limit: Int, $from_index: Int) { 
          orderHistory( id: $id, limit: $limit, from_index: $from_index ) {
            changes {
              id
              event_origin
              changed_by
              created
              field
              payload
            }
            count_changes
          }
        }`,
      {
        id: requestBody.id,
        limit: requestBody.limit,
        from_index: requestBody.from_index,
      }
    );
  },
  async getInstallerHistory(requestBody) {
    return GraphQLService.requestWithOpenID(
      `
        query History ( $id: String!, $limit: Int, $from_index: Int) { 
          installerHistory( id: $id, limit: $limit, from_index: $from_index ) {
            changes {
              id
              event_origin
              changed_by
              created
              field
              payload
            }
            count_changes
          }
        }`,
      {
        id: requestBody.id,
        limit: requestBody.limit,
        from_index: requestBody.from_index,
      }
    );
  },
  async getEmobilityHistory(requestBody) {
    return GraphQLService.requestWithOpenID(
      `
      query History ( $id: String!, $limit: Int, $from_index: Int) { 
        emobilityHistory( id: $id, limit: $limit, from_index: $from_index ) {
            changes {
              id
              event_origin
              changed_by
              created
              field
              payload
            }
            count_changes
          }
        }`,
      {
        id: requestBody.id,
        limit: requestBody.limit,
        from_index: requestBody.from_index,
      }
    );
  },
  async getPhotovoltaicHistory(requestBody) {
    return GraphQLService.requestWithOpenID(
      `
      query History ( $id: String!, $limit: Int, $from_index: Int) { 
        photovoltaicHistory( id: $id, limit: $limit, from_index: $from_index ) {
            changes {
              id
              event_origin
              changed_by
              created
              field
              payload
            }
            count_changes
          }
        }`,
      {
        id: requestBody.id,
        limit: requestBody.limit,
        from_index: requestBody.from_index,
      }
    );
  },
  async getOrdersSummary(status) {
    return GraphQLService.requestWithOpenID(
      `
        query getOrdersSummary($status: String) { 
          getOrdersSummary(status: $status) {
            count_orders 
            count_orders_einfamilienhaus
            count_orders_eMobility
          }
        }`,
      {
        status: status,
      }
    );
  },

  async getOrdersSummaryForDiagram() {
    return GraphQLService.requestWithOpenID(
      `
        query getOrdersSummaryForDiagram { 
          getOrdersSummaryForDiagram {
            count_orders 
            count_orders_einfamilienhaus
            count_orders_eMobility
          }
        }`
    );
  },

  async getOrdersSummaryForPipeline(requestBody) {
    return GraphQLService.requestWithOpenID(
      `
        query getOrdersSummaryForPipeline($journey: String) { 
          getOrdersSummaryForPipeline ( journey: $journey ) {
            count_order_created
            count_order_cancelled
            count_order_technical_feasibility
            count_order_confirmed
            count_order_appointment_pending
            count_order_appointment_confirmed
            count_order_in_execution
            count_order_fulfilled
            count_order_manual
            count_order_billed
          }
        }`,
      {
        journey: requestBody.journey,
      }
    );
  },
  async getOrderByIdAndDate(orderId) {
    return GraphQLService.requestWithOpenID(getOrderByIdAndDate, {
      id: orderId,
    });
  },
  async getOrderByIdForCalendar(orderId) {
    return GraphQLService.requestWithOpenID(
      `
        query getOrderByIdAndDate ($id: String) { 
          getOrderByIdAndDate( id: $id) {
            id
            applicant {
              title
              surname
              name
              phone_number
              email
              additional_phone_number
              preferred_contact_channel
              address {
                street
                street_number
                city
                country
                zip
              }
              alternative_invoice_address{
                street
                street_number
                city
                country
                zip
              }
            }
            product
            journey
            product_installation_full_address{
              street
              street_number
              city
              country
              zip
            }
            product_installation_with_plot_number_address {
              district
              landing
              location
              new_building_area_name
              plot
              zip
            }
            assembly_electricity_company {
              company_name
              contact_person
              construction_planned_date {
                dates
                start_time {
                  start_hour
                  start_minute
                }
                end_time {
                  end_hour
                  end_minute
                }
              }
            }
            assembly_gas_water_company {
              company_name
              contact_person
              construction_planned_date {
                dates
                start_time {
                  start_hour
                  start_minute
                }
                end_time {
                  end_hour
                  end_minute
                }
              }
            }
            groundwork_company {
              company_name
              contact_person
              groundwork_by_client
              construction_planned_date {
                dates
                start_time {
                  start_hour
                  start_minute
                }
                end_time {
                  end_hour
                  end_minute
                }
              }
            }
            internal_construction_management {
              construction_manager
              construction_planned_date {
                dates
                start_time {
                  start_hour
                  start_minute
                }
                end_time {
                  end_hour
                  end_minute
                }
              }
              regional_team {
                team_id
                team_name
              }
            }
          }
        }`,
      {
        id: orderId,
      }
    );
  },
  async getAppointmentsByRegionalTeam(requestBody) {
    return GraphQLService.requestWithOpenID(
      `
          query getAppointmentsByRegionalTeam($limit: Int, $nextToken: String, $regional_team: String) { 
            getAppointmentsByRegionalTeam(limit: $limit, nextToken: $nextToken, regional_team: $regional_team ) {
              items {
                id
                appointment_type
                regional_team
                payload
                title
            }
            nextToken
          }
        }`,
      {
        limit: requestBody.limit,
        nextToken: requestBody.nextToken,
        regional_team: requestBody.regional_team,
      }
    );
  },
  async searchByCaseNumber(search) {
    return GraphQLService.requestWithOpenID(
      `
      query caseNumberSearch($search: String) { 
        caseNumberSearch(search: $search) {
          obj_type
          id
          case_number
          summary
        }
      }`,
      {
        search: search,
      }
    );
  },
};
