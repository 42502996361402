<template>
  <div>
    <div
      :class="
        isLargeScreen
          ? 'installer_id_card_container'
          : 'installer_id_card_container_xs'
      "
    >
      <div v-for="(filename, index) in filenames" v-bind:key="index">
        <download-id-component
          :fileName="filename"
          :class="
            isLargeScreen ? 'download_id_container' : 'download_id_container_xs'
          "
          @deliver-badge-error="alertError = true"
        />
      </div>
    </div>
    <v-alert
      class="alert_success"
      v-if="alert"
      max-width="270px"
      max-height="35px"
      text
      transition="scale-transition"
    >
      <template v-slot:append>
        <v-icon color="green" class="ml-2">mdi-check</v-icon>
      </template>
      Erfolgreich versendet
    </v-alert>

    <v-alert
      class="error_alert mt-4"
      max-width="270px"
      v-if="alertError"
      text
      transition="scale-transition"
    >
      <template v-slot:append>
        <v-icon color="white" class="ml-2">mdi-alert-outline</v-icon>
      </template>
      Versenden fehlgeschlagen
    </v-alert>
  </div>
</template>

<script>
import DownloadIdComponent from './DownloadIdComponent';
import { emptyEventData } from '../../store/ordersModule';

export default {
  name: 'InstallerIdComponent',
  components: {
    DownloadIdComponent,
  },
  data() {
    return {
      alert: false,
      alertError: false,
    };
  },
  created() {
    this.$store.commit('setLastUpdatedComponent', '');
  },
  computed: {
    notifyEvent() {
      return this.$store.getters.getNotifyEvent
        ? this.$store.getters.getNotifyEvent
        : emptyEventData;
    },
    filenames() {
      return this.$store.getters.getSelectedInstaller.badge_filenames;
    },
    isLargeScreen() {
      return this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
  watch: {
    notifyEvent(newValue) {
      if (newValue.field === 'deliverInstallerBadge') this.alert = true;
      setTimeout(() => {
        (this.alert = false),
          this.$store.commit('setNotifyEvent', emptyEventData);
      }, 3000);
    },
  },
};
</script>

<style>
.installer_id_card_container {
  display: flex;
}
.installer_id_card_container_xs {
  display: flex;
  flex-direction: column;
}
.installer_id_number {
  font-size: 16px;
  margin-right: 50px;
}
.upload-file-default-style {
  width: 100px;
}
.error_alert {
  background-color: #ff5252 !important;
  color: white !important;
}
.download_id_container {
  margin-right: 5px;
}
.download_id_container_xs {
  margin-top: 5px;
}
</style>
