import Vue from 'vue';

export const feedInSystemsUpdateCounterModule = {
  namespaced: true,
  state: {
    counters: [],
  },
  getters: {
    getCounterByPosition: (state) => (position) => {
      return state.counters[position];
    },
    getCounterFieldByPosition: (state) => (position, field) => {
      return state.counters[position] ? state.counters[position][field] : null;
    },
  },
  mutations: {
    setCounter(state, { position, counter }) {
      if (!state.counters[position]) {
        Vue.set(state.counters, position, {});
      }
      Vue.set(state.counters, position, counter);
    },
    updateCounterField(state, { position, field, value }) {
      if (!state.counters[position]) {
        Vue.set(state.counters, position, {});
      }
      Vue.set(state.counters[position], field, value);
    },
    setCounters(state, counters) {
      state.counters = counters;
    },
  },
  actions: {
    setCounter({ commit }, payload) {
      commit('setCounter', payload);
    },
    updateCounterField({ commit }, payload) {
      commit('updateCounterField', payload);
    },
    setCounters({ commit }, counters) {
      commit('setCounters', counters);
    },
  },
};
