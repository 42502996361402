export const emobilityChargingPowerModule = {
  state: {},
  mutations: {
    setChargingPower(state, e_mobility_installation) {
      this.state.changed_object.e_mobility_installation =
        e_mobility_installation;
    },
    setChargingStationCustomValue(state, charging_station_custom_value) {
      this.state.changed_object.charging_station_custom_value =
        charging_station_custom_value;
    },
  },
  getters: {
    getChargingPower: (state, getters, rootState) => {
      return rootState.changed_object.e_mobility_installation;
    },
    getChargingStationCustomValue: (state, getters, rootState) => {
      return rootState.changed_object.charging_station_custom_value;
    },
  },
};
