<template>
  <div>
    <v-row style="justify-content: flex-end; margin-right: 0px"> </v-row>
    <single-raw
      first
      class="single-raw"
      :title="$t('installer_details.date')"
      :editable="false"
      >{{ formatDate(selectedEmobility.request_date) }}</single-raw
    >

    <single-raw
      class="single-raw"
      :title="$t('order_details.connection_address')"
      @edit="
        $emit('show-modal', {
          component: 'EmobilityInstallationAddressComponent',
          method: 'updateEmobilityInstallationAddress',
        })
      "
    >
      <div v-html="emobilityDetails.installationAddress"></div>
      <userfeedback-component
        v-if="notifyEvent.field === 'updateEmobilityInstallationAddress'"
      />
    </single-raw>

    <single-raw
      class="single-raw"
      :title="$t('emobility_details.location')"
      @edit="
        $emit('show-modal', {
          component: 'EmobilityWallboxLocationComponent',
          method: 'updateEmobilityWallboxLocation',
        })
      "
    >
      <div>{{ emobilityDetails.wallbox_location }}</div>
      <userfeedback-component
        v-if="notifyEvent.field === 'updateEmobilityWallboxLocation'"
      />
    </single-raw>

    <single-raw
      class="single-raw"
      :title="$t('emobility_details.charging_power')"
      @edit="
        $emit('show-modal', {
          component: 'EmobilityChargingPowerComponent',
          method: 'updateEmobilityChargingPower',
        })
      "
    >
      <div>{{ emobilityDetails.e_mobility_installation }}</div>
      <userfeedback-component
        v-if="notifyEvent.field === 'updateEmobilityChargingPower'"
      />
    </single-raw>

    <single-raw
      class="single-raw"
      :title="$t('order_details.blueprints')"
      @edit="
        $emit('show-modal', {
          component: 'EmobilityFileUploadComponent',
          method: 'updateEmobilityFilesName',
        })
      "
    >
      <div v-if="selectedEmobility.plans_available">
        <div class="blueprints_component_content">
          <div>
            {{ $t('emobility_details.site_plan') }}
            <v-btn
              icon
              v-for="item in emobilityDetails.blueprints"
              :key="item"
              @click="downloadEmobilityFile(item)"
              ><v-icon>mdi-download</v-icon></v-btn
            >
          </div>
        </div>
      </div>
      <div v-else>
        <div>-</div>
      </div>
      <userfeedback-component
        v-if="notifyEvent.field === 'updateEmobilityFilesName'"
      />
    </single-raw>

    <single-raw
      class="single-raw"
      :title="$t('order_details.applicant')"
      @edit="
        $emit('show-modal', {
          component: 'EmobilityAppplicantComponent',
          method: 'updateEmobilityApplicant',
        })
      "
    >
      <div v-html="emobilityDetails.applicant"></div>

      <userfeedback-component
        v-if="notifyEvent.field === 'updateEmobilityApplicant'"
      />
    </single-raw>

    <single-raw
      class="single-raw"
      :title="$t('order_details.additional_contact_person')"
      @edit="
        $emit('show-modal', {
          component: 'EmobilityAdditionalContactComponent',
          method: 'updateEmobilityAdditionalContactPerson',
        })
      "
    >
      <div v-html="emobilityDetails.otherContacts"></div>
      <userfeedback-component
        v-if="notifyEvent.field === 'updateEmobilityAdditionalContactPerson'"
      />
    </single-raw>

    <single-raw
      class="single-raw"
      :title="$t('order_details.landowner')"
      last
      @edit="
        $emit('show-modal', {
          component: 'EmobilityLandownerComponent',
          method: 'updateEmobilityLandowner',
        })
      "
    >
      <div v-html="emobilityDetails.landowner"></div>
      <userfeedback-component
        v-if="notifyEvent.field === 'updateEmobilityLandowner'"
      />
    </single-raw>
  </div>
</template>

<script>
import SingleRaw from '../order-details/SingleRaw';
import { formatEmobilityData } from './transform_raw_data_to_emobility_format';
import axios from 'axios';
import fileMethods from '../../helpers/fileMethods';
import documentsService from '../../services/DocumentsService';
import { emptyEventData } from '../../store/ordersModule';
import UserfeedbackComponent from './UserfeedbackComponent.vue';

export default {
  name: 'EmobilityDetails',
  components: {
    SingleRaw,
    UserfeedbackComponent,
  },
  props: {
    file_names: {
      type: Object,
    },
  },
  created() {
    for (const [key, value] of Object.entries(this.file_names)) {
      if (value) {
        this.getEmobilityDocument(value, key);
      }
    }
  },
  watch: {
    notifyEvent(newValue) {
      if (newValue !== null && newValue.field.includes('Emobility')) {
        setTimeout(() => {
          this.$store.commit('setNotifyEvent', emptyEventData);
        }, 3000);
      }
    },
  },
  computed: {
    notifyEvent() {
      return this.$store.getters.getNotifyEvent
        ? this.$store.getters.getNotifyEvent
        : emptyEventData;
    },
    selectedEmobility() {
      return this.$store.getters.getSelectedEmobility;
    },
    emobilityDetails() {
      return formatEmobilityData(this.selectedEmobility);
    },
  },
  methods: {
    formatDate(date) {
      return this.$dayjs(new Date(date)).format('DD.MM.YYYY HH:mm');
    },
    getEmobilityDocument(file_name, file_type) {
      documentsService
        .getEmobilityDocumentGraphQL(
          this.selectedEmobility.id + '/' + file_name,
          process.env.VUE_APP_STAGE
        )
        .then(async (res) => {
          this.downloadFromPresignedURL(res.downloadFile, file_name, file_type);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    downloadEmobilityFile(item) {
      fileMethods.downloadFile(item, 'Emobility');
    },
    async downloadFromPresignedURL(fileURL, file_name, file_type) {
      const downloadResponse = await axios({
        url: fileURL,
        method: 'GET',
        headers: {
          Accept: '*',
          'Content-Type': 'application/pdf;charset=utf-8',
        },
        responseType: 'arraybuffer',
        data: {},
      });
      const product = 'emobility';
      const position = parseInt(file_name.charAt(0));
      const id = this.selectedEmobility.id + '_' + position;
      const fileObject = fileMethods.convertToBlobAndReturnFileObject(
        downloadResponse.data,
        file_name,
        product,
        id,
        file_type
      );
      this.$store.commit('setEmobilityFileURL', fileObject);
    },
  },
};
</script>

<style>
.blueprints_component_content {
  line-height: 22px;
  font-size: 18px;
  letter-spacing: 0px;
  opacity: 1;
}
</style>
