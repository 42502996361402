<template>
  <svg
    id="Icon_Montage_Strom"
    data-name="Icon Montage_Strom"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
  >
    <path
      id="Pfad_235"
      data-name="Pfad 235"
      d="M16,0A16,16,0,1,1,0,16,16,16,0,0,1,16,0Z"
      fill="#9b004b"
    />
    <g
      id="Icon_metro-power-cord"
      data-name="Icon metro-power-cord"
      transform="translate(7.724 7.011)"
    >
      <path
        id="Icon_metro-power-cord-2"
        data-name="Icon metro-power-cord"
        d="M22.191,7.13,20.524,5.464,17.233,8.755,15.364,6.886l3.292-3.292L16.989,1.928,13.7,5.22,11.584,3.107l-1.6,1.6,9.428,9.428,1.6-1.6L18.9,10.422Zm-4.234,7.205L9.784,6.163c-1.764,2.116-3.766,5.385-2.383,8.2L4.966,16.8a1.477,1.477,0,0,0,0,2.083l.274.274a1.477,1.477,0,0,0,2.083,0l2.435-2.435C12.571,18.1,15.84,16.1,17.956,14.335Z"
        transform="translate(-4.536 -1.928)"
        fill="#fff"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'BaubeabsichtigungsterminIcon',
  props: {
    width: {
      type: [Number, String],
      default: 30,
    },
    height: {
      type: [Number, String],
      default: 30,
    },
  },
};
</script>

<style scoped></style>
