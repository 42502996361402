var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"dashboard"},[_c('div',{staticClass:"container_dashboard"},[_c('construction-dashboard-data-component',{attrs:{"contactPerson":_vm.internalConstructionManagement.construction_manager,"title":_vm.$t('calendar_side_selection_component.construction_manager_NEW'),"constructionDate":_vm.internalConstructionManagement.construction_planned_date},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('baubesichtigungstermin-icon',{staticClass:"mr-1"})]},proxy:true}])}),_c('construction-dashboard-data-component',{attrs:{"companyName":_vm.groundwork.company_name,"contactPerson":_vm.groundwork.contact_person,"title":_vm.$t('construction_work.civil_engineering'),"constructionDate":_vm.groundwork.construction_planned_date},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('tiefbau-icon',{staticClass:"mr-1"})]},proxy:true}])}),(
          _vm.selectedOrderProduct.includes('electricity') ||
          this.selectedOrderProduct.includes('eMobility')
        )?_c('construction-dashboard-data-component',{attrs:{"companyName":_vm.assemblyElectricity.company_name,"contactPerson":_vm.assemblyElectricity.contact_person,"title":_vm.$t('calendar_side_selection_component.montage_electricity'),"constructionDate":_vm.assemblyElectricity.construction_planned_date},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('montage-strom-icon',{staticClass:"mr-1"})]},proxy:true}],null,false,211782913)}):_vm._e(),(
          _vm.selectedOrderProduct.includes('gas') ||
          this.selectedOrderProduct.includes('water')
        )?_c('construction-dashboard-data-component',{attrs:{"companyName":_vm.assemblyGasWater.company_name,"contactPerson":_vm.assemblyGasWater.contact_person,"title":_vm.selectedOrderProduct === 'electricity&water&gas'
            ? _vm.$t('calendar_side_selection_component.montage_gas_water')
            : _vm.selectedOrderProduct === 'electricity&water'
            ? _vm.$t('calendar_side_selection_component.montage_water')
            : _vm.$t('calendar_side_selection_component.montage_gas'),"constructionDate":_vm.assemblyGasWater.construction_planned_date,"secondIcon":_vm.selectedOrderProduct === 'electricity&water&gas'
            ? (_vm.secondIcon = true)
            : false},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(_vm.selectedOrderProduct.includes('gas'))?_c('montage-gas-icon',{staticClass:"mr-1"}):_vm._e(),(_vm.selectedOrderProduct === 'electricity&water')?_c('montage-wasser-icon',{staticClass:"mr-1 mt-2"}):_vm._e()]},proxy:true},(_vm.selectedOrderProduct === 'electricity&water&gas')?{key:"icon2",fn:function(){return [_c('montage-wasser-icon',{staticClass:"mr-1 mt-2"})]},proxy:true}:null],null,true)}):_vm._e()],1),_c('div',{staticClass:"edit_icon"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('edit', $event)}}},[_c('edit-icon')],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }