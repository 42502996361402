import GraphQLService from './GraphQLService';

export default {
  async addEmobilityNote(requestObject, user) {
    return GraphQLService.requestWithOpenID(
      `
        mutation addEmobilityNote($id: String!, $noteObject: NotePayload, $event_origin: String!, $changed_by: String!) {
          addEmobilityNote(id: $id, payload: $noteObject, event_origin: $event_origin, changed_by: $changed_by){
            _persistent_event_id_
            id
            changed_by
            event_origin
            created
            title
            field 
            note
            filename
          }
        }`,
      {
        id: requestObject.id,
        noteObject: requestObject,
        event_origin: 'BACKOFFICE',
        changed_by: user,
      }
    );
  },
  async addInstallerNote(requestObject, user) {
    return GraphQLService.requestWithOpenID(
      `
        mutation addInstallerNote($id: String!, $noteObject: NotePayload, $event_origin: String!, $changed_by: String!) {
          addInstallerNote(id: $id, payload: $noteObject, event_origin: $event_origin, changed_by: $changed_by){
            _persistent_event_id_
            id
            changed_by
            event_origin
            created
            title
            field 
            old_value
            new_value
            note
            filename
          }
        }`,
      {
        id: requestObject.id,
        noteObject: requestObject,
        event_origin: 'BACKOFFICE',
        changed_by: user,
      }
    );
  },
  async addOrderNote(requestObject, user) {
    return GraphQLService.requestWithOpenID(
      `
        mutation addOrderNote($id: String!, $noteObject: NotePayload, $event_origin: String!, $changed_by: String!) {
          addOrderNote(id: $id, payload: $noteObject, event_origin: $event_origin, changed_by: $changed_by){
            _persistent_event_id_
            id
            changed_by
            event_origin
            created
            title
            field 
            old_value
            new_value
            note
            filename
          }
        }`,
      {
        id: requestObject.id,
        noteObject: requestObject,
        event_origin: 'BACKOFFICE',
        changed_by: user,
      }
    );
  },
  async addPhotovoltaicNote(requestObject, user) {
    return GraphQLService.requestWithOpenID(
      `
        mutation addPhotovoltaicNote($id: String!, $noteObject: NotePayload, $event_origin: String!, $changed_by: String!) {
          addPhotovoltaicNote(id: $id, payload: $noteObject, event_origin: $event_origin, changed_by: $changed_by){
            _persistent_event_id_
            id
            changed_by
            event_origin
            created
            title
            field
            note
            filename
          }
        }`,
      {
        id: requestObject.id,
        noteObject: requestObject,
        event_origin: 'BACKOFFICE',
        changed_by: user,
      }
    );
  },
};
