import GraphQLService from './GraphQLService';

export default {
  async getOrderDocumentGraphQL(fileName, stage) {
    return GraphQLService.requestWithOpenID(
      `
        query downloadFileS3 ($bucketName: String, $fileName: String) {
          downloadFile(bucket: $bucketName, key: $fileName)
        }`,
      {
        fileName: fileName,
        bucketName: `netzportal-orders-${stage}`,
      }
    );
  },
  async putOrderDocumentGraphQL(fileName, stage, encrypted) {
    return GraphQLService.requestWithOpenID(
      `
        query uploadFileS3($bucketName: String, $fileName: String, $encrypted: Boolean) {
          uploadFile(bucket: $bucketName, key: $fileName, encrypted: $encrypted)
        }`,
      {
        encrypted: Boolean(encrypted),
        fileName: fileName,
        bucketName: `netzportal-orders-${stage}`,
      }
    );
  },
  async getInstallerDocumentGraphQL(fileName, stage) {
    return GraphQLService.requestWithOpenID(
      `
      query downloadFileS3($bucketName: String, $fileName: String) {
        downloadFile(bucket: $bucketName, key: $fileName)
      }`,
      {
        fileName: fileName,
        bucketName: `netzportal-installers-concessions-${stage}`,
      }
    );
  },
  async putInstallerDocumentGraphQL(fileName, stage) {
    return GraphQLService.requestWithOpenID(
      `
      query uploadFileS3($bucketName: String, $fileName: String) {
        uploadFile(bucket: $bucketName, key: $fileName)
      }`,
      {
        fileName: fileName,
        bucketName: `netzportal-installers-concessions-${stage}`,
      }
    );
  },
  async getEmobilityDocumentGraphQL(fileName, stage) {
    return GraphQLService.requestWithOpenID(
      `
      query downloadFileS3($bucketName: String, $fileName: String) {
        downloadFile(bucket: $bucketName, key: $fileName)
      }`,
      {
        fileName: fileName,
        bucketName: `netzportal-emobility-${stage}`,
      }
    );
  },
  async putEmobilityDocumentGraphQL(fileName, stage, encrypted) {
    return GraphQLService.requestWithOpenID(
      `
        query uploadFileS3($bucketName: String, $fileName: String, $encrypted: Boolean){
          uploadFile(bucket: $bucketName, key: $fileName, encrypted: $encrypted)
        }`,
      {
        encrypted: Boolean(encrypted),
        fileName: fileName,
        bucketName: `netzportal-emobility-${stage}`,
      }
    );
  },
  async getPhotovoltaicDocumentGraphQL(fileName, stage) {
    return GraphQLService.requestWithOpenID(
      `
        query downloadFileS3 ($bucketName: String, $fileName: String) {
          downloadFile(bucket: $bucketName, key: $fileName)
        }`,
      {
        fileName: fileName,
        bucketName: `netzportal-photovoltaic-${stage}`,
      }
    );
  },
  async putPhotovoltaicDocumentGraphQL(fileName, stage, encrypted) {
    return GraphQLService.requestWithOpenID(
      `
        query uploadFileS3($bucketName: String, $fileName: String, $encrypted: Boolean){
          uploadFile(bucket: $bucketName, key: $fileName, encrypted: $encrypted)
        }`,
      {
        encrypted: Boolean(encrypted),
        fileName: fileName,
        bucketName: `netzportal-photovoltaic-${stage}`,
      }
    );
  },
  async putPVTechnicalInspectionDocumentGraphQL(fileName, stage, encrypted) {
    return GraphQLService.requestWithOpenID(
      `
        query uploadFileExtended($bucket: String, $key: String, $encrypted: Boolean){
          uploadFileExtended(bucket: $bucket, key: $key, encrypted: $encrypted) {
            url
            key
            uuid
          }
        }`,
      {
        bucket: `netzportal-photovoltaic-${stage}`,
        key: fileName,
        encrypted: Boolean(encrypted),
      }
    );
  },
  async resendDocument(
    type,
    id,
    recipients,
    additionalComment,
    filename,
    user
  ) {
    return GraphQLService.requestWithOpenID(
      `
        mutation resendFile($type: String!, $id: String!,$recipients: [String!]!, $additional_comment: String, $filename: String!, $changed_by: String!){
          resendFile(type: $type, id: $id,recipients: $recipients, additional_comment: $additional_comment, filename: $filename, event_origin: "BACKOFFICE", changed_by: $changed_by) {
            field
            id
            filename
            changed_by
            created
          }  
        }`,
      {
        type,
        id,
        recipients,
        additional_comment: additionalComment,
        filename,
        changed_by: user,
      }
    );
  },
};
