export const photovoltaicInstallationAddressModule = {
  state: {
    is_with_plot_number: false,
  },
  mutations: {
    setIsPhotovoltaicWithPlotNumber(state, is_with_plot_number) {
      state.is_with_plot_number = is_with_plot_number;
    },
    setPhotovoltaicProductInstallationChangedObject(state, changedData) {
      this.state.changed_object.product_installation_full_address[
        changedData.key
      ] = changedData.value;
    },
    setPhotovoltaicWithPlotNumberChangedObject(state, changedData) {
      this.state.changed_object.product_installation_with_plot_number_address[
        changedData.key
      ] = changedData.value;
    },
  },
  getters: {
    getIsPhotovoltaicWithPlotNumber: (state) => {
      return state.is_with_plot_number;
    },
  },
  actions: {},
};
