import i18n from '../../plugins/i18n';
import { ORDER_STATUSES, REGIONAL_TEAMS } from '../../helpers/constants';

const configurations = {
  statusValuesList: getStatusesConfig(),
  headersAktive: [
    { text: 'Datum', value: 'date' },
    { text: 'Antragsteller', value: 'applicant' },
    { text: 'Produktgruppe', value: 'product' },
    { text: 'Anschlussadresse', value: 'address' },
    { text: 'zul. bearbeitet von', value: 'lastChangedBy' },
    {
      text: 'Journey',
      value: 'journey',
      class: 'hide-column',
      align: ' d-none',
      filterable: true,
      filter: (value) =>
        this.filterJourney
          ? value === this.filterJourney
          : value === 'einfamilienhaus' || value === 'eMobility',
    },
    {
      text: 'Status',
      value: 'status',
      filterable: true,
      filter: (value) =>
        this.statusSelection === 'all' || value === this.statusSelection,
    },
    { value: 'edit' },
    {
      value: 'regional_team',
      class: 'hide-column',
      align: ' d-none',
      filterable: true,
      filter: (value) =>
        this.regionalTeamSelection.value === 'all' ||
        value === this.regionalTeamSelection.value,
    },
  ],
  regionalTeams: getRTConfig(),
  countOrderStatuses: [
    {
      name: 'count_order_created',
      color: '#d79bb8',
    },
    {
      name: 'count_order_technical_feasibility',
      color: '#CF8AA9',
    },
    {
      name: 'count_order_confirmed',
      color: '#C7799A',
    },
    {
      name: 'count_order_appointment_pending',
      color: '#BF678B',
    },
    {
      name: 'count_order_appointment_confirmed',
      color: '#B7567B',
    },
    {
      name: 'count_order_in_execution',
      color: '#AE436C',
    },
    {
      name: 'count_order_fulfilled',
      color: '#A52F5C',
    },
    {
      name: 'count_order_billed',
      color: '#9B144C',
    },
    {
      name: 'count_order_manual',
      color: '#A52F5C',
    },
    {
      name: 'count_order_cancelled',
      color: '#525252',
    },
  ],
};

export const getOrdersTableConfig = (configName) => {
  return JSON.parse(JSON.stringify(configurations[configName]));
};

function getStatusesConfig() {
  let statusesArray = [];
  statusesArray = Object.keys(ORDER_STATUSES).map((key) => {
    const { value, color } = ORDER_STATUSES[key];
    return { value, color };
  });
  statusesArray.forEach((item) => {
    item.label = i18n.t(`order_statuses.${item.value}`);
  });
  statusesArray.unshift({ label: 'alle', value: 'all', color: '#999999' });
  return statusesArray;
}

function getRTConfig() {
  let statusesArray = [];
  statusesArray = Object.keys(REGIONAL_TEAMS).map((key) => {
    const { label, value, color } = REGIONAL_TEAMS[key];
    return { label, value, color };
  });

  statusesArray.unshift({ label: 'alle', value: 'all', color: '#999999' });
  return statusesArray;
}
