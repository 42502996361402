export const pricingModule = {
  state: {
    houseDistance: 'lessThan25meters',
    requires_grid_crosspoint: '0',
    ownContributionPrice: 75,
    lastPrice: 0,
    recalculate_prices: false,
    preferred_network_connection_price: 0,
    gas_price: 0,
    emobility_price: 0,
    own_contribution_selected: false,
  },
  mutations: {
    setLastPrice(state, lastPrice) {
      state.lastPrice = lastPrice;
    },
    setOwnContributionPrice(state, ownContributionPrice) {
      state.ownContributionPrice = ownContributionPrice;
    },
    setOwnContributionSelected(state, own_contribution_selected) {
      state.own_contribution_selected = own_contribution_selected;
      this.state.changed_object.own_contribution_selected =
        own_contribution_selected;
    },
    setPreferredNetworkConnectionSelected(state, preferred_network_connection) {
      state.preferred_network_connection = preferred_network_connection;
      this.state.changed_object.preferred_network_connection =
        preferred_network_connection;
    },
    setRequiresGridCrosspoint(state, requires_grid_crosspoint) {
      state.requires_grid_crosspoint = requires_grid_crosspoint;
    },
    setRequiresGridCrosspointChangedObject(state, requires_grid_crosspoint) {
      this.state.changed_object.requires_grid_crosspoint =
        requires_grid_crosspoint;
    },
    setEChargingStationControllableSelected(
      state,
      e_charging_station_controllable_selected
    ) {
      this.state.changed_object.e_charging_station_controllable_selected =
        e_charging_station_controllable_selected;
    },
    setRecalculatePrices(state, recalculate_prices) {
      state.recalculate_prices = recalculate_prices;
    },
    setPreferredNetworkConnectionPrice(state, price) {
      state.preferred_network_connection_price = price;
    },
    setGasPrice(state, price) {
      state.gas_price = price;
    },
    setEmoblityPrice(state, price) {
      state.emobility_price = price;
    },
  },
  getters: {
    getOwnContributionSelected: (state, getters, rootState) => {
      return rootState.changed_object.own_contribution_selected;
    },
    getPreferredNetworkConnectionSelected: (state, getters, rootState) => {
      return rootState.changed_object.preferred_network_connection;
    },
    getRequiresGridCrosspoint: (state) => {
      return state.requires_grid_crosspoint;
    },
    getElectricityPricingParametersForGetRequest: (
      state,
      getters,
      rootState
    ) => {
      const electricity_parameters = {
        heat_pump: false,
        e_charging_station: false,
        water_heater: 0,
        requires_grid_crosspoint: false,
      };
      const electricInstallations =
        rootState.orders.selectedOrder.electric_installations;
      if (electricInstallations) {
        for (const e_installation of Object.keys(electricInstallations)) {
          switch (e_installation) {
            case 'heat_pump_hot_water':
            case 'heat_pump':
              electricity_parameters.heat_pump = true;
              break;
            case 'e_charging_station':
              electricity_parameters.e_charging_station = true;
              break;
            case 'water_heater':
              electricity_parameters.water_heater =
                rootState.orders.selectedOrder.electric_installations.water_heater;
              break;

            default:
              break;
          }
        }
      }
      if (state.requires_grid_crosspoint === '1') {
        electricity_parameters.requires_grid_crosspoint = true;
      }
      return electricity_parameters;
    },
    getOwnContributionPrice: (state) => {
      return state.ownContributionPrice;
    },
    getEChargingStationControllableSelected: (state, getters, rootState) => {
      return rootState.changed_object.e_charging_station_controllable_selected;
    },
    getLastPrice(state) {
      return state.lastPrice;
    },
    getQuantityAllSelectedUnits: (state, getters, rootState) => {
      if (rootState.orders.selectedOrder.journey !== 'mehrfamilienhaus') {
        return 1;
      } else {
        const quantityResidentialUnits =
          +rootState.orders.selectedOrder?.residential_units;
        const quantityBusinessUnits =
          +rootState.orders.selectedOrder?.business_units;
        const allQuantityUnits =
          quantityResidentialUnits + quantityBusinessUnits;

        return allQuantityUnits;
      }
    },
    getEMobPricingParameters: (state, getters) => {
      const emob_params = {
        e_charging_station_controllable_selected:
          getters.getEChargingStationControllableSelected,
        number_of_e_charging_stations: 1,
      };
      return {
        electricity: emob_params,
        own_contribution_selected: getters.getOwnContributionSelected,
      };
    },
    getPricingParametersRequest: (state, getters, rootState) => {
      const selectedOrder = rootState.orders.selectedOrder;
      const choosedProduct = selectedOrder.product.split('&');

      const params = {
        number_of_apartments: selectedOrder.residential_units,
        own_contribution_selected: getters.getOwnContributionSelected,
        electricity: choosedProduct.includes('electricity')
          ? getters.getElPricingParameters
          : null,
        gas: choosedProduct.includes('gas')
          ? getters.getGasPricingParameters
          : null,
        water: choosedProduct.includes('water')
          ? getters.getWaterPricingParameters
          : null,
      };
      return params;
    },
    getWaterPricingParameters: (state, getters, rootState) => {
      const selectedOrder = rootState.orders.selectedOrder;
      const water_inst = selectedOrder.water_installations;
      const water_params = {
        apartment_units_water_ratings: water_inst
          ? water_inst.apartment_units_water_ratings
          : 0,
        business_units_water_ratings: getters.getBusinessUnitsRatings(
          'water_installations_m3_h'
        ),
      };
      return water_params;
    },
    getElPricingParameters: (state, getters, rootState) => {
      const selectedOrder = rootState.orders.selectedOrder;
      const journey = selectedOrder.journey;
      const el_inst = selectedOrder.electric_installations;

      const el_params = {
        preferred_network_connection_selected:
          getters.getPreferredNetworkConnectionSelected,
        business_units_power_ratings:
          journey === 'mehrfamilienhaus'
            ? getters.getBusinessUnitsRatings('electric_installations_kW')
            : null,
        water_heaters_power_rating:
          journey === 'mehrfamilienhaus'
            ? el_inst.water_heaters_power_rating
            : null,
        number_of_water_heaters:
          journey === 'einfamilienhaus' ? el_inst.water_heater : null,
      };
      return el_params;
    },
    getGasPricingParameters: (state, getters, rootState) => {
      const selectedOrder = rootState.orders.selectedOrder;
      const journey = selectedOrder.journey;
      const gas_inst = selectedOrder.gas_installations;
      const installations =
        journey === 'mehrfamilienhaus'
          ? {
              cooker_gas_rating: gas_inst.cooker_gas_rating,
              heating_gas_rating: gas_inst.heating_gas_rating,
            }
          : { gas_installations: getters.getEfhGasInstallationsAsArray };
      const gas_params = {
        business_units_gas_ratings:
          journey === 'mehrfamilienhaus'
            ? getters.getBusinessUnitsRatings('gas_installations_kW')
            : null,
        ...installations,
      };
      return gas_params;
    },
    getEfhGasInstallationsAsArray: (state, getters, rootState) => {
      const currentOrder = rootState.orders.selectedOrder;
      const gas_inst = currentOrder.gas_installations;
      const result = Object.keys(gas_inst).filter((k) => gas_inst[k]);

      let gasInstallations = [];
      if (gas_inst) {
        gasInstallations = result.map((el) => {
          if (el === 'gas_heating_hot_water') {
            el = 'gas_heating';
          }
          return el;
        });
      }
      return gasInstallations;
    },
    getBusinessUnitsRatings: (state, getters, rootState) => (key) => {
      const business_data_array = rootState.orders.selectedOrder.business_data
        ? rootState.orders.selectedOrder.business_data
        : [];
      const businessUnitsRatings = [];
      if (business_data_array.length > 0) {
        business_data_array.forEach((element) => {
          businessUnitsRatings.push(Number(element[key]));
        });
      }

      return businessUnitsRatings;
    },
    getChoosedProduct: (state, getters, rootState) => {
      return rootState.orders.selectedOrder.product;
    },
    getRecalculatePrices: (state) => {
      return state.recalculate_prices;
    },
    getGasPrice: (state) => {
      return state.gas_price;
    },
    getEmoblityPrice: (state) => {
      return state.emobility_price;
    },
    getPreferredNetworkConnectionPrice: (state) => {
      return state.preferred_network_connection_price;
    },
  },
};
