<template>
  <div>
    <v-btn
      class="ma-0 pa-0"
      active-class="active-class"
      text
      min-width="0"
      :to="to"
      >{{ title }}</v-btn
    >
  </div>
</template>

<script>
export default {
  name: 'NavLink1',
  props: ['to', 'title'],
};
</script>

<style scoped>
.active-class {
  border-style: none none solid none;
  border-radius: 0;
  border-color: #9b004b;
  background-color: transparent;
  color: #9b004b !important;
}
.active-class:before {
  opacity: 0 !important;
}
</style>
