<template>
  <div>
    <div class="heading">{{ $t('feed_in_systems.page_title') }}</div>
    <filter-product-button :toggleWidth="'25%'" :valuesList="valuesList" />
    <new-glas-card :title="this.translateFilterValue">
      <template v-slot:back-button>
        <v-btn class="back-button mb-6" @click="goOnePageBack()"
          ><v-icon color="#6E6E6E">mdi-arrow-left</v-icon></v-btn
        >
      </template>
      <FeedInSystemsOverviewComponent />
    </new-glas-card>
  </div>
</template>

<script>
import { NewGlasCard } from '@bit/new-netzportal.netzportal.new-glas-card';
import FilterProductButton from '../components/design-components/FilterProductButton';
import FeedInSystemsOverviewComponent from '../components/feed-in-systems-overview-component/FeedInSystemsOverviewComponent.vue';
import { getPhotovoltaicsSummary } from '../services/FeedInSystemsService';

export default {
  name: 'FeedInSystemsPage',
  components: {
    FeedInSystemsOverviewComponent,
    NewGlasCard,
    FilterProductButton,
  },
  created() {
    this.getPVOrdersSummaryFromBackend();
  },
  data() {
    return {
      ordersSummary: [],
    };
  },
  computed: {
    journeyFilter() {
      return this.$store.state.orders.journeyFilter;
    },
    translateFilterValue() {
      switch (this.journeyFilter) {
        case 'alle':
          return this.$t('feed_in_systems.all_pv_orders');
        case 'photovoltaik':
          return this.$t('feed_in_systems.pv_system_request');
        case 'pv-inbetriebnahme':
          return this.$t('feed_in_systems.pv_system_commissioning');
        case 'pv-inbetriebnahme-protokoll':
          return this.$t('feed_in_systems.pv_system_commissioning_protocol');
        // case 'blockheizkraftwerk':
        //   return this.$t('feed_in_systems.combined_heat_and_power_plant');
        // case 'windkraft':
        //   return this.$t('feed_in_systems.wind_power');
        // case 'wasserkraft':
        //   return this.$t('feed_in_systems.water_power');
        // case 'biogas':
        //   return this.$t('feed_in_systems.biogas');
        default:
          return '';
      }
    },
    valuesList() {
      return [
        {
          label: 'Alle Aufträge',
          id: 'alle',
          value: this.countAllPVOrders,
        },
        {
          label: 'PV Anfrage',
          id: 'photovoltaik',
          value: this.countOrdersPVSystemRequest,
        },
        {
          label: 'PV Inbetriebnahme',
          id: 'pv-inbetriebnahme',
          value: this.countOrdersPVSystemCommissioning,
        },
        {
          label: 'PV Inbetriebnahme Protokoll',
          id: 'pv-inbetriebnahme-protokoll',
          value: this.countOrdersPVSystemCommissioningProtocol,
        },
        // {
        //   label: 'Blockheizkraftwerk',
        //   id: 'blockheizkraftwerk',
        //   value: 'ADDCORRECTVALUE',
        // },
        // {
        //   label: 'Windkraft',
        //   id: 'windkraft',
        //   value: 'ADDCORRECTVALUE',
        // },
        // {
        //   label: 'Wasserkraft',
        //   id: 'wasserkraft',
        //   value: 'ADDCORRECTVALUE',
        // },
        // {
        //   label: 'Biogas',
        //   id: 'biogas',
        //   value: 'ADDCORRECTVALUE',
        // },
      ];
    },
    countAllPVOrders() {
      return this.ordersSummary.count_orders_all;
    },
    countOrdersPVSystemRequest() {
      return this.ordersSummary.count_orders_photovoltaik;
    },
    countOrdersPVSystemCommissioning() {
      return this.ordersSummary.count_orders_pv_inbetriebnahme;
    },
    countOrdersPVSystemCommissioningProtocol() {
      return this.ordersSummary.count_orders_pv_inbetriebnahme_protokoll;
    },
  },
  methods: {
    goOnePageBack() {
      const previousPage = this.$store.getters.getPreviousPage;
      if (previousPage) {
        this.$router.push(previousPage);
      } else {
        this.$router.push('/');
      }
    },
    getPVOrdersSummaryFromBackend() {
      getPhotovoltaicsSummary()
        .then((res) => {
          this.ordersSummary = res.getPhotovoltaicsSummary;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.heading {
  font-size: 50px;
  margin-bottom: 0;
}
.back-button {
  border-radius: 40px;
  background-color: rgba(255, 255, 255, 0.9);
  height: 35px !important;
  width: 90px;
  box-shadow: none;
  margin-right: 30px;
}
</style>
