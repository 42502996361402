import * as dayjs from 'dayjs';
import * as weekOfYear from 'dayjs/plugin/weekOfYear';
dayjs.extend(weekOfYear);
import i18n from '../../plugins/i18n';
import store from '../../store/index';
import {
  applicantObject,
  invoiceAddressObject,
  installationAddressObject,
  landownerObject,
  additionalContactPersonObject,
  wallboxLocationObject,
  emobilityInstallationObject,
  dateObject,
  installationCompanyObject,
} from '../../helpers/transformToPresentableData';

export function transformRawDataToOderDetailFormat(rawData) {
  return {
    requestDate: rawData.request_date,
    installationAddress: installationAddressObject(rawData),
    product: `products.${rawData.product}.title`,
    selectedInstallations: installationsObject(rawData),
    totalPrice: rawData.combined_pricings.total,
    priceDetails: pricingObject(
      rawData.combined_pricings,
      rawData.preferred_network_connection,
      rawData.own_contribution_selected
    ),
    blueprints: fileDataObject(rawData),
    appointment: dateObject(rawData),
    installationCompanies: installationCompanyObject(rawData),
    applicant: applicantObject(rawData.applicant),
    invoiceReceiver: invoiceAddressObject(rawData),
    otherContacts: additionalContactPersonObject(rawData),
    landowner: landownerObject(rawData),
    e_mobility_installation: rawData.emobility_payload
      ? emobilityInstallationObject(rawData.emobility_payload)
      : '',
    wallbox_location: rawData.emobility_payload
      ? wallboxLocationObject(rawData.emobility_payload)
      : '',
    emobility_plans: rawData.emobility_payload
      ? rawData.emobility_payload.file_uploads
      : '',
  };
}

function pricingObject(
  pricings,
  preferred_network_connection,
  own_contribution_selected
) {
  const journey = store.getters.getSelectedOrder.journey;
  const journeyTranslation = i18n.t(`pricing_component.${journey}`);
  const res = [];

  for (const price in pricings) {
    if (pricings[price])
      switch (price) {
        case 'electricity_pricings':
          // code block
          res.push({
            name: i18n.t('pricing_component.electricity_price', {
              journey: journeyTranslation,
            }),
            price: pricings[price].price,
          });
          break;
        case 'water_pricings':
          res.push({
            name: i18n.t('pricing_component.water_price', {
              journey: journeyTranslation,
            }),
            price: pricings[price].price,
          });
          res.push({
            name: i18n.t('pricing_component.water_building_costs', {
              journey: journeyTranslation,
            }),
            price: pricings[price].water_building_costs,
          });
          // code block
          break;
        case 'gas_pricings':
          res.push({
            name: i18n.t('pricing_component.gas_price', {
              journey: journeyTranslation,
            }),
            price: pricings[price].price,
          });
          break;
        case 'emobility_pricings':
          res.push({
            name: i18n.t('pricing_component.e_mobility_price'),
            price: pricings[price].price,
          });
          break;
        default:
          // code block
          break;
      }
  }
  if (preferred_network_connection) {
    res.push({
      name: 'Vorgezogener Netzanschluss als Baustromanschluss',
      price: 800,
    });
  }
  if (own_contribution_selected) {
    res.push({ name: 'Eigenleistung', price: -1 * pricings.own_contribution });
  }
  return res;
}

function fileDataObject(rawData) {
  const blueprints = rawData.uploaded_file_names_blueprint
    ? rawData.uploaded_file_names_blueprint
    : [];

  return blueprints
    .concat(rawData.uploaded_file_names_cadastral || [])
    .filter((el) => el !== '');
}

function installationsObject(rawData) {
  let presentable_installations = '';
  const el_inst = rawData['electric_installations'];
  if (el_inst) {
    for (const e_installation of Object.keys(el_inst)) {
      switch (e_installation) {
        case 'heat_pump_hot_water':
          el_inst[e_installation]
            ? (presentable_installations += 'Wärmepumpe mit Warmwasser, ')
            : '';
          break;
        case 'heat_pump_power_rating':
        case 'heat_pump':
          el_inst[e_installation]
            ? (presentable_installations += 'Wärmepumpe, ')
            : '';
          break;
        case 'e_charging_station':
          el_inst[e_installation]
            ? (presentable_installations += 'E-Ladesäule, ')
            : '';
          break;
        case 'water_heater':
        case 'water_heaters_power_rating':
          el_inst[e_installation]
            ? (presentable_installations += 'Durchlauferhitzer, ')
            : '';
          break;
      }
    }
    if (presentable_installations.endsWith(', ')) {
      presentable_installations = presentable_installations.substr(
        0,
        presentable_installations.length - 2
      );
    }
  }

  const gas_inst = rawData['gas_installations'];
  if (gas_inst) {
    presentable_installations ? (presentable_installations += '<br>') : '';
    for (const g_installation of Object.keys(gas_inst)) {
      switch (g_installation) {
        case 'gas_cooker':
        case 'cooker_gas_rating':
          gas_inst[g_installation]
            ? (presentable_installations += 'Gasherd, ')
            : '';
          break;
        case 'terrace_connection':
          gas_inst[g_installation]
            ? (presentable_installations += 'Terrassenanschluss, ')
            : '';
          break;
        case 'gas_heating':
          gas_inst[g_installation]
            ? (presentable_installations += 'Gasheizung, ')
            : '';
          break;
        case 'selected_heating':
          switch (gas_inst[g_installation]) {
            case 'central_heating_with_hot_water':
              presentable_installations += 'Zentralheizung mit Warmwasser, ';
              break;
            case 'central_heating_without_hot_water':
              presentable_installations += 'Zentralheizung ohne Warmwasser, ';
              break;
            case 'self_contained_central_heating_with_hot_water':
              presentable_installations += 'Gasetagenheizung mit Warmwasser, ';
              break;
            case 'self_contained_central_heating_without_hot_water':
              presentable_installations += 'Gasetagenheizung ohne Warmwasser, ';
              break;
            default:
              break;
          }
          break;
        case 'gas_heating_hot_water':
          gas_inst[g_installation]
            ? (presentable_installations += 'Gasheizung mit Warmwasser, ')
            : '';
          break;
      }
    }
    if (presentable_installations.endsWith(', ')) {
      presentable_installations = presentable_installations.substr(
        0,
        presentable_installations.length - 2
      );
    }
  }

  return presentable_installations;
}
