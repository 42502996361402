import axios from 'axios';

const url =
  'https://p7pln0qega.execute-api.eu-central-1.amazonaws.com/dev/upload_urls';

export const requestSignedUrl = (fileNames) => {
  const body = {
    files: fileNames,
  };

  return axios.post(url, JSON.stringify(body));
};

export const uploadFile = (signedUrl, file) => {
  const instance = axios.create({});
  return instance.put(signedUrl, file);
};
