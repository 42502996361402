import { render, staticRenderFns } from "./FeedInSystemsPage.vue?vue&type=template&id=1b4b559c&scoped=true"
import script from "./FeedInSystemsPage.vue?vue&type=script&lang=js"
export * from "./FeedInSystemsPage.vue?vue&type=script&lang=js"
import style0 from "./FeedInSystemsPage.vue?vue&type=style&index=0&id=1b4b559c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b4b559c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VIcon})
